import React, { useCallback, useState } from "react";
import {
  CommonModal,
  CommonSearch,
  CommonTable,
  CommonTextField,
} from "../../../common";
import "./styles.scss";
import {
  BIDS_DETAIL,
  BID_STATUS_KEYS,
  BRANCH_TABlE_DATA,
  DATE_FORMAT1,
  EDIT_BID_ROUTE,
} from "../../../../constants";
import {
  getPaginationQuery,
  replaceValInString,
  toastAlert,
} from "../../../../utils";
import { useNavigate } from "react-router-dom";
import CommonThreeDotMenu from "../../../common/CommonThreeDotMenu";
import { Space } from "antd";
import { Images } from "../../../../theme";
import moment from "moment";
import { useSelector } from "react-redux";
import CancelBidModal from "../../bidDetaild/cancelBidModal";
import { updateBidRequest } from "../../../../redux/slicers/bid";
import { CustomDispatch } from "../../../../helpers";

const BidsTable = ({
  bids,
  pagination,
  handleGetMyBids,
  handleGetMyBidsWithSearch,
  bidsLoader,
}) => {
  const [updateBid, loading] = CustomDispatch(updateBidRequest);
  const [deleteModalPreview, setDeleteModalPreview] = useState({
    id: null,
    preview: false,
  });
  const navigate = useNavigate();
  const { data: userData, isSeller } = useSelector((state) => state?.user);

  const renderServiceColumn = (text) => (
    <CommonTextField text={text} className={"light-text"} fontWeight={700} />
  );

  const renderOrganizationColumn = (_, _record) => (
    <CommonTextField
      text={_record?.companyDetail?.companyName}
      className={"light-text"}
      fontWeight={700}
    />
  );

  const renderActionColumn = (_, data) => (
    <Space>
      <div
        className={`statusButton ${
          data.status == "Open"
            ? "statusButton_open"
            : data?.status == "Awarded"
            ? "statusButton_awarded"
            : data?.status == "Closed"
            ? "statusButton_closed"
            : data?.status == "Terminated"
            ? "statusButton_closed"
            : "statusButton_open"
        }`}
      >
        <CommonTextField
          text={data.status}
          className={"text-11"}
          fontWeight={700}
          textTransform="capitalize"
        />
      </div>
      {(!isSeller && data?.status == BID_STATUS_KEYS.open ) ? (
        <CommonThreeDotMenu
          onEdit={() =>
            navigate(replaceValInString(EDIT_BID_ROUTE, { ":id": data.id }))
          }
          onDelete={() => setDeleteModalPreview({ preview: true, id: data.id })}
        />
      ) : (
        <CommonThreeDotMenu disabled={true}
        />
      )}
    </Space>
  );

  const renderNameColumn = (text, _record) => (
    <CommonTextField
      text={text}
      fontWeight={700}
      className="c-p"
      onClick={() =>
        navigate(replaceValInString(BIDS_DETAIL, { ":id": _record?.id }))
      }
    />
  );

  const columns = [
    {
      title: "Bid Name",
      dataIndex: "displayName",
      key: "displayName",
      render: renderNameColumn,
      sorter: {
        compare: (a, b) => a.displayName.localeCompare(b.displayName),
        multiple: 1,
      },
      sortIcon: ({ sortOrder }) =>
        sortOrder ? (
          sortOrder === "ascend" ? (
            <Images.SortDsc />
          ) : (
            <></>
          )
        ) : (
          <Images.SortAsc />
        ),
    },
    {
      title: "Opening Date",
      dataIndex: "openingDate",
      key: "openingDate",
      render: (date) => {
        return renderServiceColumn(moment(date).format(DATE_FORMAT1));
      },
      sorter: {
        compare: (a, b) => new Date(a.openingDate) - new Date(b.openingDate),
        multiple: 1,
      },
      sortIcon: ({ sortOrder }) =>
        sortOrder ? (
          sortOrder === "ascend" ? (
            <Images.SortDsc />
          ) : (
            <></>
          )
        ) : (
          <Images.SortAsc />
        ),
    },
    {
      title: "Closing Date",
      dataIndex: "closingDate",
      key: "closingDate",
      render: (date) => {
        return renderServiceColumn(moment(date).format(DATE_FORMAT1));
      },
      sorter: {
        compare: (a, b) => new Date(a.closingDate) - new Date(b.closingDate),
        multiple: 1,
      },
      sortIcon: ({ sortOrder }) =>
        sortOrder ? (
          sortOrder === "ascend" ? (
            <Images.SortDsc />
          ) : (
            <></>
          )
        ) : (
          <Images.SortAsc />
        ),
    },
    {
      title: "Organization",
      dataIndex: "organization",
      key: "organization",
      render: renderOrganizationColumn,
      sorter: {
        compare: (a, b) =>
          a.companyDetail?.companyName.localeCompare(
            b.companyDetail?.companyName
          ),
        multiple: 1,
      },
      sortIcon: ({ sortOrder }) =>
        sortOrder ? (
          sortOrder === "ascend" ? (
            <Images.SortDsc />
          ) : (
            <></>
          )
        ) : (
          <Images.SortAsc />
        ),
    },
    {
      title: "Status",
      key: "action",
      width: 150,
      align: "center",
      render: renderActionColumn,
    },
  ];

  const handleSetPage = (page) => {
    handleGetMyBids(getPaginationQuery(page));
  };

  const handleCancelBid = (val) => {
    const payload = {
      data: {
        status: BID_STATUS_KEYS.cancelled,
        cancellation_reason: val?.cancelReason ?? "",
      },
    };

    updateBid({
      payload,
      pathParams: deleteModalPreview.id,
      logic: () => {
        handleGetMyBidsWithSearch();
        setDeleteModalPreview({ preview: false, id: null });
        toastAlert("Bid cancelled successfully.");
      },
    });
  };

  return (
    <div>
      <CommonTable
        columns={columns}
        dataSource={bids}
        pagination
        total={pagination?.total}
        handleSetPage={handleSetPage}
        loading={bidsLoader}
      />

      <CancelBidModal
        loading={loading}
        handleConfirm={handleCancelBid}
        preview={deleteModalPreview.preview}
        handleClose={() =>
          setDeleteModalPreview({ preview: !deleteModalPreview.preview })
        }
      />
    </div>
  );
};

export default BidsTable;
