import React from "react";
import { Col, Form, Modal, Row } from "antd";
import "./styles.scss";
import { Images } from "../../../../theme";
import { CommonButton, CommonInputField } from "../../../common";
import { creditCardValidator, inputFieldRule } from "../../../../utils";

const AddCardModal = ({ handleClose, handleFinish, preview }) => {
  return (
    <Modal
      destroyOnClose
      open={preview}
      centered
      footer={null}
      width={636}
      className="add-card-modal"
      closeIcon={false}
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={handleClose}>
            <Images.CloseIcon />
          </div>
          <div className="title">
            <h3>Add Card</h3>
          </div>
          <Form onFinish={handleFinish} className="payment-form">
            <Row gutter={[12, 0]}>
              <Col xs={24} sm={12}>
                <CommonInputField
                  placeholder={"First Name"}
                  rules={inputFieldRule({
                    name: "First name",
                    isMax: true,
                    max: 30,
                  })}
                  name="firstName"
                />
              </Col>
              <Col xs={24} sm={12}>
                <CommonInputField
                  placeholder={"Last Name"}
                  rules={inputFieldRule({
                    name: "Last name",
                    isMax: true,
                    max: 30,
                  })}
                  name="lastName"
                />
              </Col>
              <Col xs={24} sm={12}>
                <CommonInputField
                  placeholder={"Card Number"}
                  rules={inputFieldRule({
                    name: "Card number",
                    isMax: true,
                    max: 16,
                    min: 16,
                    isMin: true,
                    // isCardNumber: true,
                  })}
                  name="cardNumber"
                  onKeyDown={(e) => creditCardValidator(e, "card", 16)}
                />
              </Col>
              <Col xs={24} sm={12}>
                <CommonInputField
                  placeholder={"Expiration"}
                  rules={inputFieldRule({
                    name: "Expiration date",
                    // isMax: true,
                    // max: 7,
                    // isCardExpiry: true,
                  })}
                  name="cardExpiration"
                  onKeyDown={(e) => creditCardValidator(e, "date")}
                />
              </Col>
              <Col xs={24} sm={12}>
                <CommonInputField
                  placeholder={"CVV"}
                  rules={inputFieldRule({
                    name: "CVV",
                    isMax: true,
                    max: 3,
                    isNumberOnly: true,
                  })}
                  name="cvv"
                  onKeyDown={(e) => creditCardValidator(e, "card", 3)}
                />
              </Col>
              <Col xs={24} sm={12}>
                <CommonInputField
                  placeholder={"Postal Code"}
                  rules={inputFieldRule({
                    name: "Postal code",
                    isAlphabetsAndNumber: true,
                    isMax: true,
                    max: 20,
                  })}
                  name="postalCode"
                />
              </Col>
              <Col span={24}>
                <CommonButton
                  text={"Add Card"}
                  width="100%"
                  borderRadius={"16px"}
                  htmlType="submit"
                  height={"56px"}
                />
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default AddCardModal;
