import { Carousel, Form } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { AuthCarousel } from "../../components";
import {
  CommonButton,
  CommonInputField,
  CommonPasswordInput,
  CommonTextField,
} from "../../components/common";
import { FORGOT_PASSWORD_ROUTE, SIGN_UP_ROUTE } from "../../constants";
import { CustomDispatch } from "../../helpers";
import { loginRequest } from "../../redux/slicers/user";
import { Images } from "../../theme";
import { inputFieldRule } from "../../utils";
import "./auth.scss";

const Login = () => {
  const [form] = Form.useForm();

  //CUSTOM DISPATCH
  const [userLoginRequest, loginLoader] = CustomDispatch(loginRequest);

  //HANDLERS
  const handleLogin = (vals) => {
    const isSeller = vals.email.includes("seller");
    userLoginRequest({
      payload: { identifier: vals.email, password: vals.password },
      otherkeys: { isSignUp: false },
    });
  };
  return (
    <div className="auth-box">
      <div className="login-container">
        <div className="login-wrapper">
          <div className="login-form-wrapper">
            <CommonTextField
              text={"Welcome back"}
              fontSize="39px"
              fontWeight={"700"}
              color="#031946"
              mb={"12px"}
              whiteSpace="nowrap"
            />
            <CommonTextField
              text={
                "We've missed you! Please sign in to catch up on what you've missed"
              }
              fontSize="14px"
              fontWeight={"400"}
              color="#465E7D"
              mb={"30px"}
            />
            <Form form={form} className="login-form" onFinish={handleLogin}>
              <CommonInputField
                placeholder={"Enter your email"}
                prefix={<Images.EmailIcon />}
                rules={inputFieldRule({
                  name: "Email",
                  isEmail: true,
                  isMax: true,
                  max: 100,
                })}
                name="email"
              />
              <CommonPasswordInput
                placeholder={"Enter your password"}
                prefix={<Images.PasswordIcon />}
                name="password"
                rules={inputFieldRule({
                  name: "Password",
                  isWhiteSpace: false,
                })}
              />
              <Link to={FORGOT_PASSWORD_ROUTE} className="forgot-pass-link">
                Forgot Password
              </Link>
              <CommonButton
                text={"Log in"}
                fontSize="18px"
                fontWeight={"400"}
                height="56px"
                htmlType="submit"
                loading={loginLoader}
              />
            </Form>
          </div>
          <span className="signup-prompt">
            Don't have an account yet? <Link to={SIGN_UP_ROUTE}>Sign up</Link>
          </span>
        </div>
      </div>
      <div className="auth-image-wrapper">
        <img src={Images.LoginImage} alt="Login Banner" />
        <AuthCarousel />
      </div>
    </div>
  );
};
export default Login;
