import { Col, Row } from "antd";
import React from "react";
import { CommonButton, CommonHeading, CommonTextField } from "../../../common";
import { Images } from "../../../../theme";
import "./styles.scss";

const points = [
  "- Construction and Infrastructure",
  "- Information Technology",
  "- Professional Services",
  "- Healthcare and Medical Supplies",
  "- Office Supplies and Equipment",
  "- Transportation and Logistics",
  "- Environmental Services",
  "- Marketing and Advertising"
];

const BidOpportunity = () => {
  return (
    <div className="container">
      <Row className="bid-oppurtunity-parent">
        <Col md={24} lg={12} className="left-side">
          <img src={Images.bidOpportunity} />
        </Col>
        <Col md={24} lg={12} className="right-side">
          <CommonHeading
            color={"#021349"}
            text="BID OPPORTUNITIES"
            fontFamily={"seventyTwoBold"}
            // lineHeight="63px"
            className={"headingWordBreak"}
            mb={"10px"}
          />
          <CommonTextField
            color="#465E7D"
            fontFamily="seventyTwoLight"
            fontSize={"16px"}
            lineHeight="24px"
            text="Discover a World of Possibilities."
            mb={"30px"}
          />
          <CommonTextField
            color="#465E7D"
            fontFamily="seventyTwoLight"
            fontSize={"16px"}
            lineHeight="24px"
            mb={"23px"}
            text={
              "As we connect you with a wide array of bid opportunities, whether you’re looking for construction projects, IT services, office supplies, or specialized consulting, our platform offers a diverse range of bids to match your needs."
            }
          />
          <CommonTextField
            color="#465E7D"
            fontFamily="seventyTwoLight"
            fontSize={"16px"}
            lineHeight="24px"
            mb={"23px"}
            text={"Explore bid opportunities across various categories:"}
          />

          {points?.map((content) => (
            <CommonTextField
              color="#465E7D"
              fontFamily="seventyTwoLight"
              fontSize={"16px"}
              lineHeight="24px"
              mb={"7px"}
              text={content}
            />
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default BidOpportunity;
