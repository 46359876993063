import { Carousel } from "antd";
import React from "react";
import { CommonTextField } from "../../common";
import "./styles.scss";

function AuthCarousel({ items = [1, 2, 3, 4] }) {
  return (
    <div className="login-messages">
      <div className="card-background"></div>

      <Carousel autoplay draggable={false}>
        {items.map((item, index) => (
          <div key={index}>
            <CommonTextField
              text={"Login Now"}
              color="#fff"
              fontSize={"24px"}
              fontWeight="600"
              mb={"15px"}
            />
            <CommonTextField
              text={
                "The digital gateway to personalized experiences, ensuring security and access in our interconnected world."
              }
              color="#fff"
              fontWeight={"400"}
              fontSize="16px"
              mb={"20px"}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default AuthCarousel;
