import React from "react";
import { useLocation } from "react-router-dom";
import { AuthHeader, Header } from "../../components";
import "./styles.scss";
function AuthSharedLayout({ children }) {
  //CONST VALS
  const { pathname } = useLocation();
  console.log(pathname);
  return (
    <section
      className={`auth-wrapper ${pathname === "/signup" ? "signup-auth" : ""}`}
    >
      <AuthHeader />
      {children}
    </section>
  );
}

export default AuthSharedLayout;
