import React from "react";
import { useState } from "react";
import { ALERT_TYPES } from "../../../constants";
import { CustomDispatch } from "../../../helpers";
import { uploadImageRequest } from "../../../redux/slicers/user";
import { Images } from "../../../theme";
import { imageValidation, toastAlert } from "../../../utils";
import "./styles.scss";
function ImageUploader({
  setImage,
  setPreviewImage,
  isIcon,
  iconClassName,
  isButton = false,
  defaultImage = null,
  setImageId,
  setLoader = () => {},
}) {
  //STATES
  const [image, setImg] = useState(defaultImage);
  //CUSTOM DISPATCH
  const [uploadImage] = CustomDispatch(uploadImageRequest);
  //HANDLERS
  const setErrorToggle = (error) => {
    toastAlert(error, ALERT_TYPES.ERROR);
  };
  const handleChange = (e) => {
    // if (isLoading) {
    //   return;
    // }
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImg(reader.result);
      };
      reader.readAsDataURL(file);
    }
    if (file) {
      if (imageValidation(file, setErrorToggle)) {
        const data = new FormData();
        data.append("files", file);
        setLoader(true);
        uploadImage({
          payload: data,
          callback: () => {
            setLoader(false);
          },
          logic(res) {
            setImageId(res?.[0]?.id);
          },
        });
      }
    }
  };
  return isButton ? (
    <div className="upload-btn-wrapper">
      {image ? (
        <img src={image} />
      ) : (
        <div className="image-uploader">
          <Images.Camera />
        </div>
      )}
      <div className="upload-btn">
        <input
          type="file"
          onChange={handleChange}
          value=""
          accept=".jpg, .jpeg, .png"
        />
        Upload Image
      </div>
    </div>
  ) : (
    <div className="image-uploader">
      <input
        type="file"
        onChange={handleChange}
        value=""
        accept=".jpg, .jpeg, .png"
      />
      {image ? <img src={image} /> : <Images.Camera />}
    </div>
  );
}

export default ImageUploader;
