import { Col, Form, Row } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ALERT_TYPES,
  BID_QUERY_POPULATE_PARAMS,
  BID_STATUS,
  BID_SUBMISSION_STATUS,
  DASHBOARD_ROUTE,
  DATE_RULE,
  ELECTRONIC_AUCTIONS,
  ONLINE_SUBMISSIONS,
  PUBLIC_OPENING,
  multiEmailTag,
} from "../../../constants";
import { inputFieldRule, toastAlert } from "../../../utils";
import {
  CommonButton,
  CommonDatePicker,
  CommonInputField,
  CommonModal,
  CommonSelect,
  CommonTextAreaField,
  CommonTextField,
  DocUploader,
} from "../../common";
import { connect, useSelector } from "react-redux";
import { CustomDispatch } from "../../../helpers";
import {
  createBidRequest,
  getBidDetailRequest,
  updateBidRequest,
} from "../../../redux/slicers/bid";
import dayjs from "dayjs";

function CreateBidForm() {
  //STATES
  const [inviteSupplier, setInviteSupplier] = useState(false);
  const [discard, setDiscard] = useState(false);
  const [docId, SetDocId] = useState([]);
  const [addendaId, SetAddendaId] = useState([]);
  const [documentsFileInfo, setDocumentsFileInfo] = useState([]);
  const [addendaFileInfo, setAddendaFileInfo] = useState([]);

  //CONST VALS
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();

  const [createBid, bidLoading] = CustomDispatch(createBidRequest);
  const [updateBid, updateBidLoading] = CustomDispatch(updateBidRequest);
  const [getBidDetail] = CustomDispatch(getBidDetailRequest);

  const mainCategory = Form.useWatch("categories", form);
  const user = useSelector((state) => state?.user?.data);

  const {
    classifications,
    types,
    categories,
    negotitationTypes,
    participantConditions,
    subCategories,
  } = useSelector((state) => state.bid);

  //HOOKS
  useEffect(() => {
    if (id) {
      getBidDetail({
        pathParams: id,
        queryParams: BID_QUERY_POPULATE_PARAMS,
        logic(res) {
          console.log("getBidDetail res ==>>>", res);
          if (isValidBidToEdit(res)) {
            setValuesHandler(res);
          }
        },
        error(err) {
          toastAlert("Bid not found");
          navigate(DASHBOARD_ROUTE);
        },
      });
    }
  }, [id]);

  const isValidBidToEdit = (data) => {
    if (data?.user?.id !== user?.id) {
      toastAlert("You are not allowed to edit this bid.", ALERT_TYPES.ERROR);
      navigate(DASHBOARD_ROUTE);
      return false;
    }

    return true;
  };

  const filteredSubCategories =
    mainCategory?.length !== 0
      ? subCategories?.filter((x) =>
          mainCategory?.includes(String(x?.parentId))
        )
      : [];

  console.log({ filteredSubCategories });
  //HANDLERS

  const handleSubmit = (val) => {
    if (bidLoading) return;

    if (docId.length === 0) {
      toastAlert("Documents are required.", "error");
      return;
    }

    if (addendaId.length === 0) {
      toastAlert("Addendas are required.", "error");
      return;
    }

    const payload = {
      documents: docId,
      addendas: addendaId,
      description: val?.bidDescription,
      title: val?.bidName,
      status: "Open",
      opening_date: val?.openingDate.format("YYYY-MM-DD"),
      closing_date: val?.closingDate.format("YYYY-MM-DD"),
      publicOpening: val?.publicOpening,
      expected_duration: Number(val?.expectedDuration),
      cp_email: val?.cp_email,
      cp_name: val?.cp_name,
      cp_phone_number: val?.cp_phone_number,
      cp_title: val?.cp_title,
      purchase_email: val?.purchase_email,
      purchase_employee: val?.purchase_employee,
      purchase_name: val?.purchase_name,
      purchase_phone: val?.purchase_phone,
      purchase_role: val?.purchase_role,
      submission_address: val?.submissionAddress,
      submission_status: val?.submissionStatus,
      submission_type: val?.submissionType,
      electronic_auction: val?.eAuctions,
      suppliers: val?.email?.map((t) => ({
        email: t,
      })),
      classification: { connect: [{ id: val.bidClassification }] },
      bid_type: { connect: [{ id: val?.bidType }] },
      categories: { connect: val?.categories?.map((item) => ({ id: item })) },
      sub_categories: {
        connect: val?.subCategories?.map((item) => ({ id: item })),
      },
      negotitation_type: { connect: [{ id: val?.negotitationType }] },
      participant_condition: { connect: [{ id: val?.participantConditions }] },
      user: { connect: [{ id: user?.id }] },
    };

    if (id) {
      handleUpdateBid(payload);
    } else {
      handleCreateBid(payload);
    }
  };
  const handleCreateBid = (payload) => {
    createBid({
      payload: {
        data: payload,
      },
      logic() {
        toastAlert("Bid created successfully", "success");
        navigate(DASHBOARD_ROUTE);
      },
    });
  };

  const handleUpdateBid = (payload) => {
    updateBid({
      payload: {
        data: payload,
      },
      pathParams: id,
      logic() {
        toastAlert("Bid updated successfully", "success");
        navigate(DASHBOARD_ROUTE);
      },
    });
  };

  const handleDiscard = () => {
    setDiscard(true);
  };

  const setValuesHandler = (data) => {
    const formFields = {
      bidDescription: data?.description,
      bidName: data?.bidName,
      // openingDate: moment(moment(data?.openingDate).format("YYYY-MM-DD")),
      // closingDate: moment(moment(data?.closingDate).format("YYYY-MM-DD")),
      openingDate: dayjs(data?.openingDate),
      closingDate: dayjs(data?.closingDate),
      cp_email: data?.cpEmail,
      cp_name: data?.cpName,
      cp_phone_number: data?.cpPhoneNumber,
      cp_title: data?.cpTitle,
      purchase_email: data?.purchaseEmail,
      purchase_employee: data?.purchaseEmployee,
      purchase_name: data?.purchaseName,
      purchase_phone: data?.purchasePhone,
      purchase_role: data?.purchaseRole,
      submissionAddress: data?.submissionAddress,
      submissionStatus: data?.submissionStatus,
      submissionType: data?.submissionType,
      eAuctions: data?.electronicAuction,
      email: data?.suppliers?.map((t) => t?.email),
      expectedDuration: `${data?.expectedDuration}`,
      publicOpening: data?.publicOpening,
      participantConditions: `${data?.participantCondition?.id}`,
      negotitationType: `${data?.negotiationType?.id}`,
      bidType: `${data?.bidType?.id}`,
      bidClassification: `${data?.classification?.id}`,
      categories: data?.category?.map((t) => `${t?.id}`),
      subCategories: data?.subCategory?.map((t) => `${t?.id}`),
    };

    form.setFieldsValue({
      ...formFields,
    });

    SetDocId(data?.documents?.map((t) => t?.id));
    SetAddendaId(data?.addendas?.map((t) => t?.id));

    setDocumentsFileInfo(data?.documents);
    setAddendaFileInfo(data?.addendas);
  };

  return (
    <>
      <Form form={form} onFinish={handleSubmit}>
        <Row gutter={[22, 0]}>
          <Col span={24}>
            <CommonSelect
              placeholder={"Bid Classification"}
              name="bidClassification"
              options={classifications}
              rules={inputFieldRule({
                name: "Bid classification",
              })}
            />
          </Col>
          <Col span={24}>
            <CommonSelect
              placeholder={"Bid Type"}
              name="bidType"
              options={types}
              rules={inputFieldRule({
                name: "Bid type",
              })}
            />
          </Col>
          {/* <Col span={12} xs={24} sm={24} md={12}>
            <CommonInputField
              placeholder={"Bid Number"}
              name="bidNumber"
              rules={inputFieldRule({
                name: "Bid number",
                isMax: true,
                max: 100,
              })}
            />
          </Col> */}
          <Col span={24}>
            <CommonInputField
              placeholder={"Bid Name"}
              name="bidName"
              rules={inputFieldRule({
                name: "Bid name",
                isMax: true,
                max: 100,
              })}
            />
          </Col>
          <Col span={24}>
            <CommonTextAreaField
              placeholder={"Description"}
              name="bidDescription"
              rules={inputFieldRule({
                name: "Description",
                isMax: true,
                max: 1000,
              })}
              height={"165px"}
            />
          </Col>
          {/* <Col span={24}>
            <CommonSelect
              placeholder={"Bid Status"}
              name="bidStatus"
              options={BID_STATUS}
              rules={inputFieldRule({
                name: "Bid status",
              })}
            />
          </Col> */}

          <Col span={24}></Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonDatePicker
              placeholder={"Bid Opening Date"}
              name="openingDate"
              rules={[
                {
                  validator: (_, value) => {
                    console.log({ _ });
                    return DATE_RULE(_, value);
                  },
                },
              ]}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonDatePicker
              placeholder={"Bid Closing Date"}
              name="closingDate"
              rules={[
                ({ getFieldValue }) => ({
                  validator: (_, value) => {
                    return DATE_RULE(_, value, getFieldValue, "openingDate");
                  },
                }),
              ]}
            />
          </Col>
          <Col span={24}>
            <CommonSelect
              placeholder={"Electronic Auctions"}
              name="eAuctions"
              options={ELECTRONIC_AUCTIONS}
              rules={inputFieldRule({
                name: "Electronic auctions",
              })}
            />
          </Col>

          <Col span={12} xs={24} sm={24} md={12}>
            <CommonInputField
              placeholder={"Expected Duration (In Months)"}
              name="expectedDuration"
              rules={inputFieldRule({
                name: "Expected duration",
                isNumberOnly: true,
              })}
            />
          </Col>

          <Col span={12} xs={24} sm={24} md={12}>
            <CommonSelect
              placeholder={"Submission Type"}
              name="submissionType"
              options={ONLINE_SUBMISSIONS}
              rules={inputFieldRule({
                name: "Submission type",
              })}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonSelect
              placeholder={"Submission Status"}
              name="submissionStatus"
              options={BID_SUBMISSION_STATUS}
              rules={inputFieldRule({
                name: "Submission Status",
              })}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonInputField
              placeholder={"Submission Address"}
              name="submissionAddress"
              rules={inputFieldRule({
                name: "Submission address",
                isMax: true,
                max: 100,
              })}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonSelect
              onSelect={(res) =>
                res === PUBLIC_OPENING[1].value
                  ? setInviteSupplier(true)
                  : setInviteSupplier(false)
              }
              placeholder={"Public Opening"}
              name="publicOpening"
              options={PUBLIC_OPENING}
              rules={inputFieldRule({
                name: "Public opening",
              })}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonSelect
              placeholder={"Negotiation Type"}
              name="negotitationType"
              options={negotitationTypes}
              rules={inputFieldRule({
                name: "Negotiation Type",
              })}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonSelect
              mode="multiple"
              placeholder={"Categories"}
              name="categories"
              options={categories}
              rules={inputFieldRule({
                name: "Categories",
                isRequired: false,
                isMultiSelect: true,
                isWhiteSpace: false,
              })}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonSelect
              mode="multiple"
              placeholder={"Sub Categories"}
              name="subCategories"
              options={filteredSubCategories}
              rules={inputFieldRule({
                name: "sub Categories",
                isRequired: false,
                isMultiSelect: true,
                isWhiteSpace: false,
              })}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonSelect
              placeholder={"Participant Conditions"}
              name="participantConditions"
              options={participantConditions}
              rules={inputFieldRule({
                name: "Participants Condition",
              })}
            />
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "22px",
            }}
          >
            <CommonTextField
              text={"Invite suppliers if job is close bid"}
              fontFamily="seventyTwoSemiBold"
              fontSize={"16px"}
            />
            {/* <CommonSwitch state={inviteSupplier} setState={setInviteSupplier} /> */}
          </Col>

          <Col span={24}>
            <CommonSelect
              open={false}
              mode="tags"
              placeholder={"Email Address"}
              name="email"
              rules={multiEmailTag(inviteSupplier)}
              disabled={!inviteSupplier}
            />
          </Col>

          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "22px",
            }}
          >
            <CommonTextField
              text={"Contact Person"}
              fontFamily="seventyTwoSemiBold"
              fontSize={"16px"}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonInputField
              placeholder={"Name"}
              name="cp_name"
              rules={inputFieldRule({
                name: "Name",
                isMax: true,
                max: 100,
              })}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonInputField
              placeholder={"Title"}
              name="cp_title"
              rules={inputFieldRule({
                name: "Title",
                isMax: true,
                max: 100,
              })}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonInputField
              placeholder={"Phone Number"}
              name="cp_phone_number"
              rules={inputFieldRule({
                name: "Phone number",
                isMax: true,
                isMin: true,
                max: 20,
                min: 7,
                isNumberOnly: true,
              })}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonInputField
              placeholder={"Email"}
              name="cp_email"
              rules={inputFieldRule({
                name: "Email",
                isMax: true,
                isEmail: true,
                max: 100,
              })}
            />
          </Col>

          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "22px",
            }}
          >
            <CommonTextField
              text={"Purchasing Contact / Representative"}
              fontFamily="seventyTwoSemiBold"
              fontSize={"16px"}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonInputField
              placeholder={"Employee"}
              name="purchase_employee"
              rules={inputFieldRule({
                name: "Employee",
                isMax: true,
                max: 100,
              })}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonInputField
              placeholder={"Name"}
              name="purchase_name"
              rules={inputFieldRule({
                name: "Name",
                isMax: true,
                max: 100,
              })}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonInputField
              placeholder={"Phone Number"}
              name="purchase_phone"
              rules={inputFieldRule({
                name: "Phone number",
                isMax: true,
                isMin: true,

                max: 20,
                min: 7,
                isNumberOnly: true,
              })}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonInputField
              placeholder={"Email"}
              name="purchase_email"
              rules={inputFieldRule({
                name: "Email",
                isMax: true,
                isEmail: true,
                max: 100,
              })}
            />
          </Col>

          <Col span={12} xs={24} sm={24} md={12}>
            <CommonInputField
              placeholder={"Role"}
              name="purchase_role"
              rules={inputFieldRule({
                name: "Role",
                isMax: true,
                max: 100,
              })}
            />
          </Col>

          {/* <Col span={24}>
            <CommonTextAreaField
              placeholder={"Description"}
              name="description"
              rules={inputFieldRule({
                name: "Description",
                isRequired: inviteSupplier,
              })}
              disabled={!inviteSupplier}
              height={"165px"}
            />
          </Col>
          <Col span={24}>
            <CommonTextAreaField
              placeholder={"Bid document access"}
              name="docAccess"
              rules={inputFieldRule({
                name: "Bid Document Access",
                isRequired: inviteSupplier,
              })}
              disabled={!inviteSupplier}
              height={"165px"}
            />
          </Col> */}
          {/* <Col
            span={24}
            style={{
              marginBottom: "22px",
            }}
          >
            <CommonTextField
              text={"Company Tracker"}
              fontFamily="seventyTwoSemiBold"
              fontSize={"16px"}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonInputField
              placeholder={"Company Name"}
              name="companyName"
              rules={inputFieldRule({
                name: "Company name",
                isMax: true,
                max: 100,
              })}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonInputField
              placeholder={"Contact"}
              name="companyContact"
              rules={inputFieldRule({
                name: "Contact",
                isMax: true,
                max: 100,
              })}
            />
          </Col> */}

          {/* <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "22px",
            }}
          >
            <CommonTextField
              text={"Site Meetings"}
              fontFamily="seventyTwoSemiBold"
              fontSize={"16px"}
            />
            <CommonSwitch state={siteMeetings} setState={setSiteMeetings} />
          </Col>
          <Col span={24}>
            <CommonInputField
              placeholder={"Meeting Location"}
              name="meetingLocation"
              rules={inputFieldRule({
                name: "Meeting location",
                isMax: true,
                max: 100,
                isRequired: siteMeetings,
              })}
              disabled={!siteMeetings}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonDatePicker
              placeholder={"Date"}
              name="date"
              rules={[
                {
                  validator: (_, value) => {
                    return DATE_RULE(_, value);
                  },
                },
              ]}
              disabled={!siteMeetings}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonTimePicker
              placeholder={"Time"}
              name="time"
              rules={[
                {
                  validator: (_, value) => {
                    return DATE_RULE(_, value);
                  },
                },
              ]}
              disabled={!siteMeetings}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonInputField
              placeholder={"Mandatory"}
              name="mandatory"
              rules={inputFieldRule({
                name: "Mandatory",
                isMax: true,
                max: 100,
                isRequired: siteMeetings,
              })}
              disabled={!siteMeetings}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonInputField
              placeholder={"Meeting Documents"}
              name="meetingDocuments"
              rules={inputFieldRule({
                name: "Meeting documents",
                isMax: true,
                max: 100,
                isRequired: siteMeetings,
              })}
              disabled={!siteMeetings}
            />
          </Col>
          <Col span={24}>
            <CommonTextAreaField
              placeholder={"Description"}
              name="meetingDescription"
              rules={inputFieldRule({
                name: "Description",
                isRequired: siteMeetings,
              })}
              disabled={!siteMeetings}
              height={"165px"}
            />
          </Col> */}
          <Col span={24} style={{ marginBottom: "30px" }}>
            <Form.Item name={"docUploader"}>
              <DocUploader
                docType="Doc"
                maxSize={10}
                resetField={() => form.resetFields(["docUploader"])}
                callback={(res) => SetDocId((pre) => [...pre, res])}
                propFiles={documentsFileInfo}
                handleRemoveFile={(id) => {
                  SetDocId((pre) => pre.filter((x) => x !== id));
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <DocUploader
              docType="Addenda"
              maxSize={10}
              callback={(res) => SetAddendaId((pre) => [...pre, res])}
              propFiles={addendaFileInfo}
              handleRemoveFile={(id) => {
                SetAddendaId((pre) => pre.filter((x) => x !== id));
              }}
            />
          </Col>
        </Row>
        <div className="create-btns-wrapper">
          <CommonButton
            text={"Discard"}
            background="#031946"
            fontFamily="seventyTwoBold"
            fontSize="18px"
            onClick={handleDiscard}
          />

          <CommonButton
            text={`${id ? "Edit" : "Create"} Job`}
            fontFamily="seventyTwoBold"
            fontSize="18px"
            htmlType="submit"
            disabled={bidLoading || updateBidLoading}
            loading={bidLoading || updateBidLoading}
          />
        </div>
      </Form>
      <CommonModal
        isModalVisible={discard}
        setIsModalVisible={setDiscard}
        discription="Do you want to discard your changes?"
        onConfirm={() => {
          setDiscard(false);
          navigate(DASHBOARD_ROUTE);
        }}
      ></CommonModal>
    </>
  );
}

export default CreateBidForm;
