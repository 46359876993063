import React, { useEffect, useState } from "react";
import "../styles.scss";
import {
  CommonButton,
  CommonInputField,
  CommonTable,
  CommonTextField,
} from "../../../../../../components/common";
import { Form, Col, Row, Space } from "antd";
import { TABLE_DATA, TABLE_DATA_STEP_1 } from "../../../../../../constants";
import {
  creditCardValidator,
  inputFieldRule,
  toastAlert,
} from "../../../../../../utils";
import { uniqueId } from "lodash";

const STATIC_INFO = {
  description: "Pricing Information",
  totalColumns: 3,
  requiredColumns: 3,
  optionalColumns: "0",
  mandatory: "Yes",
};

function Step1({
  submittedBidDetail,
  bidDetail,
  isHidden,
  handleNextStep,
  setPricingInfo = () => {},
}) {
  //CONST VALS
  const [step1Form] = Form.useForm();
  const [pricingInformation, setPricingInformation] = useState([
    {
      description: "Pricing Information",
      totalColumns: 3,
      requiredColumns: 3,
      optionalColumns: "0",
      mandatory: "Yes",
      total: 0,
      price2024: 0,
      price2025: 0,
      price2026: 0,
    },
  ]);

  const [priceData, setPriceData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (pricingInformation?.[currentIndex]?.description) {
      step1Form.setFieldsValue({
        price2024: pricingInformation[currentIndex].price2024,
        price2025: pricingInformation[currentIndex].price2025,
        price2026: pricingInformation[currentIndex].price2026,
      });
    } else {
      step1Form.resetFields();
    }
  }, [currentIndex]);

  useEffect(() => {
    if (submittedBidDetail?.pricing?.length > 0) {
      const pData = submittedBidDetail?.pricing.map((item) => ({
        ...STATIC_INFO,
        ...item,
        price2024: `${item?.price2024}`,
        price2025: `${item?.price2025}`,
        price2026: `${item?.price2026}`,
      }));

      setPricingInformation(pData);
      setPriceData(pData);
      setCurrentIndex(pData?.length);
    }
  }, [submittedBidDetail]);

  const renderTableText = (text) => (
    <CommonTextField
      text={text}
      fontFamily={"seventyTwoRegular"}
      fontSize={"12px"}
      lineHeight={"13.8px"}
      color={"rgba(2, 19, 73, 1)"}
    />
  );
  const renderFormItem = (text, index) => {
    return (
      <CommonInputField
        placeholder={"Type"}
        name={text}
        type="number"
        rules={inputFieldRule({
          isRequired: true,
          isNumberOnly: true,
          name: text,
        })}
      />
    );
  };

  const renderTableTotal = (text) => (
    <Space>
      <CommonTextField
        text={text}
        fontFamily={"seventyTwoRegular"}
        fontSize={"12px"}
        lineHeight={"13.8px"}
        color={"rgba(2, 19, 73, 1)"}
      />
    </Space>
  );

  const renderButton = (_, __, index) => {
    let text = "Add";
    console.log(
      index,
      currentIndex,
      pricingInformation[index]?.price2024,
      pricingInformation[index]?.total
    );
    if (pricingInformation[currentIndex]?.price2024) {
      text = "Update";
    }
    return <CommonButton text={text} onClick={() => step1Form?.submit()} />;
  };
  const renderActions = (_, record, index) => {
    return (
      <Space>
        <CommonButton text={"Edit"} onClick={() => setCurrentIndex(index)} />
        <CommonButton
          text={"Delete"}
          disabled={index == 0}
          onClick={() => handleDelete(index)}
        />
      </Space>
    );
  };

  const columns = [
    {
      title: "Table",
      dataIndex: "description",
      key: "description",
      render: renderTableText,
    },
    {
      title: "# of Columns",
      dataIndex: "totalColumns",
      key: "totalColumns",
      align: "center",
      render: renderTableText,
    },
    {
      title: "# of Required Columns",
      dataIndex: "requiredColumns",
      key: "requiredColumns",
      align: "center",
      render: renderTableText,
    },
    {
      title: "# of Optional Columns",
      dataIndex: "optionalColumns",
      key: "optionalColumns",
      align: "center",
      render: renderTableText,
    },
    {
      title: "Mandatory",
      dataIndex: "mandatory",
      key: "mandatory",
      align: "center",
      render: renderTableText,
    },
    {
      title: "Sub Total",
      dataIndex: "total",
      key: "total",
      align: "center",
      render: renderTableTotal,
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      align: "center",
      render: renderActions,
    },
  ];
  const pricingInformationColumn = [
    {
      title: "Descriptions",
      dataIndex: "description",
      key: "table",
      render: renderTableText,
      width: "400px",
    },
    {
      title: "Hourly rate 2024",
      dataIndex: "hr2024",
      key: "noOfRows",
      align: "center",
      render: renderFormItem,
      width: "200px",
    },
    {
      title: "Hourly rate 2025",
      dataIndex: "hr2025",
      key: "noOfRequiredRows",
      align: "center",
      render: renderFormItem,
      width: "200px",
    },
    {
      title: "Hourly rate 2026",
      dataIndex: "hr2026",
      key: "noOfOptionalRows",
      align: "center",
      render: renderFormItem,
      width: "200px",
    },
    {
      title: "Action",
      dataIndex: "hr2027",
      key: "action",
      align: "center",
      render: renderButton,
      width: "200px",
    },
  ];

  const handleSubmitForm = () => {
    const values = step1Form.getFieldsValue();
    const total =
      Number(values?.price2024) +
      Number(values?.price2025) +
      Number(values?.price2026);
    const newPricingInformation = [...pricingInformation];
    newPricingInformation[currentIndex] = {
      ...STATIC_INFO,
      ...newPricingInformation[currentIndex],
      ...values,
      total,
    };

    setPriceData(newPricingInformation);
    setPricingInformation(newPricingInformation);
    setCurrentIndex(newPricingInformation?.length);
  };

  const handleDelete = (index) => {
    const newPricingInformation = [...pricingInformation];
    newPricingInformation.splice(index, 1);
    setPricingInformation(newPricingInformation);
    setPriceData(newPricingInformation);

    setCurrentIndex(newPricingInformation?.length);
  };

  return (
    <div className={`${isHidden ? "hidden" : ""} `}>
      <Row>
        <Col lg={14}>
          <CommonTextField
            text={"Schedule of Prices"}
            color={"rgba(2, 19, 73, 1)"}
            fontSize={"20px"}
            lineHeight={"23px"}
            fontFamily={"seventyTwoSemiBold"}
          />
        </Col>

        <Col lg={22} style={{ marginTop: 15 }}>
          <CommonTextField
            text={
              "The Bidder hereby Bids and offers to enter into the Contract referred to and to supply and do all or any part of the Work which is set out or called for in this Bid, at the unit prices, and/or lump sums, hereinafter stated. HST is additional."
            }
            color={"rgba(2, 19, 73, 1)"}
            fontSize={"14px"}
            lineHeight={"20px"}
            fontFamily={"seventyTwoRegular"}
          />
          <CommonTextField
            text={"* Denotes a 'MANDATORY' field"}
            color={"rgba(2, 19, 73, 1)"}
            fontSize={"14px"}
            lineHeight={"20px"}
            fontFamily={"seventyTwoRegular"}
            mt={"20px"}
          />
          <CommonTextField
            text={
              "Do not enter $0.00 dollars unless you are providing the line item at zero dollars to the Owner (unless otherwise specified). If the line item and/or table is 'NON-MANDATORY' and you are not bidding on it, leave the table and/or line item blank.Do not enter a $0.00 dollar value."
            }
            color={"rgba(2, 19, 73, 1)"}
            fontSize={"14px"}
            lineHeight={"20px"}
            fontFamily={"seventyTwoRegular"}
            mt={"20px"}
          />
        </Col>
      </Row>

      <div className="tableWrapper">
        <CommonTable
          columns={columns}
          dataSource={pricingInformation}
          width={true}
        />
      </div>

      <CommonTextField
        text={"Pricing Information - Landscape Architects"}
        color={"rgba(2, 19, 73, 1)"}
        fontSize={"20px"}
        lineHeight={"23px"}
        fontFamily={"seventyTwoSemiBold"}
        mt={"20px"}
      />

      <div className="tableWrapper pricing-table-wrapper">
        <Form form={step1Form} onFinish={handleSubmitForm}>
          <CommonTable
            columns={pricingInformationColumn}
            dataSource={TABLE_DATA_STEP_1}
          />
        </Form>
      </div>

      <div className="buttonWrapper">
        {!submittedBidDetail?.id && (
          <CommonButton
            text={"Back"}
            classname={"button mr-10"}
            width="200px"
            height={"56px"}
            fontSize={"18px"}
            onClick={() => {
              handleNextStep(0);
            }}
          />
        )}

        <CommonButton
          text={"Continue"}
          classname={"button"}
          width="200px"
          height={"56px"}
          fontSize={"18px"}
          onClick={() => {
            if (priceData?.length == 0) {
              toastAlert("Please add atleast one pricing information", "error");
              return;
            }
            setPricingInfo(priceData);
            handleNextStep(2);
          }}
        />
      </div>
    </div>
  );
}

export default Step1;
