import _ from "lodash";

export const manipulateUserData = (data) => {
  if (_.isEmpty(data)) return {};
  let user = {};
  user["id"] = data?.user?.id ?? 0;
  user["userName"] = data?.user?.username ?? "";
  user["email"] = data?.user?.email ?? "";
  user["fullName"] = data?.user?.fullName ?? "";
  user["firstName"] = data?.user?.firstName ?? "";
  user["lastName"] = data?.user?.lastName ?? "";
  user["phone"] = data?.user?.phone ?? "";
  user["isBuyer"] = data?.user?.isBuyer ?? "";
  user["isActive"] = data?.user?.isActive ?? "";
  user["access_token"] = data?.jwt ?? "";
  user["notificationCount"] = data?.notification_count ?? 0;
  user["allowedJobs"] = data?.allowed_jobs ?? 0;
  user["connects"] = data?.connects ?? 0;

  return user;
};

export const manipulateCompanyData = (data) => {
  if (_.isEmpty(data)) return {};
  let company = {};
  company["id"] = data?.id ?? 0;
  company["name"] = data?.attributes?.companyName ?? "";
  company["type"] = data?.attributes?.companyType ?? "";
  company["industry"] = data?.attributes?.industry ?? "";
  company["contact"] = data?.attributes?.contact ?? "";
  company["address"] = data?.attributes?.address ?? "";
  company["description"] = data?.attributes?.description ?? "";
  company["companyLogo"] = data?.attributes?.companyLogo ?? "";

  return company;
};

export const manipulateUserProfileData = (data) => {
  if (_.isEmpty(data)) return {};
  let user = {};
  user["id"] = data?.id ?? 0;
  user["userName"] = data?.username ?? "";
  user["email"] = data?.email ?? "";
  user["fullName"] = data?.fullName ?? "";
  user["firstName"] = data?.firstName ?? "";
  user["lastName"] = data?.lastName ?? "";
  user["phone"] = data?.phone ?? "";
  user["isBuyer"] = data?.isBuyer ?? "";
  user["isActive"] = data?.isActive ?? "";
  user["passwordUpdated"] = data?.password_updated ?? "";
  user["notificationCount"] = data?.notification_count ?? 0;
  user["allowedJobs"] = data?.allowed_jobs ?? 0;
  user["connects"] = data?.connects ?? 0;

  return user;
};

export const manipulateCompanyProfileData = (data) => {
  if (_.isEmpty(data)) return {};
  let company = {};
  company["id"] = data?.id ?? 0;
  company["name"] = data?.companyName ?? "";
  company["type"] = data?.companyType ?? "";
  company["industry"] = data?.industry ?? "";
  company["contact"] = data?.contact ?? "";
  company["address"] = data?.address ?? "";
  company["description"] = data?.description ?? "";
  company["companyLogo"] = data?.companyLogo?.url ?? "";

  return company;
};

export const manipulateCompanyDetailData = (data) => {
  if (_.isEmpty(data)) return {};
  let company = {};
  company["id"] = data?.id ?? 0;
  const attributes = data?.attributes;
  company["name"] = attributes?.companyName ?? "";
  company["type"] = attributes?.companyType ?? "";
  company["industry"] = attributes?.industry ?? "";
  company["contact"] = attributes?.contact ?? "";
  company["address"] = attributes?.address ?? "";
  company["description"] = attributes?.description ?? "";
  company["companyLogo"] = attributes?.companyLogo?.data?.attributes?.url ?? "";

  return company;
};

export const manipulateProfileData = (data) => {
  if (_.isEmpty(data)) return {};
  let profile = {};
  profile["user"] = manipulateUserProfileData(data);
  profile["company"] = manipulateCompanyProfileData(data?.company_detail);

  return profile;
};

export const manipulateFeaturesList = (data) => {
  let features = [];
  data.forEach((item, index) => {
    features.push({ id: item?.id ?? 0, title: item?.feature_title ?? "" });
  });
  return features;
};

export const manipulatePackageData = (data) => {
  if (_.isEmpty(data)) return {};

  let packages = {};

  packages["id"] = data?.id ?? 0;
  packages["name"] = data?.attributes?.name ?? "";
  packages["amount"] = data?.attributes?.amount ?? "";
  packages["productId"] = data?.attributes?.productId ?? "";
  packages["priceId"] = data?.attributes?.priceId ?? "";
  packages["features"] = manipulateFeaturesList(data?.attributes?.Features);

  return packages;
};

export const manipulatePackagesList = (data) => {
  let packages = [];
  data.forEach((item, index) => {
    packages.push(manipulatePackageData(item));
  });
  return packages;
};

export const notificationListManipulator = (list) => {
  if (_.isEmpty(list)) return [];

  try {
    const payload = [];

    for (let item of list) {
      const attributes = item?.attributes;
      const notification = {
        id: item?.id ?? 0,
        title: attributes?.title ?? "",
        description: attributes?.description ?? "",
        notificationUrl: attributes?.notification_url ?? "",
        isRead: attributes?.is_read ?? false,
        createdAt: attributes?.createdAt ?? "",
        updatedAt: attributes?.updatedAt ?? "",
      };

      payload.push(notification);
    }

    return payload;
  } catch (error) {
    console.error("notificationListManipulator error ==>>>", error);
    return [];
  }
};
