import React, { useEffect, useState } from "react";
import { Col, Flex, Row } from "antd";
import {
  CommonButton,
  CommonHeading,
  CommonTextField,
} from "../../../components/common";
import { Images } from "../../../theme";
import "./styles.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import dataHandler from "../../../services/data-handler";
import { useSelector } from "react-redux";
import { getCurrentAccessToken, toastAlert } from "../../../utils";
import { ALERT_TYPES, SUBSCRIPTION_ROUTE } from "../../../constants";
import { BASE_URL } from "../../../config/web-service";
import { Loader } from "../../../components";

function ManageSubscription() {
  // State to hold plan data
  const [plans, setPlans] = useState([]);
  // const [trxref, setTrxref] = useState([]);
  // const [reference, setReference] = useState([]);
  const [currentPlan, setCurrentPlan] = useState({});
  const [searchParams] = useSearchParams();
  const user = useSelector((state) => state.user.data);
  const [isLoading, setIsLoading] = useState(true);
  const [packageId, setPackageId] = useState(null);
  //vdffhbvzne
  const trxref = searchParams.get("trxref");
  const reference = searchParams.get("reference");

  useEffect(() => {
    if (reference) {
      verifySubscription(reference);
    }
  }, [reference]);

  const navigate = useNavigate();
  // TODO convert to slicer
  async function verifySubscription(reference) {
    try {
      const token = getCurrentAccessToken();
      const response = await fetch(`${BASE_URL}api/verify-subscription`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          reference,
          userId: user.id,
          email: user.email,
        }),
      });
      const data = await response.json();
      if (!data) {
        toastAlert("Error fetching plans.", ALERT_TYPES.ERROR);
        return;
      }

      navigate(SUBSCRIPTION_ROUTE, {
        replace: true,
      });
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  }
  //TODO convert to slicer
  async function handleInitializeSubscription(plan) {
    try {
      setPackageId(plan?.plan_id);
      // TODO Add auth token as well
      const payload = {
        email: user.email,
        plan: plan.plan_id,
        subscription: plan.id,
        userId: user.id,
      };
      const response = await fetch(`${BASE_URL}api/initilize-subscription`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      setPackageId(null);
      if (!data.status) {
        throw new Error("Error fetching plans");
      }
      window.location.replace(data.data.authorization_url);
    } catch (error) {
      console.error("Error fetching plans:", error);
      setPackageId(null);
    }
  }
  // Fetch plans from the API
  useEffect(() => {
    console.log({ plan: "plans" });
    // TODO convert to slicer

    const fetchPlans = async () => {
      try {
        setIsLoading(true);
        const token = getCurrentAccessToken();
        const userType = user.isBuyer ? "Buyer" : "Seller";
        const response = await fetch(
          `${BASE_URL}api/fetch-plans?type=` + userType,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        // console.log({ data });
        // console.log({ user });
        if (!data.status) {
          throw new Error("Error fetching plans");
        }

        setPlans(data.data);
        const allCurrentPlans = data?.currenPlan?.filter(
          (item) => item?.isCancelled == false
        );
        setCurrentPlan(allCurrentPlans?.[0] || {});
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching plans:", error);
        setIsLoading(false);
      }
    };

    fetchPlans();
  }, []);

  console.log({ currentPlan });

  if (reference) {
    return <Loader />;
  }

  return (
    <div className="manage-sub-container">
      {isLoading && <Loader />}
      <Flex
        justify={"flex-start"}
        gap="8px"
        align={"center"}
        style={{ marginBottom: "46px" }}
      >
        <Images.LeftArrow className="c-p" onClick={() => navigate(-1)} />
        <Flex vertical justify={"center"} align="flex-start">
          <CommonHeading
            text={"Our Subscription"}
            fontFamily={"seventyTwoSemiBold"}
            fontSize={"34px"}
            color={"#021349"}
            lineHeight={"42px"}
          />
          <CommonTextField
            text={"Choose any subscription"}
            fontFamily={"seventyTwoRegular"}
            color="#465E7D"
            fontSize={"16px"}
            margin={"0 0 0 2px"}
          />
        </Flex>
      </Flex>
      <Row gutter={[23, 23]}>
        {plans.map((plan, index) => (
          <Col key={index} xs={24} lg={10} xl={9}>
            <div
              className={`package-card-wrapper ${
                plan?.plan_id == currentPlan?.subscription?.plan_id
                  ? "active"
                  : ""
              }`}
            >
              <div className="package-info">
                <CommonTextField
                  text={plan.name}
                  color={"#000"}
                  fontFamily={"seventyTwoSemiBold"}
                  fontSize={"24px"}
                  lineHeight={"28px"}
                  textAlign={"center"}
                />
                <CommonTextField
                  text={`${plan?.currency || "NGN"} ${plan.amount}/m`}
                  color={"#000"}
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"20px"}
                  lineHeight={"23px"}
                  textAlign={"center"}
                />
              </div>
              <Flex
                vertical
                align={"center"}
                justify={"flex-start"}
                className="package-benefits"
              >
                <Flex
                  gap={"12px"}
                  align={"center"}
                  justify={"flex-start"}
                  style={{ marginBottom: "17px" }}
                >
                  <Images.CheckMark />
                  <CommonTextField
                    text={`Jobs Allowed: ${plan.JobsAllowed}`}
                    fontFamily={"seventyTwoRegular"}
                    fontSize={"16px"}
                    color={"#000"}
                    lineHeight={"30px"}
                  />
                </Flex>
                <Flex
                  gap={"12px"}
                  align={"center"}
                  justify={"flex-start"}
                  style={{ marginBottom: "17px" }}
                >
                  <Images.CheckMark />
                  <CommonTextField
                    text={`Connects: ${plan.Connects}`}
                    fontFamily={"seventyTwoRegular"}
                    fontSize={"16px"}
                    color={"#000"}
                    lineHeight={"30px"}
                  />
                </Flex>
              </Flex>
              <CommonButton
                text={
                  plan?.plan_id == currentPlan?.subscription?.plan_id
                    ? "Subscribed"
                    : "Buy Now"
                }
                height={"39px"}
                disabled={plan?.plan_id == currentPlan?.subscription?.plan_id}
                loading={packageId === plan?.plan_id}
                onClick={() => handleInitializeSubscription(plan)}
              />
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default ManageSubscription;
