import React from "react";
import "./styles.scss";

export default function CommonTabs({
  buttonsList,
  state,
  setState,
  style,
  tabStyle,
  className,
  disabled = false,
}) {
  return (
    <div
      style={style ? style : {}}
      className={`buttons-container ${className} `}
    >
      {buttonsList?.map((a, i) => (
        <div
          key={i}
          onClick={() => !disabled && setState(a.id)}
          className={`flex-center trans ${state == a.id ? "active-btn" : ""}`}
          style={tabStyle ? tabStyle : {}}
        >
          <p>{a.text}</p>
          {a.subtext && <h6>{a.subtext}</h6>}
        </div>
      ))}
    </div>
  );
}
