import React from "react";
import { Col, Form, Modal, Row } from "antd";
import {
  CommonButton,
  CommonInputField,
  CommonTextAreaField,
  CommonTextField,
} from "../../../common";
import { Images } from "../../../../theme";
import "./styles.scss";
import { inputFieldRule } from "../../../../utils";

const ConfirmBidModal = ({ handleClose, preview, handleConfirm }) => {
  const [form] = Form.useForm();

  const handleFormFinish = (val) => {
    handleConfirm(val);
  };

  return (
    <Modal
      destroyOnClose
      open={preview}
      centered
      footer={null}
      width={633}
      className="confirm-bid-modal"
      closeIcon={false}
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={handleClose}>
            <Images.CloseIcon />
          </div>
          <div className="title">
            <h3>Mark as Completed</h3>
            <p>Are you sure job completed?</p>
          </div>

          <Form
            form={form}
            className="payment-form"
            onFinish={handleFormFinish}
          >
            <CommonInputField
              placeholder={"$150.00"}
              name="amount"
              rules={inputFieldRule({
                name: "Amount",
                isNumberOnly: true,
                isRequired: true,
              })}
            />
            <div className="modal-footer">
              <CommonButton
                text={"Completed"}
                borderRadius={"12px"}
                width={"200px"}
                height={"56px"}
                htmlType="submit"
              />
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmBidModal;
