import React from "react";
import "./styles.scss";

import { Col, Row } from "antd";
import { CommonHeading, CommonTextField } from "../../../components/common";
import { PublicFooter } from "../../../components";

const conditions = [
  {
    title: "Information Collection",
    text: "We collect information you provide during registration, such as your name, email address, and company details. Additionally, we gather data on your interactions with our platform, including bid submissions and communications."
  },
  {
    title: "Use of Information",
    text: "Your information is used to enhance your experience on Central Bids, including processing transactions, providing customer support, and sending updates about our services. We may also use your data to improve our platform and prevent fraudulent activities."
  },
  {
    title: "Data Sharing",
    text: "We do not sell or rent your personal information to third parties. However, we may share your data with trusted partners who assist us in operating our platform, conducting business, or providing services to you, as long as they agree to keep this information confidential."
  },
  {
    title: "Security",
    text: "We implement robust security measures to protect your data from unauthorized access, alteration, disclosure, or destruction. Your information is stored on secure servers, and we use encryption where appropriate."
  },

  {
    title: "Cookies",
    text: "Our platform uses cookies to enhance your experience, track usage patterns, and gather analytics. You can manage your cookie preferences through your browser settings."
  },
  {
    title: "Third-Party Links",
    text: "Central Bids may contain links to third-party websites. We are not responsible for the privacy practices or content of these sites. We encourage you to read the privacy policies of any linked websites."
  },
  {
    title: "Changes to This Policy",
    text: "We may update this Privacy Policy periodically. We will notify you of any significant changes by posting the revised policy on our platform and updating the effective date."
  }
];

const Privacy = () => {
  return (
    <section className="privacy-wrapper">
      <div className="">
        <div className="container">
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <CommonHeading
                color={"#021349"}
                text="Privacy Policy"
                fontFamily={"seventyTwoBold"}
                fontSize={"36px"}
                lineHeight={"68.99px"}
                mt={20}
              />
              <CommonTextField
                color="#465E7D"
                text="At Central Bids, we prioritize your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data."
                mt={8}
                fontFamily={"seventyTwoRegular"}
                fontSize={"16px"}
                lineHeight={"20px"}
              />
            </Col>

            <Col span={24}>
              {conditions?.map((item, index) => (
                <>
                  <CommonHeading
                    color={"#021349"}
                    text={`${item?.title}`}
                    fontFamily={"seventyTwoBold"}
                    fontSize={"20px"}
                    lineHeight={"48.99px"}
                    mt={0}
                  />

                  {item?.text && (
                    <CommonTextField
                      mt={0}
                      color="#465E7D"
                      fontFamily={"seventyTwoRegular"}
                      fontSize={"16px"}
                      lineHeight={"20px"}
                      text={item?.text}
                    />
                  )}
                </>
              ))}

              <CommonTextField
                mt={20}
                mb={40}
                color="#465E7D"
                fontFamily={"seventyTwoRegular"}
                fontSize={"16px"}
                lineHeight={"20px"}
                text={
                  "By using Central Bids, you acknowledge that you have read, understood, and agreed to this Privacy Policy."
                }
              />
            </Col>
          </Row>
        </div>
      </div>

      <PublicFooter />
    </section>
  );
};

export default Privacy;
