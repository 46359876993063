import React, { useEffect } from "react";
import "./styles.scss";
import { CommonTextField } from "../../../components/common";

import { CreateBidForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { CustomDispatch } from "../../../helpers";
import {
  getBidTypesRequest,
  getCategoryRequest,
  getClassificationsRequest,
  getNegotitationTypeRequest,
  getParticipantConditionsRequest,
  getSubCategoryRequest,
} from "../../../redux/slicers/bid";
import { useSelector } from "react-redux";
const CreateBid = () => {
  //CONST VALS
  const { id } = useParams();
  const navigate = useNavigate();
  const [getBidCategories] = CustomDispatch(getCategoryRequest);
  const [getClassifications] = CustomDispatch(getClassificationsRequest);
  const [getBidTypes] = CustomDispatch(getBidTypesRequest);
  const [getNegotitationType] = CustomDispatch(getNegotitationTypeRequest);
  const [getParticipantConditions] = CustomDispatch(
    getParticipantConditionsRequest
  );
  const user = useSelector((state) => state?.user?.profileData?.user);
  const [getSubCategory] = CustomDispatch(getSubCategoryRequest);

  useEffect(() => {
    getBidCategories();
    getClassifications();
    getBidTypes();
    getNegotitationType();
    getParticipantConditions();
    getSubCategory();

    if (!id && !user?.allowedJobs) {
      navigate(-1);
    }
  }, [id]);

  return (
    <section className="dashboard create-bid-wrapper">
      <CommonTextField
        text={`${id ? "Edit" : "Create"} Bid`}
        fontFamily="seventyTwoBold"
        fontSize={"34px"}
        color="#021349"
        mb={"12px"}
        lineHeight="42px"
      />
      <CreateBidForm />
    </section>
  );
};

export default CreateBid;
