import React, { useEffect } from "react";
import { Header, Loader, Sidebar } from "../../components";
import "./styles.scss";
import { CustomDispatch } from "../../helpers";
import { getProfileDataRequest } from "../../redux/slicers/user";
import { fetchToken, requestPermission } from "../../firebase";
import {
  addDeviceTokenRequest,
  getAllNotificationsRequest,
} from "../../redux/slicers/notification";
import { useSelector } from "react-redux";

const PrivateSharedLayout = ({ children }) => {
  const [getProfileData] = CustomDispatch(getProfileDataRequest);
  const [addDeviceToken] = CustomDispatch(addDeviceTokenRequest);
  const [getNotifications] = CustomDispatch(getAllNotificationsRequest);

  const user = useSelector((state) => state?.user?.data);

  useEffect(() => {
    getProfileData();
    handleGetToken();
    getNotifications({
      queryParams: {
        "filters[user][id][$eq]": user?.id,
        sort: "id:desc",
        populate: "user.company_detail",
        "pagination[pageSize]": 500000,
      },
    });

    // browser?.tabs?.onActivated?.addListener?.(handleTabActivation)

    // return () => {
    //   browser?.tabs?.onActivated?.removeListener?.(handleTabActivation)
    // }
  }, []);

  const handleTabActivation = () => {
    getNotifications({
      queryParams: {
        "filters[user][id][$eq]": user?.id,
        sort: "id:desc",
        populate: "user.company_detail",
        "pagination[pageSize]": 500000,
      },
    });

    getProfileData();
  };

  async function handleGetToken() {
    let notifPermission = await requestPermission();
    if (notifPermission) {
      const token = await fetchToken();
      if (token)
        addDeviceToken({
          payload: {
            deviceToken: token,
          },
        });
    } else {
    }
  }

  return (
    <section className="private-wrapper">
      <Header />
      <Sidebar />
      <div className="private-content">{children}</div>
    </section>
  );
};

export default PrivateSharedLayout;
