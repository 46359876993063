import React from "react";
import { Avatar, Col, Form, Modal, Row, Space } from "antd";
import {
  CommonButton,
  CommonTextAreaField,
  CommonTextField,
} from "../../../common";
import { Images } from "../../../../theme";
import "./styles.scss";
import {
  inputFieldRule,
  replaceValInString,
  toastAlert,
} from "../../../../utils";
import { useNavigate, useParams } from "react-router-dom";
import { ASK_QUESTION_ROUTE } from "../../../../constants";

const SellerSubmissionModal = ({
  bidInfo,
  handleClose,
  preview,
  handleConfirm,
  confirm_bids = [],
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  console.log({ bidInfo, confirm_bids });
  const isConfirmed =
    confirm_bids.find((item) => item.id === bidInfo?.id) ?? false;
  return (
    <Modal
      destroyOnClose
      open={preview}
      centered
      footer={null}
      width={633}
      className="seller-sub-modal"
      closeIcon={false}
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={handleClose}>
            <Images.CloseIcon />
          </div>
          <Space size={15} style={{ marginBottom: "30px" }}>
            <Avatar size={51} src={<Images.UserAvatar />} />
            <Space size={6} direction="vertical">
              <CommonTextField
                fontFamily={"seventyTwoRegular"}
                className={""}
                fontSize={"14px"}
                text={bidInfo?.user?.fullName}
                lineHeight={"10px"}
              />
              <CommonTextField
                fontFamily={"seventyTwoRegular"}
                fontSize={"12px"}
                className={"light-text"}
                text={bidInfo?.supplierTitle}
              />
            </Space>
          </Space>
          <a
            download={bidInfo?.previewBid?.filename}
            href={bidInfo?.previewBid?.url}
            target="_blank"
            className="bid-download c-p"
            style={{ marginBottom: "40px" }}
          >
            <CommonTextField text={"Bid.pdf"} fontFamily={"seventyTwoBold"} />
            <Images.DownloadIcon />
          </a>
          <div className="modal-footer">
            <CommonButton
              text={"Ask More Questions"}
              borderRadius={"12px"}
              width={"200px"}
              height={"56px"}
              background={"rgba(3, 25, 70, 1)"}
              onClick={() =>
                navigate(
                  replaceValInString(ASK_QUESTION_ROUTE, {
                    ":id": id,
                    ":subId": bidInfo?.id,
                  }),
                )
              }
            />
            <CommonButton
              disabled={isConfirmed ? true : false}
              text={isConfirmed ? "Confirmed" : "Confirm Bid"}
              borderRadius={"12px"}
              width={"200px"}
              height={"56px"}
              onClick={() => {
                handleConfirm(bidInfo?.id);
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SellerSubmissionModal;
