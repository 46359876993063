import React, { useEffect, useState } from "react";
import "../styles.scss";
import {
  CommonButton,
  CommonInputField,
  CommonTable,
  CommonTextField,
} from "../../../../../../components/common";
import { Checkbox, Col, Form, Radio, Row } from "antd";
import {
  downloadFileFromUrl,
  inputFieldRule,
  toastAlert,
} from "../../../../../../utils";
import {
  ALERT_TYPES,
  TABLE_DATA,
  TABLE_DATA_STEP_4,
} from "../../../../../../constants";
import _ from "lodash";
import moment from "moment";

function Step4({
  submittedBidDetail,
  setAddendaInfo,
  setAddendaReviewAgreement,
  bidDetail,
  isHidden,
  handleNextStep,
}) {
  //STATES
  const [fileCheckList, setFileCheckList] = useState([]);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [radioAgreement, setRadioAgreement] = useState(1);
  //CONST VALS
  const [form] = Form.useForm();

  useEffect(() => {
    if (!submittedBidDetail?.id) return;

    if (submittedBidDetail?.addendaInfo) {
      form.setFieldsValue(submittedBidDetail.addendaInfo);
      setRadioAgreement(submittedBidDetail.addendum ? 1 : 2);
      setTermsAgreed(true);

      const ids = bidDetail?.addendas?.map((item) => item.id);
      setFileCheckList(ids ?? []);
    }
  }, [submittedBidDetail]);

  //HANDLERS
  const handleFileCheck = (key) => {
    if (fileCheckList.includes(key)) {
      // Remove key from fileCheckList
      setFileCheckList(fileCheckList.filter((item) => item !== key));
    } else {
      // Add key to fileCheckList
      setFileCheckList([...fileCheckList, key]);
    }
  };

  //CUSTOM COMPONENTS
  const renderTableText = (text, _record, index) => {
    return (
      <a download={text} href={_record?.url} target="_blank">
        <CommonTextField
          text={`Addenda ${index + 1} - ${text}`}
          fontFamily={"seventyTwoRegular"}
          fontSize={"12px"}
          lineHeight={"13.8px"}
          color={"rgba(2, 19, 73, 1)"}
        />

        <CommonTextField
          text={moment(_record?.updatedAt).format("ddd MMM DD, YYYY")}
          fontFamily={"seventyTwoRegular"}
          fontSize={"12px"}
          lineHeight={"13.8px"}
          color={"rgba(2, 19, 73, 1)"}
        />
      </a>
    );
  };
  const renderTableCheckBox = (_, record) => (
    <Checkbox
      checked={fileCheckList.includes(record?.id)}
      onChange={() => handleFileCheck(record?.id)}
    ></Checkbox>
  );

  const columns = [
    {
      title: "File name",
      dataIndex: "name",
      key: "name",
      render: renderTableText,
    },
    {
      title:
        "I have reviewed the below addendum and attachments (if applicable)",
      dataIndex: "key",
      key: "key",
      align: "center",
      render: renderTableCheckBox,
    },
  ];

  //HANDLERS
  const handleStepFourForm = () => {
    form.validateFields();
    if (termsAgreed) {
      if (fileCheckList?.length === bidDetail?.addendas?.length) {
        form.submit();
      } else {
        toastAlert(
          "Please review all the files and addendums.",
          ALERT_TYPES.ERROR
        );
      }
    } else {
      toastAlert(
        "Please agree to the terms and conditions.",
        ALERT_TYPES.ERROR
      );
    }
  };

  const handleFormFinish = (vals) => {
    setAddendaInfo(vals);
    setAddendaReviewAgreement(radioAgreement);
    handleNextStep(5);
  };

  return (
    <div className={` ${isHidden ? "hidden" : ""} `}>
      <Form form={form} style={{ marginTop: 20 }} onFinish={handleFormFinish}>
        <Row gutter={[12, 0]}>
          <Col
            className="checkbox-parent"
            span={24}
            style={{ marginBottom: "15px" }}
          >
            <Checkbox
              checked={termsAgreed}
              onChange={() => setTermsAgreed(!termsAgreed)}
            />
            <CommonTextField
              text={
                "I/WE agree to be bound by the terms and conditions and have authority to bind the Corporation and submit this Bid on behalf of the Bidder."
              }
            />
          </Col>

          <Col lg={12} xs={24}>
            <CommonInputField
              placeholder={"First Name"}
              rules={inputFieldRule({
                name: "First Name",
                isMax: true,
                max: 30,
              })}
              name="firstName"
            />
          </Col>

          <Col lg={12} xs={24}>
            <CommonInputField
              placeholder={"Last Name"}
              rules={inputFieldRule({
                name: "Last Name",
                isMax: true,
                max: 30,
              })}
              name="lastName"
            />
          </Col>

          <Col lg={12} xs={24}>
            <CommonInputField
              placeholder={"Title"}
              rules={inputFieldRule({
                name: "Title",
                isMax: true,
                max: 30,
              })}
              name="title"
            />
          </Col>

          <Col lg={12} xs={24}>
            <CommonInputField
              placeholder={"Legal Company Name"}
              rules={inputFieldRule({
                name: "Legal Company Name",
                isMax: true,
                max: 30,
              })}
              name="legalCompanyName"
            />
          </Col>

          <Col span={24}>
            <CommonTextField
              text={
                "Proponents must fullydisclose, in writing to the County on or before the Closing Date and Time ofthis Bid, the circumstances of any potential conflict of interest or what couldbe perceived as a possible conflict of interest if the Proponent were to becomea contracting party pursuant to this Bid. The County shall review anysubmissions by Proponents under this provision and may reject any Submissions where, in the sole opinion of County, the Proponent could be in a conflict ofinterest or could be perceived to be in a possible conflict of interestposition if the Proponent were to become a contracting party pursuant to this Bid."
              }
              color={"rgba(2, 19, 73, 1)"}
              fontSize={"14px"}
              lineHeight={"20px"}
              fontFamily={"seventyTwoRegular"}
              mt={"15px"}
              mb={"15px"}
            />
            <Radio.Group
              name="radioGroup"
              value={radioAgreement}
              onChange={(e) => {
                setRadioAgreement(e.target.value);
              }}
            >
              <Radio value={1}>Yes</Radio>
              <Radio value={2}>No</Radio>
            </Radio.Group>
          </Col>

          <div></div>

          <Col span={24}>
            <CommonTextField
              text={
                "The Bidder acknowledges and agrees that the addendum/addenda below form part of the Bid Document"
              }
              color={"rgba(2, 19, 73, 1)"}
              fontSize={"14px"}
              lineHeight={"20px"}
              fontFamily={"seventyTwoRegular"}
              mt={"15px"}
            />
            <CommonTextField
              // text={
              //   ""
              // }
              color={"rgba(2, 19, 73, 1)"}
              fontSize={"14px"}
              lineHeight={"20px"}
              fontFamily={"seventyTwoRegular"}
            >
              Please check the box in the column{" "}
              <b>'I have reviewed this addendum'</b> below to acknowledge each
              of the addenda.
            </CommonTextField>
          </Col>

          <Col span={24} style={{ marginTop: 30 }} className="tableWrapper">
            <CommonTable columns={columns} dataSource={bidDetail?.addendas} />
          </Col>
        </Row>
      </Form>

      <div className="buttonWrapper">
        <CommonButton
          text={"Back"}
          classname={"button mr-10"}
          width="200px"
          height={"56px"}
          fontSize={"18px"}
          onClick={() => {
            handleNextStep(3);
          }}
        />

        <CommonButton
          text={"Continue"}
          classname={"button"}
          width="200px"
          height={"56px"}
          fontSize={"18px"}
          onClick={handleStepFourForm}
        />
      </div>
    </div>
  );
}

export default Step4;
