import React, { useEffect } from "react";
import { useState } from "react";
import { BidsListing, BidsTable, CardsSection } from "../../../components";
import {
  CommonButton,
  CommonHeading,
  CommonSelect,
  CommonTabs,
  CommonTextField,
} from "../../../components/common";
import {
  BID_FILTERS_LIST,
  BID_QUERY_POPULATE_PARAMS,
  BID_QUERY_POPULATE_PARAMS_OBJECT,
  BID_STATUS,
} from "../../../constants";
import { Images } from "../../../theme";
import "./styles.scss";
import { CustomDispatch } from "../../../helpers";
import {
  getCategoryRequest,
  getOpenBidsRequest,
} from "../../../redux/slicers/bid";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "@uidotdev/usehooks";
import { getBidsByTitleQuery, getPageSizeQuery } from "../../../utils";
import { Button, Col, Form, Row } from "antd";
import { getProfileDataRequest } from "../../../redux/slicers/user";
const OpenBids = () => {
  //STATES
  const [selectedFilter, setSelectedFilter] = useState("ongoing");
  const [bids, setBids] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [searchCategory, setSearchCategory] = React.useState("");
  const [categoryOptionList, setCategoryOptionList] = React.useState([]);

  const [pagination, setPagination] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const dispatch = useDispatch();

  // HOOKS
  const [form] = Form.useForm();
  const categorySelected = Form.useWatch("category", form);
  const statusSelected = Form.useWatch("status", form);

  const debouncedSearch = useDebounce(search, 400);
  const debouncedCategorySearch = useDebounce(searchCategory, 400);

  const user = useSelector(({ user }) => user?.data);
  const [getOpenBids] = CustomDispatch(getOpenBidsRequest);
  const [getBidCategories, loading] = CustomDispatch(getCategoryRequest);
  const [getProfile] = CustomDispatch(getProfileDataRequest);

  const categoryOptions = categoryOptionList?.map((item) => ({
    label: item?.attributes?.title,
    value: item?.id,
  }));

  //HANDLERS
  const setFilterHandler = (data) => {
    setSelectedFilter(data);
  };

  useEffect(() => {
    handleGetMyBids();
  }, [debouncedSearch]);

  useEffect(() => {
    getBidCategories({
      queryParams: handleSearchCategoryQuery(),
      callback: (resp) => {
        setCategoryOptionList(resp?.data || []);
      },
    });
  }, [debouncedCategorySearch]);

  useEffect(() => {
    getProfile();
  }, []);

  // search category query
  const handleSearchCategoryQuery = () => {
    return debouncedCategorySearch
      ? `filters[title][$containsi]=${debouncedCategorySearch}`
      : "";
  };

  // search query for bids by title
  const handleSearchQuery = () => {
    return debouncedSearch ? getBidsByTitleQuery(debouncedSearch) : "";
  };

  // default query for open bids
  const getOpenBidsDefaultQuery = () => {
    return `filters[status][$eq]=Open&filters[$and][0][$or][0][publicOpening][$eq]=Yes&filters[$and][0][$or][1][suppliers][email][$containsi]=${
      user?.email
    }&filters[closing_date][$gte]=${moment().format(
      "YYYY-MM-DD"
    )}&filters[user][id][$ne]=${
      user?.id
    }&filters[$or][0][bid_submissions][id][$null]=true&filters[$or][1][bid_submissions][users_permissions_user][id][$notIn]=${
      user?.id
    }&`;
  };

  function handleGetMyBids(query = "", isAddSortQuery = true) {
    let sortQuery = isAddSortQuery
      ? handleGetSortQuery({
          category: categorySelected,
          status: statusSelected,
        })
      : "";
    setIsLoading(true);

    dispatch(
      getOpenBidsRequest({
        queryParams: `${
          query +
          sortQuery +
          handleSearchQuery() +
          getOpenBidsDefaultQuery() +
          BID_QUERY_POPULATE_PARAMS +
          getPageSizeQuery()
        }`,

        responseCallback: (status, response) => {
          setIsLoading(false);

          if (status) {
            setBids(response?.data || []);
            response?.pagination && setPagination(response?.pagination);
          }
        },
      })
    );
  }

  const handleSearch = (val) => {
    setSearchCategory(val);
  };

  const handleSubmit = (values) => {
    handleGetMyBids(handleGetSortQuery(values), false);
  };

  const handleClearFilter = () => {
    form.resetFields();
  };

  function handleGetSortQuery(values) {
    let query = [];
    values?.category?.map((item, index) => {
      query.push(
        `filters[categories][id][$in][${index}]=${item.toString()}${
          index == values?.category?.length - 1 ? "&" : ""
        }`
      );
    });

    let status = values.status ? `filters[status][$eq]=${values.status}&` : "";

    return query.join("&") + status;
  }

  return (
    <section className="open-bids-listing-wrapper">
      <CommonTextField
        text={"Bids"}
        fontFamily="seventyTwoBold"
        fontSize={"34px"}
        color="#021349"
        mb={"12px"}
        lineHeight="42px"
      />{" "}
      <div className="bids-section">
        <div className="heading-wrapper">
          <CommonTextField
            text={"Bids"}
            fontFamily="seventyTwoBold"
            fontSize={"24px"}
          />

          <div
            className="table-filters-wrapper"
            style={{ width: "469px", maxWidth: "100%" }}
          >
            <div className="search-box" style={{ width: "100%" }}>
              <Images.Search />
              <input
                type="text"
                placeholder={"Search any Bids..."}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="filter-wrapper">
          <Form form={form} onFinish={handleSubmit}>
            <Row align={"middle"}>
              <Col flex={"auto"}>
                <Row gutter={[16, 16]}>
                  <Col flex={"auto"}>
                    <Row gutter={[16, 16]}>
                      <Col span={24} lg={{ span: 24 }}>
                        <CommonSelect
                          formClassName={"formClassName"}
                          mode="multiple"
                          name={"category"}
                          allowClear={true}
                          placeholder={"Select Category"}
                          className={"selectSize"}
                          options={categoryOptions ?? []}
                          handleSearch={handleSearch}
                        />
                      </Col>
                      {/* <Col span={24} lg={{ span: 12 }}>
                        <CommonSelect
                          formClassName={"formClassName"}
                          name={"status"}
                          options={BID_STATUS}
                          allowClear={true}
                          placeholder={"Select Status"}
                          className={"selectSize"}
                        />
                      </Col> */}
                    </Row>
                  </Col>

                  <Col span={24} lg={{ span: 8 }}>
                    <Row gutter={[16, 16]}>
                      <Col span={24} lg={{ span: 12 }}>
                        <CommonButton
                          disabled={
                            categorySelected?.length || statusSelected
                              ? false
                              : true
                          }
                          text={"Clear Filters"}
                          onClick={handleClearFilter}
                        />
                      </Col>
                      <Col span={24} lg={{ span: 12 }}>
                        <CommonButton
                          text={"Apply Filters"}
                          htmlType="submit"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>

        <BidsListing
          filter={"open"}
          isOpen={true}
          bids={bids}
          pagination={pagination}
          handleGetMyBids={handleGetMyBids}
          bidsLoader={isLoading}
        />
      </div>
    </section>
  );
};

export default OpenBids;
