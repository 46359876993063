import { createSlice } from "@reduxjs/toolkit";

const BidReducer = createSlice({
  name: "bid",
  initialState: {
    classifications: [],
    types: [],
    categories: [],
    negotitationTypes: [],
    participantConditions: [],
    subCategories: [],
    bids: [],
  },
  reducers: {
    getClassificationsRequest() {},
    getClassificationsSuccess(state, action) {
      state.classifications = action.payload;
    },

    getBidTypesRequest() {},
    getBidTypesSuccess(state, action) {
      state.types = action.payload;
    },

    getCategoryRequest() {},
    getCategorySuccess(state, action) {
      state.categories = action.payload;
    },

    getNegotitationTypeRequest() {},
    getNegotitationTypeSuccess(state, action) {
      state.negotitationTypes = action.payload;
    },

    getParticipantConditionsRequest() {},
    getParticipantConditionsSuccess(state, action) {
      state.participantConditions = action.payload;
    },

    getSubCategoryRequest() {},
    getSubCategorySuccess(state, action) {
      state.subCategories = action.payload;
    },

    createBidRequest() {},
    createBidSuccess() {},

    updateBidRequest() {},
    updateBidSuccess() {},

    getOpenBidsRequest() {},
    getOpenBidsSuccess(state, action) {
      state.bids = action.payload;
    },
    getBidDetailRequest() {},
    getMyBidsRequest() {},

    createBidSubmissionRequest() {},
    createBidSubmissionSuccess() {},

    getSubmittedBidRequest() {},
    getSubmittedBidSuccess() {},

    updateBidSubmissionRequest() {},
  },
});

export const {
  getMyBidsRequest,
  getClassificationsRequest,
  getClassificationsSuccess,
  getBidTypesRequest,
  getBidTypesSuccess,
  getCategoryRequest,
  getCategorySuccess,
  getNegotitationTypeRequest,
  getNegotitationTypeSuccess,
  getParticipantConditionsRequest,
  getParticipantConditionsSuccess,
  getSubCategoryRequest,
  getSubCategorySuccess,

  createBidRequest,
  createBidSuccess,

  getOpenBidsRequest,
  getOpenBidsSuccess,

  getBidDetailRequest,

  createBidSubmissionRequest,
  createBidSubmissionSuccess,

  getSubmittedBidRequest,
  getSubmittedBidSuccess,

  updateBidSubmissionRequest,

  updateBidRequest,
  updateBidSuccess,
} = BidReducer.actions;

export default BidReducer.reducer;
