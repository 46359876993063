import React, { useEffect } from "react";
import { useState } from "react";
import { BidsListing } from "../../../components";
import {
  CommonButton,
  CommonSelect,
  CommonTabs,
  CommonTextField,
} from "../../../components/common";
import {
  BID_FILTERS_LIST,
  BID_QUERY_POPULATE_PARAMS,
  BID_STATUS,
} from "../../../constants";
import { Images } from "../../../theme";
import "./styles.scss";
import {
  getCategoryRequest,
  getMyBidsRequest,
} from "../../../redux/slicers/bid";
import { CustomDispatch } from "../../../helpers";
import { useDebounce } from "@uidotdev/usehooks";
import {
  getActiveBidsQuery,
  getBidsBySubmittedUserQuery,
  getBidsByTitleQuery,
  getBidsByUserQuery,
  getBidsOngoingQuery,
  getHistoryBidsQuery,
  getPageSizeQuery,
} from "../../../utils";
import { useSelector } from "react-redux";
import { Col, Form, Row } from "antd";
import { getProfileDataRequest } from "../../../redux/slicers/user";
const MyBids = () => {
  const [getMyBidData, bidsLoader] = CustomDispatch(getMyBidsRequest);
  const { profileData } = useSelector((state) => state.user);
  //STATES
  const [selectedFilter, setSelectedFilter] = useState(
    profileData?.user?.isBuyer ? "active" : "ongoing"
  );
  const [bids, setBids] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const debouncedSearch = useDebounce(search, 400);
  const [pagination, setPagination] = React.useState(null);
  const [searchCategory, setSearchCategory] = React.useState("");
  const [categoryOptionList, setCategoryOptionList] = React.useState([]);

  const [getBidCategories] = CustomDispatch(getCategoryRequest);
  const [getProfile] = CustomDispatch(getProfileDataRequest);

  // CONSTS
  const [form] = Form.useForm();
  const categorySelected = Form.useWatch("category", form);
  const statusSelected = Form.useWatch("status", form);

  const debouncedCategorySearch = useDebounce(searchCategory, 400);
  const categoryOptions = categoryOptionList?.map((item) => ({
    label: item?.attributes?.title,
    value: item?.id,
  }));

  //HANDLERS
  const setFilterHandler = (data) => {
    setSelectedFilter(data);
  };

  // hooks

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    handleGetMyBids();
  }, [debouncedSearch]);

  useEffect(() => {
    handleGetMyBids();
  }, [selectedFilter]);

  useEffect(() => {
    getBidCategories({
      queryParams: handleSearchCategoryQuery(),
      callback: (resp) => {
        setCategoryOptionList(resp?.data || []);
      },
    });
  }, [debouncedCategorySearch]);

  // search category query
  const handleSearchCategoryQuery = () => {
    return debouncedCategorySearch
      ? `filters[title][$containsi]=${debouncedCategorySearch}`
      : "";
  };

  // filter status ongoing active history query
  const handleGetFilterQuery = () => {
    return selectedFilter == "history"
      ? getHistoryBidsQuery(!profileData?.user?.isBuyer)
      : selectedFilter == "active"
      ? getActiveBidsQuery(
          profileData?.user?.isBuyer ? true : false,
          profileData?.user?.id
        )
      : selectedFilter == "ongoing"
      ? getBidsOngoingQuery(
          profileData?.user?.isBuyer ? true : false,
          profileData?.user?.id
        )
      : "";
  };

  // bids by user query
  const handleGetQueryForBuyerSellerByUser = () => {
    return profileData?.user?.isBuyer
      ? getBidsByUserQuery(profileData?.user?.id)
      : getBidsBySubmittedUserQuery(profileData?.user?.id);
  };

  // search query for bids by title
  const handleSearchQuery = () => {
    return debouncedSearch
      ? getBidsByTitleQuery(debouncedSearch?.replaceAll("&", "%26"))
      : "";
  };

  // handler for get my bids
  function handleGetMyBids(query = "", isAddSortQuery = true) {
    let sortQuery = isAddSortQuery
      ? handleGetSortQuery({
          category: categorySelected,
          status: statusSelected,
        })
      : "";
    getMyBidData({
      queryParams:
        handleGetQueryForBuyerSellerByUser() +
        handleSearchQuery() +
        handleGetFilterQuery() +
        sortQuery +
        query +
        BID_QUERY_POPULATE_PARAMS +
        getPageSizeQuery(),
      callback: (bids) => {
        bids.pagination && setPagination(bids.pagination);
        setBids(bids?.data || []);
      },
    });
  }

  const handleSearch = (val) => {
    setSearchCategory(val);
  };

  // handle submit category and status filter
  const handleSubmit = (values) => {
    handleGetMyBids(handleGetSortQuery(values), false);
  };

  function handleGetSortQuery(values) {
    let query = [];
    values?.category?.map((item, index) => {
      query.push(
        `filters[categories][id][$in][${index}]=${item.toString()}${
          index == values?.category?.length - 1 ? "&" : ""
        }`
      );
    });

    let status = values.status ? `filters[status][$eq]=${values.status}&` : "";

    return query.join("&") + status;
  }

  const handleClearFilter = () => {
    form.resetFields();
  };

  return (
    <section className="bids-listing-wrapper">
      <CommonTextField
        text={"My Bids"}
        fontFamily="seventyTwoBold"
        fontSize={"34px"}
        color="#021349"
        mb={"12px"}
        lineHeight="42px"
      />
      <div className="bids-section">
        <div className="heading-wrapper">
          <CommonTextField
            text={"Bids"}
            fontFamily="seventyTwoBold"
            fontSize={"24px"}
          />
          <div className="table-filters-wrapper">
            <div className="search-box">
              <Images.Search />
              <input
                type="text"
                placeholder={"Search any Bids..."}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
            <CommonTabs
              buttonsList={BID_FILTERS_LIST}
              setState={setFilterHandler}
              state={selectedFilter}
            />
          </div>
        </div>
        <div className="filter-wrapper">
          <Form form={form} onFinish={handleSubmit}>
            <Row align={"middle"}>
              <Col flex={"auto"}>
                <Row gutter={[16, 16]}>
                  <Col flex={"auto"}>
                    <Row gutter={[16, 16]}>
                      <Col span={24} lg={{ span: 12 }}>
                        <CommonSelect
                          formClassName={"formClassName"}
                          mode="multiple"
                          name={"category"}
                          allowClear={true}
                          placeholder={"Select Category"}
                          className={"selectSize"}
                          options={categoryOptions ?? []}
                          handleSearch={handleSearch}
                        />
                      </Col>
                      <Col span={24} lg={{ span: 12 }}>
                        <CommonSelect
                          formClassName={"formClassName"}
                          name={"status"}
                          options={BID_STATUS}
                          allowClear={true}
                          placeholder={"Select Status"}
                          className={"selectSize"}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col span={24} lg={{ span: 8 }}>
                    <Row gutter={[16, 16]}>
                      <Col span={24} lg={{ span: 12 }}>
                        <CommonButton
                          disabled={
                            categorySelected?.length || statusSelected
                              ? false
                              : true
                          }
                          text={"Clear Filters"}
                          onClick={handleClearFilter}
                        />
                      </Col>
                      <Col span={24} lg={{ span: 12 }}>
                        <CommonButton
                          text={"Apply Filters"}
                          htmlType="submit"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
        <BidsListing
          filter={selectedFilter}
          bids={bids}
          pagination={pagination}
          handleGetMyBids={handleGetMyBids}
          bidsLoader={bidsLoader}
        />
      </div>
    </section>
  );
};

export default MyBids;
