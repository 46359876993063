import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  ABOUT_ROUTE,
  AUTH_SUBSCRIPTION_ROUTE,
  DASHBOARD_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE
} from "../../../constants";
import { Images } from "../../../theme";
import { CommonButton } from "../../common";
import "./styles.scss";
function PublicHeader() {
  const navigate = useNavigate();
  const location = useLocation();
  const [navOpen, setNavOpen] = useState(false);
  const [isScrolledToTop, setIsScrolledToTop] = useState(true);
  const { pathname } = useLocation();
  const { isAuthenticated } = useSelector((state) => state.user);
  const navOpenToggle = () => {
    setNavOpen(!navOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop === 0) {
        setIsScrolledToTop(true);
      } else {
        setIsScrolledToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className={`public-header ${isScrolledToTop ? "transparent" : ""}`}>
      <div className="container">
        <Row style={{ width: "100%" }}>
          <Col span={6} xs={18} sm={18} md={6}>
            <Images.PublicLogo
              className="c-p"
              onClick={() => navigate(LOGIN_ROUTE)}
            />
          </Col>
          <Col xs={6} sm={6} md={1} lg={1} className="toggle-wrapper">
            <button
              className={`togglebtn c-p ${navOpen ? "active" : ""}`}
              onClick={navOpenToggle}
            >
              <span />
              <span />
              <span />
            </button>
          </Col>
          <Col sm={24} md={17} lg={17} className="options-col">
            <div className={`header-options c-p ${navOpen ? "active" : ""}`}>
              <Link
                to={HOME_ROUTE}
                onClick={navOpenToggle}
                className={`header-nav ${
                  location?.pathname == HOME_ROUTE && "header-nav-active"
                }`}
              >
                Home
              </Link>
              <Link
                to={ABOUT_ROUTE}
                onClick={navOpenToggle}
                className={`header-nav ${
                  location?.pathname == ABOUT_ROUTE && "header-nav-active"
                }`}
              >
                About
              </Link>
              {/* <Link className="header-nav" onClick={navOpenToggle}>
                Contact Us
              </Link> */}
              <CommonButton
                text={isAuthenticated ? "Account" : "Login"}
                fontSize={"16px"}
                fontWeight="600"
                onClick={() => {
                  navigate(isAuthenticated ? DASHBOARD_ROUTE : LOGIN_ROUTE);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default PublicHeader;
