import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "antd";
import {
  PaymentElement,
  useElements,
  useStripe,
  CardElement,
} from "@stripe/react-stripe-js";
import { CommonButton, CommonInputField, CommonTextField } from "../common";
import { Images } from "../../theme";
import { creditCardValidator, inputFieldRule, toastAlert } from "../../utils";
import "./styles.scss";
import { CustomDispatch } from "../../helpers";
import {
  authenticateUser,
  purchasePackagesRequest,
} from "../../redux/slicers/user";

const options = {
  style: {
    base: {
      iconColor: "#000",
      color: "#000",
      fontWeight: "500",
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "12",
      fontSmoothing: "antialiased",
      border: "1px solid red",
      ":focus": {
        borderColor: "rgb(82 60 230 / 70%)",
      },
      height: 56,
      borderRadius: 12,
    },
    invalid: {
      iconColor: "#FFC7EE",
      color: "#000",
    },
  },
};

const CheckoutForm = ({
  handleClose,
  handleFinish,
  preview,
  subscriptionPackage = "",
  selected,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const [stripeCardError, setStripeCardError] = useState("");
  const [purhcasePackage, purchaseLoader] = CustomDispatch(
    purchasePackagesRequest
  );
  const [authenticate] = CustomDispatch(authenticateUser);
  useEffect(() => {
    if (elements?.getElement) {
      const _cardElement = elements.getElement(CardElement);

      _cardElement.on("ready", () => {
        _cardElement.focus();
      });
    }
  }, [elements]);

  const handlSubmit = async (e) => {
    e.preventDefault();
    // const cardElement = elements.getElement(CardElement)
    setLoading(true);
    const paymentMethod = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (paymentMethod?.error?.message) {
      setStripeCardError(paymentMethod.error.message);
      setLoading(false);
      return;
    } else {
      purhcasePackage({
        payload: {
          planId: selected?.id,
          paymentMethodId: paymentMethod?.paymentMethod?.id,
        },
        logic(res) {
          toastAlert("Subscription purchased successfully.");
          setLoading(false);
          handleClose();
          authenticate();
        },
        error() {
          setLoading(false);
        },
      });
    }
  };

  return (
    <Modal
      destroyOnClose
      open={preview}
      centered
      footer={null}
      width={633}
      className="buy-sub-modal"
      closeIcon={false}
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={handleClose}>
            <Images.CloseIcon />
          </div>
          <div className="title">
            <h3>Buy Subscriptions</h3>
          </div>
          <div className="payment-form">
            <Row gutter={[12, 0]}>
              <Col span={24}>
                <div className="package-details">
                  <CommonTextField
                    text={`${selected?.name} | $${selected?.amount}/m`}
                    color="#0070F2"
                    fontWeight={"700"}
                    fontFamily="Lato"
                    fontSize={"28px"}
                  />
                </div>
              </Col>

              <Col span={24}>
                <div style={{ marginBottom: 20 }}>
                  <div className="card-element-parent">
                    <CardElement
                      onChange={(cardDetails) => {
                        setStripeCardError("");
                      }}
                      options={{
                        style: {
                          base: {
                            iconColor: "#000",
                            color: "#000",
                            fontWeight: "400",
                            fontSize: "16px",
                            fontSmoothing: "antialiased",
                            "::placeholder": {
                              color: "rgba(150, 160, 173, 1)",
                            },
                          },
                          invalid: {
                            iconColor: "red",
                            color: "#000",
                          },
                        },
                      }}
                      className="card-element"
                    />
                  </div>

                  {stripeCardError && (
                    <span style={{ color: "red" }}>{stripeCardError}</span>
                  )}
                </div>
              </Col>

              {/* <Col span={12}>
								<CommonInputField
									placeholder={"First Name"}
									rules={inputFieldRule({
										name: "First name",
										isMax: true,
										max: 30,
									})}
									name="firstName"
								/>
							</Col>
							<Col span={12}>
								<CommonInputField
									placeholder={"Last Name"}
									rules={inputFieldRule({
										name: "Last name",
										isMax: true,
										max: 30,
									})}
									name="lastName"
								/>
							</Col>
							<Col span={12}>
								<CommonInputField
									placeholder={"Card Number"}
									rules={inputFieldRule({
										name: "Card number",
										// isMax: true,
										// max: 12,
										// isCardNumber: true,
									})}
									name="cardNumber"
									onKeyDown={(e) => creditCardValidator(e, "card")}
								/>
							</Col>
							<Col span={12}>
								<CommonInputField
									placeholder={"Expiration"}
									rules={inputFieldRule({
										name: "Expiration date",
										// isMax: true,
										// max: 7,
										// isCardExpiry: true,
									})}
									name="cardExpiration"
									onKeyDown={(e) => creditCardValidator(e, "date")}
								/>
							</Col>
							<Col span={12}>
								<CommonInputField
									placeholder={"CVV"}
									rules={inputFieldRule({
										name: "CVV",
										isMax: true,
										max: 3,
										isNumberOnly: true,
									})}
									name="cvv"
									onKeyDown={(e) => creditCardValidator(e, "card", 3)}
								/>
							</Col>
							<Col span={12}>
								<CommonInputField
									placeholder={"Postal Code"}
									rules={inputFieldRule({
										name: "Postal code",
										isAlphabetsAndNumber: true,
										isMax: true,
										max: 20,
									})}
									name="postalCode"
								/>
							</Col> */}
              <Col span={24}>
                <CommonButton
                  text={"Pay Now"}
                  width="100%"
                  borderRadius={"12px"}
                  htmlType="submit"
                  onClick={handlSubmit}
                  loading={loading}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CheckoutForm;
