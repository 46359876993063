import React, { useEffect, useState } from "react";
import "./styles.scss";
import Images from "../../../../theme/Images";
import {
  CommonTextField,
  CommonButton,
  CommonTable,
  CommonModal,
  CommonTextAreaField,
} from "../../../../components/common";
import { Col, Form, Row } from "antd";
import {
  BidsTable,
  CancelBidModal,
  SellerBidSubmission,
  YourBid,
} from "../../../../components";
import {
  ALERT_TYPES,
  BID_QUERY_POPULATE_PARAMS,
  BID_QUERY_POPULATE_PARAMS_DETAIL,
  BID_STATUS,
  BID_STATUS_KEYS,
  BRANCH_TABlE_DATA,
  DASHBOARD_ROUTE,
  EDIT_BID_ROUTE,
  LOGIN_ROUTE,
  ONGOING_TABlE_DATA,
  SIGN_UP_ROUTE,
  STEPS_DETAIL,
} from "../../../../constants";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MoreBidsSection from "./partials/moreBids";
import CommonBidData from "./partials/commonBidData";
import CommonThreeDotMenu from "../../../../components/common/CommonThreeDotMenu";
import {
  getSubmittedBidQuery,
  inputFieldRule,
  replaceValInString,
  toastAlert,
} from "../../../../utils";
import { CustomDispatch } from "../../../../helpers";
import {
  getBidDetailRequest,
  getSubmittedBidRequest,
  updateBidRequest,
  updateBidSubmissionRequest,
} from "../../../../redux/slicers/bid";
import {
  getSubmittedBidsManipulator,
  singleSubmittedBidManipulator,
} from "../../../../data-manipulator/bid";
import ConfirmBidModal from "../../../../components/private/bidDetaild/confirmBidModal";

function BidDetails() {
  //STATES
  const [currentBidTabActive, setCurrentBidTabActive] = useState(true);
  const [deleteModalPreview, setDeleteModalPreview] = useState(false);
  const [showModalForLogin, setShowModalForLogin] = useState(false);
  const [data, setData] = useState({});
  const [submittedBid, setSubmittedBid] = useState(null);
  const [bidSubmissionData, setBidSubmissionData] = useState([]);
  const [cancelModalPreview, setCancelModalPreview] = useState(false);
  const [confirmModalPreview, setConfirmModalPreview] = useState(false);

  //CONST VALS
  const { id } = useParams();
  const navigate = useNavigate();
  const [getBidDetail] = CustomDispatch(getBidDetailRequest);
  const [getSubmittedBid, bidLoading] = CustomDispatch(getSubmittedBidRequest);
  const [updateSubmittedBid] = CustomDispatch(updateBidSubmissionRequest);
  const [updateBid] = CustomDispatch(updateBidRequest);

  //REDUX DATA
  const {
    isSeller,
    data: userData,
    isAuthenticated,
    profileData,
  } = useSelector((state) => state?.user);

  useEffect(() => {
    getBidDetail({
      pathParams: id,
      // query for bid detail
      queryParams: BID_QUERY_POPULATE_PARAMS_DETAIL,
      logic(res) {
        setData(res);

        if (isSeller && res?.publicOpening == "No") {
          const isMyEmailIncluded = res?.suppliers?.includes(userData?.email);

          if (!isMyEmailIncluded) {
            toastAlert("You are not allowed to view this bid");
            navigate(DASHBOARD_ROUTE);
          }
        }
      },
      error(err) {
        toastAlert("Bid not found");
        navigate(-1);
      },
    });

    if (!isAuthenticated) return;

    if (isSeller)
      getSubmittedBid({
        queryParams: getSubmittedBidQuery(id, userData?.id),
        logic(res) {
          const bData = singleSubmittedBidManipulator(res?.[0]);
          setSubmittedBid(bData);
        },
        error(err) {},
      });

    if (!isSeller) {
      getSubmittedBid({
        queryParams: getSubmittedBidQuery(id),
        logic(res) {
          const bData = getSubmittedBidsManipulator(res);
          setBidSubmissionData(bData);
        },
        error(err) {},
      });
    }
  }, [id]);

  console.log({ bidSubmissionData });

  //HANDLERS
  const activeTabFunction = (val) => {
    setCurrentBidTabActive(val);
  };

  console.log({ submittedBid });

  const handleAddInquiry = (val, callback = () => {}) => {
    const inquiries = [
      ...submittedBid?.inquiry,
      { description: val?.cancelReason, isSeller: true },
    ];

    const inquiyPayload = inquiries?.map((item) => ({
      description: item.description,
      is_seller: item.isSeller,
    }));

    const payload = {
      data: {
        inquiry: inquiyPayload,
        isInquiry: true,
        fromSeller: true,
      },
    };

    updateSubmittedBid({
      payload,
      pathParams: submittedBid?.id,
      logic: () => {
        toastAlert("Inquiry submitted successfully.");
        setSubmittedBid({ ...submittedBid, inquiry: inquiries });
        callback();
      },
      error(err) {
        console.error("updateBidSubmission errr", err);
      },
    });
  };

  const handleConfirmCancelBid = (val) => {
    const payload = {
      data: {
        status: "Terminated",
        cancellation_reason: val?.reason ?? "",
      },
    };

    updateBid({
      payload,
      pathParams: id,
      logic: () => {
        setDeleteModalPreview(false);
        toastAlert("Bid cancelled successfully.");
        navigate(-1);
      },
    });
  };

  const cancelModalPreviewHandler = () => {
    setCancelModalPreview(!cancelModalPreview);
  };
  const confirmModalPreviewHandler = () => {
    setConfirmModalPreview(!confirmModalPreview);
  };

  const handleCancelBid = (val) => {
    const payload = {
      data: {
        status: BID_STATUS_KEYS.cancelled,
        cancellation_reason: val?.cancelReason ?? "",
      },
    };

    updateBid({
      payload,
      pathParams: id,
      logic: () => {
        setConfirmModalPreview(false);
        toastAlert("Bid cancelled successfully.");
        navigate(DASHBOARD_ROUTE);
      },
    });
  };
  const handleConfirmBid = (val) => {
    const payload = {
      data: {
        amount: val?.amount ?? "",
        status: BID_STATUS_KEYS.completed,
      },
    };

    updateBid({
      payload,
      pathParams: id,
      logic: () => {
        setConfirmModalPreview(false);
        toastAlert("Bid Completed successfully.");
        navigate(DASHBOARD_ROUTE);
      },
    });
  };

  const handleConfirmBidSubmission = (bidSubmissionId, callback = () => {}) => {
    const payload = {
      data: {
        confirm_bids: { connect: [{ id: bidSubmissionId }] },
        confirmingBid: true,
        bidSubId: bidSubmissionId,
      },
    };

    updateBid({
      payload,
      pathParams: id,
      logic: () => {
        callback();
        toastAlert("Bid Submission Confirmed successfully.");
        navigate(DASHBOARD_ROUTE);
      },
    });
  };

  console.log({ data });

  return (
    <div className="bidDetailMainWrapper">
      <div className="backArrowWrapper">
        <Images.LeftArrow className="c-p" onClick={() => navigate(-1)} />
        <CommonTextField
          text={"Bid Details"}
          fontSize={"34px"}
          color={"rgba(2, 19, 73, 1)"}
          lineHeight={"42px"}
          letterSpacing={"-2%"}
          fontFamily={"seventyTwoBold"}
        />
      </div>

      {isSeller && (
        <div className="tabsWrapper">
          <div
            className={`tabsItemWrapper tabsItemWrapper1 ${
              currentBidTabActive === true ? "activeTab" : ""
            }`}
            onClick={() => activeTabFunction(true)}
          >
            <CommonTextField
              text={"Current Bid"}
              fontSize={"16px"}
              lineHeight={"18.4px"}
              fontFamily={"seventyTwoSemiBold"}
              textAlign={"center"}
            />
          </div>
          <div
            className={`tabsItemWrapper tabsItemWrapper2 ${
              currentBidTabActive === false ? "activeTab" : ""
            }`}
            onClick={() => activeTabFunction(false)}
          >
            <CommonTextField
              text={"More Bids"}
              fontSize={"16px"}
              lineHeight={"18.4px"}
              fontFamily={"seventyTwoSemiBold"}
              textAlign={"center"}
            />
          </div>
        </div>
      )}

      <div
        className={`${isSeller ? "" : "sellerMainWrapper"} ContentMainWrapper`}
      >
        {currentBidTabActive && (
          <div className="CurrentBitContentWrapper">
            <div className="bid-header">
              <img src={Images.Upsun} />
              <div className="bid-options-wrapper">
                {data?.category === "history" && (
                  <span
                    className={`bid-status ${
                      data?.isCancelled ? "cancel" : "complete"
                    }`}
                  >
                    {data?.isCancelled ? "Cancelled" : "Completed"}
                  </span>
                )}
                {!isSeller &&
                  data?.status !== BID_STATUS_KEYS.cancelled &&
                  data?.status != BID_STATUS_KEYS.completed && (
                    <CommonThreeDotMenu
                      onEdit={() =>
                        navigate(
                          replaceValInString(EDIT_BID_ROUTE, {
                            ":id": data.key,
                          })
                        )
                      }
                      onDelete={cancelModalPreviewHandler}
                    />
                  )}
              </div>
            </div>
            <CommonBidData
              id={data?.category}
              data={data}
              isSeller={isSeller}
            />

            {isSeller && submittedBid?.id && (
              <YourBid
                ongoing={
                  data?.status !== BID_STATUS_KEYS.completed &&
                  data?.status !== BID_STATUS_KEYS.cancelled &&
                  submittedBid?.isWithdraw !== true
                }
                isCancelled={data?.status == BID_STATUS_KEYS.cancelled}
                bidId={data?.key}
                submittedBidId={submittedBid?.id}
                submittedBid={submittedBid}
                handleAddInquiry={handleAddInquiry}
              />
            )}

            {/* {isSeller &&
              (data?.status === "Open" ? (
                <></>
              ) : submittedBid?.id === "ongoing" ? (
                <YourBid ongoing={true} />
              ) : data?.status === "active" ? (
                <YourBid />
              ) : (
                <YourBid isCancelled={data?.isCancelled} />
              ))} */}

            {isAuthenticated &&
              !isSeller &&
              (data?.status === BID_STATUS_KEYS.open ||
                data?.status == BID_STATUS_KEYS.closed) && (
                <>
                  <SellerBidSubmission
                    bidDetail={data}
                    data={bidSubmissionData}
                    active={bidSubmissionData?.length > 0}
                    onBidConfirm={handleConfirmBidSubmission}
                  />

                  <div className="buttonWrapper">
                    <CommonButton
                      text={"Mark as Cancel"}
                      background={"#FF8484"}
                      fontSize={"18px"}
                      classname={"buttons"}
                      fontFamily={"seventyTwoBold"}
                      onClick={cancelModalPreviewHandler}
                    />
                    <CommonButton
                      text={"Mark as Completed"}
                      fontSize={"18px"}
                      classname={"buttons"}
                      fontFamily={"seventyTwoBold"}
                      onClick={confirmModalPreviewHandler}
                    />
                  </div>
                </>
              )}

            {isAuthenticated &&
              !isSeller &&
              data?.status !== BID_STATUS_KEYS.open &&
              data?.status !== BID_STATUS_KEYS.closed && (
                <>
                  <SellerBidSubmission
                    bidDetail={data}
                    data={bidSubmissionData}
                    active={false}
                    isCancelled={data?.status == BID_STATUS_KEYS.cancelled}
                    history={true}
                  />

                  {data?.status == BID_STATUS_KEYS.cancelled && (
                    <div className="cancel-reason">
                      <CommonTextField
                        fontFamily={"seventyTwoRegular"}
                        fontSize={"12px"}
                        className={"light-text"}
                        text="Cancellation Reason"
                      />
                      <CommonTextField text={data?.cancellationReason} />
                    </div>
                  )}
                </>
              )}

            {isAuthenticated &&
              isSeller &&
              data?.status === BID_STATUS_KEYS.open &&
              !submittedBid?.id && (
                <div className="buttonWrapper">
                  <CommonButton
                    text={"Send Bid"}
                    fontSize={"16px"}
                    classname={"buttons"}
                    fontFamily={"seventyTwoBold"}
                    onClick={() => {
                      if (!profileData?.user?.allowedJobs) {
                        toastAlert(
                          "Your subscription is inactive or the limit is reached. Please subscribe or upgrade to continue",
                          ALERT_TYPES.ERROR
                        );
                        return;
                      }
                      navigate(
                        replaceValInString(STEPS_DETAIL, {
                          ":id": data.key,
                        })
                      );
                    }}
                  />
                </div>
              )}

            {!isAuthenticated && (
              <div className="buttonWrapper">
                <CommonButton
                  text={"Send Bid"}
                  fontSize={"16px"}
                  classname={"buttons"}
                  fontFamily={"seventyTwoBold"}
                  onClick={() => setShowModalForLogin(true)}
                />
              </div>
            )}
          </div>
        )}

        {!currentBidTabActive && <MoreBidsSection />}
      </div>

      <CommonModal
        isModalVisible={showModalForLogin}
        setIsModalVisible={setShowModalForLogin}
        discription="Please click here to sign up."
        descriptionTitle="Do you want to sign up?"
        confirmText="Signup"
        onConfirm={() => {
          setShowModalForLogin(false);
          navigate(SIGN_UP_ROUTE);
        }}
      ></CommonModal>

      <CancelBidModal
        preview={cancelModalPreview}
        handleClose={cancelModalPreviewHandler}
        handleConfirm={handleCancelBid}
      />
      <ConfirmBidModal
        preview={confirmModalPreview}
        handleClose={confirmModalPreviewHandler}
        handleConfirm={handleConfirmBid}
      />
    </div>
  );
}

export default BidDetails;
