import { createSlice } from "@reduxjs/toolkit";

const UserReducer = createSlice({
  name: "user",
  initialState: {
    data: {},
    companyDetails: {},
    isAuthenticated: false,
    isSeller: null,
    card: null,
    signUpEmail: "",
    profileData: {},
    verificationEmail: "",
    resetOtp: "",
    packages: [],
    subscribedPackages: [],
  },
  reducers: {
    // USER LOGIN
    loginRequest() {},
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.data = action.payload;
      state.isSeller = !action.payload.isBuyer;
    },

    // USER SIGNOUT
    logoutRequest(state, action) {
      state.isAuthenticated = false;
      state.data = {};
      state.isSeller = false;
      state.card = null;
      state.profileData.company = {};
      state.profileData.user = {};
    },

    //GET OTP
    getOtpRequest(state, action) {},

    //CONFIRM OTP
    confirmOtpRequest(state, action) {},

    //SIGN UP
    signUpRequest(state, action) {},
    signUpSuccess(state, action) {
      state.data = action.payload;
      state.isSeller = !action.payload.isBuyer;
    },

    //CREATE COMPANY REQUEST
    createCompanyRequest() {},
    createCompanySuccess(state, action) {
      state.companyDetails = action.payload;
    },

    setSginupRoleRequest(state, action) {
      state.isSeller = action.payload.payload.isSeller;
    },

    addCardRequest(state, action) {
      state.card = action.payload.payload;
    },

    uploadImageRequest() {},

    authenticateUser(state, action) {
      state.isAuthenticated = true;
    },

    //GET PROFILE DATA
    getProfileDataRequest() {},
    getProfileDataSuccess(state, action) {
      console.log(action.payload, "res");
      state.profileData = action.payload;
    },

    setVerificationEmail(state, action) {
      console.log(action, "res");
      state.verificationEmail = action.payload.payload;
    },

    setResetOtp(state, action) {
      state.resetOtp = action.payload.payload;
    },

    resetPasswordRequest(state, action) {},
    resetPasswordSuccess(state, action) {},

    getPackagesRequest() {},
    getPackagesSuccess(state, action) {
      console.log(action.payload, "res");
      state.packages = action.payload;
    },

    purchasePackagesRequest() {},
    purchasePackagesSuccess(state, action) {},

    updateProfileRequest() {},
    updateProfileSuccess(state, action) {
      state.data = { ...state.data, ...action.payload };
      state.profileData.user = action.payload;
    },

    updateCompanyDetailRequest() {},
    updateCompanyDetailSuccess(state, action) {
      state.profileData.company = action.payload;
      state.companyDetails = action.payload;
    },

    changePasswordRequest() {},
    changePasswordSuccess() {},

    getSubscribedPackageRequest() {},
    getSubscribedPackageSuccess(state, action) {
      const data = action.payload?.map((item) => ({
        ...item,
        ...item?.attributes,
        subscription: {
          id: item?.attributes?.subscription?.data?.id,
          ...item?.attributes?.subscription?.data?.attributes,
          amount: item?.attributes?.subscription?.data?.attributes?.amount,
        },
      }));

      state.subscribedPackages = data;
    },

    cancelSubscriptionRequest() {},
    cancelSubscriptionSuccess(state) {
      state.subscribedPackages = [];
    },

    updateCardRequest() {},
    updateCardSuccess() {},

    fetchPlansRequest() {},
    fetchPlansSuccess() {},

    initializeSubscriptionRequest() {},
    initializeSubscriptionSuccess() {},

    verifySubscriptionRequest() {},
    verifySubscriptionSuccess() {},
  },
});

export const {
  loginRequest,
  loginSuccess,
  logoutRequest,
  setSginupRoleRequest,
  addCardRequest,
  getOtpRequest,
  confirmOtpRequest,
  signUpRequest,
  signUpSuccess,
  createCompanyRequest,
  createCompanySuccess,
  uploadImageRequest,
  authenticateUser,
  getProfileDataRequest,
  getProfileDataSuccess,
  setVerificationEmail,
  setResetOtp,
  resetPasswordRequest,
  resetPasswordSuccess,
  getPackagesRequest,
  getPackagesSuccess,
  purchasePackagesRequest,
  purchasePackagesSuccess,

  updateProfileRequest,
  updateProfileSuccess,

  updateCompanyDetailRequest,
  updateCompanyDetailSuccess,

  changePasswordRequest,
  getSubscribedPackageRequest,
  getSubscribedPackageSuccess,

  cancelSubscriptionRequest,
  cancelSubscriptionSuccess,

  updateCardRequest,
  updateCardSuccess,

  fetchPlansRequest,
  fetchPlansSuccess,

  initializeSubscriptionRequest,
  initializeSubscriptionSuccess,

  verifySubscriptionRequest,
  verifySubscriptionSuccess,
} = UserReducer.actions;

export default UserReducer.reducer;
