import React from "react";
import { Col, Form, Modal, Row } from "antd";
import "./styles.scss";
import { Images } from "../../../../theme";
import {
  CommonButton,
  CommonInputField,
  CommonPasswordInput,
  CommonTextField,
} from "../../../common";
import {
  creditCardValidator,
  inputFieldRule,
  isPasswordValid,
} from "../../../../utils";
import { CustomDispatch } from "../../../../helpers";
import {
  changePasswordRequest,
  updateProfileRequest,
} from "../../../../redux/slicers/user";
import moment from "moment";

const ChangePasswordModal = ({
  handleClose,
  handleFinish,
  preview,
  profileData = {},
}) => {
  const [form] = Form.useForm();

  const [updatePassword, loading] = CustomDispatch(changePasswordRequest);
  const [updateUserProfile, loadingProfile] =
    CustomDispatch(updateProfileRequest);

  const handleSubmit = (val) => {
    updatePassword({
      payload: val,
      logic: () => {
        updateUserProfile({
          payload: {
            password_updated: moment().format("YYYY-MM-DD"),
          },
          pathParams: {
            id: profileData?.user?.id,
          },
          logic: () => {
            handleFinish();
          },
        });
      },
    });
  };

  return (
    <Modal
      destroyOnClose
      open={preview}
      centered
      footer={null}
      width={636}
      className="edit-profile-modal"
      closeIcon={false}
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={handleClose}>
            <Images.CloseIcon />
          </div>
          <div className="title">
            <h3>Change Password</h3>
          </div>
          <Form form={form} onFinish={handleSubmit}>
            <Row gutter={[12, 0]}>
              <Col span={24}>
                <CommonPasswordInput
                  placeholder={"Current Password"}
                  name="currentPassword"
                  rules={[
                    ...inputFieldRule({
                      name: "Current password",
                      isWhiteSpace: false,
                    }),
                    () => ({
                      validator(_, value) {
                        if (!value || isPasswordValid(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "Password must contain 8 characters including 1 small letter, 1 capital letter, 1 digit and 1 special character!"
                          )
                        );
                      },
                    }),
                  ]}
                />
              </Col>
              <Col span={24}>
                <CommonPasswordInput
                  placeholder={"New Password"}
                  name="password"
                  rules={[
                    ...inputFieldRule({
                      name: "New password",
                      isWhiteSpace: false,
                    }),
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || isPasswordValid(value)) {
                          if (
                            !value ||
                            getFieldValue("currentPassword") !== value
                          ) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              new Error(
                                "New password can not be the same as the current password."
                              )
                            );
                          }
                        } else {
                          return Promise.reject(
                            new Error(
                              "Password must contain 8 characters including 1 small letter, 1 capital letter, 1 digit, and 1 special character!"
                            )
                          );
                        }
                      },
                    }),
                  ]}
                />
              </Col>
              <Col span={24}>
                <CommonPasswordInput
                  placeholder={"Confirm New Password"}
                  name="passwordConfirmation"
                  rules={[
                    ...inputFieldRule({
                      name: "Confirm Password",
                      isWhiteSpace: false,
                      requiredMessage: "Please re-enter your new password.",
                    }),
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                />
              </Col>
              <Col span={24}>
                <CommonButton
                  text={"Update"}
                  width="100%"
                  borderRadius={"12px"}
                  htmlType="submit"
                  height={"56px"}
                  loading={loading || loadingProfile}
                />
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
