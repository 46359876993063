import { Avatar, Space } from "antd";
import React, { useState } from "react";
import { Images } from "../../../../theme";
import { CommonButton, CommonTextField } from "../../../common";
import "./styles.scss";
import SellerSubmissionModal from "../sellerSubModal";

const SellerBidSubmission = ({
  bidDetail = null,
  data = [],
  onBidConfirm = () => {},
}) => {
  //STATES
  const [subDetailModalPreview, setSubDetailModalPreview] = useState(false);
  const [bidInfo, setBidInfo] = useState(null);

  //HANDLERS

  const subDetailModalPreviewHandler = (info) => {
    setBidInfo(info);
    setSubDetailModalPreview(!subDetailModalPreview);
  };

  const handleConfirmBid = (id) => {
    onBidConfirm(id, () => {
      setSubDetailModalPreview(false);
    });
  };

  console.log({ bidDetail, data });

  const confirm_bids = bidDetail?.confirm_bids ?? [];

  return (
    <div className="sellerbid-parent">
      <CommonTextField
        text={"Seller Bid Submissions"}
        fontFamily={"seventyTwoSemiBold"}
        fontSize={"24px"}
        lineHeight={"36px"}
        color={"rgba(2, 19, 73, 1)"}
      />
      <div className="list-parent">
        {data?.map((item) => (
          <div key={item.id} className="sellerbid-card">
            <Space>
              <Avatar size={51} src={<Images.UserAvatar />} />
              <Space size={1} direction="vertical">
                <CommonTextField
                  fontFamily={"seventyTwoRegular"}
                  className={""}
                  fontSize={"14px"}
                  text={`${item?.user?.fullName} `}
                  lineHeight={"10px"}
                />
                <CommonTextField
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"12px"}
                  className={"light-text"}
                  text={item.supplierTitle}
                />
              </Space>
            </Space>
            <CommonButton
              text={"View Details"}
              background={"rgba(3, 25, 70, 1)"}
              fontFamily={"seventyTwoBold"}
              fontSize={"16px"}
              onClick={() => subDetailModalPreviewHandler(item)}
            />
          </div>
        ))}
      </div>

      <SellerSubmissionModal
        confirm_bids={confirm_bids}
        bidInfo={bidInfo}
        preview={subDetailModalPreview}
        handleClose={() => subDetailModalPreviewHandler(null)}
        handleConfirm={handleConfirmBid}
      />
    </div>
  );
};

export default SellerBidSubmission;
