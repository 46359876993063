import React from "react";
import "./styles.scss";

import { Col, Row } from "antd";
import { CommonHeading, CommonTextField } from "../../../components/common";
import { PublicFooter } from "../../../components";
const About = () => {
  return (
    <section className="about-wrapper">
      <div className="aboutWrapper">
        <div className="container">
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <CommonHeading
                color={"#021349"}
                text="About Us"
                fontFamily={"seventyTwoBold"}
                className={"aboutusHead"}
                lineHeight={"48.99px"}
                mt={20}
              />
              <CommonTextField
                color="#465E7D"
                text="At Central Bids, we redefine the bidding landscape by uniting private businesses and government entities on a single, intuitive platform. Our goal is to cultivate a dynamic marketplace where buyers and suppliers engage effortlessly, ensuring fair, efficient, and transparent procurement for all."
                mt={0}
                fontFamily={"seventyTwoRegular"}
                fontSize={"16px"}
                lineHeight={"24px"}
              />
            </Col>

            <Col span={24}>
              <CommonHeading
                color={"#021349"}
                text="Our Vision"
                fontFamily={"seventyTwoBold"}
                className={"aboutusHead"}
                lineHeight={"48.99px"}
                mt={0}
              />
              <CommonTextField
                color="#465E7D"
                text={
                  "To become the leading global platform for transparent and competitive bidding, enabling organizations to achieve optimal procurement outcomes."
                }
                mt={0}
                fontFamily={"seventyTwoRegular"}
                fontSize={"16px"}
                lineHeight={"24px"}
              />
            </Col>

            <Col span={24}>
              <CommonHeading
                color={"#021349"}
                text="Our Mission"
                fontFamily={"seventyTwoBold"}
                className={"aboutusHead"}
                lineHeight={"48.99px"}
                mt={0}
              />
              <CommonTextField
                color="#465E7D"
                text={
                  "To provide a secure, efficient, and transparent bidding environment that connects buyers with the best vendors, ensuring quality and cost-effectiveness."
                }
                mt={0}
                fontFamily={"seventyTwoRegular"}
                fontSize={"16px"}
                lineHeight={"24px"}
              />
            </Col>

            <Col md={24} lg={24} className="left-side">
              <CommonHeading
                text="Why Central Bid?"
                fontFamily={"seventyTwoBold"}
                className={"aboutusHead"}
                color={"#021349"}
                lineHeight={"48.99px"}
                mb={"10px"}
                mt={0}
              />
              <CommonTextField
                fontFamily="seventyTwoSemiBold"
                fontSize={"18px"}
                lineHeight="24px"
                color={"#465E7D"}
                mb="5px"
                text="Streamlined Procurement"
              />
              <CommonTextField
                fontFamily="seventyTwoLight"
                fontSize={"16px"}
                lineHeight="24px"
                color={"#465E7D"}
                mb="20px"
                text="Effortlessly post, manage, and track bids for smooth procurement."
              />
              <CommonTextField
                fontFamily="seventyTwoSemiBold"
                fontSize={"18px"}
                lineHeight="24px"
                color={"#465E7D"}
                mb="5px"
                text="Access to Top Vendors"
              />
              <CommonTextField
                fontFamily="seventyTwoLight"
                fontSize={"16px"}
                lineHeight="24px"
                color={"#465E7D"}
                mb="20px"
                text={"Connect with verified, high-quality vendors."}
              />
              <CommonTextField
                fontFamily="seventyTwoSemiBold"
                fontSize={"18px"}
                lineHeight="24px"
                color={"#465E7D"}
                mb="5px"
                text="Competitive Pricing"
              />
              <CommonTextField
                fontFamily="seventyTwoLight"
                fontSize={"16px"}
                lineHeight="24px"
                color={"#465E7D"}
                mb="20px"
                text={"Secure the best deals through competitive bidding."}
              />
              <CommonTextField
                fontFamily="seventyTwoSemiBold"
                fontSize={"18px"}
                lineHeight="24px"
                color={"#465E7D"}
                mb="5px"
                text="Transparency and Accountability"
              />
              <CommonTextField
                fontFamily="seventyTwoLight"
                fontSize={"16px"}
                lineHeight="24px"
                color={"#465E7D"}
                mb="20px"
                text={"Ensure clear audit trails and accountability."}
              />
              <CommonTextField
                fontFamily="seventyTwoSemiBold"
                fontSize={"18px"}
                lineHeight="24px"
                color={"#465E7D"}
                mb="5px"
                text="Real-Time Updates"
              />
              <CommonTextField
                fontFamily="seventyTwoLight"
                fontSize={"16px"}
                lineHeight="24px"
                color={"#465E7D"}
                mb="20px"
                text={"Receive instant notifications on bid activities."}
              />
              <CommonTextField
                fontFamily="seventyTwoSemiBold"
                fontSize={"18px"}
                lineHeight="24px"
                color={"#465E7D"}
                mb="5px"
                text="Secure and Reliable"
              />
              <CommonTextField
                fontFamily="seventyTwoLight"
                fontSize={"16px"}
                lineHeight="24px"
                color={"#465E7D"}
                mb="30px"
                text={
                  "Experience robust security for all your data and transactions."
                }
              />

              <CommonTextField
                fontFamily="seventyTwoLight"
                fontSize={"16px"}
                lineHeight="24px"
                color={"#465E7D"}
                mb="50px"
                text={
                  "Choose Central Bids to revolutionize your procurement process, connect with top vendors, and achieve optimal procurement outcomes. Join us today and experience the future of bidding."
                }
              />
            </Col>
          </Row>
        </div>
      </div>

      <PublicFooter />
    </section>
  );
};

export default About;
