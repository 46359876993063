import { Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { Images } from "../../../../theme";
import { CommonTextField } from "../../../common";
import "./styles.scss";
import {
  ABOUT_ROUTE,
  HOME_ROUTE,
  PRIVACY_ROUTE,
  TERMS_ROUTE
} from "../../../../constants";

function PublicFooter() {
  return (
    <div className="public-footer">
      <div className="container">
        <Row gutter={[30, 20]}>
          <Col xs={24} lg={7}>
            <Images.FooterLogo />
            <CommonTextField
              text={
                "Choose us to revolutionize your procurement process, connect with top vendors, and achieve optimal procurement outcomes. Join us today and experience the future of bidding."
              }
              fontFamily="seventyTwoLight"
              fontSize={"16px"}
              color="#465E7D"
              lineHeight={"24px"}
              mt="18px"
            />
          </Col>
          <Col xs={24} lg={4}>
            <CommonTextField
              text={"Quick Links"}
              fontFamily="seventyTwoSemiBold"
              fontSize={"16px"}
              color="#2B3144"
              lineHeight={"18px"}
              mb="17px"
            />
            <div className="seperator"></div>
            <div className="links-wrapper">
              <Link to={HOME_ROUTE} className="link-item">
                Home
              </Link>
              <Link to={ABOUT_ROUTE} className="link-item">
                About Us
              </Link>
              <Link to={TERMS_ROUTE} className="link-item">
                Terms & Conditions
              </Link>
              <Link to={PRIVACY_ROUTE} className="link-item">
                Privacy Policy
              </Link>
              {/* <Link className="link-item">Contact</Link> */}
            </div>
          </Col>
          <Col xs={24} lg={8}>
            <CommonTextField
              text={"Contact Us"}
              fontFamily="seventyTwoSemiBold"
              fontSize={"16px"}
              color="#2B3144"
              lineHeight={"18px"}
              mb="17px"
            />
            <div className="seperator"></div>
            <div className="contacts-wrapper">
              <div className="contact-item">
                <Images.LocationIcon />
                <CommonTextField
                  text={"6391 Elgin St. Celina, Delaware 10299"}
                  fontFamily="seventyTwoLight"
                  fontSize={"16px"}
                  color="#465E7D"
                  lineHeight={"27px"}
                />
              </div>
              <div className="contact-item">
                <Images.CallIcon />
                <CommonTextField
                  text={"+0 123 456 7890"}
                  fontFamily="seventyTwoLight"
                  fontSize={"16px"}
                  color="#465E7D"
                  lineHeight={"27px"}
                />
              </div>
              <div className="contact-item">
                <Images.MailIcon />
                <CommonTextField
                  text={"john.smith@domain.com"}
                  fontFamily="seventyTwoLight"
                  fontSize={"16px"}
                  color="#465E7D"
                  lineHeight={"27px"}
                />
              </div>
            </div>
          </Col>
          <Col xs={24} lg={5}>
            <CommonTextField
              text={"Follow Us"}
              fontFamily="seventyTwoSemiBold"
              fontSize={"16px"}
              color="#2B3144"
              lineHeight={"18px"}
              mb="17px"
            />
            <div className="seperator"></div>
            <div className="social-wrapper">
              <a
                className="social-item"
                href="https://www.facebook.com/"
                target="_blank"
              >
                <Images.Facebook />
              </a>
              <a
                className="social-item"
                target="_blank"
                href="https://www.twitter.com/"
              >
                <Images.Twitter />
              </a>
              <a
                className="social-item"
                target="_blank"
                href="https://www.linkedin.com/"
              >
                <Images.Linkedin />
              </a>
              <a
                className="social-item"
                target="_blank"
                href="https://www.instagram.com/"
              >
                <Images.Instagram />
              </a>
            </div>
          </Col>
        </Row>
      </div>
      <div className="copyright-text">
        <CommonTextField
          text={"Central Bids 2024. All Rights Reserved"}
          fontFamily="seventyTwoLight"
          fontSize={"16px"}
          color="#465E7D"
        />
      </div>
    </div>
  );
}

export default PublicFooter;
