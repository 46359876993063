import { fork } from "redux-saga/effects";
import user from "./user";
import bid from "./bid";
import notification from "./notification";

export default function* root() {
  yield fork(user);
  yield fork(bid);
  yield fork(notification);
}
