/* eslint-disable react/prop-types */
import React from "react";
import { Form, Input } from "antd";
import "./styles.scss";
import { Images } from "../../../theme";

const CommonPasswordInput = ({
  placeholder,
  name,
  className,
  type,
  height,
  rules,
  autoFocus,
  reference,
  onKeyDown,
  prefix,
}) => {
  return (
    <Form.Item name={name} rules={rules}>
      <Input.Password
        onKeyDown={onKeyDown}
        ref={reference}
        autoFocus={autoFocus}
        prefix={prefix}
        iconRender={(visible) =>
          visible ? (
            <img width={"22px"} src={Images.EyeOpen} />
          ) : (
            <img width={"22px"} src={Images.EyeClose} />
          )
        }
        style={{ height }}
        type={type}
        className={`ad-input  ${className || "common-password"}`}
        placeholder={placeholder}
      />
    </Form.Item>
  );
};

export default CommonPasswordInput;
