import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllNotificationsRequest } from "../redux/slicers/notification";
import { getProfileDataRequest } from "../redux/slicers/user";

const WindowFocusHandler = ({ children }) => {
  const userId = useSelector((state) => state?.user?.data?.id);
  const dispatch = useDispatch();

  // User has switched back to the tab
  const onFocus = () => {
    if (userId) {
      dispatch(
        getAllNotificationsRequest({
          queryParams: `filters[user][id][$eq]=${userId}&pagination[pageSize]=${500000}&sort=id:desc&populate=user.company_detail`,
        })
      );
      dispatch(
        getProfileDataRequest({
          responseCallback: () => {},
        })
      );
    }
  };

  useEffect(() => {
    window.addEventListener("visibilitychange", onFocus);

    return () => {
      window.removeEventListener("visibilitychange", onFocus);
    };
  }, []);

  return <>{children}</>;
};
export default WindowFocusHandler;
