import React from "react";
import "./styles.scss";
import {
  BidOpportunity,
  HeroSection,
  HomeBidTable,
  IndustrySection,
  Pricing,
  PublicFooter,
  WhyCentral,
} from "../../../components";
const Home = () => {
  return (
    <section className="home-wrapper">
      <HeroSection />
      <WhyCentral />
      <IndustrySection />
      <BidOpportunity />
      <HomeBidTable />
      <Pricing />
      <PublicFooter />
    </section>
  );
};

export default Home;
