import _ from "lodash";

export function classificationManipulator(list) {
  if (_.isEmpty(list)) return [];

  try {
    const res = [];

    for (let item of list) {
      const attr = item?.attributes;
      const payload = {
        id: `${item?.id}`,
        value: `${item?.id}`,
        title: attr?.title,
        label: attr?.title,
      };

      res.push(payload);
    }

    return res;
  } catch (error) {
    console.error("classificationManipulator error -->>", error);
    return [];
  }
}

export function subCategoriesManipulator(list) {
  if (_.isEmpty(list)) return [];

  try {
    const res = [];

    for (let item of list) {
      const payload = {
        id: `${item?.id}`,
        value: `${item?.id}`,
        title: item?.title,
        label: item?.title,
        parentId: item?.category?.id,
      };

      res.push(payload);
    }

    return res;
  } catch (error) {
    console.error("subCategoriesManipulator error -->>", error);
    return [];
  }
}

export const getBidsManipulator = (list) => {
  if (_.isEmpty(list)) return [];

  try {
    const res = [];

    for (let item of list) {
      const payload = singleBidManipulator(item);
      if (!_.isEmpty(payload)) res.push(payload);
    }

    return res;
  } catch (error) {
    console.error("getBidsManipulator error -->>", error);
    return [];
  }
};

export const singleBidManipulator = (data) => {
  if (_.isEmpty(data)) return {};
  try {
    const attr = data?.attributes;

    const payload = {
      id: `${data?.id}`,
      key: `${data?.id}`,
      bidName: attr?.title,
      bidNumber: attr?.bid_number,
      displayName: `${attr?.bid_number} - ${attr?.title}`,
      description: attr?.description,
      publishedDate: attr?.createdAt,
      closingDate: attr?.closing_date,
      openingDate: attr?.opening_date,
      status: attr?.status,
      electronicAuction: attr?.electronic_auction,
      submissionType: attr?.submission_type,
      submissionStatus: attr?.submission_status,
      publicOpening: attr?.publicOpening,
      expectedDuration: attr?.expected_duration,
      cpName: attr?.cp_name,
      cpTitle: attr?.cp_title,
      cpPhoneNumber: attr?.cp_phone_number,
      cpEmail: attr?.cp_email,
      submissionAddress: attr?.submission_address,
      purchaseEmployee: attr?.purchase_employee,
      purchaseName: attr?.purchase_name,
      purchasePhone: attr?.purchase_phone,
      purchaseEmail: attr?.purchase_email,
      purchaseRole: attr?.purchase_role,
      cancellationReason: attr?.cancellation_reason,
      category: attr?.categories?.data?.map((t) => ({
        title: t?.attributes?.title,
        id: t?.id,
      })),
      subCategory: attr?.sub_categories?.data?.map((t) => ({
        title: t?.attributes?.title,
        id: t?.id,
        parentId: t?.attributes.category.data.id,
      })),
      classification: {
        title: attr?.classification?.data?.attributes?.title,
        id: attr?.classification?.data?.id,
      },
      negotiationType: {
        title: attr?.negotitation_type?.data?.attributes?.title,
        id: attr?.negotitation_type?.data?.id,
      },
      participantCondition: {
        title: attr?.participant_condition?.data?.attributes?.title,
        id: attr?.participant_condition?.data?.id,
      },
      bidType: {
        title: attr?.bid_type?.data?.attributes?.title,
        id: attr?.bid_type?.data?.id,
      },
      user: {
        id: attr?.user?.data?.id,
        ...attr?.user?.data?.attributes,
      },
      suppliers: attr?.suppliers?.map?.((item) => item?.email),
      documents:
        attr?.documents?.data?.map?.((item) => ({
          id: item?.id,
          ...item?.attributes,
        })) || [],
      addendas:
        attr?.addendas?.data?.map?.((item) => ({
          id: item?.id,
          ...item?.attributes,
        })) || [],
      confirm_bids:
        attr?.confirm_bids?.data?.map?.((item) => ({
          id: item?.id,
          ...item?.attributes,
        })) ?? [],
    };
    const compDetail =
      attr?.user?.data?.attributes?.company_detail?.data?.attributes;

    const companyDetailsObject = {
      ...compDetail,
      logo: compDetail?.companyLogo?.data?.attributes?.url,
      userFirstName: attr?.user?.data?.attributes?.firstName,
      userLastName: attr?.user?.data?.attributes?.lastName,
      userFullName: attr?.user?.data?.attributes?.fullName,
      userEmail: attr?.user?.data?.attributes?.email,
      userPhone: attr?.user?.data?.attributes?.phone,
      updatedAt: attr?.user?.data?.attributes?.updatedAt,
    };

    payload.companyDetail = {
      ...companyDetailsObject,
    };

    return payload;
  } catch (error) {
    console.error("singleBidManipulator error -->>", error);
    return {};
  }
};

export const getSubmittedBidsManipulator = (list) => {
  if (_.isEmpty(list)) return [];

  const res = [];

  try {
    for (let item of list) {
      const payload = singleSubmittedBidManipulator(item);
      if (!_.isEmpty(payload)) res.push(payload);
    }

    return res;
  } catch (error) {
    console.error("getSubmittedBidsManipulator error -->>", error);
    return [];
  }
};

export const singleSubmittedBidManipulator = (data) => {
  if (_.isEmpty(data)) return {};

  try {
    const attr = data?.attributes;

    const payload = {
      id: data?.id,
      addendum: attr?.addendum_terms,
      updatedAt: attr?.updatedAt,
      supplierCompany: attr?.supplier_company,
      supplierFirstName: attr?.supplier_first_name,
      supplierLastName: attr?.supplier_last_name,
      supplierTitle: attr?.supplier_title,
      isWithdraw: attr?.is_withdraw,
      appendix: {
        id: attr?.appendix?.data?.id,
        ...attr?.appendix?.data?.attributes,
      },
      healthSafety: {
        id: attr?.health_safety?.data?.id,
        ...attr?.health_safety?.data?.attributes,
      },
      other: {
        id: attr?.other?.data?.id,
        ...attr?.other?.data?.attributes,
      },
      proposal: {
        id: attr?.proposal?.data?.id,
        ...attr?.proposal?.data?.attributes,
      },
      previewBid: {
        id: attr?.preview_bid?.data?.id,
        ...attr?.preview_bid?.data?.attributes,
      },
      pricing: attr?.pricing?.map((item) => ({
        ...item,
        description: item?.title,
      })),
      references:
        attr?.references?.map((item) => ({
          businessLength: item?.business_length,
          client: item?.client,
          email: item?.email,
          phone: item?.phone,
          contactName: item?.contact_name,
          descriptionInfo: item?.description,
        })) || [],
      noSubContractor: attr?.no_sub_contractor || false,
      addendaInfo: {
        title: attr?.supplier_title,
        firstName: attr?.supplier_first_name,
        lastName: attr?.supplier_last_name,
        legalCompanyName: attr?.supplier_company,
      },
      contractors:
        attr?.sub_contractors?.map?.((item) => ({
          name: item?.name,
          phone: item?.phone,
          company: item?.company,
          address: item?.address,
          previousProjects: item?.previous_projects,
          reference: item?.references,
        })) || [],

      user: {
        id: attr?.users_permissions_user?.data?.id,
        ...attr?.users_permissions_user?.data?.attributes,
      },
      inquiry: attr?.inquiry?.map((item) => ({
        description: item?.description,
        isSeller: item?.is_seller,
      })),
    };

    return payload;
  } catch (error) {
    console.error("singleSubmittedBidManipulator error -->>", error);
    return {};
  }
};
