import React, { useEffect, useState } from "react";
import {
  CommonButton,
  CommonModal,
  CommonTable,
  CommonTextField,
} from "../../../../../components/common";
import { Images } from "../../../../../theme";
import {
  BIDS_DETAIL,
  BID_QUERY_POPULATE_PARAMS,
} from "../../../../../constants";
import { Space } from "antd";
import CommonThreeDotMenu from "../../../../../components/common/CommonThreeDotMenu";
import {
  getPageSizeQuery,
  getPaginationQueryObj,
  replaceValInString,
  toastAlert,
} from "../../../../../utils";
import { useNavigate, useParams } from "react-router-dom";
import { getOpenBidsRequest } from "../../../../../redux/slicers/bid";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

function MoreBidsSection() {
  //STATES
  const [deleteModalPreview, setDeleteModalPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [bids, setBids] = React.useState([]);
  const [pagination, setPagination] = React.useState(null);
  const user = useSelector(({ user }) => user?.data);
  const dispatch = useDispatch();

  //CONST VALS
  const navigate = useNavigate();
  const { id } = useParams();

  //CUSTOM COMPONENTS
  const renderServiceColumn = (text, _record) => {
    return (
      <CommonTextField
        text={_record?.companyDetail?.companyName}
        className={"light-text"}
        fontWeight={700}
      />
    );
  };

  const renderStatusColumn = (data) => (
    <div
      className={`statusButton ${
        data.status == "Open"
          ? "statusButton_open"
          : data?.status == "Awarded"
          ? "statusButton_awarded"
          : data?.status == "Closed"
          ? "statusButton_closed"
          : data?.status == "Terminated"
          ? "statusButton_closed"
          : "statusButton_open"
      }`}
    >
      <CommonTextField
        text={data.status}
        className={"text-11"}
        fontWeight={700}
        textTransform="capitalize"
      />
    </div>
  );

  const renderActionColumn = (_, data) => (
    <Space>
      <CommonButton
        text={"View Details"}
        background={"rgba(197, 222, 251, 1)"}
        borderRadius={"12px"}
        color={"rgba(0, 112, 242, 1)"}
        fontFamily={"seventyTwoSemiBold"}
        fontSize={"14px"}
      />
    </Space>
  );

  const renderNameColumn = (text, record) => (
    <CommonTextField
      text={text}
      fontWeight={700}
      className="c-p"
      onClick={() =>
        navigate(replaceValInString(BIDS_DETAIL, { ":id": record.id }))
      }
    />
  );
  //CONST VALS
  const columns = [
    {
      title: "Bid Name",
      dataIndex: "displayName",
      key: "displayName",
      render: renderNameColumn,
      sorter: {
        compare: (a, b) => a.displayName.localeCompare(b.displayName),
        multiple: 1,
      },
      sortIcon: ({ sortOrder }) =>
        sortOrder ? (
          sortOrder === "ascend" ? (
            <Images.SortDsc />
          ) : (
            <></>
          )
        ) : (
          <Images.SortAsc />
        ),
    },
    {
      title: "Opening Date",
      dataIndex: "openingDate",
      key: "openingDate",
      render: renderServiceColumn,
      sorter: {
        compare: (a, b) => new Date(a.openingDate) - new Date(b.openingDate),
        multiple: 1,
      },
      sortIcon: ({ sortOrder }) =>
        sortOrder ? (
          sortOrder === "ascend" ? (
            <Images.SortDsc />
          ) : (
            <></>
          )
        ) : (
          <Images.SortAsc />
        ),
    },
    {
      title: "Closing Date",
      dataIndex: "closingDate",
      key: "closingDate",
      render: renderServiceColumn,
      sorter: {
        compare: (a, b) => new Date(a.closingDate) - new Date(b.closingDate),
        multiple: 1,
      },
      sortIcon: ({ sortOrder }) =>
        sortOrder ? (
          sortOrder === "ascend" ? (
            <Images.SortDsc />
          ) : (
            <></>
          )
        ) : (
          <Images.SortAsc />
        ),
    },
    {
      title: "Organization",
      dataIndex: "organization",
      key: "organization",
      render: renderServiceColumn,
      sorter: {
        compare: (a, b) => a.organization.localeCompare(b.organization),
        multiple: 1,
      },
      sortIcon: ({ sortOrder }) =>
        sortOrder ? (
          sortOrder === "ascend" ? (
            <Images.SortDsc />
          ) : (
            <></>
          )
        ) : (
          <Images.SortAsc />
        ),
    },
    {
      title: "Status",
      key: "action",
      width: 120,
      align: "center",
      render: renderStatusColumn,
    },
    {
      title: "Action",
      key: "action",
      width: 120,
      align: "center",
      render: renderActionColumn,
    },
  ];

  useEffect(() => {
    handleGetMyBids();
  }, [id]);

  // default query for open bids
  const getOpenBidsDefaultQuery = () => {
    return `filters[status][$eq]=Open&filters[$and][0][$or][0][publicOpening][$eq]=Yes&filters[$and][0][$or][1][suppliers][email][$containsi]=${
      user?.email
    }&filters[closing_date][$gte]=${moment().format(
      "YYYY-MM-DD"
    )}&filters[user][id][$ne]=${
      user?.id
    }&filters[$or][0][bid_submissions][id][$null]=true&filters[$or][1][bid_submissions][users_permissions_user][id][$notIn]=${
      user?.id
    }&filters[id][$ne]=${id}&`;
  };

  function handleGetMyBids(query = "", isAddSortQuery = true) {
    let sortQuery = "";
    setIsLoading(true);
    dispatch(
      getOpenBidsRequest({
        queryParams: `${
          query +
          sortQuery +
          getOpenBidsDefaultQuery() +
          BID_QUERY_POPULATE_PARAMS +
          getPageSizeQuery()
        }`,

        responseCallback: (status, response) => {
          setIsLoading(false);
          if (status) {
            setBids(response?.data || []);
            response?.pagination && setPagination(response?.pagination);
          }
        },
      })
    );
  }

  const handleSetPage = (page) => {
    handleGetMyBids(getPaginationQueryObj(page));
  };

  return (
    <div className="MoreBitContentWrapper">
      <img src={Images.Upsun} />

      <CommonTextField
        text={
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        }
        color={"rgba(2, 19, 73, 1)"}
        fontFamily={"seventyTwoRegular"}
        fontSize={"14px"}
        lineHeight={"20px"}
        mt={"10px"}
      />
      <CommonTextField
        text={
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        }
        color={"rgba(2, 19, 73, 1)"}
        fontFamily={"seventyTwoRegular"}
        fontSize={"14px"}
        lineHeight={"20px"}
        mt={"20px"}
      />

      <div className="moreBidTableSection">
        <CommonTable
          columns={columns}
          dataSource={bids}
          pagination
          total={pagination?.total}
          handleSetPage={handleSetPage}
          loading={isLoading}
        />
      </div>
      <CommonModal
        isModalVisible={deleteModalPreview}
        setIsModalVisible={setDeleteModalPreview}
        discription="Do you want to delete this bid?"
        onConfirm={() => {
          setDeleteModalPreview(false);
          toastAlert("Bid deleted successfully.");
        }}
      ></CommonModal>
    </div>
  );
}

export default MoreBidsSection;
