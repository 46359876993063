import { Carousel, Form } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AuthCarousel } from "../../components";
import {
  CommonButton,
  CommonInputField,
  CommonPasswordInput,
  CommonTextField,
} from "../../components/common";
import { LOGIN_ROUTE } from "../../constants";
import { CustomDispatch } from "../../helpers";
import { loginSuccess, resetPasswordRequest } from "../../redux/slicers/user";
import { Images } from "../../theme";
import { inputFieldRule, isPasswordValid } from "../../utils";
import "./auth.scss";

const ResetPassword = () => {
  //REDUX DATA
  const { verificationEmail, resetOtp } = useSelector((state) => state.user);
  //CONST VALS
  const [form] = Form.useForm();
  const navigate = useNavigate();

  //CUSTOM DISPATCH
  const [resetPassword, isLoading] = CustomDispatch(resetPasswordRequest);

  //HANDLERS
  const handleReset = (vals) => {
    resetPassword({
      payload: {
        password: vals.rePassword,
        otp: resetOtp,
        email: verificationEmail,
      },
      logic(res) {
        navigate(LOGIN_ROUTE);
      },
    });
  };
  return (
    <div className="auth-box">
      <div className="login-container">
        <div className="login-wrapper">
          <div className="login-form-wrapper">
            <CommonTextField
              text={"Change Password"}
              fontSize="39px"
              fontWeight={"600"}
              color="#031946"
              mb={"18px"}
              whiteSpace="nowrap"
            />
            <CommonTextField
              text={
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry"
              }
              fontSize="14px"
              fontWeight={"400"}
              color="#465E7D"
              mb={"30px"}
            />
            <Form form={form} className="login-form" onFinish={handleReset}>
              <CommonPasswordInput
                placeholder={"Enter your password"}
                prefix={<Images.PasswordIcon />}
                name="password"
                rules={[
                  ...inputFieldRule({
                    name: "Password",
                    isWhiteSpace: false,
                  }),
                  () => ({
                    validator(_, value) {
                      if (!value || isPasswordValid(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "Password must contain 8 characters including 1 small letter, 1 capital letter, 1 digit and 1 special character!"
                        )
                      );
                    },
                  }),
                ]}
              />
              <CommonPasswordInput
                placeholder={"Confirm Password"}
                prefix={<Images.PasswordIcon />}
                name="rePassword"
                rules={[
                  ...inputFieldRule({
                    name: "Confirm Password",
                    isWhiteSpace: false,
                  }),
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              />
              <CommonButton
                text={"Reset Password"}
                fontSize="18px"
                fontWeight={"400"}
                height="56px"
                htmlType="submit"
                loading={isLoading}
              />
            </Form>
          </div>
        </div>
      </div>
      <div className="auth-image-wrapper">
        <img src={Images.ResetPassImage} alt="Login Banner" />
        <AuthCarousel />
      </div>
    </div>
  );
};
export default ResetPassword;
