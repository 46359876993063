import React, { useState } from "react";
import "./styles.scss";
import {
  CommonButton,
  CommonHeading,
  CommonModal,
  CommonTextField,
} from "../../common";
import { Divider, Space } from "antd";
import { AppStyles, Images } from "../../../theme";
import {
  ALERT_TYPES,
  BUYER_SIDEBAR_ITEMS,
  CREATE_BID_ROUTE,
  DASHBOARD_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  PUBLIC_SIDEBAR_ITEMS,
  SIDEBAR_ITEMS,
} from "../../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutRequest, userSignOutRequest } from "../../../redux/slicers/user";
import { toastAlert } from "../../../utils";
import { deleteDeviceTokenRequest } from "../../../redux/slicers/notification";
import { fetchToken } from "../../../firebase";

const Sidebar = ({ className, closeDrawer = () => {} }) => {
  const [logout, setLogout] = useState(false);
  const location = useLocation();
  const userInfo = useSelector((state) => state?.user?.userInfo);
  const { isSeller, isAuthenticated, profileData } = useSelector(
    (state) => state?.user
  );
  const deviceToken = useSelector((state) => state?.notification?.deviceToken);
  const sideBarItems = isSeller ? BUYER_SIDEBAR_ITEMS : SIDEBAR_ITEMS;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className={className ? className : "sidebar-parent"}>
      <div className="dot-parent">
        <div className="dot"></div>
      </div>
      <div className="test"></div>
      <div className="main-logo">
        <Images.Mainlogo className="c-p" onClick={() => navigate(HOME_ROUTE)} />
      </div>

      {!isSeller && isAuthenticated && (
        <>
          <div
            className="create-bid"
            onClick={() => {
              if (!profileData?.user?.allowedJobs) {
                toastAlert(
                  "Your subscription is inactive or the limit is reached. Please subscribe or upgrade to continue",
                  ALERT_TYPES.ERROR
                );
                return;
              }
              navigate(CREATE_BID_ROUTE);
            }}
          >
            <CommonTextField text={"Create Bid"} fontWeight={600} />
            <img src={Images.addIcon} width={"40px"} height={"40px"} />
          </div>
        </>
      )}

      <Divider />
      <div className="content-parent">
        {isAuthenticated &&
          sideBarItems.map((t) => {
            const isActive = t?.active?.some((route) => {
              const regex = new RegExp(`^${route.replace(/:\w+/g, "\\w+")}$`);
              return regex.test(location.pathname);
            });

            return (
              <div
                className={`${isActive && "active"} item `}
                key={Math.random()}
              >
                <div
                  className="content"
                  onClick={() => {
                    navigate(t.route);
                    closeDrawer();
                  }}
                >
                  {t.src}
                  <CommonTextField text={t.text} />
                </div>
                {isActive && (
                  <div className="active-dot">
                    <div className="dot"></div>
                  </div>
                )}
              </div>
            );
          })}

        {!isAuthenticated &&
          PUBLIC_SIDEBAR_ITEMS.map((t) => {
            const isActive = t?.active?.some((route) => {
              const regex = new RegExp(`^${route.replace(/:\w+/g, "\\w+")}$`);
              return regex.test(location.pathname);
            });

            return (
              <div
                className={`${isActive && "active"} item `}
                key={Math.random()}
              >
                <div
                  className="content"
                  onClick={() => {
                    navigate(t.route);
                    closeDrawer();
                  }}
                >
                  {t.src}
                  <CommonTextField text={t.text} />
                </div>
                {isActive && (
                  <div className="active-dot">
                    <div className="dot"></div>
                  </div>
                )}
              </div>
            );
          })}
      </div>

      {isAuthenticated && (
        <>
          <Divider />
          <div className="logout-but">
            <CommonButton text={"Logout"} onClick={() => setLogout(true)} />
          </div>
        </>
      )}
      <CommonModal
        isModalVisible={logout}
        setIsModalVisible={setLogout}
        discription="Do you want to logout?"
        onConfirm={async () => {
          const deviceToken = await fetchToken();
          dispatch(
            deleteDeviceTokenRequest({
              payload: {
                deviceToken,
              },
            })
          );
          dispatch(logoutRequest());
          toastAlert("Logout Successfully!", ALERT_TYPES.SUCCESS);
          navigate(LOGIN_ROUTE);
        }}
      ></CommonModal>
    </div>
  );
};

export default Sidebar;
