import React, { useEffect, useState } from "react";
import "../styles.scss";
import {
  CommonButton,
  CommonInputField,
  CommonTable,
  CommonTextAreaField,
  CommonTextField,
} from "../../../../../../components/common";
import { Checkbox, Col, Form, Row, Space } from "antd";
import { TABLE_DATA } from "../../../../../../constants";
import {
  creditCardValidator,
  inputFieldRule,
  toastAlert,
} from "../../../../../../utils";
import _ from "lodash";

const STATIC_INFO = {
  description: "References",
  totalColumns: 6,
  requiredColumns: 3,
  optionalColumns: "3",
  mandatory: "Yes",
};

const STATIC_CONTRACTOR_INFO = {
  description: "References",
  totalColumns: 6,
  requiredColumns: 6,
  optionalColumns: "0",
  mandatory: "Yes",
};

function Step2({
  submittedBidDetail,
  bidDetail,
  isHidden,
  handleNextStep,
  setParentReferences,
  setParentContractors,
  setParentNoSubContractors,
}) {
  //STATES

  const [noSubContractors, setNoSubContractors] = useState(false);
  const [referenceCurrentIndex, setReferenceCurrentIndex] = useState(0);
  const [references, setReferences] = useState([
    {
      description: "References",
      totalColumns: 6,
      requiredColumns: 3,
      optionalColumns: "3",
      mandatory: "Yes",
      total: 0,
      client: "",
      businessLength: "",
      contactName: "",
      phone: "",
      email: "",
      descriptionInfo: "",
    },
  ]);
  const [referenceData, setReferenceData] = useState([]);

  const [contractorCurrentIndex, setContractorCurrentIndex] = useState(0);
  const [contractors, setContractors] = useState([
    {
      // description: "Sub Contractors",
      // totalColumns: 6,
      // requiredColumns: 3,
      // optionalColumns: "3",
      // mandatory: "Yes",
      ...STATIC_CONTRACTOR_INFO,
      total: 0,
      company: "",
      name: "",
      phone: "",
      previousProjects: "",
      address: "",
      reference: "",
    },
  ]);
  const [contractorData, setContractorData] = useState([]);

  //CONST VALS
  const [contractorForm] = Form.useForm();
  const [referenceForm] = Form.useForm();

  useEffect(() => {
    if (!submittedBidDetail?.id) return;

    if (submittedBidDetail?.references) {
      const rData = submittedBidDetail?.references.map((item) => ({
        ...STATIC_INFO,
        ...item,
      }));
      setReferences(rData);
      setReferenceData(rData);
      setReferenceCurrentIndex(rData?.length);
    }

    if (
      !submittedBidDetail?.noSubContractors &&
      submittedBidDetail?.contractors?.length > 0
    ) {
      const rData =
        submittedBidDetail?.contractors?.map?.((item) => ({
          ...STATIC_CONTRACTOR_INFO,
          ...item,
        })) || [];
      setContractors(rData);
      setContractorData(rData);
      setContractorCurrentIndex(rData?.length);
    }

    setNoSubContractors(submittedBidDetail?.noSubContractor);
  }, [submittedBidDetail]);

  useEffect(() => {
    if (references?.[referenceCurrentIndex]?.description) {
      referenceForm.setFieldsValue({
        client: references[referenceCurrentIndex]?.client,
        businessLength: references[referenceCurrentIndex]?.businessLength,
        contactName: references[referenceCurrentIndex]?.contactName,
        phone: references[referenceCurrentIndex]?.phone,
        email: references[referenceCurrentIndex]?.email,
        descriptionInfo: references[referenceCurrentIndex]?.descriptionInfo,
      });
    } else {
      referenceForm.resetFields();
    }
  }, [referenceCurrentIndex]);

  useEffect(() => {
    if (contractors?.[contractorCurrentIndex]?.description) {
      contractorForm.setFieldsValue({
        company: contractors[contractorCurrentIndex]?.company,
        name: contractors[contractorCurrentIndex]?.name,
        phone: contractors[contractorCurrentIndex]?.phone,
        previousProjects: contractors[contractorCurrentIndex]?.previousProjects,
        address: contractors[contractorCurrentIndex]?.address,
        reference: contractors[contractorCurrentIndex]?.reference,
      });
    } else {
      contractorForm.resetFields();
    }
  }, [contractorCurrentIndex]);

  //HANDLERS
  const handleStep = () => {
    if (referenceData.length === 0) {
      toastAlert("Please add atleast one reference", "error");
      return;
    }

    if (!noSubContractors && contractorData.length === 0) {
      toastAlert("Please add atleast one sub contractor", "error");
      return;
    }

    setParentReferences(referenceData);
    setParentContractors(contractorData);
    setParentNoSubContractors(noSubContractors);
    handleNextStep(3);
  };

  const handleReferenceSubmit = (values) => {
    const newReferences = [...references];
    newReferences[referenceCurrentIndex] = {
      ...STATIC_INFO,
      ...newReferences[referenceCurrentIndex],
      ...values,
    };

    setReferenceData(newReferences);
    setReferences(newReferences);
    setReferenceCurrentIndex(newReferences?.length);
  };

  const handleDeleteReference = (index) => {
    const newReferences = [...references];
    newReferences.splice(index, 1);
    setReferences(newReferences);

    const newReferenceData = [...referenceData];
    newReferenceData.splice(index, 1);
    setReferenceData(newReferenceData);

    setReferenceCurrentIndex(newReferenceData?.length);
  };

  const handleContractSubmit = (values) => {
    const newContractors = [...contractors];
    newContractors[contractorCurrentIndex] = {
      ...STATIC_CONTRACTOR_INFO,
      ...newContractors[contractorCurrentIndex],
      ...values,
    };

    setContractorData([...newContractors]);
    setContractors(newContractors);
    setContractorCurrentIndex(newContractors?.length);
  };

  const handleDeleteContract = (index) => {
    const newContractors = [...contractors];
    newContractors.splice(index, 1);
    setContractors(newContractors);
    setContractorData(newContractors);
    setReferenceCurrentIndex(newContractors?.length);
  };

  const renderTableText = (text) => (
    <CommonTextField
      text={text}
      fontFamily={"seventyTwoRegular"}
      fontSize={"12px"}
      lineHeight={"13.8px"}
      color={"rgba(2, 19, 73, 1)"}
    />
  );

  const renderActions = (_, record, index) => {
    return (
      <Space>
        <CommonButton
          text={"Edit"}
          onClick={() => setReferenceCurrentIndex(index)}
        />
        <CommonButton
          text={"Delete"}
          disabled={index == 0}
          onClick={() => handleDeleteReference(index)}
        />
      </Space>
    );
  };

  const renderContractorActions = (_, record, index) => {
    return (
      <Space>
        <CommonButton
          text={"Edit"}
          onClick={() => setContractorCurrentIndex(index)}
        />
        <CommonButton
          text={"Delete"}
          disabled={index == 0}
          onClick={() => handleDeleteContract(index)}
        />
      </Space>
    );
  };

  const columns = [
    {
      title: "Table",
      dataIndex: "description",
      key: "description",
      render: renderTableText,
    },
    {
      title: "# of Columns",
      dataIndex: "totalColumns",
      key: "totalColumns",
      align: "center",
      render: renderTableText,
    },
    {
      title: "# of Required Columns",
      dataIndex: "requiredColumns",
      key: "requiredColumns",
      align: "center",
      render: renderTableText,
    },
    {
      title: "# of Optional Columns",
      dataIndex: "optionalColumns",
      key: "optionalColumns",
      align: "center",
      render: renderTableText,
    },
    {
      title: "Mandatory",
      dataIndex: "mandatory",
      key: "mandatory",
      align: "center",
      render: renderTableText,
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      align: "center",
      render: renderActions,
    },
  ];

  const contractorColumns = [
    {
      title: "Table",
      dataIndex: "description",
      key: "description",
      render: renderTableText,
    },
    {
      title: "# of Columns",
      dataIndex: "totalColumns",
      key: "totalColumns",
      align: "center",
      render: renderTableText,
    },
    {
      title: "# of Required Columns",
      dataIndex: "requiredColumns",
      key: "requiredColumns",
      align: "center",
      render: renderTableText,
    },
    {
      title: "# of Optional Columns",
      dataIndex: "optionalColumns",
      key: "optionalColumns",
      align: "center",
      render: renderTableText,
    },
    {
      title: "Mandatory",
      dataIndex: "mandatory",
      key: "mandatory",
      align: "center",
      render: renderTableText,
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      align: "center",
      render: renderContractorActions,
    },
  ];

  return (
    <div className={`${isHidden ? "hidden" : ""} `}>
      <Row>
        <Col lg={22} style={{ marginTop: 15 }}>
          <CommonTextField
            text={
              "All references stated shall be for the same or similar scope as the one described in this Bid. For newly formed business entity including, corporations, partnerships and sole proprietors or a Contractor teaming arrangement you shall state below in the Client Column that you were not the “Contractor” for the named project and should state whose past experience on the named project is relevant to that reference."
            }
            color={"rgba(2, 19, 73, 1)"}
            fontSize={"14px"}
            lineHeight={"20px"}
            fontFamily={"seventyTwoRegular"}
          />
        </Col>
      </Row>

      {/* Reference Section Started  */}

      <div className="tableWrapper">
        <CommonTable columns={columns} dataSource={references} />
      </div>

      <div className="refenceFormWrapper">
        <CommonTextField
          text={"References"}
          fontSize={"24px"}
          lineHeight={"27.6px"}
          color={"rgba(2, 19, 73, 1)"}
          fontFamily={"seventyTwoSemiBold"}
          mb={"20px"}
        />

        <Form
          className="stepDetailForm"
          form={referenceForm}
          onFinish={handleReferenceSubmit}
        >
          <Row gutter={[12, 0]}>
            <Col span={12}>
              <CommonInputField
                placeholder={"Client/Company"}
                rules={inputFieldRule({
                  name: "Client/Company",
                  isRequired: false,
                  isMax: true,
                  max: 30,
                })}
                name="client"
              />
            </Col>
            <Col span={12}>
              <CommonInputField
                placeholder={"Length of Time in Business & Core Competencies"}
                rules={inputFieldRule({
                  name: "Lenght of Time",
                  isRequired: false,
                  isMax: true,
                  max: 30,
                })}
                name="businessLength"
              />
            </Col>
            <Col span={12}>
              <CommonInputField
                placeholder={"Contact Name*"}
                rules={inputFieldRule({
                  name: "Contact Name*",
                  isMax: true,
                  max: 30,
                })}
                name="contactName"
              />
            </Col>
            <Col span={12}>
              <CommonInputField
                placeholder={"Phone Number*"}
                rules={inputFieldRule({
                  name: "Phone",
                  isMax: true,
                  isMin: true,

                  min: 7,
                  max: 20,
                })}
                name="phone"
                onKeyDown={(e) => creditCardValidator(e, "card", 20)}
              />
            </Col>
            <Col span={24}>
              <CommonInputField
                placeholder={"Email Address*"}
                rules={inputFieldRule({
                  name: "Email Address*",
                  isEmail: true,
                  isMax: true,
                  max: 50,
                })}
                name="email"
              />
            </Col>
            <Col span={24}>
              <CommonTextAreaField
                placeholder={"Description"}
                rules={inputFieldRule({
                  name: "Description",
                  isRequired: false,
                })}
                name="descriptionInfo"
                height={"149px"}
              />
            </Col>
            <Col span={24}>
              <div className="buttonWrapper">
                <CommonButton
                  text={
                    references?.[referenceCurrentIndex]?.email
                      ? "Update Row"
                      : "Add Row"
                  }
                  htmlType="submit"
                  classname={"button"}
                  width="200px"
                  height={"56px"}
                  fontSize={"18px"}
                />
              </div>
            </Col>
          </Row>
        </Form>
      </div>

      {/* Reference Section Ended  */}

      {/* Subcontractor Section Started  */}

      <div className="tableWrapper">
        <CommonTextField
          text={"Sub-Contractors"}
          fontFamily={"seventyTwoSemiBold"}
          fontSize={"20px"}
          lineHeight={"23px"}
        />
        <CommonTextField
          text={`The Bidder shall state all Subcontractor(s) and type of Work proposed to be used for this project. Bidders shall not indicate “TBD” (To Be Determined) or “TBA” (To Be Announced) or similar wording and shall not indicate multiple choices of Subcontractor names for any Subcontractor category in their list of Subcontractors.`}
          fontSize={"14px"}
          lineHeight={"20px"}
          fontFamily={"seventyTwoRegular"}
          mt={"15px"}
        />
        <CommonTextField
          text={`The Bidder shall state only one (1) subcontractor for each type of work Bidder(s) shall upon request by the Owner produce a list of references for all or any proposed Subcontractors within three (3) business days.`}
          fontSize={"14px"}
          lineHeight={"20px"}
          fontFamily={"seventyTwoRegular"}
          mt={"15px"}
          mb={"30px"}
        />
        <CommonTable columns={contractorColumns} dataSource={contractors} />
      </div>

      <div style={{ marginTop: 20 }}>
        <CommonTextField
          text={"Subcontractor -Names and References if Applicable"}
          fontSize={"18px"}
          lineHeight={"20.7px"}
          color={"rgba(2, 19, 73, 1)"}
          fontFamily={"seventyTwoRegular"}
          mb={"10px"}
        />

        <Form
          form={contractorForm}
          className="stepDetailForm"
          onFinish={handleContractSubmit}
        >
          <Row gutter={[12, 0]}>
            <Col
              className="checkbox-parent"
              span={24}
              style={{ marginBottom: "15px" }}
            >
              <Checkbox
                checked={noSubContractors}
                onChange={() => setNoSubContractors(!noSubContractors)}
              />
              <CommonTextField
                text={
                  'By clicking here I confirm that there are no Subcontractor(s) and the Bidder shall perform the project with their “OWN FORCES".'
                }
              />
            </Col>
            <Col span={12}>
              <CommonInputField
                placeholder={"Company*"}
                rules={inputFieldRule({
                  name: "Client/Company",
                  isMax: true,
                  max: 30,
                  isRequired: noSubContractors ? false : true,
                })}
                name="company"
                disabled={noSubContractors}
              />
            </Col>
            <Col span={12}>
              <CommonInputField
                placeholder={"Name*"}
                rules={inputFieldRule({
                  name: "Contact Name",
                  isMax: true,
                  max: 30,
                  isRequired: noSubContractors ? false : true,
                })}
                name="name"
                disabled={noSubContractors}
              />
            </Col>
            <Col span={12}>
              <CommonInputField
                placeholder={"Phone Contact Information*"}
                rules={inputFieldRule({
                  name: "Phone Contact Information",
                  isMax: true,
                  isMin: true,

                  max: 20,
                  min: 7,
                  isRequired: noSubContractors ? false : true,
                  isNumberOnly: true,
                })}
                name="phone"
                disabled={noSubContractors}
              />
            </Col>
            <Col span={12}>
              <CommonInputField
                placeholder={"Previous Projects*"}
                rules={inputFieldRule({
                  name: "Previous Projects",
                  isMax: true,
                  max: 20,
                  isRequired: noSubContractors ? false : true,
                })}
                name="previousProjects"
                disabled={noSubContractors}
              />
            </Col>
            <Col span={24}>
              <CommonInputField
                placeholder={"Address*"}
                rules={inputFieldRule({
                  name: "Email Address",
                  isMax: true,
                  max: 200,
                  isRequired: noSubContractors ? false : true,
                })}
                name="address"
                disabled={noSubContractors}
              />
            </Col>
            <Col span={24}>
              <CommonTextAreaField
                placeholder={"Reference*"}
                rules={inputFieldRule({
                  name: "Reference",
                  isRequired: noSubContractors ? false : true,
                })}
                name="reference"
                height={"149px"}
                disabled={noSubContractors}
              />
            </Col>
            <Col span={24}>
              <div className="buttonWrapper">
                <CommonButton
                  text={
                    contractors?.[contractorCurrentIndex]?.company
                      ? "Update Row"
                      : "Add Row"
                  }
                  htmlType="submit"
                  classname={"button"}
                  width="200px"
                  height={"56px"}
                  fontSize={"18px"}
                  disabled={noSubContractors}
                />
              </div>
            </Col>
          </Row>
        </Form>
      </div>

      {/* Subcontractor Section Ended  */}

      <div className="separator"></div>

      <div className="buttonWrapper">
        <CommonButton
          text={"Back"}
          classname={"button mr-10"}
          width="200px"
          height={"56px"}
          fontSize={"18px"}
          onClick={() => {
            handleNextStep(1);
          }}
        />

        <CommonButton
          text={"Continue"}
          classname={"button"}
          width="200px"
          height={"56px"}
          fontSize={"18px"}
          onClick={handleStep}
        />
      </div>
    </div>
  );
}

export default Step2;
