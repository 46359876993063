import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
  },

  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  rowView: {
    display: "flex",
    flexDirection: "row",

    textAlign: "left",
  },
  text: {
    fontSize: 10,
    lineHeight: 1.5,
  },
  border: {
    borderTop: "1px solid #000",
    // borderBottom: "1px solid #000",
    borderLeft: "1px solid #000",
    paddingTop: 8,
    paddingBottom: 8,
    paddingHorizontal: 8,
  },
  borderC: {
    borderRight: "1px solid #000",
    paddingTop: 8,
    paddingBottom: 8,
    paddingHorizontal: 8,
  },
  borderR: {
    borderRight: "1px solid #000",
    paddingTop: 8,
    paddingBottom: 8,
    paddingHorizontal: 8,
  },
  borderRBottom: {
    borderBottom: "1px solid #000",
    paddingTop: 8,
    paddingBottom: 8,
    paddingHorizontal: 8,
  },
});

export const Table = ({ data }) => {
  return (
    <View style={styles.tableContainer}>
      <View style={styles.rowView}>
        {data["column"].map((c, indx) => (
          <Text
            style={[
              {
                width: `${100 / data["column"].length}%`,
              },
              styles.text,
              {
                fontSize: 11,
              },
              styles.border,
              data["column"].length - 1 == indx ? { ...styles.borderC } : {},
            ]}
          >
            {c}
          </Text>
        ))}
      </View>
      {data?.["data"]?.map((rowData, indexRow) => (
        <>
          <View style={styles.rowView}>
            {data["column"].map((c, indx) =>
              rowData?.[c]?.isElement ? (
                <View
                  style={[
                    {
                      width: `${100 / data["column"].length}%`,
                    },
                    styles.text,
                    styles.border,
                    { justifyContent: "center", alignItems: "center" },
                    data["column"].length - 1 == indx
                      ? { ...styles.borderR }
                      : {},
                    data["data"].length - 1 == indexRow
                      ? { ...styles.borderRBottom }
                      : {},
                  ]}
                >
                  {rowData[c]?.element}
                </View>
              ) : (
                <Text
                  style={[
                    {
                      width: `${100 / data["column"].length}%`,
                    },
                    styles.text,
                    styles.border,
                    data["column"].length - 1 == indx
                      ? { ...styles.borderR }
                      : {},
                    data["data"].length - 1 == indexRow
                      ? { ...styles.borderRBottom }
                      : {},
                  ]}
                >
                  {rowData[c]}
                </Text>
              ),
            )}
          </View>
        </>
      ))}
    </View>
  );
};
