import React, { useState } from "react";
import "../styles.scss";
import {
  CommonButton,
  CommonTextField,
} from "../../../../../../components/common";
import { Col, Row } from "antd";
import { Images } from "../../../../../../theme";
import { downloadBlob, toastAlert } from "../../../../../../utils";
import { CustomDispatch } from "../../../../../../helpers";
import { uploadImageRequest } from "../../../../../../redux/slicers/user";
import {
  createBidSubmissionRequest,
  updateBidSubmissionRequest,
} from "../../../../../../redux/slicers/bid";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

function Step6({
  submittedBidDetail,
  isHidden,
  handleNextStep,
  bidDetailPdfBlob,
  pricingInfo,
  contractors,
  references,
  noSubContractors,
  documents,
  addendaInfo,
  addendaReviewAgreement,
}) {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadFile] = CustomDispatch(uploadImageRequest);
  const [createBidSubmission] = CustomDispatch(createBidSubmissionRequest);
  const [updateBidSubmission] = CustomDispatch(updateBidSubmissionRequest);

  const userData = useSelector(({ user }) => user?.data);

  const handleDownloadPdf = () => {
    downloadBlob(bidDetailPdfBlob, "Bid.pdf");
  };

  const handleUploadFile = () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("files", bidDetailPdfBlob, "Bid.pdf");
    uploadFile({
      payload: formData,
      logic: (res) => {
        if (!submittedBidDetail?.id) {
          handleSubmitBid(res?.[0]?.id);
        } else {
          handleUpdateBid(res?.[0]?.id);
        }
      },
      error: () => {
        setIsLoading(false);
        console.error("File upload failed", error);
        toastAlert("File upload failed", "error");
      },
    });
  };

  const handleSubmitBid = (previewBidId = "") => {
    const pricingData = pricingInfo?.map((item) => ({
      title: item?.description,
      price2024: Number(item?.price2024),
      price2025: Number(item?.price2025),
      price2026: Number(item?.price2026),
      total: Number(item?.total),
    }));

    let contractorsData = contractors?.map((item) => ({
      address: item?.address,
      name: item?.name,
      company: item?.company,
      phone: item?.phone,
      references: item?.reference,
      previous_projects: item?.previousProjects,
    }));

    const referencesData = references?.map((item) => ({
      client: item?.client || "",
      description: item?.descriptionInfo || "",
      email: item?.email,
      phone: item?.phone || "",
      contact_name: item?.contactName || "",
      business_length: item?.businessLength || "",
    }));

    const supplierInfo = {
      supplier_first_name: addendaInfo?.firstName,
      supplier_last_name: addendaInfo?.lastName,
      supplier_company: addendaInfo?.legalCompanyName,
      supplier_title: addendaInfo?.title,
    };

    const payload = {
      data: {
        no_sub_contractor: noSubContractors,
        addendum_terms: addendaReviewAgreement == 1 ? true : false,
        proposal: documents?.proposal?.id,
        appendix: documents?.appendix?.id,
        health_safety: documents?.healthSafety?.id,
        other: documents?.other?.id,
        pricing: pricingData,
        sub_contractors: noSubContractors ? [] : contractorsData,
        references: referencesData,
        users_permissions_user: userData?.id,
        bid: params?.id,
        preview_bid: previewBidId,
        ...supplierInfo,
      },
    };

    createBidSubmission({
      payload,
      logic: (res) => {
        toastAlert("Bid submitted successfully.");
        setIsLoading(false);
        handleNextStep(7);
      },
      error(err) {
        setIsLoading(false);
        console.error("createBidSubmission errr", err);
      },
    });
  };

  const handleUpdateBid = (previewBidId = "") => {
    const pricingData = pricingInfo?.map((item) => ({
      title: item?.description,
      price2024: Number(item?.price2024),
      price2025: Number(item?.price2025),
      price2026: Number(item?.price2026),
      total: Number(item?.total),
    }));

    let contractorsData = contractors?.map((item) => ({
      address: item?.address,
      name: item?.name,
      company: item?.company,
      phone: item?.phone,
      references: item?.reference,
      previous_projects: item?.previousProjects,
    }));

    const referencesData = references?.map((item) => ({
      client: item?.client || "",
      description: item?.descriptionInfo || "",
      email: item?.email,
      phone: item?.phone || "",
      contact_name: item?.contactName || "",
      business_length: item?.businessLength || "",
    }));

    const supplierInfo = {
      supplier_first_name: addendaInfo?.firstName,
      supplier_last_name: addendaInfo?.lastName,
      supplier_company: addendaInfo?.legalCompanyName,
      supplier_title: addendaInfo?.title,
    };

    const payload = {
      data: {
        no_sub_contractor: noSubContractors,
        addendum_terms: addendaReviewAgreement == 1 ? true : false,
        proposal: documents?.proposal?.id,
        appendix: documents?.appendix?.id,
        health_safety: documents?.healthSafety?.id,
        other: documents?.other?.id,
        pricing: pricingData,
        sub_contractors: noSubContractors ? [] : contractorsData,
        references: referencesData,
        users_permissions_user: userData?.id,
        bid: params?.id,
        preview_bid: previewBidId,
        ...supplierInfo,
      },
    };

    updateBidSubmission({
      payload,
      pathParams: submittedBidDetail?.id,
      logic: (res) => {
        setIsLoading(false);
        toastAlert("Bid updated successfully.");
        handleNextStep(7);
      },
      error(err) {
        setIsLoading(false);
        console.error("createBidSubmission errr", err);
      },
    });
  };

  return (
    <div
      className={` ${isHidden ? "hidden" : ""} `}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Row gutter={[23, 23]}>
        <Col span={24}>
          <CommonTextField
            text={"Download PDF"}
            color={"rgba(2, 19, 73, 1)"}
            fontSize={"20px"}
            lineHeight={"16.1px"}
            fontFamily={"seventyTwoSemiBold"}
            mt={"30px"}
          />
        </Col>
        {bidDetailPdfBlob && (
          <Col span={24}>
            <div className="bid-download" onClick={handleDownloadPdf}>
              <CommonTextField text={"Bid.pdf"} fontFamily={"seventyTwoBold"} />
              <Images.DownloadIcon />
            </div>
          </Col>
        )}
      </Row>

      <div className="buttonWrapper" style={{ marginTop: "16em" }}>
        <CommonButton
          text={"Back"}
          classname={"button mr-10"}
          width="200px"
          height={"56px"}
          fontSize={"18px"}
          onClick={() => handleNextStep(5)}
        />

        <CommonButton
          text={"Submit"}
          classname={"button"}
          width="200px"
          height={"56px"}
          loading={isLoading}
          fontSize={"18px"}
          onClick={() => handleUploadFile()}
        />
      </div>
    </div>
  );
}

export default Step6;
