import React, { useState } from "react";
import {
  CommonButton,
  CommonModal,
  CommonTextField,
  DocUploader,
} from "../../../common";
import "./styles.scss";
import { Images } from "../../../../theme";
import { Col, Row } from "antd";
import {
  downloadFileFromUrl,
  replaceValInString,
  toastAlert,
} from "../../../../utils";
import { useNavigate } from "react-router-dom";
import {
  EDIT_BID_ROUTE,
  EDIT_SUBMITTED_BID_ROUTE,
} from "../../../../constants";
import InquiryModal from "../inquiryModal";
import { updateBidSubmissionRequest } from "../../../../redux/slicers/bid";
import { CustomDispatch } from "../../../../helpers";

const yourBid = ({
  bidId,
  submittedBidId,
  submittedBid,
  ongoing,
  isCancelled,
  handleAddInquiry = () => {},
}) => {
  //STATES
  const [inquiryView, setInquiryView] = useState(false);
  const [confirmationModalPreview, setConfirmationModalPreview] =
    useState(false);
  const [inquiryModalPreview, setInquiryModalPreview] = useState(false);
  const [selected, setSelected] = useState(null);

  //CONST VALS
  const navigate = useNavigate();
  const [updateSubmittedBid] = CustomDispatch(updateBidSubmissionRequest);

  //HANDLERS
  const inquiryModalPreviewHandler = () => {
    setInquiryModalPreview(!inquiryModalPreview);
  };

  const handleConfirmWithdraw = () => {
    updateSubmittedBid({
      pathParams: submittedBid?.id,
      payload: {
        data: {
          is_withdraw: true,
        },
      },
      logic() {
        toastAlert("Bid Withdrawn Successfully", "success");
        navigate(-1);
        setConfirmationModalPreview(false);
      },
    });
  };

  return (
    <div>
      <CommonTextField
        text={"Your Bid"}
        fontFamily={"seventyTwoSemiBold"}
        fontSize={"24px"}
        lineHeight={"36px"}
      />
      {submittedBid?.previewBid?.url && (
        <div
          className="bid-download"
          onClick={() => downloadFileFromUrl(submittedBid?.previewBid)}
          style={{ cursor: "pointer" }}
        >
          <CommonTextField text={"Bid.pdf"} fontFamily={"seventyTwoBold"} />
          <Images.DownloadIcon />
        </div>
      )}

      {isCancelled && (
        <>
          <CommonTextField
            text={"Cancellation Reason"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
            mt={"30px"}
          />
          <CommonTextField
            text={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
            }
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </>
      )}

      {ongoing && (
        <div className="ongoing-option-wrapper">
          <div className="buttonWrapper">
            {!inquiryView && (
              <CommonButton
                text={"Ask more Questions"}
                background={"rgba(3, 25, 70, 1)"}
                fontSize={"16px"}
                classname={"buttons"}
                fontFamily={"seventyTwoBold"}
                onClick={() => setInquiryView(true)}
              />
            )}
            <CommonButton
              text={"Withdraw Bid"}
              fontSize={"18px"}
              classname={"buttons"}
              fontFamily={"seventyTwoBold"}
              onClick={() => setConfirmationModalPreview(true)}
            />
            <div
              className="edit-icon c-p"
              onClick={() =>
                navigate(
                  replaceValInString(EDIT_SUBMITTED_BID_ROUTE, {
                    ":id": bidId,
                    ":submittedId": submittedBidId,
                  })
                )
              }
            >
              <Images.EditIcon />
            </div>
          </div>
          {inquiryView && (
            <div className="questions-wrapper">
              <Row gutter={[0, 20]}>
                <Col span={24}>
                  <CommonTextField
                    fontFamily={"seventyTwoBold"}
                    fontSize={"20px"}
                    text={"Ask Question"}
                    color={"#021349"}
                  />
                </Col>
                {submittedBid?.inquiry?.length > 0 &&
                  submittedBid?.inquiry?.map((item, index) => (
                    <Col span={24} key={index}>
                      <div
                        className={`question-item ${
                          selected === index ? "selected" : ""
                        }`}
                        onClick={() => setSelected(index)}
                      >
                        <div
                          className={
                            item?.isSeller
                              ? "statusButton statusButton_open"
                              : "statusButton statusButton_awarded"
                          }
                        >
                          <p>{item?.isSeller ? "Seller" : "Buyer"}</p>
                        </div>
                        <CommonTextField
                          paddingTop={"10px"}
                          fontFamily={"seventyTwoLight"}
                          fontSize={"16px"}
                          text={item?.description}
                          color={"#021349"}
                        />
                      </div>
                    </Col>
                  ))}
                <Col span={24}></Col>
              </Row>
              <div className="ask-btn-wrapper">
                <CommonButton
                  text={"Type an inquiry"}
                  background={"#031946"}
                  width={"200px"}
                  height={"56px"}
                  onClick={inquiryModalPreviewHandler}
                />
              </div>
            </div>
          )}
        </div>
      )}

      <CommonModal
        isModalVisible={confirmationModalPreview}
        setIsModalVisible={setConfirmationModalPreview}
        discription="Do you want to withdraw your bid?"
        onConfirm={handleConfirmWithdraw}
      ></CommonModal>

      <InquiryModal
        preview={inquiryModalPreview}
        handleClose={inquiryModalPreviewHandler}
        handleConfirm={(val) => {
          console.log("val", val);
          handleAddInquiry(val, () => {
            inquiryModalPreviewHandler();
          });
        }}
      />
    </div>
  );
};

export default yourBid;
