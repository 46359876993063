import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  BIDS_DETAIL,
  BID_QUERY_POPULATE_PARAMS_OBJECT,
  DATE_FORMAT1,
  HOME_TABLE_DATA,
  LOGIN_ROUTE,
} from "../../../../constants";
import { getPageSizeQueryObj, replaceValInString } from "../../../../utils";
import { CommonButton, CommonTable, CommonTextField } from "../../../common";
import "./styles.scss";
import { CustomDispatch } from "../../../../helpers";
import { getMyBidsRequest } from "../../../../redux/slicers/bid";
import moment from "moment";
function HomeBidTable() {
  //REDUX DATA
  const { isAuthenticated } = useSelector((state) => state.user);
  const [getMyBidData, bidsLoader] = CustomDispatch(getMyBidsRequest);
  const [bids, setBids] = React.useState([]);
  //CONST VALS
  const navigate = useNavigate();
  //CUSTOM COMPONENTS
  const renderActionColumn = (_, data) => (
    <CommonButton
      width={"105px"}
      height="38px"
      text={"View"}
      onClick={() => {
        // isAuthenticated
        // : navigate(LOGIN_ROUTE);
        // ?
        navigate(replaceValInString(BIDS_DETAIL, { ":id": data?.id }));
      }}
    />
  );

  const renderStatusColumn = (status) => (
    <CommonTextField
      text={status}
      fontFamily={"seventyTwoSemiBold"}
      fontSize={"14px"}
      color="#219653"
    />
  );

  useEffect(() => {
    getMyBidData({
      queryParams: {
        ...getPageSizeQueryObj(10),
        ...BID_QUERY_POPULATE_PARAMS_OBJECT,
        sort: "id:desc",
        "filters[status][$eq]": "Open",
      },
      callback: (resp) => {
        setBids(resp.data);
      },
    });
  }, []);

  const columns = [
    {
      title: "Bid Name",
      dataIndex: "bidName",
      key: "bidName",
      render: (text, data) => (
        <p className="table-text" style={{ maxWidth: "170px" }}>
          {text}
        </p>
      ),
      width: "200px",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: renderStatusColumn,
      align: "center",
    },
    {
      title: "Openinig Date",
      dataIndex: "openingDate",
      key: "openingDate",
      align: "center",
      render: (text, data) => (
        <p className="table-text" style={{ maxWidth: "175px" }}>
          {moment(text).format(DATE_FORMAT1)}
        </p>
      ),
    },
    {
      title: "Closing Date",
      dataIndex: "closingDate",
      key: "closingDate",
      align: "center",
      render: (text, data) => (
        <p className="table-text" style={{ maxWidth: "175px" }}>
          {moment(text).format(DATE_FORMAT1)}
        </p>
      ),
    },
    {
      title: "Organization",
      dataIndex: "organization",
      key: "organization",
      align: "center",
      render: (text, data) => (
        <p className="table-text" style={{ maxWidth: "239px" }}>
          {data?.companyDetail?.companyName}
        </p>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: renderActionColumn,
    },
  ];
  return (
    <div className="container home-table-wrapper">
      <CommonTable columns={columns} dataSource={bids} width={true} />
    </div>
  );
}

export default HomeBidTable;
