import React, { useState } from "react";
import "../styles.scss";
import {
  CommonButton,
  CommonTable,
  CommonTextField,
} from "../../../../../../components/common";
import { Col, Row } from "antd";
import { TABLE_DATA } from "../../../../../../constants";

function Step0({ isHidden, handleNextStep }) {
  return (
    <div
      className={`stepDetailItemWrapper stepZero ${isHidden ? "hidden" : ""} `}
      style={{ height: "calc(100vh - 262px)", position: "relative" }}
    >
      <div className="terms-content-wrapper">
        <CommonTextField
          text={"Terms Of Service"}
          color="#021349"
          fontFamily={"seventyTwoSemiBold"}
          fontSize="24px"
          mb={"12px"}
        />
        <CommonTextField
          text={
            "It is the sole responsibility of each bidder to check this Web Page often to review any open bid opportunities. Your firm must be registered with the Sturgeon County “Bid Opportunities Website” to be notified of Addenda/Addendum for all Sturgeon County Bid Documents."
          }
          fontFamily="seventyTwoRegular"
          fontSize={"14px"}
          color="#021349"
          mb={"15px"}
        />
        <CommonTextField
          text={
            "Amendments to Bid documents will be posted to the Sturgeon County Bid Opportunities Website in the form of Addenda/Addendum. The onus is unequivocally with Bidders to ensure that they have downloaded all Addenda/Addendum posted prior to submission of their Bids, whether they have received notification from the Sturgeon County Bid Opportunities website or not. Failure to acknowledge Addenda/Addendum on the bid submission form may result in a non-compliant bid and rejection by the Sturgeon County."
          }
          fontFamily="seventyTwoRegular"
          fontSize={"14px"}
          color="#021349"
          mb={"15px"}
        />
        <CommonTextField
          text={
            "The Sturgeon County is not responsible or liable whatsoever for misdirected notices of solicitations or for misdirected Addenda/Addendum which may result from Registered Vendors who fail to update their contact information."
          }
          fontFamily="seventyTwoRegular"
          fontSize={"14px"}
          color="#021349"
          mb={"15px"}
        />
        <CommonTextField
          text={
            "Bid submissions must be received no later than the date and time specified on the bid document. Bids that are received after the official closing time and date or that are not properly signed will be “rejected” and not considered."
          }
          fontFamily="seventyTwoRegular"
          fontSize={"14px"}
          color="#021349"
          mb={"15px"}
        />
        <CommonTextField
          text={
            "All prices submitted must be in Canadian funds, Free on Board (F.O.B.) destination."
          }
          fontFamily="seventyTwoRegular"
          fontSize={"14px"}
          color="#021349"
          mb={"60px"}
        />
        <CommonTextField
          text={"Disclaimer"}
          color="#021349"
          fontFamily={"seventyTwoSemiBold"}
          fontSize="24px"
          mb={"12px"}
        />
        <CommonTextField
          text={
            "The Sturgeon County makes every effort to keep the information on the ‘Bid Opportunities’ website up to date and correct, and makes no representations or warranties of any kind, express or implied about the completeness, timeliness, accuracy, reliability, suitability or availability with respect to the information contained on the website for any purpose. Any reliance you place on any materials on this website is therefore strictly at your own risk."
          }
          fontFamily="seventyTwoRegular"
          fontSize={"14px"}
          color="#021349"
          mb={"15px"}
        />
        <CommonTextField
          text={
            "Although every effort is made to keep the website up and running smoothly, due to the nature of the Internet and the technology involved, the Sturgeon County assumes no responsibility or liability whatsoever for any temporary interruptions to, or unavailability of, the website due to any technical or other issues which are beyond the Sturgeon County control, or for any loss, injury or damage suffered by any party which may result from accessing or using this website, or from any inability to use or access this website for any reason whatsoever Sturgeon County will not be liable for any false, inaccurate, outdated, inappropriate or incomplete information presented on the website and assumes no responsibility for any damages arising out of the use of this website."
          }
          fontFamily="seventyTwoRegular"
          fontSize={"14px"}
          color="#021349"
          mb={"15px"}
        />
      </div>
      <CommonButton
        text={"Agree"}
        width="200px"
        height={"56px"}
        onClick={() => handleNextStep(1)}
        fontSize={"18px"}
      />
    </div>
  );
}

export default Step0;
