import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BIDS_DETAIL, DASHBOARD_ROUTE, LOGIN_ROUTE } from "../constants";
import {
  AuthSharedLayout,
  PrivateSharedLayout,
  PublicSharedLayout,
} from "../shared-layouts";

const PublicRoute = ({ children }) => {
  return <PublicSharedLayout>{children}</PublicSharedLayout>;
};

const PrivateRoute = ({ children }) => {
  // CONST VALS
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  // REDUX DATA
  const { isAuthenticated } = useSelector((state) => state.user);

  // HOOKS
  useEffect(() => {
    if (
      !isAuthenticated &&
      location?.pathname !== BIDS_DETAIL?.replace(":id", params?.id)
    ) {
      navigate(LOGIN_ROUTE);
    }
  }, [isAuthenticated, location]);

  return <PrivateSharedLayout>{children}</PrivateSharedLayout>;
};

const AuthRoute = ({ children }) => {
  // CONST VALS
  const navigate = useNavigate();

  // REDUX DATA
  const { isAuthenticated } = useSelector((state) => state.user);

  // HOOKS
  useEffect(() => {
    if (isAuthenticated) {
      navigate(DASHBOARD_ROUTE);
    }
  }, [isAuthenticated]);

  return <AuthSharedLayout>{children}</AuthSharedLayout>;
};

export { PublicRoute, PrivateRoute, AuthRoute };
