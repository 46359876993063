import React, { useState } from "react";
import "./styles.scss";
import PersonalProfile from "./partials/personal";
import CompanyProfile from "./partials/company";
import ChangePassword from "./partials/changePassword";
import { useSelector } from "react-redux";
import { CustomDispatch } from "../../../helpers";
import { getProfileDataRequest } from "../../../redux/slicers/user";
import { useEffect } from "react";
import { Loader } from "../../../components";
const Profile = () => {
  //REDUX DATA
  const { isSeller, profileData } = useSelector((state) => state.user);

  //CUSTOM DISPATCH
  const [getProfileData] = CustomDispatch(getProfileDataRequest);
  const [profileLoader, setProfileLoader] = useState(true);

  //HOOKS
  useEffect(() => {
    getProfileData({
      callback: () => {
        setProfileLoader(false);
      },
    });
  }, []);

  return (
    <div className="profile-wrapper">
      {profileLoader ? (
        <Loader height="calc(100vh - 210px)" />
      ) : (
        <>
          <PersonalProfile data={profileData?.user} />
          {!isSeller && <CompanyProfile data={profileData?.company} />}
          <ChangePassword profileData={profileData} />
        </>
      )}
    </div>
  );
};

export default Profile;
