import { createSlice } from "@reduxjs/toolkit";

const NotificationReducer = createSlice({
  name: "notification",
  initialState: {
    notifications: [],
    deviceToken: "",
  },
  reducers: {
    addDeviceTokenRequest: (state, action) => {
      state.deviceToken = action.payload?.payload?.deviceToken;
    },
    addDeviceTokenSuccess: () => {},

    deleteDeviceTokenRequest: () => {},
    deleteDeviceTokenSuccess: () => {},

    getAllNotificationsRequest: () => {},
    getAllNotificationsSuccess: (state, action) => {
      state.notifications = action.payload;
    },

    markNotificationReadRequest(state, action) {
      const notificationId = action?.payload?.pathParams;
      const allNotifications = [...state.notifications];
      const notificationIndex = allNotifications.findIndex(
        (notification) => notification.id == notificationId
      );
      if (notificationIndex > -1) {
        allNotifications[notificationIndex].isRead = true;
        state.notifications = allNotifications;
      }
    },
    markNotificationReadSuccess() {},

    markAllAsReadRequest(state) {
      const allNotifications = [...state.notifications]?.map((notification) => {
        notification.isRead = true;
        return notification;
      });

      state.notifications = [...allNotifications];
    },
  },
});

export const {
  addDeviceTokenRequest,
  addDeviceTokenSuccess,
  deleteDeviceTokenRequest,
  deleteDeviceTokenSuccess,

  getAllNotificationsRequest,
  getAllNotificationsSuccess,

  markNotificationReadRequest,
  markNotificationReadSuccess,

  markAllAsReadRequest,
} = NotificationReducer.actions;

export default NotificationReducer.reducer;
