import { Dropdown, Menu } from "antd";
import React from "react";
import { Images } from "../../../theme";
import "./styles.scss";
function CommonThreeDotMenu({ onEdit, onDelete, isEdit = true, disabled = false }) {
  //CUSTOM COMPONENTS
  const bidMenu = (item) => (
    <Menu
      items={[
        isEdit && {
          key: "1",
          label: (
            <span className="group-member-options" onClick={onEdit}>
              <p>Edit</p>
            </span>
          ),
        },
        {
          key: "2",
          label: (
            <span className="group-member-options" onClick={onDelete}>
              <p>Cancel</p>
            </span>
          ),
        },
      ]}
    />
  );
  return (
    <Dropdown
    disabled={disabled}
      getPopupContainer={(trigger) => trigger.parentElement}
      trigger={"click"}
      overlay={bidMenu()}
      placement={"bottomRight"}
    >
      <span className="c-p" style={{ padding: "5px" }}>
        <Images.ThreeDots className={`c-p ${disabled && "diabled-menu"} `} />
      </span>
    </Dropdown>
  );
}

export default CommonThreeDotMenu;
