import { Form, Select } from "antd";
import React, { useState } from "react";
import "./styles.scss";

const CommonSelect = ({
  name,
  rules = [],
  options,
  placeholder,
  defaultValue,
  className,
  prefix,
  form,
  onSelect = () => {},
  mode = "none",
  disabled = false,
  open,
  allowClear = false,
  formClassName = "",
  onChange = () => {},
  handleSearch = () => {},
  loading = false,
  filterOption = false,
}) => {
  return (
    <Form.Item
      name={name}
      className={formClassName}
      rules={[...rules]}
      style={{ width: "100%" }}
    >
      <Select
        onSearch={handleSearch}
        onChange={onChange}
        maxTagCount="responsive"
        allowClear={allowClear}
        open={open}
        defaultValue={defaultValue}
        {...(mode ? { mode: mode } : {})}
        className={`${className ? className : "common-select"}`}
        placeholder={placeholder}
        options={options}
        disabled={disabled}
        onSelect={onSelect}
        loading={loading}
        filterOption={filterOption}
      />
    </Form.Item>
  );
};

export default CommonSelect;
