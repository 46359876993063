import React, { useEffect } from "react";
import "./styles.scss";
import { CommonHeading, CommonTextField } from "../../../components/common";
import { Images } from "../../../theme";
import { BidsTable, CardsSection } from "../../../components";
import { CustomDispatch } from "../../../helpers";
import { useSelector } from "react-redux";
import { getMyBidsRequest } from "../../../redux/slicers/bid";
import {
  getBidsBySubmittedUserQuery,
  getBidsByTitleQuery,
  getBidsByUserQuery,
  getBidsCompletedQuery,
  getBidsOngoingQuery,
  getPageSizeQuery,
  getActiveBidsQuery,
} from "../../../utils";
import { useDebounce } from "@uidotdev/usehooks";
import { BID_QUERY_POPULATE_PARAMS } from "../../../constants";
import { fetchToken, requestPermission } from "../../../firebase";
import { addDeviceTokenRequest } from "../../../redux/slicers/notification";
import { getProfileDataRequest } from "../../../redux/slicers/user";
const Dashboard = () => {
  //CUSTOM DISPATCH
  const [getMyBidData, bidsLoader] = CustomDispatch(getMyBidsRequest);
  const [addDeviceToken] = CustomDispatch(addDeviceTokenRequest);
  const [getProfile] = CustomDispatch(getProfileDataRequest);

  // states
  const [bids, setBids] = React.useState([]);
  const [pagination, setPagination] = React.useState(null);
  const [search, setSearch] = React.useState("");
  const [analytics, setAnalytic] = React.useState({
    active: 0,
    completed: 0,
    ongoing: 0,
  });

  const debouncedSearch = useDebounce(search, 400);

  //REDUX DATA
  const { profileData } = useSelector((state) => state.user);
  const userId = profileData?.user?.id;
  //HOOKS
  useEffect(() => {
    getProfile();
    if (!userId) return;
    // get ongoing bids count
    handleGetAnalyticBids(
      getBuyerSellerAnalyticQuery({ active: false, completed: false })
    );
    // get active bids count
    handleGetAnalyticBids(getBuyerSellerAnalyticQuery({ active: true }), {
      active: true,
    });
    // get completed bids count
    handleGetAnalyticBids(getBuyerSellerAnalyticQuery({ completed: true }), {
      completed: true,
    });
    handleGetAnalyticBids(
      `&${getBidsOngoingQuery(
        profileData?.user?.isBuyer ? true : false,
        profileData?.user?.id
      )}`,
      {}
    );

    handleGetToken();
  }, [userId]);

  useEffect(() => {
    // get bids
    if (!userId) return;
    handleGetMyBids();
  }, [debouncedSearch, userId]);

  // query for buyer and seller bids
  const getBuyerSellerQuery = () => {
    return profileData?.user?.isBuyer
      ? getBidsByUserQuery(profileData?.user?.id)
      : // + getBidsOngoingQuery()
        getBidsBySubmittedUserQuery(profileData?.user?.id);
    // +
    //   getBidsOngoingQuery(profileData?.user?.id);
  };

  async function handleGetToken() {
    let notifPermission = await requestPermission();
    if (notifPermission) {
      const token = await fetchToken();
      if (token)
        addDeviceToken({
          payload: {
            deviceToken: token,
          },
        });
    } else {
    }
  }

  // query for buyer and seller bids analytic

  function getBuyerSellerAnalyticQuery(
    props = { active: false, completed: false }
  ) {
    const active = props?.active;
    const completed = props?.completed;

    const statusQuery = completed
      ? getBidsCompletedQuery(profileData?.user?.isBuyer, profileData?.user?.id)
      : active
      ? getActiveBidsQuery(profileData?.user?.isBuyer, profileData?.user?.id)
      : getBidsOngoingQuery(profileData?.user?.isBuyer, profileData?.user?.id);

    return profileData?.user?.isBuyer
      ? getBidsByUserQuery(profileData?.user?.id) + statusQuery
      : getBidsBySubmittedUserQuery(profileData?.user?.id) + statusQuery;
  }

  // get bids for table

  function handleGetMyBids(query = "") {
    getMyBidData({
      queryParams:
        getBuyerSellerQuery() +
        getBidsByTitleQuery(debouncedSearch) +
        BID_QUERY_POPULATE_PARAMS +
        query +
        getPageSizeQuery(),
      callback: (bids) => {
        bids.pagination && setPagination(bids.pagination);
        setBids(bids?.data || []);
      },
    });
  }

  // get bids for analytic

  function handleGetAnalyticBids(
    query = "",
    props = { active: false, completed: false }
  ) {
    const active = props?.active;
    const completed = props?.completed;
    getMyBidData({
      queryParams: query,
      callback: (response) => {
        if (response.pagination) {
          if (active)
            setAnalytic((prev) => {
              return { ...prev, active: response.pagination?.total };
            });
          else if (completed)
            setAnalytic((prev) => {
              return { ...prev, completed: response.pagination?.total };
            });
          else {
            setAnalytic((prev) => {
              return { ...prev, ongoing: response.pagination?.total };
            });
          }
        }
      },
    });
  }
  return (
    <section className="dashboard">
      <CommonTextField text={"Company Profile"} fontWeight={600} />
      <CommonHeading
        text={`Welcome Back ${profileData?.user?.firstName}!`}
        mb={"8px"}
        mt={"8px"}
      />
      <CardsSection
        active={analytics?.active}
        completed={analytics?.completed}
        ongoing={analytics?.ongoing}
      />
      <div className="bids-section">
        <div className="heading-wrapper">
          <CommonTextField
            text={"Bids"}
            fontFamily="seventyTwoBold"
            fontSize={"24px"}
          />
          <div
            className="table-filters-wrapper"
            style={{ width: "469px", maxWidth: "100%" }}
          >
            <div className="search-box" style={{ width: "100%" }}>
              <Images.Search />
              <input
                type="text"
                placeholder={"Search any Bids..."}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <BidsTable
          bids={bids}
          pagination={pagination}
          handleGetMyBids={handleGetMyBids}
          handleGetMyBidsWithSearch={handleGetMyBids}
          bidsLoader={bidsLoader}
        />
      </div>
    </section>
  );
};

export default Dashboard;
