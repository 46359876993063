import React, { useEffect, useState } from "react";
import "../styles.scss";
import {
  CommonButton,
  CommonTextField,
  DocUploader,
} from "../../../../../../components/common";
import { Col, Row } from "antd";
import { toastAlert } from "../../../../../../utils";

function Step3({
  setDocuments = () => {},
  isHidden,
  handleNextStep,
  submittedBidDetail,
}) {
  const [proposal, setProposal] = useState(null);
  const [appendix, setAppendix] = useState(null);
  const [healthSafety, setHealthSafety] = useState(null);
  const [other, setOther] = useState(null);

  useEffect(() => {
    if (submittedBidDetail?.id) {
      if (submittedBidDetail?.proposal?.id)
        setProposal(submittedBidDetail?.proposal);

      if (submittedBidDetail?.appendix?.id)
        setAppendix(submittedBidDetail?.appendix);

      if (submittedBidDetail?.healthSafety?.id)
        setHealthSafety(submittedBidDetail?.healthSafety);

      if (submittedBidDetail?.other?.id) setOther(submittedBidDetail?.other);
    }
  }, [submittedBidDetail]);

  const handleNext = () => {
    if (!proposal) {
      toastAlert("Proposal is required", "error");
      return;
    }

    if (!appendix) {
      toastAlert("Appendix is required", "error");
      return;
    }

    if (!healthSafety) {
      toastAlert("Health Safety is required", "error");
      return;
    }

    if (!other) {
      toastAlert("Other is required", "error");
      return;
    }

    setDocuments({
      proposal,
      appendix,
      healthSafety,
      other,
    });

    handleNextStep(4);
  };

  return (
    <div className={` ${isHidden ? "hidden" : ""} `}>
      <Row gutter={[23, 23]}>
        <Col span={24}>
          <CommonTextField
            text={"Maximum file upload size is 10 MB"}
            color={"rgba(2, 19, 73, 1)"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            fontFamily={"seventyTwoRegular"}
            mt={"30px"}
          />
        </Col>

        <Col xs={24} lg={12}>
          <DocUploader
            fileNameProp={proposal?.name}
            isCustom
            customCode={
              <CommonTextField
                text={"Submit 1 Proposal"}
                fontFamily={"seventyTwoSemiBold"}
                fontSize={"16px"}
                lineHeight={"18.4px"}
              />
            }
            docType="Doc"
            maxSize={500}
            multiple={false}
            callback={setProposal}
          />
        </Col>

        <Col xs={24} lg={12}>
          <DocUploader
            fileNameProp={appendix?.name}
            isCustom
            customCode={
              <CommonTextField
                text={"Submitter 2 - Appendix C 0 Submission Form (Completed)"}
                fontFamily={"seventyTwoSemiBold"}
                fontSize={"16px"}
                lineHeight={"18.4px"}
              />
            }
            docType="Doc"
            maxSize={500}
            multiple={false}
            callback={setAppendix}
          />{" "}
        </Col>

        <Col xs={24} lg={12}>
          <DocUploader
            fileNameProp={healthSafety?.name}
            isCustom
            customCode={
              <CommonTextField
                text={
                  "Submittal 3 - Health & Safety Questionnaire ( Completed)"
                }
                fontFamily={"seventyTwoSemiBold"}
                fontSize={"16px"}
                lineHeight={"18.4px"}
              />
            }
            docType="Doc"
            maxSize={500}
            multiple={false}
            callback={setHealthSafety}
          />{" "}
        </Col>

        <Col xs={24} lg={12}>
          <DocUploader
            fileNameProp={other?.name}
            isCustom
            customCode={
              <CommonTextField
                text={
                  "Submitter 4 - Other ( additional information as required)"
                }
                fontFamily={"seventyTwoSemiBold"}
                fontSize={"16px"}
                lineHeight={"18.4px"}
              />
            }
            docType="Doc"
            maxSize={500}
            multiple={false}
            callback={setOther}
          />{" "}
        </Col>
      </Row>

      <div className="buttonWrapper">
        <CommonButton
          text={"Back"}
          classname={"button mr-10"}
          width="200px"
          height={"56px"}
          fontSize={"18px"}
          onClick={() => {
            handleNextStep(2);
          }}
        />
        <CommonButton
          text={"Continue"}
          classname={"button"}
          width="200px"
          height={"56px"}
          fontSize={"18px"}
          onClick={handleNext}
        />
      </div>
    </div>
  );
}

export default Step3;
