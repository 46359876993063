import React, { useEffect, useState } from "react";
import "./styles.scss";
import {
  CommonButton,
  CommonHeading,
  CommonModal,
  CommonTextField,
} from "../../../components/common";
import { Avatar, Col, Row, Space } from "antd";
import { Images } from "../../../theme";
import { BidsTable, CardsSection, InquiryModal } from "../../../components";
import { toastAlert } from "../../../utils";
import { useParams } from "react-router-dom";
import {
  getSubmittedBidRequest,
  updateBidSubmissionRequest,
} from "../../../redux/slicers/bid";
import { singleSubmittedBidManipulator } from "../../../data-manipulator/bid";
import { CustomDispatch } from "../../../helpers";
const AskQuestion = () => {
  const [selected, setSelected] = useState(null);
  const [inquiry, setInquiry] = useState(false);
  const [question, setQuestion] = useState("");
  const [submittedBidDetail, setSubmittedBidDetail] = useState(null);

  const [getSubmittedBidDetail] = CustomDispatch(getSubmittedBidRequest);
  const [updateBidSubmission] = CustomDispatch(updateBidSubmissionRequest);
  const params = useParams();

  console.log({ params });

  useEffect(() => {
    getSubmittedBidDetail({
      pathParams: params?.subId,
      queryParams: {
        populate: "*",
      },
      logic(res) {
        if (res) {
          const manipulatedRes = singleSubmittedBidManipulator(res);
          setSubmittedBidDetail(manipulatedRes);
          setInquiry(manipulatedRes?.inquiry || []);

          console.log("manipulatedRes", manipulatedRes);
        }
      },
    });
  }, [params?.subId]);

  const inquiryModalPreviewHandler = () => {
    setInquiry(!inquiry);
  };

  const handleInquirySubmit = (val) => {
    const newInquiry = [
      ...submittedBidDetail?.inquiry,
      { description: val?.cancelReason, is_seller: false },
    ];
    const payload = {
      data: {
        inquiry: newInquiry,
        isInquiry: true,
        fromSeller: false,
      },
    };

    updateBidSubmission({
      payload,
      pathParams: params?.subId,
      logic: (res) => {
        toastAlert("Question submitted successfully.");
        inquiryModalPreviewHandler();
        setSubmittedBidDetail({ ...submittedBidDetail, inquiry: newInquiry });
      },
      error(err) {
        console.error("updateBidSubmission errr", err);
      },
    });
  };

  return (
    <section className="ask-question-wrapper">
      <Space size={15} style={{ marginBottom: "30px" }}>
        <Avatar size={51} src={<Images.UserAvatar />} />
        <Space size={6} direction="vertical">
          <CommonTextField
            fontFamily={"seventyTwoRegular"}
            className={""}
            fontSize={"14px"}
            text={submittedBidDetail?.user?.fullName}
            lineHeight={"10px"}
          />
          <CommonTextField
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            className={"light-text"}
            text={submittedBidDetail?.supplierTitle}
          />
        </Space>
      </Space>
      <a
        href={submittedBidDetail?.previewBid?.url}
        download={"bid.pdf"}
        target="_blank"
        className="bid-download c-p"
        style={{ marginBottom: "30px" }}
      >
        <CommonTextField text={"Bid.pdf"} fontFamily={"seventyTwoBold"} />
        <Images.DownloadIcon />
      </a>
      {submittedBidDetail?.inquiry?.length > 0 && (
        <div className="questions-wrapper">
          <Row gutter={[0, 20]}>
            <Col span={24}>
              <CommonTextField
                fontFamily={"seventyTwoBold"}
                fontSize={"20px"}
                text={"Ask Question"}
                color={"#021349"}
              />
            </Col>
            {submittedBidDetail?.inquiry?.map((item, index) => (
              <Col span={24} key={index}>
                <div
                  className={`question-item ${
                    selected === index ? "selected" : ""
                  }`}
                  onClick={() => setSelected(index)}
                >
                  <div
                    className={
                      item?.isSeller
                        ? "statusButton statusButton_open"
                        : "statusButton statusButton_awarded"
                    }
                  >
                    <p>{item?.isSeller ? "Seller" : "Buyer"}</p>
                  </div>
                  <CommonTextField
                    fontFamily={"seventyTwoLight"}
                    fontSize={"16px"}
                    paddingTop={"10px"}
                    text={item?.description}
                    color={"#021349"}
                  />
                </div>
              </Col>
            ))}
          </Row>
          <div className="ask-btn-wrapper">
            <CommonButton
              onClick={() => setInquiry(true)}
              text={"Type an inquiry"}
              background={"#031946"}
              width={"200px"}
              height={"56px"}
            />
          </div>
        </div>
      )}
      <InquiryModal
        preview={inquiry}
        handleClose={inquiryModalPreviewHandler}
        handleConfirm={handleInquirySubmit}
      />
    </section>
  );
};

export default AskQuestion;
