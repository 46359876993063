import ErrorThumbnail from "../assets/images/error-thumb.svg";
import { ReactComponent as BidsIcon } from "../assets/sidebarIcons/bids-icon.svg";
import { ReactComponent as MyBidsIcon } from "../assets/sidebarIcons/my-bids-icon.svg";
import { ReactComponent as DashboardIcon } from "../assets/sidebarIcons/dashboard-icon.svg";
import { ReactComponent as ProfileIcon } from "../assets/sidebarIcons/profile-icon.svg";
import { ReactComponent as SubscriptionIcon } from "../assets/sidebarIcons/subscription-icon.svg";
import upLogo from "../assets/headerIcons/up-logo.png";
import { ReactComponent as NotificationIcon } from "../assets/headerIcons/notification.svg";
import { ReactComponent as Mainlogo } from "../assets/sidebarIcons/main-logo.svg";
import addIcon from "../assets/sidebarIcons/add-icon.svg";
import hamburger from "../assets/headerIcons/hamburger.png";
import onGoingIcon from "../assets/dashboard/ongoing-icon.png";
import activeIcon from "../assets/dashboard/active-icon.png";
import completeIcon from "../assets/dashboard/complete.png";
import { ReactComponent as BetterBidIcon } from "../assets/home/better-icon.svg";
import { ReactComponent as WhyNotificationIcon } from "../assets/home/notification-icon.svg";
import { ReactComponent as OpportunityIcon } from "../assets/home/opportunity-icon.svg";
import { ReactComponent as CheckMark } from "../assets/commonIcons/checkMark.svg";
import { ReactComponent as CloseIcon } from "../assets/commonIcons/closeIcon.svg";
import { ReactComponent as Search } from "../assets/commonIcons/search.svg";
import { ReactComponent as SortAsc } from "../assets/commonIcons/sortAsc.svg";
import { ReactComponent as SortDsc } from "../assets/commonIcons/sortDsc.svg";
import { ReactComponent as ProfileImage } from "../assets/images/profile-image.svg";
import bidOpportunity from "../assets/home/bid-opportunity.png";
import { ReactComponent as FooterLogo } from "../assets/images/footerLogo.svg";

//AUTH
import { ReactComponent as HeaderLogo } from "../assets/auth/header-logo.svg";
import { ReactComponent as EmailIcon } from "../assets/formIcons/email.svg";
import { ReactComponent as PasswordIcon } from "../assets/formIcons/password.svg";
import { ReactComponent as UsernameIcon } from "../assets/formIcons/username.svg";
import { ReactComponent as PhoneIcon } from "../assets/formIcons/phone.svg";
import { ReactComponent as Organization } from "../assets/formIcons/organization.svg";
import { ReactComponent as Industry } from "../assets/formIcons/industry.svg";
import { ReactComponent as Location } from "../assets/formIcons/location.svg";
import { ReactComponent as Camera } from "../assets/commonIcons/camera.svg";
import { ReactComponent as ThreeDots } from "../assets/commonIcons/threeDots.svg";
import { ReactComponent as UserAvatar } from "../assets/bidDetail/Avatar.svg";
import { ReactComponent as EditIcon } from "../assets/bidDetail/editIcon.svg";

import { ReactComponent as Delete } from "../assets/commonIcons/delete.svg";
import EyeClose from "../assets/formIcons/eyeClose.svg";
import EyeOpen from "../assets/formIcons/eyeOpen.svg";
import LoginImage from "../assets/auth/login-image.png";
import ForgotPassImage from "../assets/auth/forgot-pass-image.png";
import ResetPassImage from "../assets/auth/reset-pass-image.png";
import SignupImage1 from "../assets/auth/sign-up-image1.png";
import SignupImage2 from "../assets/auth/sign-up-image2.png";
// Common Icons
import { ReactComponent as LeftArrow } from "../assets/commonIcons/left-arrow.svg";
import { ReactComponent as DocumentIcon } from "../assets/commonIcons/document-icon.svg";
import { ReactComponent as DownloadIcon } from "../assets/commonIcons/download-icon.svg";
import { ReactComponent as LocationIcon } from "../assets/commonIcons/location.svg";
import { ReactComponent as CallIcon } from "../assets/commonIcons/call.svg";
import { ReactComponent as MailIcon } from "../assets/commonIcons/mail.svg";
import { ReactComponent as DocIcon } from "../assets/commonIcons/docIcon.svg";
import { ReactComponent as PublicLogo } from "../assets/images/publicLogo.svg";
import Upsun from "../assets/images/upsun.png";

import AccountVerification from "../assets/auth/accountVerification.png";
import EmailVerification from "../assets/auth/emailVerification.png";

import laptop1 from "../assets/home/laptop1.png";
import image1 from "../assets/home/image1.png";
import image2 from "../assets/home/image2.png";
import image3 from "../assets/home/image3.png";
import image4 from "../assets/home/image4.png";
import { ReactComponent as CheckIcon } from "../assets/home/Check Circle.svg";

import { ReactComponent as Facebook } from "../assets/commonIcons/facebook.svg";
import { ReactComponent as Twitter } from "../assets/commonIcons/twitter.svg";
import { ReactComponent as Linkedin } from "../assets/commonIcons/linkedin.svg";
import { ReactComponent as Instagram } from "../assets/commonIcons/instagram.svg";
import Industry1 from "../assets/images/industry-1.png";
import Industry2 from "../assets/images/industry-2.png";
import Industry3 from "../assets/images/industry-3.png";
import Next from "../assets/commonIcons/next.svg";
import Previous from "../assets/commonIcons/previous.svg";
import { ReactComponent as EditProfile } from "../assets/commonIcons/editProfile.svg";
import { ReactComponent as Lock } from "../assets/commonIcons/lock.svg";
import { ReactComponent as Forward } from "../assets/commonIcons/forward.svg";
import CompanyProfile from "../assets/images/companyProfile.svg";
import Visa from "../assets/cardVendors/visa.png";
import DeleteCross from "../assets/Delete.png";

export default {
  ErrorThumbnail,
  BidsIcon,
  MyBidsIcon,
  DashboardIcon,
  ProfileIcon,
  SubscriptionIcon,
  upLogo,
  NotificationIcon,
  Mainlogo,
  addIcon,
  hamburger,
  onGoingIcon,
  activeIcon,
  completeIcon,
  WhyNotificationIcon,
  BetterBidIcon,
  OpportunityIcon,
  bidOpportunity,
  CheckMark,
  CloseIcon,
  //AUTH
  HeaderLogo,
  LoginImage,
  PasswordIcon,
  EmailIcon,
  EyeClose,
  EyeOpen,
  ForgotPassImage,
  ResetPassImage,
  SignupImage1,
  SignupImage2,
  // COMMON ICONS
  LeftArrow,
  DocumentIcon,
  DownloadIcon,
  // Images
  Upsun,

  UsernameIcon,
  PhoneIcon,
  AccountVerification,
  EmailVerification,
  Camera,
  Organization,
  Location,
  Industry,
  DocIcon,
  Search,
  ThreeDots,
  UserAvatar,
  EditIcon,

  Delete,
  ProfileImage,

  laptop1,
  image1,
  image2,
  image3,
  image4,
  CheckIcon,

  SortAsc,
  SortDsc,
  PublicLogo,
  FooterLogo,
  LocationIcon,
  MailIcon,
  CallIcon,
  Facebook,
  Twitter,
  Linkedin,
  Instagram,
  Industry1,
  Industry2,
  Industry3,
  Next,
  Previous,
  EditProfile,
  Lock,
  Forward,
  CompanyProfile,
  Visa,

  DeleteCross,
};
