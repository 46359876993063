import React from "react";
import "./styles.scss";

import { Col, Row } from "antd";
import { CommonHeading, CommonTextField } from "../../../components/common";
import { PublicFooter } from "../../../components";

const conditions = [
  {
    title: "Acceptance of Terms",
    text: "By accessing and using Central Bids, you agree to comply with and be bound by the following terms and conditions. If you do not agree with any part of these terms, you must not use our platform."
  },
  {
    title: "Definitions",
    items: [
      "Platform: Refers to the Central Bids website and any associated services.",
      "User: Any individual or entity that accesses and uses the Platform.",
      "Buyer: Users who post bids or procurement requests.",
      "Vendor: Users who submit bids or proposals in response to posted opportunities."
    ]
  },
  {
    title: "User Accounts",
    items: [
      "Registration: Users must register for an account to access certain features of the Platform. You agree to provide accurate and complete information during registration.",
      "Account Security: You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account."
    ]
  },
  {
    title: "Use of the Platform",
    items: [
      "Eligibility: Users must be at least 18 years old and have the legal capacity to enter into agreements.",
      "Prohibited Conduct: Users must not engage in any activity that is unlawful, fraudulent, or harmful to the Platform or other Users."
    ]
  },

  {
    title: "Posting and Submitting Bids",
    items: [
      "Accuracy: Buyers must ensure that all bid postings are accurate and complete. Vendors must provide truthful and complete information in their submissions.",
      "Compliance: All bids and submissions must comply with applicable laws and regulations."
    ]
  },
  {
    title: "Fees and Payments",
    items: [
      "Subscription Plans: Access to certain features of the Platform may require a subscription plan. Details of the plans and pricing are available on the Platform.",
      "Payments: Users agree to pay all fees associated with their chosen subscription plan. Fees are non-refundable except as required by law."
    ]
  },
  {
    title: "Confidentiality",
    items: [
      "Confidential Information: Users may have access to confidential information. Users agree to keep such information confidential and not to disclose it to any third party without proper authorization."
    ]
  },
  {
    title: "Intellectual Property",
    items: [
      "Ownership: Central Bids owns all intellectual property rights in the Platform. Users are granted a limited, non-exclusive license to use the Platform for its intended purposes.",
      "Restrictions: Users must not copy, modify, or distribute any part of the Platform without prior written consent from Central Bids."
    ]
  },
  {
    title: "Disclaimers and Limitation of Liability",
    items: [
      'No Warranty: The Platform is provided "as is" without any warranties, express or implied. Central Bids does not guarantee the accuracy or reliability of any content on the Platform.',
      "Limitation of Liability: Central Bids is not liable for any indirect, incidental, or consequential damages arising from the use of the Platform."
    ]
  },
  {
    title: "Termination",
    items: [
      "Suspension or Termination: Central Bids reserves the right to suspend or terminate User accounts for any violation of these terms.",
      "Effect of Termination: Upon termination, Users must cease all use of the Platform and may lose access to their account data."
    ]
  },
  {
    title: "Changes to Terms",
    text: "Central Bids reserves the right to modify these terms at any time. Users will be notified of any significant changes, and continued use of the Platform constitutes acceptance of the revised terms."
  },
  {
    title: "Governing Law",
    text: "These terms and conditions are governed by the laws of the jurisdiction in which Central Bids operates. Any disputes arising from these terms will be resolved in the appropriate courts of that jurisdiction."
  },
  {
    title: "Contact Information",
    text: "For any questions or concerns about these terms, please contact us at:"
  }
];

const Terms = () => {
  return (
    <section className="terms-wrapper">
      <div className="">
        <div className="container">
          <Row gutter={[20, 16]}>
            <Col span={24}>
              <CommonHeading
                color={"#021349"}
                text="TERMS AND CONDITIONS"
                fontFamily={"seventyTwoBold"}
                className={"terms-heading"}
                // lineHeight={"68.99px"}
                mt={8}
              />
              <CommonTextField
                color="#465E7D"
                text="At Central Bids, we redefine the bidding landscape by uniting private businesses and government entities on a single, intuitive platform. Our goal is to cultivate a dynamic marketplace where buyers and suppliers engage effortlessly, ensuring fair, efficient, and transparent procurement for all."
                mt={0}
                fontFamily={"seventyTwoRegular"}
                fontSize={"16px"}
                lineHeight={"20px"}
              />
            </Col>

            <Col span={24}>
              {conditions?.map((item, index) => (
                <>
                  <CommonHeading
                    color={"#021349"}
                    text={`${index + 1}. ${item?.title}`}
                    fontFamily={"seventyTwoBold"}
                    fontSize={"20px"}
                    lineHeight={"48.99px"}
                    mt={8}
                  />

                  {item?.text && (
                    <CommonTextField
                      mt={0}
                      color="#465E7D"
                      fontFamily={"seventyTwoRegular"}
                      fontSize={"16px"}
                      lineHeight={"20px"}
                      text={item?.text}
                    />
                  )}

                  {item?.items?.length > 0 &&
                    item?.items?.map((content) => (
                      <CommonTextField
                        text={`- ${content}`}
                        mt={8}
                        color="#465E7D"
                        fontFamily={"seventyTwoRegular"}
                        fontSize={"16px"}
                        lineHeight={"20px"}
                      />
                    ))}
                </>
              ))}

              <CommonTextField
                text={`Email: john.smith@domain.com`}
                mt={16}
                color="#465E7D"
                fontFamily={"seventyTwoRegular"}
                fontSize={"16px"}
                lineHeight={"20px"}
              />

              <CommonTextField
                text={`Phone: +0 123 456 7890`}
                mt={3}
                color="#465E7D"
                fontFamily={"seventyTwoRegular"}
                fontSize={"16px"}
                lineHeight={"20px"}
              />

              <CommonTextField
                text={`Address: 6391 Elgin St. Celina, Delaware 10299`}
                mt={3}
                mb={45}
                color="#465E7D"
                fontFamily={"seventyTwoRegular"}
                fontSize={"16px"}
                lineHeight={"20px"}
              />
            </Col>
          </Row>
        </div>
      </div>

      <PublicFooter />
    </section>
  );
};

export default Terms;
