import React, { useEffect, useRef } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Col, message, Row, Upload } from "antd";
import { Images } from "../../../theme";
import CommonTextField from "../TextField";
import "./styles.scss";
import { useState } from "react";
import {
  BASE_URL,
  callRequest,
  UPLOAD_IMAGE,
} from "../../../config/web-service";

function DocUploader({
  fileNameProp = "",
  docType = "Doc",
  maxSize = 10,
  isCustom = false,
  customCode,
  resetField,
  multiple = true,
  callback = () => {},
  handleRemoveFile = () => {},
  propFiles = [],
}) {
  //STATES
  const [fileList, setFileList] = useState([]);
  const [fileName, setFileName] = useState("");
  // const [imgId, StImgId] = useState([]);
  // console.log("🚀 ~ imgId:", imgId);

  //CONST VALS
  const { Dragger } = Upload;
  const fileInputRef = useRef(null);

  useEffect(() => {
    fileNameProp && setFileName(fileNameProp);
  }, [fileNameProp]);

  useEffect(() => {
    propFiles?.length && setFileList(propFiles || []);
  }, [propFiles]);

  const props = {
    name: "file",
    multiple: multiple,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        // setFileList(info.fileList);
        if (!multiple) {
          setFileName(info.file.name);
        }
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    beforeUpload: (file) => {
      if (file?.type !== "application/pdf") {
        message.error("Only PDF files are acceptable.");
        return Upload.LIST_IGNORE;
      }
      const isLt10M = file.size / 1024 / 1024 < maxSize;
      if (!isLt10M) {
        message.error("Maximum upload size is 10 MB");
      }
      return isLt10M || Upload.LIST_IGNORE;
    },
  };

  const handleUploadFile = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const fmData = new FormData();

    const headers = { "content-type": "multipart/form-data" };

    fmData.append("files", file);
    try {
      const res = await callRequest({
        url: UPLOAD_IMAGE,
        header: headers,
        payload: fmData,
      });

      // console.log(res, "rtest");

      if (!res?.length) {
        throw new Error("File not uploaded successfully");
      }

      if (multiple) {
        onSuccess("OK", res);
        callback(res[0]?.id);
        setFileList([...fileList, ...res]);
        // StImgId((pre) => [...pre, res[0]?.id]);
      } else {
        onSuccess("OK", res?.[0]);
        callback(res?.[0]);
        // StImgId((pre) => [...pre, res?.id]);
      }
    } catch (error) {
      onError({ error });
    }
  };

  //HANDLERS
  const removeFileHandler = (id) => {
    console.log({ id });
    // resetField();
    let temp = [...fileList];
    let newTemp = temp.filter((x) => x.id !== id);
    handleRemoveFile(id);
    setFileList(newTemp);
    fileInputRef.current = null;
  };

  console.log({ fileList });

  return (
    <Row gutter={[20, 20]}>
      <Col span={24}>
        <Dragger
          {...props}
          headers={{
            url: `${BASE_URL}/api/upload`,
          }}
          className="custom-uploader"
          accept=".pdf"
          showUploadList={false}
          ref={fileInputRef}
          customRequest={handleUploadFile}
        >
          {!isCustom ? (
            <>
              <p className="ant-upload-drag-icon">
                <Images.DocIcon />
              </p>
              <CommonTextField
                text={`Upload ${docType}`}
                color="#0070F2"
                fontFamily={"seventyTwoRegular"}
                fontSize="18px"
                mb={"5px"}
              />
              <CommonTextField
                text={`PDF files only. Max ${maxSize} MB each`}
                color="#021349"
                fontFamily={"seventyTwoRegular"}
                fontSize="14px"
              />
            </>
          ) : (
            <>
              {customCode}
              {fileName && <CommonTextField text={fileName} mt="10px" />}
            </>
          )}
        </Dragger>
      </Col>
      {multiple &&
        fileList?.map((item, index) => (
          <Col span={12} xs={24} key={index}>
            <div className="file-item">
              <div className="item-info">
                <Images.DocumentIcon />
                <CommonTextField
                  text={item?.name}
                  fontFamily="seventyTwoLight"
                  color={"#0070F2"}
                />
              </div>
              <img src={Images.DeleteCross}
                className="c-p"
                onClick={() => removeFileHandler(item.id)}
              />
            </div>
          </Col>
        ))}
    </Row>
  );
}

export default DocUploader;
