import { Carousel, Form } from "antd";
import React, { useEffect, useRef, useState } from "react";
import OTPInput from "react-otp-input";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AuthCarousel } from "../../components";
import {
  CommonButton,
  CommonInputField,
  CommonPasswordInput,
  CommonTextField,
} from "../../components/common";
import { FORGOT_PASSWORD_ROUTE, RESET_PASSWORD_ROUTE } from "../../constants";
import { CustomDispatch } from "../../helpers";
import {
  confirmOtpRequest,
  getOtpRequest,
  loginSuccess,
  setResetOtp,
} from "../../redux/slicers/user";
import { Images } from "../../theme";
import { inputFieldRule, isPasswordValid, maskEmail } from "../../utils";
import "./auth.scss";

const EmailVerification = () => {
  //STATES
  const [_otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState(60);
  const [enableResendBtn, setEnableResendBtn] = useState(false);

  //REDUX DATA
  const { verificationEmail } = useSelector((state) => state.user);

  //CONST VALS
  const [form] = Form.useForm();
  const navigate = useNavigate();
  //CUSTOM DISPATCH
  const [getOtp, isLoading] = CustomDispatch(getOtpRequest);
  const [confirmOtp, confirmOtpLoader] = CustomDispatch(confirmOtpRequest);
  const [setPassOtp] = CustomDispatch(setResetOtp);

  //HANDLERS
  const handleConfirm = (vals) => {
    confirmOtp({
      payload: { email: verificationEmail, otp: vals.otp },
      logic(res) {
        setPassOtp({ payload: vals.otp });
        navigate(RESET_PASSWORD_ROUTE);
      },
    });
  };

  const handleResendCode = () => {
    getOtp({
      payload: { email: verificationEmail, isSignup: false },
      logic(res) {
        setSeconds(60);
        setEnableResendBtn(false);
      },
    });
  };

  // HOOKS
  useEffect(() => {
    if (seconds === 0) return setEnableResendBtn(true);
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds]);

  return (
    <div className="auth-box">
      <div className="login-container">
        <div className="login-wrapper">
          <div className="login-form-wrapper">
            <CommonTextField
              text={"Verification"}
              fontSize="39px"
              fontWeight={"600"}
              color="#031946"
              mb={"18px"}
              whiteSpace="nowrap"
            />
            <CommonTextField
              text={
                "You’ll get an Phone number. Open the Message and find the verification code"
              }
              fontSize="14px"
              fontWeight={"400"}
              color="#465E7D"
              mb={"30px"}
            />
            <span className="wrong-acc-text">
              {/* jo*****@domain.com{" "} */}
              {verificationEmail
                ? maskEmail(verificationEmail)
                : "jo*****@domain.com"}{" "}
              <Link to={FORGOT_PASSWORD_ROUTE}>Not You?</Link>
            </span>

            <Form form={form} className="login-form" onFinish={handleConfirm}>
              <Form.Item
                rules={inputFieldRule({
                  name: "Otp",
                  isWhiteSpace: false,
                  isMin: true,
                })}
                name="otp"
              >
                <OTPInput
                  inputType="number"
                  shouldAutoFocus={true}
                  value={_otp}
                  containerStyle={`otpCodeInput custom-otp-input`}
                  onChange={(e) => setOtp(e)}
                  numInputs={6}
                  renderSeparator={<span>&emsp;</span>}
                  renderInput={(props) => <input {...props} />}
                />
              </Form.Item>
              <span className="resend-timer">
                00:{`${seconds}`.padStart(2, 0)}
              </span>

              <CommonButton
                text={"Verify"}
                fontSize="18px"
                fontWeight={"600"}
                height="56px"
                htmlType="submit"
                marginBottom={"15px"}
                marginTop={"15px"}
                loading={confirmOtpLoader}
              />
              <CommonButton
                text={"Resend Code"}
                fontSize="18px"
                fontWeight={"600"}
                height="56px"
                background={"#031946"}
                onClick={handleResendCode}
                disabled={!enableResendBtn}
                loading={isLoading}
              />
            </Form>
          </div>
        </div>
      </div>
      <div className="auth-image-wrapper">
        <img src={Images.EmailVerification} alt="Login Banner" />
        <AuthCarousel />
      </div>
    </div>
  );
};
export default EmailVerification;
