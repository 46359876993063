import React from "react";
import { Col, Form, Modal, Row } from "antd";
import { CommonButton, CommonTextAreaField } from "../../../common";
import { Images } from "../../../../theme";
import "./styles.scss";
import { inputFieldRule } from "../../../../utils";

const InquiryModal = ({ handleClose, preview, handleConfirm }) => {
  //CONST VALS
  const [form] = Form.useForm();
  //HANDLERS
  const handleFormFinish = (val) => {
    handleConfirm(val);
  };
  return (
    <Modal
      destroyOnClose
      open={preview}
      centered
      footer={null}
      width={633}
      className="cancel-bid-modal"
      closeIcon={false}
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={handleClose}>
            <Images.CloseIcon />
          </div>
          <div className="title">
            <h3>Type an Inquiry</h3>
          </div>
          <Form
            form={form}
            className="payment-form"
            onFinish={handleFormFinish}
          >
            <CommonTextAreaField
              placeholder={"Type here..."}
              height={"198px"}
              name="cancelReason"
              rules={inputFieldRule({
                name: "Cancellation Reason",
              })}
            />
            <div className="modal-footer">
              <CommonButton
                text={"Submit"}
                borderRadius={"12px"}
                htmlType="submit"
                width={"200px"}
              />
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default InquiryModal;
