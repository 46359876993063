import moment from "moment";
import { Images } from "../theme";
import { isEmailValid } from "../utils";

export const ALERT_TIMEOUT = 3000;
export const DEV_ENV = "dev";
export const PROD_ENV = "prod";
export const APP_ENV = DEV_ENV;
export const API_LOG = APP_ENV === DEV_ENV;
export const API_TIMEOUT = 30000;

// DATE FORMATS
export const DATE_FORMAT1 = "MMM DD, YYYY";
export const DATE_FORMAT2 = "DD, MMM YY";
export const DATE_FORMAT3 = "YYYY-MM-DD";
export const DATE_FORMAT4 = "DD-MM-YYYY";
export const TIME_FORMAT1 = "hh:mma";
export const TIME_FORMAT2 = "hh:mm";
export const TIME_FORMAT3 = "hh";
export const DATE_TIME = "Do MM YYYY, hh:mm a";

// VALIDATION REGEX
export const ALLOW_ALPHABETS_REGEX = new RegExp(/^[a-zA-Z\s]*$/); // allow only alphabets and white spaces
export const ALLOW_ALPHANUM_REGEX = new RegExp(/^[a-zA-Z0-9]*$/); // allow only alphabets and numbers
export const ALLOW_NUMBERS_REGEX = new RegExp(/^[0-9]*$/); // allow only numbers
export const CARD_EXPIRY_REGEX = new RegExp(
  /^(0[1-9]|1[0-2])\/(2[2-9]|[3-9][0-9])$/
);
export const CARD_NUMBER_REGEX = new RegExp(
  /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13}|6(?:011|5[0-9]{2})[0-9]{12})$/
);

// ACCESS TYPES
export const ACCESS_TYPES = {
  AUTH: "auth",
  PRIVATE: "private",
  PUBLIC: "public",
};

// REACT TOASTIFY ALERT CONFIGURATION
export const ALERT_POSITIONS = {
  TOP_Right: "top-right",
  TOP_LEFT: "top-left",
  TOP_CENTER: "top-center",
  BOTTOM_RIGHT: "bottom-right",
  BOTTOM_LEFT: "bottom-left",
  BOTTOM_CENTER: "bottom-center",
};
export const ALERT_THEMES = {
  DARK: "dark",
  COLORED: "colored",
  LIGHT: "light",
};
export const ALERT_TYPES = {
  INFO: "info",
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  DEFAULT: "default",
};

// PUBLIC ROUTES
export const HOME_ROUTE = "/";
export const VERIFY_SUBSCRIPTION_ROUTE = "/verify-subscription";
export const ABOUT_ROUTE = "/about-us";
export const PRIVACY_ROUTE = "/privacy-policy";
export const TERMS_ROUTE = "/terms-and-conditions";
// AUTH ROUTES
export const LOGIN_ROUTE = "/login";
export const SIGN_UP_ROUTE = "/signup";
export const AUTH_SUBSCRIPTION_ROUTE = "/subscribe";
export const FORGOT_PASSWORD_ROUTE = "/forgot-password";
export const RESET_PASSWORD_ROUTE = "/reset-password";
export const EMAIL_VERIFICATION_ROUTE = "/email-verification";
export const ACCOUNT_VERIFICATION_ROUTE = "/account-verification";
// PRIVATE ROUTES
export const DASHBOARD_ROUTE = "/dashboard";
export const MYBIdS_ROUTE = "/my-bids";
export const BIDS_ROUTE = "/open-bids";
export const SUBSCRIPTION_ROUTE = "/subscription";
export const PROFILE_ROUTE = "/profile";
export const BIDS_DETAIL = "/bids/:id";
export const OPEN_BIDS_DETAIL = "/open-bids/:id";
export const STEPS_DETAIL = "/submit-bid/:id";
export const CREATE_BID_ROUTE = "/create-bid";
export const EDIT_BID_ROUTE = "/edit-bid/:id";
export const EDIT_SUBMITTED_BID_ROUTE = "/edit-bid/:id/submission/:submittedId";
export const ASK_QUESTION_ROUTE = "/ask-question/:id/:subId";
export const MANAGE_SUBSCRIPTION_ROUTE = "/manage-subscription";

export const validatorField = (_, value, min = 3, max = 80) => {
  if (!value || value?.length < 1) {
    return Promise.reject(new Error("Field is required."));
  } else if (value?.length > 0 && value?.trim() === "") {
    return Promise.reject(new Error("Cannot accept only white spaces."));
  } else if (value?.length < min) {
    return Promise.reject(
      new Error(`Must be equal or greater than ${min} characters.`)
    );
  } else if (value?.length > max) {
    return Promise.reject(
      new Error(`Must be less than ${max + 1} characters.`)
    );
  } else {
    return Promise.resolve();
  }
};

export const multiEmailTag = (required) => [
  {
    validator: (_, v) => {
      let value = v?.[0];
      if (required) {
        if (!value || value?.length < 1) {
          return Promise.reject(new Error("Field is required"));
        } else if (value?.includes(" ")) {
          return Promise.reject(new Error("Cannot accept whitespaces."));
        } else if (value && !isEmailValid(value)) {
          return Promise.reject(new Error("Invalid email address."));
        } else {
          return Promise.resolve();
        }
      } else {
        return Promise.resolve();
      }
    },
  },
];

export const EMAIL_RULE = [
  {
    validator: (_, value) => {
      if (!value || value?.length < 1) {
        return Promise.reject(new Error("Field is required"));
      } else if (value?.includes(" ")) {
        return Promise.reject(new Error("Cannot accept whitespaces."));
      } else if (value && !isEmailValid(value)) {
        return Promise.reject(new Error("Invalid email address."));
      } else {
        return Promise.resolve();
      }
    },
  },
];

export const DATE_RULE = (
  _,
  value,
  getFieldsValue = () => {},
  fieldName = ""
) => {
  const isValidDate = moment(value, true).isValid();

  if (!value || value?.length < 1) {
    return Promise.reject(new Error("Field is required"));
  } else if (!isValidDate) {
    return Promise.reject(new Error("Invalid date format. Use YYYY-MM-DD"));
  } else if (
    fieldName &&
    getFieldsValue &&
    value.isBefore(getFieldsValue(fieldName))
  ) {
    return Promise.reject(
      new Error("Closing date should be greater than opening date.")
    );
  } else {
    return Promise.resolve();
  }
};

export const numberValidatorField = (_, value) => {
  if (value === undefined) {
    return Promise.reject(new Error("Field is required."));
  } else if (value < 1) {
    return Promise.reject(new Error("Must be equal or greater than 1."));
  } else if (`${value}`.toLowerCase().includes("e")) {
    return Promise.reject(new Error("Invalid Value."));
  } else {
    return Promise.resolve();
  }
};
export const numberMinMaxValidatorField = (_, value) => {
  if (value === undefined) {
    return Promise.reject(new Error("Students are required."));
  } else if (value < 1) {
    return Promise.reject(new Error("Minimum 1 student required."));
  } else if (`${value}`.toLowerCase().includes("e")) {
    return Promise.reject(new Error("Invalid Value."));
  } else {
    return Promise.resolve();
  }
};

export const phoneValidation = (_, v, __, min = 10, max = 30) => {
  const value = v?.toString();
  if (!value || value?.length < 1) {
    return Promise.reject(new Error("Field is required."));
  } else if (value?.length > 0 && value?.trim() === "") {
    return Promise.reject(new Error("Cannot accept white spaces."));
  } else if (value?.length < min) {
    return Promise.reject(
      new Error(`Must be greater than ${min - 1} characters.`)
    );
  } else if (value?.length > max) {
    return Promise.reject(
      new Error(`Must be less than ${max + 1} characters.`)
    );
  } else {
    return Promise.resolve();
  }
};

export const passwordValidation = (_, value) => {
  if (!value?.length) {
    return Promise.reject(new Error("Field is required."));
  } else if (value && value?.includes() === "") {
    return Promise.reject(new Error("Cannot accept whitespace"));
  } else if (value && !checkPasswordValidation(value)) {
    return Promise.reject(
      new Error(
        "Should contain at least 8 and maximum 30 characters , 1 Upper case, 1 Lower Case and 1 Special Character!"
      )
    );
  } else {
    return Promise.resolve();
  }
};

export const handlePassworMatch = (_, value, name) => {
  if (!value?.length) {
    return Promise.reject(new Error("Field is required."));
  } else if (value && value !== name) {
    return Promise.reject(new Error("Passwords do not match."));
  } else {
    return Promise.resolve();
  }
};

export const handlePassworNotMatch = (_, value, name) => {
  if (!value?.length) {
    return Promise.reject(new Error("Field is required."));
  } else if (value && value === name) {
    return Promise.reject(new Error("Passwords is same."));
  } else if (value && value?.includes() === "") {
    return Promise.reject(new Error("Cannot accept whitespace"));
  } else if (value && !checkPasswordValidation(value)) {
    return Promise.reject(
      new Error(
        "Should contain at least 8 and maximum 30 characters , 1 Upper case, 1 Lower Case and 1 Special Character!"
      )
    );
  } else {
    return Promise.resolve();
  }
};

export const SIDEBAR_ITEMS = [
  {
    src: <Images.DashboardIcon />,
    text: "Dashboard",
    route: DASHBOARD_ROUTE,
    active: [DASHBOARD_ROUTE],
  },
  {
    src: <Images.BidsIcon />,
    text: "My Bids",
    route: MYBIdS_ROUTE,
    active: [MYBIdS_ROUTE, BIDS_DETAIL, ASK_QUESTION_ROUTE],
  },
  {
    src: <Images.SubscriptionIcon />,
    text: "Subscription ",
    route: SUBSCRIPTION_ROUTE,
    active: [SUBSCRIPTION_ROUTE, MANAGE_SUBSCRIPTION_ROUTE],
  },
  {
    src: <Images.ProfileIcon />,
    text: "Profile",
    route: PROFILE_ROUTE,
    active: [PROFILE_ROUTE],
  },
];

export const BUYER_SIDEBAR_ITEMS = [
  {
    src: <Images.DashboardIcon />,
    text: "Dashboard",
    route: DASHBOARD_ROUTE,
    active: [DASHBOARD_ROUTE],
  },
  {
    src: <Images.BidsIcon />,
    text: "Bids",
    route: BIDS_ROUTE,
    active: [BIDS_ROUTE, OPEN_BIDS_DETAIL, STEPS_DETAIL],
  },
  {
    src: <Images.MyBidsIcon />,
    text: "My Bids",
    route: MYBIdS_ROUTE,
    active: [MYBIdS_ROUTE, BIDS_DETAIL],
  },
  {
    src: <Images.SubscriptionIcon />,
    text: "Subscription ",
    route: SUBSCRIPTION_ROUTE,
    active: [SUBSCRIPTION_ROUTE, MANAGE_SUBSCRIPTION_ROUTE],
  },
  {
    src: <Images.ProfileIcon />,
    text: "Profile",
    route: PROFILE_ROUTE,
    active: [PROFILE_ROUTE],
  },
];

export const PUBLIC_SIDEBAR_ITEMS = [
  {
    src: <Images.ProfileIcon />,
    text: "Signup ",
    route: SIGN_UP_ROUTE,
  },
  {
    src: <Images.ProfileIcon />,
    text: "Login",
    route: LOGIN_ROUTE,
  },
];

export const BID_FILTERS_LIST = [
  { text: "Ongoing", id: "ongoing" },
  { text: "Active", id: "active" },
  { text: "History", id: "history" },
];

export const BRANCH_TABlE_DATA = [
  {
    key: "1",
    bidName: "X12345-RFP-2023-EWSI-90-SK-Toronto...",
    publishedDate: "Jan 15, 2022 2:45 PM PST",
    closingDate: "Dec 9, 2021 3:35 PM PST",
    organization: "ABC Corporation",
  },
  {
    key: "2",
    bidName: "Y67890-RFP-2023-EWSI-90-SK-Montreal...",
    publishedDate: "Feb 1, 2022 1:30 PM PST",
    closingDate: "Dec 5, 2021 3:35 PM PST",
    organization: "XYZ Corp",
  },
  {
    key: "3",
    bidName: "A54321-RFP-2023-EWSI-90-SK-Vancouver...",
    publishedDate: "Mar 10, 2022 3:15 PM PST",
    closingDate: "Dec 6, 2021 3:35 PM PST",
    organization: "LMN Ltd.",
  },
  {
    key: "4",
    bidName: "B98765-RFP-2023-EWSI-90-SK-Ottawa...",
    publishedDate: "Apr 5, 2022 4:20 PM PST",
    closingDate: "Dec 9, 2021 3:35 PM PST",
    organization: "PQR Inc.",
  },
  {
    key: "5",
    bidName: "C12345-RFP-2023-EWSI-90-SK-Halifax...",
    publishedDate: "May 12, 2022 2:00 PM PST",
    closingDate: "Dec 6, 2021 3:35 PM PST",
    organization: "LMN Ltd.",
  },
  {
    key: "6",
    bidName: "D67890-RFP-2023-EWSI-90-SK-Quebec...",
    publishedDate: "Jun 8, 2022 1:45 PM PST",
    closingDate: "Dec 23, 2021 3:35 PM PST",
    organization: "ABC Corporation",
  },
  {
    key: "7",
    bidName: "E54321-RFP-2023-EWSI-90-SK-Edmonton...",
    publishedDate: "Jul 20, 2022 3:30 PM PST",
    closingDate: "Dec 16, 2021 3:35 PM PST",
    organization: "XYZ Corp",
  },
  {
    key: "8",
    bidName: "F98765-RFP-2023-EWSI-90-SK-Winnipeg...",
    publishedDate: "Aug 15, 2022 4:15 PM PST",
    closingDate: "Dec 18, 2021 3:35 PM PST",
    organization: "PQR Inc.",
  },
  {
    key: "10",
    bidName: "F98765-RFP-2023-EWSI-90-SK-Winnipeg...",
    publishedDate: "Aug 15, 2022 4:15 PM PST",
    closingDate: "Dec 18, 2021 3:35 PM PST",
    organization: "PQR Inc.",
  },
];

export const ONGOING_TABlE_DATA = [
  {
    key: "1",
    bidName: "X12345-RFP-2023-EWSI-90-SK-Toronto...",
    publishedDate: "Jan 15, 2022 2:45 PM PST",
    closingDate: "Dec 9, 2021 3:35 PM PST",
    organization: "ABC Corporation",
    category: "ongoing",
    status: "open",
  },
  {
    key: "2",
    bidName: "Y67890-RFP-2023-EWSI-90-SK-Montreal...",
    publishedDate: "Feb 1, 2022 1:30 PM PST",
    closingDate: "Dec 5, 2021 3:35 PM PST",
    organization: "XYZ Corp",
    category: "active",
    status: "open",
  },
  {
    key: "3",
    bidName: "A54321-cancelledRFP-2023-EWSI-90-SK-Vancouver...",
    publishedDate: "Mar 10, 2022 3:15 PM PST",
    closingDate: "Dec 6, 2021 3:35 PM PST",
    organization: "LMN Ltd.",
    category: "history",
    status: "complete",
    isCompleted: true,
  },
  {
    key: "4",
    bidName: "X12345-RFP-2023-EWSI-90-SK-Toronto...",
    publishedDate: "Jan 15, 2022 2:45 PM PST",
    closingDate: "Dec 9, 2021 3:35 PM PST",
    organization: "ABC Corporation",
    category: "ongoing",
    status: "open",
  },
  {
    key: "5",
    bidName: "Y67890-RFP-2023-EWSI-90-SK-Montreal...",
    publishedDate: "Feb 1, 2022 1:30 PM PST",
    closingDate: "Dec 5, 2021 3:35 PM PST",
    organization: "XYZ Corp",
    category: "active",
    status: "open",
  },
  {
    key: "6",
    bidName: "A54321-RFP-2023-EWSI-90-SK-Vancouver...",
    publishedDate: "Mar 10, 2022 3:15 PM PST",
    closingDate: "Dec 6, 2021 3:35 PM PST",
    organization: "LMN Ltd.",
    category: "history",
    status: "cancelled",
    isCancelled: true,
  },
  {
    key: "7",
    bidName: "X12345-RFP-2023-EWSI-90-SK-Toronto...",
    publishedDate: "Jan 15, 2022 2:45 PM PST",
    closingDate: "Dec 9, 2021 3:35 PM PST",
    organization: "ABC Corporation",
    category: "ongoing",
    status: "open",
  },
  {
    key: "100",
    bidName: "Y67890-RFP-2023-EWSI-90-SK-Montreal...",
    publishedDate: "Feb 1, 2022 1:30 PM PST",
    closingDate: "Dec 5, 2021 3:35 PM PST",
    organization: "XYZ Corp",
    category: "active",
    status: "open",
  },
  {
    key: "8",
    bidName: "A54321-RFP-2023-EWSI-90-SK-Vancouver...",
    publishedDate: "Mar 10, 2022 3:15 PM PST",
    closingDate: "Dec 6, 2021 3:35 PM PST",
    organization: "LMN Ltd.",
    category: "history",
    status: "complete",
    isCompleted: true,
  },
  {
    key: "9",
    bidName: "X12345-RFP-2023-EWSI-90-SK-Toronto...",
    publishedDate: "Jan 15, 2022 2:45 PM PST",
    closingDate: "Dec 9, 2021 3:35 PM PST",
    organization: "ABC Corporation",
    category: "ongoing",
    status: "open",
  },
  {
    key: "10",
    bidName: "Y67890-RFP-2023-EWSI-90-SK-Montreal...",
    publishedDate: "Feb 1, 2022 1:30 PM PST",
    closingDate: "Dec 5, 2021 3:35 PM PST",
    organization: "XYZ Corp",
    category: "active",
    status: "open",
  },
  {
    key: "11",
    bidName: "A54321-RFP-2023-EWSI-90-SK-Vancouver...",
    publishedDate: "Mar 10, 2022 3:15 PM PST",
    closingDate: "Dec 6, 2021 3:35 PM PST",
    organization: "LMN Ltd.",
    category: "history",
    status: "cancelled",
    isCancelled: true,
  },
  {
    key: "12",
    bidName: "X12345-RFP-2023-EWSI-90-SK-Toronto...",
    publishedDate: "Jan 15, 2022 2:45 PM PST",
    closingDate: "Dec 9, 2021 3:35 PM PST",
    organization: "ABC Corporation",
    category: "ongoing",
    status: "open",
  },
  {
    key: "13",
    bidName: "Y67890-RFP-2023-EWSI-90-SK-Montreal...",
    publishedDate: "Feb 1, 2022 1:30 PM PST",
    closingDate: "Dec 5, 2021 3:35 PM PST",
    organization: "XYZ Corp",
    category: "active",
    status: "open",
  },
  {
    key: "99",
    bidName: "A54321-RFP-2023-EWSI-90-SK-Vancouver...",
    publishedDate: "Mar 10, 2022 3:15 PM PST",
    closingDate: "Dec 6, 2021 3:35 PM PST",
    organization: "LMN Ltd.",
    category: "history",
    status: "cancelled",
    isCancelled: true,
  },
  {
    key: "14",
    bidName: "X12345-RFP-2023-EWSI-90-SK-Toronto...",
    publishedDate: "Jan 15, 2022 2:45 PM PST",
    closingDate: "Dec 9, 2021 3:35 PM PST",
    organization: "ABC Corporation",
    category: "ongoing",
    status: "open",
  },
  {
    key: "15",
    bidName: "Y67890-RFP-2023-EWSI-90-SK-Montreal...",
    publishedDate: "Feb 1, 2022 1:30 PM PST",
    closingDate: "Dec 5, 2021 3:35 PM PST",
    organization: "XYZ Corp",
    category: "active",
    status: "open",
  },
  {
    key: "16",
    bidName: "A54321-RFP-2023-EWSI-90-SK-Vancouver...",
    publishedDate: "Mar 10, 2022 3:15 PM PST",
    closingDate: "Dec 6, 2021 3:35 PM PST",
    organization: "LMN Ltd.",
    category: "history",
    status: "cancelled",
    isCancelled: true,
  },
  {
    key: "17",
    bidName: "X12345-RFP-2023-EWSI-90-SK-Toronto...",
    publishedDate: "Jan 15, 2022 2:45 PM PST",
    closingDate: "Dec 9, 2021 3:35 PM PST",
    organization: "ABC Corporation",
    category: "ongoing",
    status: "open",
  },
  {
    key: "18",
    bidName: "Y67890-RFP-2023-EWSI-90-SK-Montreal...",
    publishedDate: "Feb 1, 2022 1:30 PM PST",
    closingDate: "Dec 5, 2021 3:35 PM PST",
    organization: "XYZ Corp",
    category: "active",
    status: "open",
  },
  {
    key: "19",
    bidName: "A54321-RFP-2023-EWSI-90-SK-Vancouver...",
    publishedDate: "Mar 10, 2022 3:15 PM PST",
    closingDate: "Dec 6, 2021 3:35 PM PST",
    organization: "LMN Ltd.",
    category: "history",
    status: "complete",
    isCompleted: true,
  },
  {
    key: "20",
    bidName: "X12345-RFP-2023-EWSI-90-SK-Toronto...",
    publishedDate: "Jan 15, 2022 2:45 PM PST",
    closingDate: "Dec 9, 2021 3:35 PM PST",
    organization: "ABC Corporation",
    category: "ongoing",
    status: "open",
  },
  {
    key: "21",
    bidName: "Y67890-RFP-2023-EWSI-90-SK-Montreal...",
    publishedDate: "Feb 1, 2022 1:30 PM PST",
    closingDate: "Dec 5, 2021 3:35 PM PST",
    organization: "XYZ Corp",
    category: "active",
    status: "open",
  },
  {
    key: "22",
    bidName: "A54321-cancelledRFP-2023-EWSI-90-SK-Vancouver...",
    publishedDate: "Mar 10, 2022 3:15 PM PST",
    closingDate: "Dec 6, 2021 3:35 PM PST",
    organization: "LMN Ltd.",
    category: "history",
    status: "complete",
    isCompleted: true,
  },
  {
    key: "23",
    bidName: "X12345-RFP-2023-EWSI-90-SK-Toronto...",
    publishedDate: "Jan 15, 2022 2:45 PM PST",
    closingDate: "Dec 9, 2021 3:35 PM PST",
    organization: "ABC Corporation",
    category: "ongoing",
    status: "open",
  },
  {
    key: "24",
    bidName: "Y67890-RFP-2023-EWSI-90-SK-Montreal...",
    publishedDate: "Feb 1, 2022 1:30 PM PST",
    closingDate: "Dec 5, 2021 3:35 PM PST",
    organization: "XYZ Corp",
    category: "active",
    status: "open",
  },
  {
    key: "25",
    bidName: "A54321-RFP-2023-EWSI-90-SK-Vancouver...",
    publishedDate: "Mar 10, 2022 3:15 PM PST",
    closingDate: "Dec 6, 2021 3:35 PM PST",
    organization: "LMN Ltd.",
    category: "history",
    status: "cancelled",
    isCancelled: true,
  },
  {
    key: "26",
    bidName: "X12345-RFP-2023-EWSI-90-SK-Toronto...",
    publishedDate: "Jan 15, 2022 2:45 PM PST",
    closingDate: "Dec 9, 2021 3:35 PM PST",
    organization: "ABC Corporation",
    category: "ongoing",
    status: "open",
  },
  {
    key: "27",
    bidName: "Y67890-RFP-2023-EWSI-90-SK-Montreal...",
    publishedDate: "Feb 1, 2022 1:30 PM PST",
    closingDate: "Dec 5, 2021 3:35 PM PST",
    organization: "XYZ Corp",
    category: "active",
    status: "open",
  },
  {
    key: "28",
    bidName: "A54321-RFP-2023-EWSI-90-SK-Vancouver...",
    publishedDate: "Mar 10, 2022 3:15 PM PST",
    closingDate: "Dec 6, 2021 3:35 PM PST",
    organization: "LMN Ltd.",
    category: "history",
    status: "complete",
    isCompleted: true,
  },
  {
    key: "29",
    bidName: "X12345-RFP-2023-EWSI-90-SK-Toronto...",
    publishedDate: "Jan 15, 2022 2:45 PM PST",
    closingDate: "Dec 9, 2021 3:35 PM PST",
    organization: "ABC Corporation",
    category: "ongoing",
    status: "open",
  },
  {
    key: "30",
    bidName: "Y67890-RFP-2023-EWSI-90-SK-Montreal...",
    publishedDate: "Feb 1, 2022 1:30 PM PST",
    closingDate: "Dec 5, 2021 3:35 PM PST",
    organization: "XYZ Corp",
    category: "active",
    status: "open",
  },
  {
    key: "31",
    bidName: "A54321-RFP-2023-EWSI-90-SK-Vancouver...",
    publishedDate: "Mar 10, 2022 3:15 PM PST",
    closingDate: "Dec 6, 2021 3:35 PM PST",
    organization: "LMN Ltd.",
    category: "history",
    status: "cancelled",
    isCancelled: true,
  },
  {
    key: "32",
    bidName: "X12345-RFP-2023-EWSI-90-SK-Toronto...",
    publishedDate: "Jan 15, 2022 2:45 PM PST",
    closingDate: "Dec 9, 2021 3:35 PM PST",
    organization: "ABC Corporation",
    category: "ongoing",
    status: "open",
  },
  {
    key: "33",
    bidName: "Y67890-RFP-2023-EWSI-90-SK-Montreal...",
    publishedDate: "Feb 1, 2022 1:30 PM PST",
    closingDate: "Dec 5, 2021 3:35 PM PST",
    organization: "XYZ Corp",
    category: "active",
    status: "open",
  },
  {
    key: "33",
    bidName: "A54321-RFP-2023-EWSI-90-SK-Vancouver...",
    publishedDate: "Mar 10, 2022 3:15 PM PST",
    closingDate: "Dec 6, 2021 3:35 PM PST",
    organization: "LMN Ltd.",
    category: "history",
    status: "cancelled",
    isCancelled: true,
  },
  {
    key: "34",
    bidName: "X12345-RFP-2023-EWSI-90-SK-Toronto...",
    publishedDate: "Jan 15, 2022 2:45 PM PST",
    closingDate: "Dec 9, 2021 3:35 PM PST",
    organization: "ABC Corporation",
    category: "ongoing",
    status: "open",
  },
  {
    key: "35",
    bidName: "Y67890-RFP-2023-EWSI-90-SK-Montreal...",
    publishedDate: "Feb 1, 2022 1:30 PM PST",
    closingDate: "Dec 5, 2021 3:35 PM PST",
    organization: "XYZ Corp",
    category: "active",
    status: "open",
  },
  {
    key: "36",
    bidName: "A54321-RFP-2023-EWSI-90-SK-Vancouver...",
    publishedDate: "Mar 10, 2022 3:15 PM PST",
    closingDate: "Dec 6, 2021 3:35 PM PST",
    organization: "LMN Ltd.",
    category: "history",
    status: "cancelled",
    isCancelled: true,
  },
  {
    key: "37",
    bidName: "X12345-RFP-2023-EWSI-90-SK-Toronto...",
    publishedDate: "Jan 15, 2022 2:45 PM PST",
    closingDate: "Dec 9, 2021 3:35 PM PST",
    organization: "ABC Corporation",
    category: "ongoing",
    status: "open",
  },
  {
    key: "38",
    bidName: "Y67890-RFP-2023-EWSI-90-SK-Montreal...",
    publishedDate: "Feb 1, 2022 1:30 PM PST",
    closingDate: "Dec 5, 2021 3:35 PM PST",
    organization: "XYZ Corp",
    category: "active",
    status: "open",
  },
  {
    key: "39",
    bidName: "A54321-RFP-2023-EWSI-90-SK-Vancouver...",
    publishedDate: "Mar 10, 2022 3:15 PM PST",
    closingDate: "Dec 6, 2021 3:35 PM PST",
    organization: "LMN Ltd.",
    category: "history",
    status: "complete",
    isCompleted: true,
  },
  {
    key: "41",
    bidName: "A54321-RFP-2023-EWSI-90-SK-Vancouver...",
    publishedDate: "Mar 10, 2022 3:15 PM PST",
    closingDate: "Dec 6, 2021 3:35 PM PST",
    organization: "LMN Ltd.",
    category: "open",
    status: "open",
  },
  {
    key: "42",
    bidName: "X12345-RFP-2023-EWSI-90-SK-Toronto...",
    publishedDate: "Jan 15, 2022 2:45 PM PST",
    closingDate: "Dec 9, 2021 3:35 PM PST",
    organization: "ABC Corporation",
    category: "open",
    status: "open",
  },
  {
    key: "786",
    bidName: "Y67890-RFP-2023-EWSI-90-SK-Montreal...",
    publishedDate: "Feb 1, 2022 1:30 PM PST",
    closingDate: "Dec 5, 2021 3:35 PM PST",
    organization: "XYZ Corp",
    category: "open",
    status: "open",
  },
  {
    key: "43",
    bidName: "A54321-RFP-2023-EWSI-90-SK-Vancouver...",
    publishedDate: "Mar 10, 2022 3:15 PM PST",
    closingDate: "Dec 6, 2021 3:35 PM PST",
    organization: "LMN Ltd.",
    category: "open",
    status: "open",
  },
  {
    key: "44",
    bidName: "X12345-RFP-2023-EWSI-90-SK-Toronto...",
    publishedDate: "Jan 15, 2022 2:45 PM PST",
    closingDate: "Dec 9, 2021 3:35 PM PST",
    organization: "ABC Corporation",
    category: "open",
    status: "open",
  },
  {
    key: "45",
    bidName: "Y67890-RFP-2023-EWSI-90-SK-Montreal...",
    publishedDate: "Feb 1, 2022 1:30 PM PST",
    closingDate: "Dec 5, 2021 3:35 PM PST",
    organization: "XYZ Corp",
    category: "open",
    status: "open",
  },
  {
    key: "46",
    bidName: "A54321-RFP-2023-EWSI-90-SK-Vancouver...",
    publishedDate: "Mar 10, 2022 3:15 PM PST",
    closingDate: "Dec 6, 2021 3:35 PM PST",
    organization: "LMN Ltd.",
    category: "open",
    status: "open",
  },
  {
    key: "47",
    bidName: "X12345-RFP-2023-EWSI-90-SK-Toronto...",
    publishedDate: "Jan 15, 2022 2:45 PM PST",
    closingDate: "Dec 9, 2021 3:35 PM PST",
    organization: "ABC Corporation",
    category: "open",
    status: "open",
  },
  {
    key: "48",
    bidName: "Y67890-RFP-2023-EWSI-90-SK-Montreal...",
    publishedDate: "Feb 1, 2022 1:30 PM PST",
    closingDate: "Dec 5, 2021 3:35 PM PST",
    organization: "XYZ Corp",
    category: "open",
    status: "open",
  },
  {
    key: "49",
    bidName: "A54321-RFP-2023-EWSI-90-SK-Vancouver...",
    publishedDate: "Mar 10, 2022 3:15 PM PST",
    closingDate: "Dec 6, 2021 3:35 PM PST",
    organization: "LMN Ltd.",
    category: "open",
    status: "open",
  },
  {
    key: "56",
    bidName: "Y67890-RFP-2023-EWSI-90-SK-Montreal...",
    publishedDate: "Feb 1, 2022 1:30 PM PST",
    closingDate: "Dec 5, 2021 3:35 PM PST",
    organization: "XYZ Corp",
    category: "open",
    status: "open",
  },
  {
    key: "67",
    bidName: "A54321-RFP-2023-EWSI-90-SK-Vancouver...",
    publishedDate: "Mar 10, 2022 3:15 PM PST",
    closingDate: "Dec 6, 2021 3:35 PM PST",
    organization: "LMN Ltd.",
    category: "open",
    status: "open",
  },
  {
    key: "57",
    bidName: "X12345-RFP-2023-EWSI-90-SK-Toronto...",
    publishedDate: "Jan 15, 2022 2:45 PM PST",
    closingDate: "Dec 9, 2021 3:35 PM PST",
    organization: "ABC Corporation",
    category: "open",
    status: "open",
  },
  {
    key: "58",
    bidName: "Y67890-RFP-2023-EWSI-90-SK-Montreal...",
    publishedDate: "Feb 1, 2022 1:30 PM PST",
    closingDate: "Dec 5, 2021 3:35 PM PST",
    organization: "XYZ Corp",
    category: "open",
    status: "open",
  },
  {
    key: "59",
    bidName: "A54321-RFP-2023-EWSI-90-SK-Vancouver...",
    publishedDate: "Mar 10, 2022 3:15 PM PST",
    closingDate: "Dec 6, 2021 3:35 PM PST",
    organization: "LMN Ltd.",
    category: "open",
    status: "open",
  },
];

export const TABLE_DATA = [
  {
    key: "1",
    table: "Pricing Information",
    noOfRows: "4",
    noOfRequiredRows: "4",
    noOfOptionalRows: "4",
    mandatory: "Yes",
    subTotal: "N/A",
  },
  {
    key: "2",
    table: "Pricing Information",
    noOfRows: "4",
    noOfRequiredRows: "4",
    noOfOptionalRows: "4",
    mandatory: "Yes",
    subTotal: "N/A",
  },
  {
    key: "3",
    table: "Pricing Information",
    noOfRows: "4",
    noOfRequiredRows: "4",
    noOfOptionalRows: "4",
    mandatory: "Yes",
    subTotal: "N/A",
  },
  {
    key: "4",
    table: "Pricing Information",
    noOfRows: "4",
    noOfRequiredRows: "4",
    noOfOptionalRows: "4",
    mandatory: "Yes",
    subTotal: "N/A",
  },
];
export const TABLE_DATA_STEP_1 = [
  {
    key: "1",
    description: "Pricing Information",
    hr2024: "price2024",
    hr2025: "price2025",
    hr2026: "price2026",
  },
];

export const TABLE_DATA_STEP_4 = [
  {
    key: "1",
    fileName:
      "Addendum 1 - RFP 2023-091 Landscape Architect Services Wednesday December 20, 2023 01:49 PM",
    pages: "N/A",
  },
  {
    key: "2",
    fileName:
      "Addendum 1 - RFP 2023-091 Landscape Architect Services Wednesday December 20, 2023 01:49 PM",
    pages: "N/A",
  },
  {
    key: "3",
    fileName:
      "Addendum 1 - RFP 2023-091 Landscape Architect Services Wednesday December 20, 2023 01:49 PM",
    pages: "N/A",
  },
];

export const WHY_CENTRAl_BID_CONTENT = [
  {
    icon: <Images.WhyNotificationIcon />,
    title: "Bid Notifications",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
  },
  {
    icon: <Images.BetterBidIcon />,
    title: "Better bids",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
  },
  {
    icon: <Images.OpportunityIcon />,
    title: "Opportunities",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
  },
];

export const HOME_TABLE_DATA = [
  {
    key: "1",
    bidName: "2022-3T53 - Heart Lake 2022-3T53 - Heart Lake",
    publishDate: "Jan 11, 2024 9:00 AM MST",
    closingDate: "Jan 30, 2024 2:00 PM MST",
    organization: "Associated Engineering (Alberta) Ltd.",
    status: "Open",
  },
  {
    key: "2",
    bidName: "2022-3T53 - Heart Lake 2022-3T53 - Heart Lake",
    publishDate: "Jan 11, 2024 9:00 AM MST",
    closingDate: "Jan 30, 2024 2:00 PM MST",
    organization: "Associated Engineering (Alberta) Ltd.",
    status: "Open",
  },
  {
    key: "3",
    bidName: "2022-3T53 - Heart Lake 2022-3T53 - Heart Lake",
    publishDate: "Jan 11, 2024 9:00 AM MST",
    closingDate: "Jan 30, 2024 2:00 PM MST",
    organization: "Associated Engineering (Alberta) Ltd.",
    status: "Open",
  },
  {
    key: "4",
    bidName: "2022-3T53 - Heart Lake 2022-3T53 - Heart Lake",
    publishDate: "Jan 11, 2024 9:00 AM MST",
    closingDate: "Jan 30, 2024 2:00 PM MST",
    organization: "Associated Engineering (Alberta) Ltd.",
    status: "Open",
  },
  {
    key: "5",
    bidName: "2022-3T53 - Heart Lake 2022-3T53 - Heart Lake",
    publishDate: "Jan 11, 2024 9:00 AM MST",
    closingDate: "Jan 30, 2024 2:00 PM MST",
    organization: "Associated Engineering (Alberta) Ltd.",
    status: "Open",
  },
  {
    key: "6",
    bidName: "2022-3T53 - Heart Lake 2022-3T53 - Heart Lake",
    publishDate: "Jan 11, 2024 9:00 AM MST",
    closingDate: "Jan 30, 2024 2:00 PM MST",
    organization: "Associated Engineering (Alberta) Ltd.",
    status: "Open",
  },
  {
    key: "7",
    bidName: "2022-3T53 - Heart Lake 2022-3T53 - Heart Lake",
    publishDate: "Jan 11, 2024 9:00 AM MST",
    closingDate: "Jan 30, 2024 2:00 PM MST",
    organization: "Associated Engineering (Alberta) Ltd.",
    status: "Open",
  },
  {
    key: "8",
    bidName: "2022-3T53 - Heart Lake 2022-3T53 - Heart Lake",
    publishDate: "Jan 11, 2024 9:00 AM MST",
    closingDate: "Jan 30, 2024 2:00 PM MST",
    organization: "Associated Engineering (Alberta) Ltd.",
    status: "Open",
  },
];
export const BID_STATUSES = {
  open: "Open",
  closed: "Closed",
  terminated: "Terminated",
  awarded: "Awarded",
};
export const BID_STATUS = [
  {
    value: "Open",
    label: "Open",
  },
  {
    value: "Awarded",
    label: "Awarded",
  },
  {
    value: "Closed",
    label: "Closed",
  },

  {
    value: "Terminated",
    label: "Terminated",
  },
];

export const BID_STATUS_KEYS = {
  open: "Open",
  closed: "Closed",
  cancelled: "Terminated",
  completed: "Awarded",
};

export const ELECTRONIC_AUCTIONS = [
  {
    value: "Not Applicable",
    label: "Not Applicable",
  },
  {
    value: "Yes",
    label: "Yes",
  },
];

export const ONLINE_SUBMISSIONS = [
  {
    value: "Online Submissions Only",
    label: "Online Submissions Only",
  },
  {
    value: "Email",
    label: "Email",
  },
];

export const BID_SUBMISSION_STATUS = [
  {
    value: "Complete",
    label: "Complete",
  },
  {
    value: "Incomplete",
    label: "Incomplete",
  },
  {
    value: "Registered but not submitted",
    label: "Registered but not submitted",
  },

  {
    value: "submitted",
    label: "submitted",
  },
];

export const PUBLIC_OPENING = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

const ARRAY_OF_POPULATE = [
  "categories",
  "sub_categories",
  "sub_categories.category",
  "bid_type",
  "classification",
  "negotitation_type",
  "participant_condition",
  "documents",
  "suppliers",
  "addendas",
  "user.company_detail.companyLogo",
];

const POPULATE_PARAMS = ARRAY_OF_POPULATE.map((item, indx) =>
  indx == ARRAY_OF_POPULATE.length - 1
    ? `populate[${indx}]=${item}&`
    : `populate[${indx}]=${item}`
);

export const BID_QUERY_POPULATE_PARAMS = POPULATE_PARAMS.join("&");

export let BID_QUERY_POPULATE_PARAMS_OBJECT = {};

ARRAY_OF_POPULATE?.map((item, index) => {
  BID_QUERY_POPULATE_PARAMS_OBJECT[`populate[${index}]`] = item;
});

// query for bids detail
const ARRAY_OF_POPULATE_BID_DETAIL = [
  "categories",
  "sub_categories",
  "sub_categories.category",
  "bid_type",
  "classification",
  "negotitation_type",
  "participant_condition",
  "documents",
  "suppliers",
  "addendas",
  "user.company_detail.companyLogo",
  "confirm_bids",
];

const POPULATE_PARAMS_DETAIL = ARRAY_OF_POPULATE_BID_DETAIL.map(
  (item, indx) => `populate[${indx}]=${item}`
);

export const BID_QUERY_POPULATE_PARAMS_DETAIL =
  POPULATE_PARAMS_DETAIL.join("&");

export let BID_QUERY_POPULATE_PARAMS_DETAIL_OBJECT = {};

ARRAY_OF_POPULATE_BID_DETAIL?.map((item, index) => {
  BID_QUERY_POPULATE_PARAMS_DETAIL_OBJECT[`populate[${index}]`] = item;
});
