import { Col, Flex, Row } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { EditProfileModal } from "../../../../components";
import { CommonTextField } from "../../../../components/common";
import { Images } from "../../../../theme";
import { toastAlert } from "../../../../utils";

function PersonalProfile({ data }) {
  //STATES
  const [editProfileModalPreview, setEditProfileModalPreview] = useState(false);

  //REDUX DATA
  // const { data } = useSelector((state) => state.user);

  //HANDLERS
  const editProfileModalPreviewHandler = () => {
    setEditProfileModalPreview(!editProfileModalPreview);
  };
  const handleFinish = () => {
    editProfileModalPreviewHandler();
    toastAlert("Personal profile updated successfully.");
  };
  //HOOKS

  return (
    <Row
      gutter={[10, 30]}
      style={{
        borderBottom: "1px solid #e7ecf1",
        paddingBottom: "20px",
        marginBottom: "30px",
      }}
    >
      <Col span={24}>
        <Flex justify="space-between" align="center">
          <CommonTextField
            text={"Personal Profile"}
            fontFamily={"seventyTwoBold"}
            fontSize={"24px"}
            color={"#1C2B48"}
            lineHeight={"28px"}
          />
          <Images.EditProfile
            className="c-p"
            onClick={editProfileModalPreviewHandler}
          />
        </Flex>
      </Col>
      <Col xs={24} md={12} lg={5}>
        <CommonTextField
          text={"Username"}
          fontFamily={"seventyTwoRegular"}
          color={"rgba(70, 94, 125, 0.5)"}
          fontSize={"12px"}
          lineHeight={"13px"}
          mb={"4px"}
        />
        <CommonTextField
          text={data?.userName}
          fontFamily={"seventyTwoRegular"}
          color={"#021349"}
          fontSize={"14px"}
          lineHeight={"16px"}
        />
      </Col>
      <Col xs={24} md={12} lg={4}>
        <CommonTextField
          text={"First Name"}
          fontFamily={"seventyTwoRegular"}
          color={"rgba(70, 94, 125, 0.5)"}
          fontSize={"12px"}
          lineHeight={"13px"}
          mb={"4px"}
        />
        <CommonTextField
          text={data?.firstName}
          fontFamily={"seventyTwoRegular"}
          color={"#021349"}
          fontSize={"14px"}
          lineHeight={"16px"}
        />
      </Col>
      <Col xs={24} md={12} lg={4}>
        <CommonTextField
          text={"Last Name"}
          fontFamily={"seventyTwoRegular"}
          color={"rgba(70, 94, 125, 0.5)"}
          fontSize={"12px"}
          lineHeight={"13px"}
          mb={"4px"}
        />
        <CommonTextField
          text={data?.lastName}
          fontFamily={"seventyTwoRegular"}
          color={"#021349"}
          fontSize={"14px"}
          lineHeight={"16px"}
        />
      </Col>
      <Col xs={24} md={12} lg={5}>
        <CommonTextField
          text={"Email Address"}
          fontFamily={"seventyTwoRegular"}
          color={"rgba(70, 94, 125, 0.5)"}
          fontSize={"12px"}
          lineHeight={"13px"}
          mb={"4px"}
        />
        <CommonTextField
          text={data?.email}
          fontFamily={"seventyTwoRegular"}
          color={"#021349"}
          fontSize={"14px"}
          lineHeight={"16px"}
        />
      </Col>
      <Col xs={24} md={12} lg={6}>
        <CommonTextField
          text={"Phone Number"}
          fontFamily={"seventyTwoRegular"}
          color={"rgba(70, 94, 125, 0.5)"}
          fontSize={"12px"}
          lineHeight={"13px"}
          mb={"4px"}
        />
        <CommonTextField
          text={"+" + data?.phone}
          fontFamily={"seventyTwoRegular"}
          color={"#021349"}
          fontSize={"14px"}
          lineHeight={"16px"}
        />
      </Col>
      <EditProfileModal
        preview={editProfileModalPreview}
        handleClose={editProfileModalPreviewHandler}
        handleFinish={handleFinish}
        data={data}
      />
    </Row>
  );
}

export default PersonalProfile;
