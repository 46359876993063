import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Col, Row, Checkbox, Radio, Space } from "antd";

import { CommonTable, CommonTextField } from "../common";
import moment from "moment";
import { useSelector } from "react-redux";
import { useScreenshot } from "use-react-screenshot";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  usePDF,
  pdf,
  Font,
} from "@react-pdf/renderer";
import { downloadBlob } from "../../utils";

import SRegular from "../../assets/fonts/72-Regular.ttf";
import SLight from "../../assets/fonts/72-Light.ttf";
import SSemibold from "../../assets/fonts/72-Semibold.ttf";
import SBold from "../../assets/fonts/72-Bold.ttf";
import { Table } from "../TableRow/TableRow";

Font.register({
  family: "SeventyTwo",
  fonts: [{ src: SRegular }, { src: SBold, fontWeight: "bold" }],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  heading: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 10,
  },
  text: {
    fontSize: 10,
    lineHeight: 1.5,
  },
  text12: {
    fontSize: 10,
    lineHeight: 1.5,
    fontFamily: "SeventyTwo",
    fontWeight: "bold",
  },

  section2: {
    flexDirection: "row",
    alignItems: "center",
  },
  marginBottom20: {
    marginBottom: 20,
  },
  marginR10: {
    marginRight: 10,
  },
  marginL10: {
    marginLeft: 10,
  },
  marginBottom10: {
    marginBottom: 10,
  },
  marginBottom5: {
    marginBottom: 5,
  },
  marginTop20: {
    marginTop: 20,
  },
  marginTop10: {
    marginTop: 10,
  },
  marginTop5: {
    marginTop: 5,
  },
  marginH20: {
    marginHorizontal: 20,
  },
  marginH10: {
    marginHorizontal: 10,
  },

  horizontalLine: {
    height: 1,
    backgroundColor: "#000",
    marginVertical: 10,
    marginHorizontal: 20,
  },
  horizontalLineNoHorizontalMargin: {
    height: 1,
    backgroundColor: "#000",
    marginVertical: 10,
  },
  footerView: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: 20,
  },

  radioCheckOuter: {
    width: 10,
    height: 10,
    backgroundColor: "#fff",
    borderRadius: 5,
    border: 1,
    borderColor: "#000",
  },
  radioCheckInner: {
    width: "100%",
    height: "100%",
    backgroundColor: "#fff",
    borderRadius: 5,
    border: 1,
    borderColor: "#fff",
  },
  radioBtnCheck: {
    backgroundColor: "#000",
  },
});

export const PdfComponent = ({
  references,
  bidDetail,
  userData,
  pricingInfo,
  noSubContractors,
  contractors,
  documents,
  addendaReviewAgreement,
  columns,
}) => {
  const [pricingInfoTable, setPricingInfoTable] = useState({
    column: [
      "Line Item",
      "Description",
      "Hourly Rate 2024*",
      "Hourly Rate 2025*",
      "Hourly Rate 2026*",
    ],
    data: [{}],
  });

  const [referencesTable, setReferencesTable] = useState({
    column: [
      "Line Item",
      "Client/Company",
      "Length of Time in Business & Core Competencies",
      "Contact Name*",
      "Phone Number*",
      "Email Address*",
      "Description of Services",
    ],
    data: [{}],
  });

  const [subContractorTable, setSubContractorTable] = useState({
    column: [
      "Line Item",
      "Company*",
      "Name*",
      "Phone Contact Information*",
      "Address*",
      "Previous Projects*",
      "References*",
    ],
    data: [{}],
  });

  const [fileName, setFileName] = useState({
    column: [
      "File Name",
      "I have reviewed the below addendum and attachments (if applicable)",
    ],
    data: [{}],
  });

  const ref = useRef();

  useEffect(() => {
    setPricingInfoTable((prev) => {
      return {
        ...prev,
        data: pricingInfo?.length
          ? pricingInfo.map((pricing, index) => {
              return {
                "Line Item": index + 1,
                Description: pricing?.description || "",
                "Hourly Rate 2024*": pricing?.price2024 || "",
                "Hourly Rate 2025*": pricing?.price2025 || "",
                "Hourly Rate 2026*": pricing?.price2026 || "",
              };
            })
          : [{}],
      };
    });
  }, []);

  useEffect(() => {
    setReferencesTable((prev) => {
      return {
        ...prev,
        data: references?.length
          ? references.map((reference, index) => {
              return {
                "Line Item": index + 1,
                "Client/Company": reference?.client || "",
                "Length of Time in Business & Core Competencies":
                  reference?.businessLength || "",
                "Contact Name*": reference?.contactName || "",
                "Phone Number*": reference?.phone || "",
                "Email Address*": reference?.email || "",
                "Description of Services": reference?.descriptionInfo || "",
              };
            })
          : [{}],
      };
    });
  }, []);

  useEffect(() => {
    setSubContractorTable((prev) => {
      return {
        ...prev,
        data: contractors?.length
          ? contractors.map((contractor, index) => {
              return {
                "Line Item": index + 1,
                "Company*": contractor?.company || "",
                "Name*": contractor?.name || "",
                "Phone Contact Information*": contractor?.phone || "",
                "Address*": contractor?.address || "",
                "Previous Projects*": contractor?.previousProjects || "",
                "References*": contractor?.reference || "",
              };
            })
          : [{}],
      };
    });
  }, []);

  useEffect(() => {
    setFileName((prev) => {
      return {
        ...prev,
        data: bidDetail?.addendas?.length
          ? bidDetail?.addendas?.map((adenda, index) => {
              return {
                "File Name": {
                  isElement: true,
                  element: (
                    <>
                      <Text style={[styles.text, styles.marginBottom5]}>
                        {`Addenda ${index + 1} - ${adenda?.name}`}
                      </Text>
                      <Text style={[styles.text, styles.marginBottom5]}>
                        {moment(adenda?.updatedAt).format("ddd MMM DD, YYYY")}
                      </Text>
                    </>
                  ),
                },
                "I have reviewed the below addendum and attachments (if applicable)":
                  {
                    isElement: true,
                    element: (
                      <View style={styles.radioCheckOuter}>
                        <View
                          style={[styles.radioCheckInner, styles.radioBtnCheck]}
                        ></View>
                      </View>
                    ),
                  },
              };
            })
          : [{}],
      };
    });
  }, []);

  return (
    <Document>
      <Page size={"A3"} style={{ paddingVertical: 50 }}>
        <View fixed>My Header</View>
        <View>
          <View style={{ flexDirection: "column" }}>
            <View>
              <Text
                style={[
                  styles.marginBottom20,
                  styles.marginH20,
                  styles.marginTop20,
                ]}
                render={() => (
                  <>
                    <h1>{bidDetail?.displayName || "-"}</h1>
                  </>
                )}
              />
              <View style={[styles.horizontalLine]} />
            </View>

            <View style={styles.section}>
              {/* vendor details */}
              <Text
                style={styles.marginBottom20}
                render={() => (
                  <>
                    <h1 style={styles.heading}>Vendor Details</h1>
                  </>
                )}
              />
              <View style={[styles.section2, styles.marginBottom5]}>
                <Text style={[styles.text, { width: "40%" }]}>
                  Company Name:
                </Text>
                <Text style={[styles.text, { width: "60%" }]}>
                  {bidDetail?.companyDetail?.companyName}
                </Text>
              </View>
              <View style={[styles.section2, styles.marginBottom5]}>
                <Text style={[styles.text, { width: "40%" }]}>Contact:</Text>
                <Text style={[styles.text, { width: "60%" }]}>
                  {bidDetail?.companyDetail?.userFullName}
                </Text>
              </View>
              <View style={[styles.section2, styles.marginBottom5]}>
                <Text style={[styles.text, { width: "40%" }]}>Email:</Text>
                <Text style={[styles.text, { width: "60%" }]}>
                  {bidDetail?.companyDetail?.userEmail}
                </Text>
              </View>
              <View style={[styles.section2, styles.marginBottom5]}>
                <Text style={[styles.text, { width: "40%" }]}>Phone:</Text>
                <Text style={[styles.text, { width: "60%" }]}>
                  {bidDetail?.companyDetail?.contact}
                </Text>
              </View>
              <View style={[styles.section2, styles.marginBottom5]}>
                <Text style={[styles.text, { width: "40%" }]}>Address:</Text>
                <Text style={[styles.text, { width: "60%" }]}>
                  {bidDetail?.companyDetail?.address}
                </Text>
              </View>

              {/* submission details */}

              <Text
                style={[styles.marginBottom20, styles.marginTop10]}
                render={() => (
                  <>
                    <h1 style={styles.heading}>Submission Details</h1>
                  </>
                )}
              />
              <View style={[styles.section2, styles.marginBottom5]}>
                <Text style={[styles.text, { width: "40%" }]}>Created on:</Text>
                <Text style={[styles.text, { width: "60%" }]}>
                  {moment().format("ddd MMM DD, YYYY hh:mm:ss A (Z)")}
                </Text>
              </View>
              <View style={[styles.section2, styles.marginBottom5]}>
                <Text style={[styles.text, { width: "40%" }]}>
                  Submitted by:
                </Text>
                <Text style={[styles.text, { width: "60%" }]}>
                  {userData?.fullName}
                </Text>
              </View>
              <View style={[styles.section2, styles.marginBottom5]}>
                <Text style={[styles.text, { width: "40%" }]}>Email:</Text>
                <Text style={[styles.text, { width: "60%" }]}>
                  {userData?.email}
                </Text>
              </View>

              {/* schedule of price details */}

              <Text
                style={[styles.marginBottom20, styles.marginTop10]}
                render={() => (
                  <>
                    <h1 style={styles.heading}>Schedule of Prices</h1>
                  </>
                )}
              />
              <View style={[styles.marginBottom5]}>
                <Text style={[styles.text, styles.marginBottom5]}>
                  The Bidder hereby Bids and offers to enter into the Contract
                  referred to and to supply and do all or any part of the Work
                  which is set out or called for in this Bid, at the unit
                  prices, and/or lump sums, hereinafter stated. HST is
                  additional.
                </Text>
                <Text
                  style={[styles.text, styles.marginBottom5]}
                  render={() => {
                    return (
                      <>
                        * Denotes a <b style={styles.text12}>"MANDATORY"</b>{" "}
                        field
                      </>
                    );
                  }}
                />
                <Text style={[styles.text, styles.marginBottom5]}>
                  Do not enter $0.00 dollars unless you are providing the line
                  item at zero dollars to the Owner (unless otherwise
                  specified).
                </Text>
                <Text style={[styles.text, styles.marginBottom5]}>
                  If the line item and/or table is 'NON-MANDATORY' and you are
                  not bidding on it, leave the table and/or line item blank.Do
                  not enter a $0.00 dollar value.
                </Text>
              </View>

              {/* Pricing Information - Landscape Architects */}
              <Text
                style={[styles.marginBottom20, styles.marginTop10]}
                render={() => (
                  <>
                    <h1 style={styles.heading}>
                      Pricing Information - Landscape Architects
                    </h1>
                  </>
                )}
              />
              <View style={styles.marginBottom10} wrap={false}>
                <Table data={pricingInfoTable} />
              </View>

              <View style={[styles.marginBottom5]}>
                <Text style={[styles.text, styles.marginBottom5]}>
                  All references stated shall be for the same or similar scope
                  as the one described in this Bid.
                </Text>

                <Text style={[styles.text, styles.marginBottom5]}>
                  For newly formed business entity including, corporations,
                  partnerships and sole proprietors or a Contractor teaming
                  arrangement you shall state below in the Client Column that
                  you were not the "Contractor" for the named project and should
                  state whose past experience on the named project is relevant
                  to that reference.
                </Text>
              </View>

              {/* Pricing Information - Landscape Architects */}
              <Text
                style={[styles.marginBottom20, styles.marginTop10]}
                render={() => (
                  <>
                    <h1 style={styles.heading}>References</h1>
                  </>
                )}
              />
              <View style={styles.marginBottom10} wrap={false}>
                <Table data={referencesTable} />
              </View>

              {/* sub contractors  */}

              <View style={styles.marginTop20}>
                <Text
                  style={[styles.marginBottom20, styles.marginTop10]}
                  render={() => (
                    <>
                      <h1 style={styles.heading}>Sub-Contractors</h1>
                    </>
                  )}
                />

                <Text style={[styles.text, styles.marginBottom5]}>
                  The Bidder shall state all Subcontractor(s) and type of Work
                  proposed to be used for this project. Bidders shall not
                  indicate "TBD" (To Be Determined) or "TBA" (To Be Announced)
                  or similar wording and shall not indicate multiple choices of
                  Subcontractor names for any Subcontractor category in their
                  list of Subcontractors.
                </Text>

                <Text style={[styles.text, styles.marginBottom5]}>
                  The Bidder shall state only one (1) subcontractor for each
                  type of work
                </Text>
                <Text style={[styles.text, styles.marginBottom5]}>
                  Bidder(s) shall upon request by the Owner produce a list of
                  references for all or any proposed Subcontractors within three
                  (3) business days.
                </Text>

                {/* Subcontractor - Names and References if Applicable  */}
                <Text
                  style={[styles.marginBottom20, styles.marginTop10]}
                  render={() => (
                    <>
                      <h1 style={styles.heading}>
                        Subcontractor - Names and References if Applicable
                      </h1>
                    </>
                  )}
                />
              </View>
              <View
                style={[
                  styles.marginBottom5,
                  { flexDirection: "row", alignItems: "flex-start" },
                ]}
              >
                <View style={styles.radioCheckOuter}>
                  <View
                    style={[
                      styles.radioCheckInner,
                      noSubContractors ? styles.radioBtnCheck : {},
                    ]}
                  ></View>
                </View>

                <Text
                  style={[styles.text, styles.marginBottom5, styles.marginH10]}
                >
                  The Bidder hereby Bids and offers to enter into the Contract
                  referred to and to supply and do all or any part of the Work
                  which is set out or called for in this Bid, at the unit
                  prices, and/or lump sums, hereinafter stated. HST is
                  additional.
                </Text>
              </View>
              <View style={styles.marginBottom10} wrap={false}>
                <Table data={subContractorTable} />
              </View>
              {/* documents */}
              <Text
                style={[styles.marginBottom20, styles.marginTop10]}
                render={() => (
                  <>
                    <h1 style={styles.heading}>Documents</h1>
                  </>
                )}
              />

              <View style={[styles.marginBottom5]}>
                <Text style={[styles.text, styles.marginBottom5]}>
                  {`Submittal 1 - Proposal * (mandatory) ${documents?.proposal?.name}`}
                </Text>
                <Text style={[styles.text, styles.marginBottom5]}>
                  {`Submittal 2 - Appendix C - Submission Form (completed) * (mandatory) ${documents?.appendix?.name}`}
                </Text>
                <Text style={[styles.text, styles.marginBottom5]}>
                  {`Submittal 3 - Health & Safety Questionnaire (completed) * (mandatory) ${documents?.healthSafety?.name}`}
                </Text>
                <Text style={[styles.text, styles.marginBottom5]}>
                  {`Submittal 4 - Other (additional information as required) (optional) ${documents?.other?.name}`}
                </Text>
              </View>
              {/* Addenda, Terms and Conditions */}
              <Text
                style={[styles.marginBottom20, styles.marginTop10]}
                render={() => (
                  <>
                    <h1 style={styles.heading}>
                      Addenda, Terms and Conditions
                    </h1>
                  </>
                )}
              />

              <View
                style={[
                  styles.marginBottom5,
                  { flexDirection: "row", alignItems: "flex-start" },
                ]}
              >
                <View style={styles.radioCheckOuter}>
                  <View
                    style={[styles.radioCheckInner, styles.radioBtnCheck]}
                  ></View>
                </View>

                <Text
                  style={[styles.text, styles.marginBottom5, styles.marginH10]}
                >
                  I/WE agree to be bound by the terms and conditions and have
                  authority to bind the Corporation and submit this Bid on
                  behalf of the Bidder.
                </Text>
              </View>

              <Text style={[styles.text, styles.marginBottom5]}>
                Proponents must fully disclose, in writing to the County on or
                before the Closing Date and Time ofthis Bid, the circumstances
                of any potential conflict of interest or what couldbe perceived
                as a possible conflict of interest if the Proponent were to
                becomea contracting party pursuant to this Bid. The County shall
                review anysubmissions by Proponents under this provision and may
                reject any Submissions where, in the sole opinion of County, the
                Proponent could be in a conflict ofinterest or could be
                perceived to be in a possible conflict of interestposition if
                the Proponent were to become a contracting party pursuant to
                this Bid.
              </Text>
              <View
                style={[
                  styles.marginBottom10,
                  { flexDirection: "row", alignItems: "flex-start" },
                ]}
              >
                <View style={[styles.radioCheckOuter, styles.marginR10]}>
                  <View
                    style={[
                      styles.radioCheckInner,
                      addendaReviewAgreement == 1 ? styles.radioBtnCheck : {},
                    ]}
                  ></View>
                </View>
                <Text style={[styles.text, styles.marginR10]}>Yes</Text>
                <View style={[styles.radioCheckOuter, styles.marginR10]}>
                  <View
                    style={[
                      styles.radioCheckInner,
                      addendaReviewAgreement != 1 ? styles.radioBtnCheck : {},
                    ]}
                  ></View>
                </View>
                <Text style={[styles.text]}>No</Text>
              </View>
              <Text style={[styles.text, styles.marginBottom5]}>
                The Bidder acknowledges and agrees that the addendum/addenda
                below form part of the Bid Document
              </Text>
              <Text style={[styles.text, styles.marginBottom5]}>
                Please check the box in the column "I have reviewed this
                addendum" below to acknowledge each of the addenda.
              </Text>
              <View style={styles.marginBottom10} wrap={false}>
                <Table data={fileName} />
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
