import { take, put, call, fork, takeEvery } from "redux-saga/effects";
import {
  cancelSubscriptionRequest,
  cancelSubscriptionSuccess,
  changePasswordRequest,
  confirmOtpRequest,
  createCompanyRequest,
  createCompanySuccess,
  fetchPlansRequest,
  getOtpRequest,
  getPackagesRequest,
  getPackagesSuccess,
  getProfileDataRequest,
  getProfileDataSuccess,
  getSubscribedPackageRequest,
  getSubscribedPackageSuccess,
  initializeSubscriptionRequest,
  loginRequest,
  loginSuccess,
  purchasePackagesRequest,
  purchasePackagesSuccess,
  resetPasswordRequest,
  signUpRequest,
  signUpSuccess,
  updateCardRequest,
  updateCardSuccess,
  updateCompanyDetailRequest,
  updateCompanyDetailSuccess,
  updateProfileRequest,
  updateProfileSuccess,
  uploadImageRequest,
  verifySubscriptionRequest,
} from "../slicers/user";
import { ALERT_TYPES } from "../../constants";
import {
  callRequest,
  CONFIRM_OTP,
  CREATE_COMPANY,
  GET_OTP,
  GET_PACKAGES,
  GET_PROFILE_DATA,
  PURCHASE_PACKAGE,
  RESET_PASSWORD,
  SIGN_UP,
  UPDATE_PROFILE_DATA,
  UPLOAD_IMAGE,
  USER_LOGIN,
  UPDATE_COMPANY_DATA,
  ERROR_SOMETHING_WENT_WRONG,
  CHANGE_PASSWORD,
  GET_SUBSCRIBED_PACKAGES,
  CANCEL_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  FETCH_PLANS,
  INITIALIZE_SUBSCRIPTION,
  VERIFY_SUBSCRIPTION,
} from "../../config/web-service";
import { toastAlert } from "../../utils";
import {
  manipulateCompanyData,
  manipulateCompanyDetailData,
  manipulatePackagesList,
  manipulateProfileData,
  manipulateUserData,
  manipulateUserProfileData,
} from "../../data-manipulator/user";

// USER LOGIN
function* login() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(loginRequest.type);
    try {
      const response = yield call(callRequest, {
        url: USER_LOGIN,
        payload,
      });
      responseCallback?.(response.status ? true : false, response);
      if (response.jwt) {
        yield put(loginSuccess(manipulateUserData(response)));
      } else {
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// GET OTP
function* getOtp() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(getOtpRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_OTP,
        payload,
      });
      console.log(response, "res");
      responseCallback?.(response.status ? true : false, response);
      if (response.status) {
        // yield put(loginSuccess(manipulateUserData(response)));
      } else {
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// CONFIRM OTP
function* confirmOtp() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(confirmOtpRequest.type);
    try {
      const response = yield call(callRequest, {
        url: CONFIRM_OTP,
        payload,
      });
      responseCallback?.(response.status ? true : false, response);
      if (response.status) {
        // yield put(loginSuccess(manipulateUserData(response)));
      } else {
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// SIGN UP
function* signUp() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(signUpRequest.type);
    try {
      const response = yield call(callRequest, {
        url: SIGN_UP,
        payload,
      });
      console.log(response, "res");
      responseCallback?.(response.status, response);
      if (response && response?.jwt) {
        // yield put(loginSuccess(manipulateUserData(response)));
        yield put(signUpSuccess(manipulateUserData(response)));
      } else {
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// CREATE COMPANY
function* createCompany() {
  while (true) {
    const {
      payload: { payload, access_token, responseCallback },
    } = yield take(createCompanyRequest.type);
    try {
      const response = yield call(callRequest, {
        url: CREATE_COMPANY,
        payload,
        access_token,
      });
      console.log(response, "res");
      responseCallback?.(response.data ? true : false, response);
      if (response?.data) {
        yield put(createCompanySuccess(manipulateCompanyData(response.data)));
      } else {
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// UPLOAD IMAGE
function* uploadImage({ payload: payloadData }) {
  const { payload, responseCallback } = payloadData;
  try {
    const response = yield call(callRequest, {
      url: UPLOAD_IMAGE,
      payload,
      header: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log(response, "res");
    responseCallback?.(response.message ? false : true, response);
    if (response?.data) {
      // yield put(createCompanySuccess(manipulateCompanyData(response.data)));
    } else {
    }
    response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
  } catch (err) {
    responseCallback?.(false, err);
  }
}

// GET PROFILE DATA
function* getProfile({ payload }) {
  // const {
  //   payload: { payload, responseCallback },
  // } = yield take(getProfileDataRequest.type);
  const { responseCallback } = payload;
  try {
    const response = yield call(callRequest, {
      url: GET_PROFILE_DATA,
      header: {
        "ngrok-skip-browser-warning": 1,
      },
    });
    responseCallback?.(response.status ? true : false, response);
    if (response?.status) {
      yield put(getProfileDataSuccess(manipulateProfileData(response.data)));
    } else {
      // response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

// RESET PASSWORD
function* resetPassword() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(resetPasswordRequest.type);
    try {
      const response = yield call(callRequest, {
        url: RESET_PASSWORD,
        payload,
      });
      console.log(response, "res");
      responseCallback?.(response.status ? true : false, response);
      if (response?.status) {
        response.message && toastAlert(response.message, ALERT_TYPES.SUCCESS);
      } else {
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// GET PACKAGES
function* getPackages() {
  while (true) {
    const {
      payload: { payload, queryParams, access_token, responseCallback },
    } = yield take(getPackagesRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_PACKAGES,
        queryParams,
        header: {
          "ngrok-skip-browser-warning": 1,
        },
      });
      console.log(response, "res");
      responseCallback?.(response.status ? true : false, response);
      if (response) {
        yield put(getPackagesSuccess(manipulatePackagesList(response.data)));
      } else {
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// PURCHASE PACKAGES
function* purchasePackage() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(purchasePackagesRequest.type);
    try {
      const response = yield call(callRequest, {
        url: PURCHASE_PACKAGE,
        payload,
      });
      console.log(response, "res");
      responseCallback?.(response.status ? true : false, response);
      if (response.data) {
        yield put(purchasePackagesSuccess(response.data));
      } else {
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// PURCHASE PACKAGES
function* updateProfile() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(updateProfileRequest.type);
    try {
      const _url = {
        ...UPDATE_PROFILE_DATA,
        route: UPDATE_PROFILE_DATA.route.replace(":id", pathParams.id),
      };

      const response = yield call(callRequest, {
        url: _url,
        payload,
      });

      console.log("updateProfile res ==>>>", response);

      responseCallback?.(response?.id ? true : false, response);

      if (response?.id) {
        yield put(updateProfileSuccess(manipulateUserProfileData(response)));
      } else {
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* updateCompany() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(updateCompanyDetailRequest.type);
    try {
      const _url = {
        ...UPDATE_COMPANY_DATA,
        route: UPDATE_COMPANY_DATA.route.replace(":id", pathParams.id),
      };

      const response = yield call(callRequest, {
        url: _url,
        payload,
      });

      console.log("updateCompany res ==>>>", response);

      responseCallback?.(response?.data ? true : false, response);

      if (response?.data) {
        yield put(
          updateCompanyDetailSuccess(
            manipulateCompanyDetailData(response?.data)
          )
        );
      } else {
        response.message &&
          toastAlert(
            response.message || ERROR_SOMETHING_WENT_WRONG,
            ALERT_TYPES.ERROR
          );
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* changePassword() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(changePasswordRequest.type);
    try {
      const response = yield call(callRequest, {
        url: CHANGE_PASSWORD,
        payload,
      });

      console.log("changePassword res ==>>>", response);

      responseCallback?.(response?.user ? true : false, response);

      if (response?.user) {
      } else {
        response.message &&
          toastAlert(
            response.message || ERROR_SOMETHING_WENT_WRONG,
            ALERT_TYPES.ERROR
          );
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getSubscribedPackage() {
  while (true) {
    const {
      payload: { queryParams, responseCallback },
    } = yield take(getSubscribedPackageRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_SUBSCRIBED_PACKAGES,
        queryParams,
      });

      console.log("getSubscribedPackage res ==>>>", response);

      responseCallback?.(response?.status ? true : false, response);

      if (response?.data) {
        yield put(getSubscribedPackageSuccess(response?.data));
      } else {
        response.message &&
          toastAlert(
            response.message || ERROR_SOMETHING_WENT_WRONG,
            ALERT_TYPES.ERROR
          );
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* cancelSubscription() {
  while (true) {
    const {
      payload: { responseCallback },
    } = yield take(cancelSubscriptionRequest.type);
    try {
      const response = yield call(callRequest, {
        url: CANCEL_SUBSCRIPTION,
      });

      console.log("cancelSubscription res ==>>>", response);

      responseCallback?.(response?.status ? true : false, response);

      if (response?.status) {
        yield put(cancelSubscriptionSuccess(response?.data));
        toastAlert(response?.message);
      } else {
        response.message &&
          toastAlert(
            response.message || ERROR_SOMETHING_WENT_WRONG,
            ALERT_TYPES.ERROR
          );
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* updateCard() {
  while (true) {
    const {
      payload: { responseCallback },
    } = yield take(updateCardRequest.type);
    try {
      const response = yield call(callRequest, {
        url: UPDATE_SUBSCRIPTION,
      });

      console.log("updateCard res ==>>>", response);

      responseCallback?.(response?.status ? true : false, response);

      if (response?.status) {
        yield put(updateCardSuccess(response?.data));
        toastAlert(response?.data?.message);
      } else {
        response.message &&
          toastAlert(
            response.message || ERROR_SOMETHING_WENT_WRONG,
            ALERT_TYPES.ERROR
          );
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* fetchPlans() {
  while (true) {
    const {
      payload: { responseCallback, queryParams },
    } = yield take(fetchPlansRequest.type);
    try {
      const response = yield call(callRequest, {
        url: FETCH_PLANS,
        queryParams,
      });

      console.log("fetchPlans res ==>>>", response);

      responseCallback?.(response?.status ? true : false, response?.data);

      if (response?.status) {
        // yield put(fetchPlansSuccess(response?.data));
        // toastAlert(response?.data?.message);
      } else {
        response.message &&
          toastAlert(
            response.message || ERROR_SOMETHING_WENT_WRONG,
            ALERT_TYPES.ERROR
          );
      }
    } catch (err) {
      console.error("fetchPlans error -->>>", err);
      responseCallback?.(false, err);
    }
  }
}

function* initializeSubscription() {
  while (true) {
    const {
      payload: { responseCallback, payload },
    } = yield take(initializeSubscriptionRequest.type);
    try {
      const response = yield call(callRequest, {
        url: INITIALIZE_SUBSCRIPTION,
        payload,
      });

      console.log("initializeSubscription res ==>>>", response);

      responseCallback?.(response?.status ? true : false, response?.data);

      if (response?.status) {
        // yield put(initializeSubscriptionSuccess(response?.data));
        // toastAlert(response?.data?.message);
      } else {
        response.message &&
          toastAlert(
            response.message || ERROR_SOMETHING_WENT_WRONG,
            ALERT_TYPES.ERROR
          );
      }
    } catch (err) {
      console.error("initializeSubscription error -->>>", err);
      responseCallback?.(false, err);
    }
  }
}

function* verifySubscription() {
  while (true) {
    const {
      payload: { responseCallback, payload },
    } = yield take(verifySubscriptionRequest.type);
    try {
      const response = yield call(callRequest, {
        url: VERIFY_SUBSCRIPTION,
        payload,
      });

      console.log("verifySubscription res ==>>>", response);

      responseCallback?.(response?.status ? true : false, response?.data);

      if (response?.status) {
        // yield put(verifySubscriptionSuccess(response?.data));
        // toastAlert(response?.data?.message);
      } else {
        response.message &&
          toastAlert(
            response.message || ERROR_SOMETHING_WENT_WRONG,
            ALERT_TYPES.ERROR
          );
      }
    } catch (err) {
      console.error("verifySubscription error -->>>", err);
      responseCallback?.(false, err);
    }
  }
}

export default function* root() {
  yield fork(login);
  yield fork(getOtp);
  yield fork(confirmOtp);
  yield fork(signUp);
  yield fork(createCompany);
  yield takeEvery(uploadImageRequest.type, uploadImage);
  yield takeEvery(getProfileDataRequest.type, getProfile);
  yield fork(resetPassword);
  yield fork(getPackages);
  yield fork(purchasePackage);
  yield fork(updateProfile);
  yield fork(updateCompany);
  yield fork(changePassword);
  yield fork(getSubscribedPackage);
  yield fork(cancelSubscription);
  yield fork(updateCard);
  yield fork(fetchPlans);
  yield fork(initializeSubscription);
  yield fork(verifySubscription);
}
