import qs from "qs";
import _ from "lodash";
import ApiHandler from "../services/api-handler";
import { getCurrentAccessToken } from "../utils";

export const API_TIMEOUT = 60000;
export const ABORT_REQUEST_MESSAGE = "Network failed. Aborted request.";

// LOCAL
export const BASE_URL = "https://api.centralbids.com/";

// ENV
// export const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const ERROR_SOMETHING_WENT_WRONG =
  "Something went wrong, Please try again later";
export const ERROR_API_NOT_FOUND = "Api not found, Please try again later";

export const ERROR_NETWORK_NOT_AVAILABLE =
  "Please connect to the working Internet";

export const ERROR_ACCOUNT_BLOCKED =
  "Either your account is blocked or deleted";

export const ERROR_TOKEN_EXPIRE = "Session Expired, Please login again!";

export const REQUEST_TYPE = {
  GET: "get",
  POST: "post",
  DELETE: "delete",
  PUT: "put",
};

//GENERAL
export const UPLOAD_IMAGE = {
  route: "/api/upload/",
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};

// AUTH ROUTES
export const USER_LOGIN = {
  route: "/api/auth/local",
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};
export const GET_OTP = {
  route: "/api/getOtp",
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};
export const CONFIRM_OTP = {
  route: "/api/confirm-otp",
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};
export const SIGN_UP = {
  route: "/api/auth/local/register",
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};
export const CREATE_COMPANY = {
  route: "/api/company-details",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};
export const RESET_PASSWORD = {
  route: "/api/reset-password",
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};

export const GET_PACKAGES = {
  route: "/api/subscriptions",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const PURCHASE_PACKAGE = {
  route: "/api/purchase-plan",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

//PROFILE
export const GET_PROFILE_DATA = {
  route: "/api/user-details",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const UPDATE_PROFILE_DATA = {
  route: "/api/users/:id",
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const UPDATE_COMPANY_DATA = {
  route: "/api/company-details/:id?populate=*",
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const CHANGE_PASSWORD = {
  route: "/api/auth/change-password",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

// CREATE BID ASSETS

export const GET_CLASSIFICATIONS = {
  route: "/api/classifications",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_CATEGORIES = {
  route: "/api/categories",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_BID_TYPES = {
  route: "/api/bid-types",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_NEGOTIATION_TYPES = {
  route: "/api/negotitation-types",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_PARTICIPANT_CONDITIONS = {
  route: "/api/participant-conditions",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_SUB_CATEGORIES = {
  route: "/api/get-cate",

  // route: "/api/sub-categories?pagination[page]=1&pagination[pageSize]=500000",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

// BID ENTITY

export const CREATE_BID = {
  route: "/api/bids",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_BID = {
  route: "/api/bids",
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const GET_BID = {
  route: "/api/bids",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_BID_DETAIL = {
  route: "/api/bids/:id",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_BID_SUBMISSION = {
  route: "/api/bid-submissions",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_BID_SUBMISSION = {
  route: "/api/bid-submissions",
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const GET_SUBMITTED_BIDS = {
  route: "/api/bid-submissions",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

// NOTIFICATIONS

export const ADD_DEVICE_TOKENS = {
  route: "/api/add-device-token",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const DELETE_DEVICE_TOKENS = {
  route: "/api/delete-device-token",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const GET_ALL_NOTIFICATIONS = {
  route: "/api/notifications",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const MARK_NOTIFICATION_READ = {
  route: "/api/mark-as-read",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const GET_SUBSCRIBED_PACKAGES = {
  route: "/api/user-subscriptions",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CANCEL_SUBSCRIPTION = {
  route: "/api/cancel-subscription",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_SUBSCRIPTION = {
  route: "/api/update-subscription",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const FETCH_PLANS = {
  route: "/api/fetch-plans",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const INITIALIZE_SUBSCRIPTION = {
  route: "/api/initilize-subscription",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const VERIFY_SUBSCRIPTION = {
  route: "/api/verify-subscription",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const callRequest = async ({
  url,
  payload = {},
  queryParams,
  pathParams,
  header = {},
  baseURL = BASE_URL,
  cacheBusting = false,
  access_token = null,
}) => {
  let _header = header;
  if (url.access_token_required) {
    const _access_token = access_token ? access_token : getCurrentAccessToken();
    if (_access_token) {
      _header = {
        ..._header,
        ...{
          Authorization: `Bearer ${_access_token}`,
        },
      };
    }
  }
  let _url = url.route;
  if (pathParams) {
    _url = `${url.route}/${pathParams}`;
  }

  if (queryParams && !_.isEmpty(queryParams)) {
    _url = `${_url}?${
      queryParams instanceof Object ? qs.stringify(queryParams) : queryParams
    }`;
  }

  if (cacheBusting) _url += `?t${Date.now()}`;

  let response = await ApiHandler(url.type, _url, payload, _header, baseURL);
  return response;
};
