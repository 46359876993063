import { Flex, Row } from "antd";
import React from "react";
import { useState } from "react";
import { BuySubscriptionModal, Loader } from "../../../components";
import { CommonButton, CommonTextField } from "../../../components/common";
import { Images } from "../../../theme";
import "./styles.scss";
import {
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  authenticateUser,
  fetchPlansRequest,
  getPackagesRequest,
  initializeSubscriptionRequest,
  loginSuccess,
  verifySubscriptionRequest,
} from "../../../redux/slicers/user";
import { CustomDispatch } from "../../../helpers";
import { DASHBOARD_ROUTE } from "../../../constants";
import { useSelector } from "react-redux";
import { useEffect } from "react";

function AuthSubscriptions() {
  const [authenticate] = CustomDispatch(authenticateUser);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  console.log({ searchParams });
  //STATES
  const [buyModalPreview, setBuyModalPreview] = useState(false);
  const [selected, setSelected] = useState(null);
  const [packages, setPackages] = useState([]);
  const [currentPlan, setCurrenPlan] = useState({});
  const [packagesLoader, setPackagesLoader] = useState(true);

  //REDUX DATA
  const { isSeller, data } = useSelector((state) => state.user);
  //CUSTOM DISPATCH
  const [getPackages] = CustomDispatch(getPackagesRequest);
  const [fetchPlans] = CustomDispatch(fetchPlansRequest);
  const [verifySubscription] = CustomDispatch(verifySubscriptionRequest);
  const [initializeSubscription] = CustomDispatch(
    initializeSubscriptionRequest
  );

  const reference = searchParams.get("reference");

  useEffect(() => {
    if (reference) {
      verifySubscription({
        payload: {
          reference,
          userId: data?.id,
          email: data?.email,
        },
        logic() {
          handleFinish();
        },
      });
    }
  }, [reference]);
  //HANDLERS
  const buyModalPreviewHandler = () => {
    setBuyModalPreview(!buyModalPreview);
  };

  const handleFinish = () => {
    authenticate();
    navigate(DASHBOARD_ROUTE);
  };

  //HOOKS
  useEffect(() => {
    setPackagesLoader(true);
    fetchPlans({
      queryParams: { type: data?.isBuyer ? "Buyer" : "Seller" },
      logic: (data) => {
        setPackages(data);
        setPackagesLoader(false);
      },
      error() {
        setPackagesLoader(false);
      },
    });
  }, []);

  const initialize = (plan) => {
    const payload = {
      email: data.email,
      plan: plan.plan_id,
      subscription: plan.id,
      userId: data?.id,
      fromSignup: true,
    };

    initializeSubscription({
      payload,
      logic(info) {
        window.location.replace(info?.authorization_url);
      },
    });
  };

  if (reference) {
    return <Loader />;
  }

  return (
    <div className="subscription-wrapper">
      <CommonTextField
        text={"Our Subscription"}
        color="#031946"
        fontSize={"34px"}
        fontWeight="600"
        textAlign={"center"}
        mb="15px"
      />
      <CommonTextField
        text={"Choose any subscriprion"}
        color="#465E7D"
        fontSize={"16px"}
        fontWeight="400"
        textAlign={"center"}
      />
      {packagesLoader ? (
        <Loader height="600px" size={15} />
      ) : (
        <div className="subscriptions-wrapper">
          {packages?.map((item, index) => (
            <div className="subscription-item" key={index}>
              <div className="sub-item-heading">
                <CommonTextField
                  text={item?.name}
                  color="#031946"
                  fontSize={"24px"}
                  fontWeight="600"
                  textAlign={"center"}
                  mb="10px"
                />
                <CommonTextField
                  text={`${item?.currency || "NGN"} ${item?.amount}/m`}
                  color="#031946"
                  fontSize={"20px"}
                  fontWeight="400"
                  textAlign={"center"}
                />
              </div>
              <div className="sub-description">
                {/* {item?.features?.map((feature, index) => (
                  <span key={feature?.id}>
                    <Images.CheckMark />
                    {feature?.title}
                  </span>
                ))} */}

                <Flex
                  vertical
                  align={"center"}
                  justify={"flex-start"}
                  className="package-benefits"
                >
                  <Flex
                    gap={"12px"}
                    align={"center"}
                    justify={"flex-start"}
                    style={{ marginBottom: "17px" }}
                  >
                    <Images.CheckMark />
                    <CommonTextField
                      text={`Jobs Allowed: ${item.JobsAllowed}`}
                      fontFamily={"seventyTwoRegular"}
                      fontSize={"16px"}
                      color={"#000"}
                      lineHeight={"30px"}
                    />
                  </Flex>
                  <Flex
                    gap={"12px"}
                    align={"center"}
                    justify={"flex-start"}
                    style={{ marginBottom: "17px" }}
                  >
                    <Images.CheckMark />
                    <CommonTextField
                      text={`Connects: ${item.Connects}`}
                      fontFamily={"seventyTwoRegular"}
                      fontSize={"16px"}
                      color={"#000"}
                      lineHeight={"30px"}
                    />
                  </Flex>
                </Flex>
              </div>
              <CommonButton
                text={"Buy Now"}
                loading={currentPlan?.id == item?.id}
                onClick={() => {
                  // buyModalPreviewHandler();
                  setCurrenPlan(item);
                  initialize(item);
                }}
              />
            </div>
          ))}
        </div>
      )}
      <BuySubscriptionModal
        handleFinish={handleFinish}
        preview={buyModalPreview}
        handleClose={buyModalPreviewHandler}
        selected={selected}
      />
    </div>
  );
}

export default AuthSubscriptions;
