import { take, put, call, fork, takeEvery } from "redux-saga/effects";
import { ALERT_TYPES } from "../../constants";
import { toastAlert } from "../../utils";
import {
  createBidRequest,
  createBidSubmissionRequest,
  createBidSuccess,
  getBidDetailRequest,
  getBidTypesRequest,
  getBidTypesSuccess,
  getCategoryRequest,
  getCategorySuccess,
  getClassificationsRequest,
  getClassificationsSuccess,
  getMyBidsRequest,
  getNegotitationTypeRequest,
  getNegotitationTypeSuccess,
  getOpenBidsRequest,
  getOpenBidsSuccess,
  getParticipantConditionsRequest,
  getParticipantConditionsSuccess,
  getSubCategoryRequest,
  getSubCategorySuccess,
  getSubmittedBidRequest,
  updateBidRequest,
  updateBidSubmissionRequest,
  updateBidSuccess,
} from "../slicers/bid";
import {
  CREATE_BID,
  CREATE_BID_SUBMISSION,
  GET_BID,
  GET_BID_TYPES,
  GET_CATEGORIES,
  GET_CLASSIFICATIONS,
  GET_NEGOTIATION_TYPES,
  GET_PARTICIPANT_CONDITIONS,
  GET_SUBMITTED_BIDS,
  GET_SUB_CATEGORIES,
  UPDATE_BID,
  UPDATE_BID_SUBMISSION,
  callRequest,
} from "../../config/web-service";
import {
  classificationManipulator,
  getBidsManipulator,
  singleBidManipulator,
  subCategoriesManipulator,
} from "../../data-manipulator/bid";

// USER LOGIN
function* getClassifications() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(getClassificationsRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_CLASSIFICATIONS,
        payload,
        header: {
          "ngrok-skip-browser-warning": 1,
        },
      });
      console.log("getClassifications res ==>>", response);
      responseCallback?.(response.data ? true : false, response);
      if (response?.data) {
        yield put(
          getClassificationsSuccess(classificationManipulator(response?.data)),
        );
      } else {
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getCategory() {
  while (true) {
    const {
      payload: { payload, responseCallback, queryParams },
    } = yield take(getCategoryRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_CATEGORIES,
        queryParams,
        payload,
        header: {
          "ngrok-skip-browser-warning": 1,
        },
      });
      console.log("getCategory res ==>>", response);
      responseCallback?.(response.data ? true : false, response);
      if (response?.data) {
        yield put(
          getCategorySuccess(classificationManipulator(response?.data)),
        );
      } else {
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getBidTypes() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(getBidTypesRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_BID_TYPES,
        payload,
        header: {
          "ngrok-skip-browser-warning": 1,
        },
      });
      console.log("getBidTypes res ==>>", response);
      responseCallback?.(response.data ? true : false, response);
      if (response?.data) {
        yield put(
          getBidTypesSuccess(classificationManipulator(response?.data)),
        );
      } else {
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getNegotitationType() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(getNegotitationTypeRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_NEGOTIATION_TYPES,
        payload,
        header: {
          "ngrok-skip-browser-warning": 1,
        },
      });
      console.log("getNegotitationType res ==>>", response);
      responseCallback?.(response.data ? true : false, response);
      if (response?.data) {
        yield put(
          getNegotitationTypeSuccess(classificationManipulator(response?.data)),
        );
      } else {
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getParticipantConditions() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(getParticipantConditionsRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_PARTICIPANT_CONDITIONS,
        payload,
        header: {
          "ngrok-skip-browser-warning": 1,
        },
      });
      console.log("getParticipantConditions res ==>>", response);
      responseCallback?.(response.data ? true : false, response);
      if (response?.data) {
        yield put(
          getParticipantConditionsSuccess(
            classificationManipulator(response?.data),
          ),
        );
      } else {
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getSubCategory() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(getSubCategoryRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_SUB_CATEGORIES,
        payload,
        header: {
          "ngrok-skip-browser-warning": 1,
        },
      });
      console.log("getSubCategory res ==>>", response);
      responseCallback?.(response.data ? true : false, response);
      if (response?.data) {
        yield put(
          getSubCategorySuccess(subCategoriesManipulator(response?.data)),
        );
      } else {
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* createBid() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(createBidRequest.type);
    try {
      const response = yield call(callRequest, {
        url: CREATE_BID,
        payload,
        header: {
          "ngrok-skip-browser-warning": 1,
        },
      });
      console.log("createBid res ==>>", response);
      responseCallback?.(response.data ? true : false, response);
      if (response?.data) {
        yield put(createBidSuccess(response?.data));
      } else {
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getOpenBids() {
  while (true) {
    const {
      payload: { queryParams, responseCallback },
    } = yield take(getOpenBidsRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_BID,
        queryParams,
        header: {
          "ngrok-skip-browser-warning": 1,
        },
      });
      console.log("getOpenBids res ==>>", response);
      responseCallback?.(response.data ? true : false, {
        data: getBidsManipulator(response?.data),
        ...(response?.meta || {}),
      });
      if (response?.data) {
        yield put(getOpenBidsSuccess(getBidsManipulator(response?.data)));
      } else {
        // response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getBidDetail() {
  while (true) {
    const {
      payload: { pathParams, queryParams, responseCallback },
    } = yield take(getBidDetailRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_BID,
        pathParams,
        queryParams,
        header: {
          "ngrok-skip-browser-warning": 1,
        },
      });
      console.log("getBidDetail res ==>>", response);

      if (response?.data) {
        responseCallback?.(
          response.data ? true : false,
          singleBidManipulator(response?.data),
        );
      } else {
        responseCallback(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getBids({ payload }) {
  // while (true) {
  // const {
  //   payload: { pathParams, queryParams, responseCallback },
  // } = yield take(getMyBidsRequest.type);
  const { pathParams, queryParams, responseCallback } = payload;

  try {
    const response = yield call(callRequest, {
      url: GET_BID,
      pathParams,
      queryParams,
      header: {
        "ngrok-skip-browser-warning": 1,
      },
    });
    console.log("getBidDetail res ==>>", response);

    if (response?.data) {
      responseCallback?.(response.data ? true : false, {
        data: getBidsManipulator(response?.data),
        ...(response?.meta || {}),
      });
    } else {
      responseCallback(false, response);
      // response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
  // }
}

function* createBidSubmission() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(createBidSubmissionRequest.type);
    try {
      const response = yield call(callRequest, {
        url: CREATE_BID_SUBMISSION,
        payload,
        header: {
          "ngrok-skip-browser-warning": 1,
        },
      });
      console.log("createBidSubmission res ==>>", response);

      if (response?.data) {
        responseCallback(true, response?.data);
      } else {
        responseCallback(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getSubmittedBid() {
  while (true) {
    const {
      payload: { queryParams, pathParams, responseCallback },
    } = yield take(getSubmittedBidRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_SUBMITTED_BIDS,
        queryParams: queryParams || {},
        pathParams: pathParams || "",
        header: {
          "ngrok-skip-browser-warning": 1,
        },
      });
      console.log("getSubmittedBid res ==>>", response);

      if (response?.data) {
        responseCallback(true, response?.data);
      } else {
        responseCallback(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error("getSubmittedBid err ===>>", err);
      responseCallback?.(false, err);
    }
  }
}

function* updateBidSubmission() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(updateBidSubmissionRequest.type);
    try {
      const response = yield call(callRequest, {
        url: UPDATE_BID_SUBMISSION,
        pathParams: pathParams || "",
        payload: payload,
        header: {
          "ngrok-skip-browser-warning": 1,
        },
      });
      console.log("updateBidSubmission res ==>>", response);

      if (response?.data) {
        responseCallback(true, response?.data);
      } else {
        responseCallback(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      console.error("getSubmittedBid err ===>>", err);
      responseCallback?.(false, err);
    }
  }
}

function* updateBid() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(updateBidRequest.type);
    try {
      const response = yield call(callRequest, {
        url: UPDATE_BID,
        pathParams,
        payload,
        header: {
          "ngrok-skip-browser-warning": 1,
        },
      });
      console.log("updateBid res ==>>", response);
      responseCallback?.(response.data ? true : false, response);
      if (response?.data) {
        yield put(updateBidSuccess(response?.data));
      } else {
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

export default function* root() {
  yield fork(getClassifications);
  yield fork(getCategory);
  yield fork(getBidTypes);
  yield fork(getNegotitationType);
  yield fork(getParticipantConditions);
  yield fork(getSubCategory);
  yield fork(createBid);
  yield fork(getOpenBids);
  yield fork(getBidDetail);
  yield takeEvery(getMyBidsRequest.type, getBids);
  yield fork(createBidSubmission);
  yield fork(getSubmittedBid);
  yield fork(updateBidSubmission);
  yield fork(updateBid);
}
