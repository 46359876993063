import React from "react";
import { CommonButton, CommonHeading, CommonTextField } from "../../../common";
import "./styles.scss";
import { WHY_CENTRAl_BID_CONTENT } from "../../../../constants";
import { Col, Row } from "antd";
import { Images } from "../../../../theme";

const WhyCentral = () => {
  return (
    <div className="container">
      <Row className="why-Central-parent" align={"middle"}>
        <Col md={24} lg={12} className="left-side">
          <CommonHeading
            text="Why Central Bid?"
            fontFamily={"seventyTwoBold"}
            className={"whyHead"}
            color={"#021349"}
            lineHeight="63px"
            mb={"10px"}
          />
          <CommonTextField
            fontFamily="seventyTwoSemiBold"
            fontSize={"18px"}
            lineHeight="24px"
            color={"#465E7D"}
            mb="5px"
            text="Streamlined Procurement"
          />
          <CommonTextField
            fontFamily="seventyTwoLight"
            fontSize={"16px"}
            lineHeight="24px"
            color={"#465E7D"}
            mb="20px"
            text="Central Bids simplifies and streamlines the entire procurement process."
          />
          <CommonTextField
            fontFamily="seventyTwoSemiBold"
            fontSize={"18px"}
            lineHeight="24px"
            color={"#465E7D"}
            mb="5px"
            text="Access to Top Vendors"
          />
          <CommonTextField
            fontFamily="seventyTwoLight"
            fontSize={"16px"}
            lineHeight="24px"
            color={"#465E7D"}
            mb="20px"
            text={
              "Gain access to a network of verified and reliable vendors for quality and compliance."
            }
          />
          <CommonTextField
            fontFamily="seventyTwoSemiBold"
            fontSize={"18px"}
            lineHeight="24px"
            color={"#465E7D"}
            mb="5px"
            text="Competitive Pricing"
          />
          <CommonTextField
            fontFamily="seventyTwoLight"
            fontSize={"16px"}
            lineHeight="24px"
            color={"#465E7D"}
            mb="20px"
            text={
              "Foster a competitive bidding environment to secure the best deals."
            }
          />
          <CommonTextField
            fontFamily="seventyTwoSemiBold"
            fontSize={"18px"}
            lineHeight="24px"
            color={"#465E7D"}
            mb="5px"
            text="Transparency and Accountability"
          />
          <CommonTextField
            fontFamily="seventyTwoLight"
            fontSize={"16px"}
            lineHeight="24px"
            color={"#465E7D"}
            mb="20px"
            text={
              "Ensure accountability with documented steps and clear audit trails."
            }
          />
          <CommonTextField
            fontFamily="seventyTwoSemiBold"
            fontSize={"18px"}
            lineHeight="24px"
            color={"#465E7D"}
            mb="5px"
            text="Real-Time Updates"
          />
          <CommonTextField
            fontFamily="seventyTwoLight"
            fontSize={"16px"}
            lineHeight="24px"
            color={"#465E7D"}
            mb="20px"
            text={
              "Receive instant notifications on bid submissions, status changes, and deadlines."
            }
          />
          <CommonTextField
            fontFamily="seventyTwoSemiBold"
            fontSize={"18px"}
            lineHeight="24px"
            color={"#465E7D"}
            mb="5px"
            text="Secure and Reliable"
          />
          <CommonTextField
            fontFamily="seventyTwoLight"
            fontSize={"16px"}
            lineHeight="24px"
            color={"#465E7D"}
            mb="30px"
            text={
              "Benefit from robust security measures for safe and reliable transactions."
            }
          />

          <CommonTextField
            fontFamily="seventyTwoLight"
            fontSize={"16px"}
            lineHeight="24px"
            color={"#465E7D"}
            mb="20px"
            text={
              "Choose Central Bids to revolutionize your procurement process, connect with top vendors, and achieve optimal procurement outcomes. Join us today and experience the future of bidding."
            }
          />
        </Col>

        <Col md={24} lg={12} className="right-side">
          <Row gutter={[0, 30]}>
            <Col xs={24} md={12} className="bid-noti-col">
              <div style={{ marginBottom: "30px" }}>
                <div className="icon">
                  <Images.WhyNotificationIcon />
                </div>
                <CommonTextField
                  text={"BID NOTIFICATIONS"}
                  fontFamily={"seventyTwoSemiBold"}
                  fontSize="24px"
                  color={"#031946"}
                  lineHeight="28px"
                />
                <CommonTextField
                  text={"See how your bids are fairing"}
                  fontFamily="seventyTwoLight"
                  fontSize={"14px"}
                  lineHeight="24px"
                  color={"#465E7D"}
                />
              </div>
              <div>
                <div className="icon">
                  <Images.OpportunityIcon />
                </div>
                <CommonTextField
                  text={"OPPORTUNITIES"}
                  fontFamily={"seventyTwoSemiBold"}
                  fontSize="24px"
                  color={"#031946"}
                  lineHeight="28px"
                />
                <CommonTextField
                  text={"See some profitable windows you should not miss"}
                  fontFamily="seventyTwoLight"
                  fontSize={"14px"}
                  lineHeight="24px"
                  color={"#465E7D"}
                />
              </div>
            </Col>
            <Col xs={24} md={12} className="better-bid-col">
              <div>
                <div className="icon">
                  <Images.BetterBidIcon />
                </div>
                <CommonTextField
                  text={"BETTER BIDS"}
                  fontFamily={"seventyTwoSemiBold"}
                  fontSize="24px"
                  color={"#031946"}
                  lineHeight="28px"
                />
                <CommonTextField
                  text={"Compare and contrast, pick your choice"}
                  fontFamily="seventyTwoLight"
                  fontSize={"14px"}
                  lineHeight="24px"
                  color={"#465E7D"}
                />
              </div>
            </Col>
            {/* {WHY_CENTRAl_BID_CONTENT.map((t) => (
              <div key={Math.random()}>
                <div className="icon">{t.icon}</div>
                <CommonTextField text={t.title} />
                <CommonTextField text={t.text} />
              </div>
            ))} */}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default WhyCentral;
