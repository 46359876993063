import { Carousel, Col, Row } from "antd";
import React from "react";
import Slider from "react-slick";
import { Images } from "../../../../theme";
import { CommonHeading, CommonTextField } from "../../../common";
import "./styles.scss";
// import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactOwlCarousel from "react-owl-carousel";
function IndustrySection() {
  var settings = {
    arrows: true,
    dots: false,
    // infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true
  };
  return (
    <div className="industry-section-wrapper">
      <div className="container">
        <Row>
          <Col xs={24} lg={14}>
            <CommonHeading
              text={"Finding the Right Bid Across Various Industries"}
              fontFamily={"seventyTwoBold"}
              // fontSize="55px"
              className={"rightAidHeading"}
              color={"#ffffff"}
              lineHeight="63px"
              mb={"10px"}
            />
          </Col>
          <Col
            span={24}
            id="docPg"
            style={{ position: "relative", paddingTop: "80px" }}
          >
            <ReactOwlCarousel
              className="all-transactions-bottom owl-main owl-theme"
              nav={true}
              navContainer="#docPg"
              navText={[
                `<img src=${Images.Previous} />`,
                `<img src=${Images.Next} />`
              ]}
              margin={18}
              responsive={{
                0: {
                  items: 1
                },
                525: {
                  items: 1.5
                },
                741: {
                  items: 2
                },
                1280: {
                  items: 2.5
                }
              }}
              dots={false}
              //   width={"525px"}
              loop
              autoplay
            >
              <div className="industry-card">
                <img src={Images.Industry1} />
                <div className="industry-info">
                  <CommonTextField
                    text={"Education"}
                    fontFamily={"seventyTwoSemiBold"}
                    fontSize="24px"
                    color={"#031946"}
                  />
                  <CommonTextField
                    text={"School boards, colleges and universities"}
                    fontFamily={"seventyTwoLight"}
                    fontSize="14px"
                    color={"#465E7D"}
                    textTransform="capitalize"
                  />
                </div>
              </div>
              <div className="industry-card">
                <img src={Images.Industry2} />
                <div className="industry-info">
                  <CommonTextField
                    text={"Engineering & Construction"}
                    fontFamily={"seventyTwoSemiBold"}
                    fontSize="24px"
                    color={"#031946"}
                  />
                  <CommonTextField
                    text={"Architects, engineers and general contractors"}
                    fontFamily={"seventyTwoLight"}
                    fontSize="14px"
                    color={"#465E7D"}
                    textTransform="capitalize"
                  />
                </div>
              </div>
              <div className="industry-card">
                <img src={Images.Industry3} />
                <div className="industry-info">
                  <CommonTextField
                    text={"Health Care"}
                    fontFamily={"seventyTwoSemiBold"}
                    fontSize="24px"
                    color={"#031946"}
                  />
                  <CommonTextField
                    text={"Public health, hospitals, clinics and community"}
                    fontFamily={"seventyTwoLight"}
                    fontSize="14px"
                    color={"#465E7D"}
                    textTransform="capitalize"
                  />
                </div>
              </div>
            </ReactOwlCarousel>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default IndustrySection;
