import { Table } from "antd";
import React, { useState } from "react";
import "./styles.scss";

const CommonTable = ({
  pagination = false,
  dataSource,
  columns,
  loading,
  width = 750,
  handleSetPage = () => {},
  total = null,
}) => {
  const [current, setCurrent] = useState(1);
  const onChange = (page) => {
    setCurrent(page);
    handleSetPage(page);
  };
  return (
    <Table
      pagination={
        pagination
          ? {
              pageSize: 10,
              current: current,
              defaultPageSize: 10,
              pageSizeOptions: [],
              showPrevNextJumpers: false,
              ...(total >= 0 ? { total } : {}),
              showSizeChanger: false,
              onChange: onChange,
            }
          : false
      }
      dataSource={dataSource}
      columns={columns}
      loading={loading}
      scroll={{
        x: width,
        // y: 500,
      }}
    />
  );
};

export default CommonTable;
