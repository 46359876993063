import { Col, Flex, Image, Row } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { EditCompanyModal } from "../../../../components";
import { CommonTextField } from "../../../../components/common";
import { Images } from "../../../../theme";
import { toastAlert } from "../../../../utils";

function CompanyProfile({ data }) {
  //STATES
  const [editCompanyModalPreview, setEditCompanyModalPreview] = useState(false);
  //HANDLERS
  const editCompanyModalPreviewHandler = () => {
    setEditCompanyModalPreview(!editCompanyModalPreview);
  };
  const handleFinish = () => {
    editCompanyModalPreviewHandler();
    toastAlert("Company profile updated successfully.");
  };

  //HOOKS
  return (
    <Row
      gutter={[10, 30]}
      style={{
        borderBottom: "1px solid #e7ecf1",
        paddingBottom: "20px",
        marginBottom: "30px",
      }}
      align="middle"
    >
      <Col span={24}>
        <Flex justify="space-between" align="center">
          <CommonTextField
            text={"Company Profile"}
            fontFamily={"seventyTwoBold"}
            fontSize={"24px"}
            color={"#1C2B48"}
            lineHeight={"28px"}
          />
          <Images.EditProfile
            className="c-p"
            onClick={editCompanyModalPreviewHandler}
          />
        </Flex>
      </Col>
      <Col xs={24} xl={4} lg={4}>
        <img
          src={data?.companyLogo}
          style={{
            borderRadius: "50%",
            height: "141px",
            width: "141px",
            objectFit: "cover",
            // border: "1px solid #000",
          }}
        />
      </Col>
      <Col xs={24} md={12} lg={3}>
        <CommonTextField
          text={"Organization"}
          fontFamily={"seventyTwoRegular"}
          color={"rgba(70, 94, 125, 0.5)"}
          fontSize={"12px"}
          lineHeight={"13px"}
          mb={"4px"}
        />
        <CommonTextField
          text={data?.name}
          fontFamily={"seventyTwoRegular"}
          color={"#021349"}
          fontSize={"14px"}
          lineHeight={"16px"}
        />
      </Col>
      <Col xs={24} md={12} lg={4}>
        <CommonTextField
          text={"Organization Type"}
          fontFamily={"seventyTwoRegular"}
          color={"rgba(70, 94, 125, 0.5)"}
          fontSize={"12px"}
          lineHeight={"13px"}
          mb={"4px"}
        />
        <CommonTextField
          text={data?.type}
          fontFamily={"seventyTwoRegular"}
          color={"#021349"}
          fontSize={"14px"}
          lineHeight={"16px"}
        />
      </Col>
      <Col xs={24} md={12} lg={4}>
        <CommonTextField
          text={"Industry or Sector"}
          fontFamily={"seventyTwoRegular"}
          color={"rgba(70, 94, 125, 0.5)"}
          fontSize={"12px"}
          lineHeight={"13px"}
          mb={"4px"}
        />
        <CommonTextField
          text={data?.industry}
          fontFamily={"seventyTwoRegular"}
          color={"#021349"}
          fontSize={"14px"}
          lineHeight={"16px"}
        />
      </Col>
      <Col xs={24} md={12} lg={5}>
        <CommonTextField
          text={"Location"}
          fontFamily={"seventyTwoRegular"}
          color={"rgba(70, 94, 125, 0.5)"}
          fontSize={"12px"}
          lineHeight={"13px"}
          mb={"4px"}
        />
        <CommonTextField
          text={data?.address}
          fontFamily={"seventyTwoRegular"}
          color={"#021349"}
          fontSize={"14px"}
          lineHeight={"16px"}
        />
      </Col>
      <Col xs={24} md={12} lg={4}>
        <CommonTextField
          text={"Additional Contact Information"}
          fontFamily={"seventyTwoRegular"}
          color={"rgba(70, 94, 125, 0.5)"}
          fontSize={"12px"}
          lineHeight={"13px"}
          mb={"4px"}
        />
        <CommonTextField
          text={"+" + data?.contact}
          fontFamily={"seventyTwoRegular"}
          color={"#021349"}
          fontSize={"14px"}
          lineHeight={"16px"}
        />
      </Col>
      <Col span={24}>
        <CommonTextField
          text={"Description"}
          fontFamily={"seventyTwoRegular"}
          color={"rgba(70, 94, 125, 0.5)"}
          fontSize={"12px"}
          lineHeight={"13px"}
          mb={"4px"}
        />
        <CommonTextField
          text={data?.description}
          fontFamily={"seventyTwoRegular"}
          color={"#021349"}
          fontSize={"14px"}
          lineHeight={"16px"}
          whiteSpace={"pre-wrap"}
        />
      </Col>
      <EditCompanyModal
        preview={editCompanyModalPreview}
        handleClose={editCompanyModalPreviewHandler}
        handleFinish={handleFinish}
        data={data}
      />
    </Row>
  );
}

export default CompanyProfile;
