import { Flex } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddCardModal } from "../../../components";
import {
  CommonButton,
  CommonHeading,
  CommonModal,
  CommonTextField,
} from "../../../components/common";
import CommonThreeDotMenu from "../../../components/common/CommonThreeDotMenu";
import { MANAGE_SUBSCRIPTION_ROUTE } from "../../../constants";
import { CustomDispatch } from "../../../helpers";
import {
  addCardRequest,
  cancelSubscriptionRequest,
  getProfileDataRequest,
  getSubscribedPackageRequest,
  updateCardRequest,
} from "../../../redux/slicers/user";
import { Images } from "../../../theme";
import { maskCreditCardNumber, toastAlert } from "../../../utils";
import "./styles.scss";
import moment from "moment";
const Subscription = () => {
  //STATES
  const [addCardModalPreview, setAddCardModalPreview] = useState(false);
  const [expired, setExpired] = useState(false);
  const [deleteCardModalPreview, setDeleteCardModalPreview] = useState(false);
  const [cancelSubModalPreview, setCancelSubModalPreview] = useState(false);
  const [renewSubModalPreview, setRenewSubModalPreview] = useState(false);

  //CONST VALS
  const navigate = useNavigate();

  //CUSTOM DISPATCH
  const [addCard, addCardLoader] = CustomDispatch(addCardRequest);
  const [subscribedPackageReq] = CustomDispatch(getSubscribedPackageRequest);
  const [cancelSubscription] = CustomDispatch(cancelSubscriptionRequest);
  const [updateCard] = CustomDispatch(updateCardRequest);
  const [getProfile] = CustomDispatch(getProfileDataRequest);

  //REDUX DATA
  const { card, data, subscribedPackages, profileData } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    subscribedPackageReq({
      queryParams: `filters[user][id][$eq]=${data?.id}&filters[isCancelled][$eq]=false&populate=subscription`,
    });

    getProfile();
  }, []);

  //HANDLERS
  const addCardModalPreviewHandler = () => {
    setAddCardModalPreview(!addCardModalPreview);
  };

  const handleAddCard = (vals) => {
    addCard({ payload: vals });
    addCardModalPreviewHandler();
    toastAlert("Card added successfully.");
  };

  const handleUpdateCard = () => {
    updateCard();
  };
  return (
    <div className="subscription-container">
      <Flex justify={"space-between"} gap="10px" wrap="wrap" align={"center"}>
        <CommonHeading
          text={"Subscription"}
          fontFamily={"seventyTwoBold"}
          fontSize={"34px"}
          color={"#021349"}
          lineHeight={"42px"}
        />
        <Flex gap={"10px"} wrap={"wrap"}>
          {subscribedPackages?.length > 0 && (
            <CommonButton
              text={"Cancel Subscription"}
              width={"234px"}
              height={"60px"}
              background={"transparent"}
              borderColor={"#0070F2"}
              color="#0070F2"
              onClick={() => setCancelSubModalPreview(true)}
            />
          )}
          <CommonButton
            text={"Manage Subscription"}
            width={"234px"}
            height={"60px"}
            onClick={() => navigate(MANAGE_SUBSCRIPTION_ROUTE)}
          />
        </Flex>
      </Flex>
      {subscribedPackages?.length > 0 &&
        subscribedPackages?.map((item) => (
          <div className="active-subscription">
            <CommonTextField
              text={`${item?.subscription?.name} | ${
                item?.subscription?.currency || "NGN"
              } ${Math.floor(item?.subscription?.amount)}/m`}
              fontFamily={"seventyTwoSemiBold"}
              fontSize={"24px"}
              color={!expired ? "#021349" : "rgba(2, 19, 73, 0.4)"}
              lineHeight={"28px"}
            />
            {!expired ? (
              <CommonTextField
                text={`Renew at: ${moment(item?.expiredAt)
                  // ?.utc(fa
                  .format("MMMM DD YYYY")}`}
                color="rgba(2, 19, 73, 0.5)"
                fontFamily={"seventyTwoSemiBold"}
                fontSize="16px"
              />
            ) : (
              <Flex gap={"5px"}>
                <CommonTextField
                  fontFamily={"seventyTwoSemiBold"}
                  fontSize={"16px"}
                  color={"#FF4550"}
                  text={"Subscription Expired: "}
                />
                <CommonTextField
                  fontFamily={"seventyTwoSemiBold"}
                  fontSize={"16px"}
                  color={"#021349"}
                  text={" Click to Renew"}
                  textDecoration={"underline"}
                  className="c-p"
                  onClick={() =>
                    card
                      ? setRenewSubModalPreview(true)
                      : setAddCardModalPreview(true)
                  }
                />
              </Flex>
            )}
          </div>
        ))}

      {subscribedPackages?.length == 0 && (
        <div className="active-subscription">
          <CommonTextField
            text={`No Package Subscribed.`}
            fontFamily={"seventyTwoSemiBold"}
            fontSize={"24px"}
            color={!expired ? "#021349" : "rgba(2, 19, 73, 0.4)"}
            lineHeight={"28px"}
          />
        </div>
      )}

      <div className="info-card">
        <CommonTextField
          text={`Remaining Jobs`}
          fontFamily={"seventyTwoSemiBold"}
          fontSize={"24px"}
          color={"#021349"}
          lineHeight={"28px"}
        />

        <CommonTextField
          text={profileData?.user?.allowedJobs || 0}
          fontFamily={"seventyTwoSemiBold"}
          fontSize={"24px"}
          ml={10}
          color={"#0070f2"}
          lineHeight={"28px"}
          fontWeight={600}
        />
      </div>

      {subscribedPackages?.length > 0 && (
        <>
          <CommonHeading
            text={"Payment Method"}
            fontFamily={"seventyTwoBold"}
            fontSize={"28px"}
            color={"#021349"}
            lineHeight={"42px"}
            mb="20px"
          />

          <div className="add-card-option" onClick={handleUpdateCard}>
            Update Card
          </div>
        </>
      )}
      {/* {card ? (
        <div className="card-wrapper">
          <div className="card-info">
            <img src={Images.Visa} alt="" />
            <div className="card-no">
              <CommonTextField
                text={"Visa Card"}
                fontFamily="seventyTwoSemiBold"
                fontSize={"16px"}
                lineHeight="18px"
                color={"#021349"}
                mb={"8px"}
              />
              <CommonTextField
                text={maskCreditCardNumber(card?.cardNumber)}
                fontFamily="seventyTwoSemiBold"
                fontSize={"16px"}
                lineHeight="26px"
                letterSpacing={"5px"}
                color={"#021349"}
              />
            </div>
          </div>
          <CommonThreeDotMenu
            isEdit={false}
            onDelete={() => setDeleteCardModalPreview(true)}
          />
        </div>
      ) : (
     
      )} */}
      <AddCardModal
        preview={addCardModalPreview}
        handleClose={addCardModalPreviewHandler}
        handleFinish={handleAddCard}
      />
      <CommonModal
        isModalVisible={deleteCardModalPreview}
        setIsModalVisible={setDeleteCardModalPreview}
        discription="Do you want to delete this card?"
        onConfirm={() => {
          setDeleteCardModalPreview(false);
          toastAlert("Card deleted successfully.");
          addCard({ payload: null });
        }}
      ></CommonModal>
      <CommonModal
        isModalVisible={cancelSubModalPreview}
        setIsModalVisible={setCancelSubModalPreview}
        discription="Do you want to cancel your subscription?"
        onConfirm={() => {
          cancelSubscription();
          setCancelSubModalPreview(false);
        }}
      ></CommonModal>
      <CommonModal
        isModalVisible={renewSubModalPreview}
        setIsModalVisible={setRenewSubModalPreview}
        discription="Do you want to renew your subscription?"
        onConfirm={() => {
          setRenewSubModalPreview(false);
          toastAlert("Subscription renewed successfully.");
          setExpired(false);
        }}
      ></CommonModal>
    </div>
  );
};

export default Subscription;
