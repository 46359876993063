import React, { useCallback } from "react";
import {
  CommonModal,
  CommonSearch,
  CommonTable,
  CommonTextField,
} from "../../../common";
import "./styles.scss";
import {
  BIDS_DETAIL,
  BRANCH_TABlE_DATA,
  DATE_FORMAT1,
  EDIT_BID_ROUTE,
  ONGOING_TABlE_DATA,
  OPEN_BIDS_DETAIL,
} from "../../../../constants";
import CommonThreeDotMenu from "../../../common/CommonThreeDotMenu";
import { useNavigate } from "react-router-dom";
import {
  getPaginationQuery,
  replaceValInString,
  toastAlert,
} from "../../../../utils";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Images } from "../../../../theme";
import moment from "moment";

const BidsListing = ({
  filter,
  isOpen,
  bids,
  pagination,
  handleGetMyBids,
  bidsLoader,
}) => {
  const [data, setData] = useState([]);
  const [deleteModalPreview, setDeleteModalPreview] = useState(false);

  const navigate = useNavigate();
  const { isSeller } = useSelector((state) => state?.user);

  useEffect(() => {
    setData(bids);
  }, [bids]);

  // const { bids } = useSelector((state) => state?.bid);

  // useEffect(() => {
  // setData([]);
  // const temp = ONGOING_TABlE_DATA.filter((x) => x?.category === filter);
  // setData(temp);

  // if (filter == "open") {
  //   setData(bids);
  // }
  // }, [filter, bids]);

  const renderServiceColumn = (text) => (
    <CommonTextField text={text} className={"light-text"} fontWeight={700} />
  );

  const renderOrganizationColumn = (text, _record) => (
    <CommonTextField
      text={_record?.companyDetail?.companyName}
      className={"light-text"}
      fontWeight={700}
    />
  );

  const renderActionColumn = (status) => (
    <div className="action-col-wrapper">
      <div
        className={`statusButton ${
          status.status === "Open"
            ? "open"
            : status.status === "Awarded"
            ? "complete"
            : status.status === "Closed"
            ? "cancelled"
            : "cancelled"
        }`}
      >
        <CommonTextField
          text={`${
            status.status === "Open"
              ? "Open"
              : status.status === "Awarded"
              ? "Awarded"
              : status.status === "Closed"
              ? "Closed"
              : "Terminated"
          }`}
          className={"text-11"}
          fontWeight={700}
          textTransform="capitalize"
        />
      </div>
      {/* {(isSeller ? isOpen : true) && (
        <CommonThreeDotMenu
          onEdit={() =>
            navigate(replaceValInString(EDIT_BID_ROUTE, { ":id": status.key }))
          }
          onDelete={() => setDeleteModalPreview(true)}
        />
      )} */}
    </div>
  );

  const renderSellerActionColumn = (data) => (
    <div className="action-col-wrapper">
      <div className={`view-detail`}>
        <CommonTextField
          text={"View Details"}
          fontFamily={"seventyTwoSemiBold"}
          fontSize="14px"
          color={"#0070F2"}
          onClick={() =>
            navigate(
              replaceValInString(isOpen ? OPEN_BIDS_DETAIL : BIDS_DETAIL, {
                ":id": data.key,
              }),
            )
          }
        />
      </div>
      {/* <CommonThreeDotMenu
        onEdit={() =>
          navigate(replaceValInString(EDIT_BID_ROUTE, { ":id": data.key }))
        }
        onDelete={() => setDeleteModalPreview(true)}
      /> */}
    </div>
  );

  const renderNameColumn = (text, data) => (
    <CommonTextField
      text={text}
      fontWeight={700}
      className="c-p"
      onClick={() =>
        navigate(
          replaceValInString(isOpen ? OPEN_BIDS_DETAIL : BIDS_DETAIL, {
            ":id": data.key,
          }),
        )
      }
    />
  );

  const columns = [
    {
      title: "Bid Name",
      dataIndex: "displayName",
      key: "displayName",
      render: renderNameColumn,
      sorter: {
        compare: (a, b) => a.displayName.localeCompare(b.displayName),
        multiple: 1,
      },
      sortIcon: ({ sortOrder }) =>
        sortOrder ? (
          sortOrder === "ascend" ? (
            <Images.SortDsc />
          ) : (
            <></>
          )
        ) : (
          <Images.SortAsc />
        ),
    },
    {
      title: "Opening Date",
      dataIndex: "openingDate",
      key: "openingDate",
      render: (val) => {
        return renderServiceColumn(moment(val).format(DATE_FORMAT1));
      },
      sorter: {
        compare: (a, b) => new Date(a.openingDate) - new Date(b.openingDate),
        multiple: 1,
      },
      sortIcon: ({ sortOrder }) =>
        sortOrder ? (
          sortOrder === "ascend" ? (
            <Images.SortDsc />
          ) : (
            <></>
          )
        ) : (
          <Images.SortAsc />
        ),
    },
    {
      title: "Closing Date",
      dataIndex: "closingDate",
      key: "closingDate",
      render: (val) => {
        return renderServiceColumn(moment(val).format(DATE_FORMAT1));
      },
      sorter: {
        compare: (a, b) => new Date(a.closingDate) - new Date(b.closingDate),
        multiple: 1,
      },
      sortIcon: ({ sortOrder }) =>
        sortOrder ? (
          sortOrder === "ascend" ? (
            <Images.SortDsc />
          ) : (
            <></>
          )
        ) : (
          <Images.SortAsc />
        ),
    },
    {
      title: "Organization",
      dataIndex: "organization",
      key: "organization",
      render: renderOrganizationColumn,
      sorter: {
        compare: (a, b) => a.organization.localeCompare(b.organization),
        multiple: 1,
      },
      sortIcon: ({ sortOrder }) =>
        sortOrder ? (
          sortOrder === "ascend" ? (
            <Images.SortDsc />
          ) : (
            <></>
          )
        ) : (
          <Images.SortAsc />
        ),
    },
    ...(filter === "active" && isSeller
      ? []
      : [
          {
            title: "Status",
            key: "status",
            width: 120,
            align: "center",
            render: renderActionColumn,
          },
        ]),
    // {
    //   title: "Status",
    //   key: "status",
    //   width: 120,
    //   align: "center",
    //   render: renderActionColumn,
    // },
    ...(isSeller && !isOpen
      ? [
          {
            title: "Action",
            key: "",
            width: 120,
            align: "center",
            render: renderSellerActionColumn,
          },
        ]
      : []),
  ];

  const handleSetPage = (page) => {
    handleGetMyBids(getPaginationQuery(page));
  };

  return (
    <div className="bid-listing-table">
      <CommonTable
        columns={columns}
        dataSource={bids}
        handleSetPage={handleSetPage}
        pagination
        total={pagination?.total}
        loading={bidsLoader}
      />
      <CommonModal
        isModalVisible={deleteModalPreview}
        setIsModalVisible={setDeleteModalPreview}
        discription="Do you want to delete this bid?"
        onConfirm={() => {
          setDeleteModalPreview(false);
          toastAlert("Bid deleted successfully.");
        }}
      ></CommonModal>
    </div>
  );
};

export default BidsListing;
