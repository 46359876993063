import React, { useEffect, useState } from "react";
import "./styles.scss";
import { CommonHeading, CommonTextField } from "../../common";
import { Images } from "../../../theme";
import { Avatar, Drawer, Flex, Grid, Space } from "antd";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBars } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../Sidebar";
import { DASHBOARD_ROUTE, PROFILE_ROUTE } from "../../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { CustomDispatch } from "../../../helpers";
import {
  getProfileDataRequest,
  updateProfileRequest,
} from "../../../redux/slicers/user";
import { ClipLoader } from "react-spinners";
import {
  markAllAsReadRequest,
  markNotificationReadRequest,
} from "../../../redux/slicers/notification";
import { timeSince } from "../../../utils";

const { useBreakpoint } = Grid;

const Header = ({ title }) => {
  const [notiOpen, setNotiOpen] = useState(false);
  const [open, setOpen] = useState(false);
  //CUSTOM DISPATCH
  const [getProfileData, profileLoader] = CustomDispatch(getProfileDataRequest);
  const [markNotificationRead] = CustomDispatch(markNotificationReadRequest);
  const [updateProfile] = CustomDispatch(updateProfileRequest);
  const [markAllNotRead] = CustomDispatch(markAllAsReadRequest);
  const notifications = useSelector(
    (state) => state?.notification?.notifications
  );

  //REDUX DATA
  const { profileData } = useSelector((state) => state.user);
  const navigate = useNavigate();

  //HOOKS
  useEffect(() => {
    getProfileData();
  }, []);
  const { isSeller } = useSelector((state) => state?.user);

  const showDrawer = () => {
    setOpen(true);
  };
  const location = useLocation();

  const onClose = () => {
    setOpen(false);
  };

  const screens = useBreakpoint();

  useEffect(() => {
    onClose();
  }, [location]);

  const handleNotificationClick = (item) => {
    navigate(item?.notificationUrl);
    onClose();
    if (item?.isRead !== true)
      markNotificationRead({
        pathParams: item?.id,
        payload: {
          data: {
            is_read: true,
          },
        },
      });
  };

  const markAllAsRead = () => {
    markAllNotRead();
  };

  const handleClickResetCount = () => {
    updateProfile({
      pathParams: {
        id: profileData?.user?.id,
      },
      payload: {
        notification_count: 0,
      },
      responseCallback: () => {},
    });
  };

  return (
    <header className="main-header">
      {!screens?.md && (
        <img
          className="sidebar-icon c-p"
          width={"20px"}
          height={"20px"}
          src={Images.hamburger}
          onClick={() => {
            showDrawer();
          }}
        />
      )}

      <Space size={20}>
        <button className="notification-parent" onClick={handleClickResetCount}>
          {profileData?.user?.notificationCount > 0 && (
            <div className="notification-circle"></div>
          )}
          <Images.NotificationIcon className="c-p" />
          <div className="notification-dropdown">
            <div className="notification-content">
              <Flex align={"center"} justify={"space-between"}>
                <CommonHeading
                  text={"Notifications"}
                  fontFamily={"seventyTwoBold"}
                  fontSize={"16px"}
                  color="#021349"
                  lineHeight={"24px"}
                />
                <CommonTextField
                  text={"Mark all as read"}
                  textDecoration="underline"
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"10px"}
                  color="#021349"
                  className={"c-p"}
                  onClick={markAllAsRead}
                />
              </Flex>
              <div className="linear-blur"></div>
              <div className="notification-list">
                {notifications?.length > 0 &&
                  notifications?.map((item) => (
                    <Flex
                      key={item?.id}
                      vertical
                      justify={"flex-start"}
                      align={"center"}
                      gap={"5px"}
                      style={{ marginTop: "8px" }}
                      onClick={() => handleNotificationClick(item)}
                    >
                      <Flex
                        className={`notification-item ${
                          item?.isRead !== true ? "unread" : ""
                        }`}
                        justify={"space-between"}
                        align={"flex-start"}
                      >
                        <Flex justify={"flex-start"} align={"center"} gap="6px">
                          <Images.ProfileImage height={35} width={35} />
                          <div>
                            <CommonTextField
                              text={item?.title}
                              fontFamily={"seventyTwoSemiBold"}
                              color="#021349"
                              fontSize={"12px"}
                              lineHeight={"18px"}
                              mb={"2px"}
                              textAlign={"left"}
                            />
                            <CommonTextField
                              text={item?.description}
                              fontFamily={"seventyTwoRegular"}
                              color="#021349"
                              fontSize={"10px"}
                              lineHeight={"15px"}
                              textAlign={"left"}
                            />
                          </div>
                        </Flex>
                        <CommonTextField
                          text={timeSince(item?.createdAt)}
                          fontFamily={"seventyTwoRegular"}
                          color={"#93969E"}
                          fontSize={"10px"}
                          lineHeight={"15px"}
                          className={"time"}
                        />
                      </Flex>
                    </Flex>
                  ))}
              </div>
            </div>
          </div>
        </button>
        {profileLoader ? (
          <ClipLoader size={40} color="#000" />
        ) : isSeller ? (
          <>
            <Space size={13}>
              <CommonTextField
                text={profileData?.user?.fullName}
                fontFamily={"seventyTwoBold"}
                fontSize={"12px"}
              />
              <Images.ProfileImage
                onClick={() => navigate(PROFILE_ROUTE)}
                style={{ cursor: "pointer" }}
              />
            </Space>
          </>
        ) : (
          <img
            src={profileData?.company?.companyLogo}
            height={45}
            onClick={() => navigate(PROFILE_ROUTE)}
            style={{ cursor: "pointer" }}
          />
        )}
      </Space>

      <Drawer
        title={
          <CommonTextField
            fontWeight={700}
            className={"logo theme-text"}
            text={"Central Bid"}
          />
        }
        placement="left"
        onClose={onClose}
        open={open}
        className="mobile-sidebar"
      >
        <Sidebar className="Mobile-sidebar-parent" closeDrawer={onClose} />
      </Drawer>
    </header>
  );
};

export default Header;
