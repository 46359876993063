import { Col, Row } from "antd";
import React from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  AUTH_SUBSCRIPTION_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE
} from "../../../constants";
import { Images } from "../../../theme";
import { CommonButton } from "../../common";
import "./styles.scss";
function AuthHeader() {
  const navigate = useNavigate();
  const location = useLocation();
  const [navOpen, setNavOpen] = useState(false);

  console.log({ location, HOME_ROUTE });

  const navOpenToggle = () => {
    setNavOpen(!navOpen);
  };
  return (
    <div className="auth-header">
      <Row style={{ width: "100%" }}>
        <Col span={6} xs={18} sm={18} md={6}>
          <Images.HeaderLogo
            className="c-p"
            onClick={() => navigate(HOME_ROUTE)}
          />
        </Col>
        <Col xs={6} sm={6} md={1} lg={1} className="toggle-wrapper">
          <button
            className={`togglebtn c-p ${navOpen ? "active" : ""}`}
            onClick={navOpenToggle}
          >
            <span />
            <span />
            <span />
          </button>
        </Col>
        <Col sm={24} md={17} lg={17}>
          <div className={`header-options c-p ${navOpen ? "active" : ""}`}>
            <Link
              to={HOME_ROUTE}
              onClick={navOpenToggle}
              className={`header-nav  ${
                location.pathname == HOME_ROUTE && "header-nav-active"
              }`}
            >
              Home
            </Link>
            <Link
              // to={AUTH_SUBSCRIPTION_ROUTE}
              onClick={navOpenToggle}
              className="header-nav"
            >
              About
            </Link>
            {/* <Link className="header-nav" onClick={navOpenToggle}>
              Contact Us
            </Link> */}
            <CommonButton text={"Account"} fontSize={"16px"} fontWeight="600" />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default AuthHeader;
