import { Carousel, Form } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import OTPInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { AuthCarousel, ImageUploader } from "../../components";
import {
  CommonButton,
  CommonHeading,
  CommonInputField,
  CommonPasswordInput,
  CommonTextAreaField,
  CommonTextField,
} from "../../components/common";
import {
  ACCOUNT_VERIFICATION_ROUTE,
  ALERT_TYPES,
  AUTH_SUBSCRIPTION_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  SIGN_UP_ROUTE,
} from "../../constants";
import { CustomDispatch } from "../../helpers";
import {
  confirmOtpRequest,
  createCompanyRequest,
  getOtpRequest,
  loginRequest,
  loginSuccess,
  setSginupRoleRequest,
  signUpRequest,
} from "../../redux/slicers/user";
import { Images } from "../../theme";
import {
  creditCardValidator,
  inputFieldRule,
  isPasswordValid,
  maskEmail,
  toastAlert,
} from "../../utils";
import "./auth.scss";

const Signup = () => {
  //STATES
  const [selectedOption, setSelectedOption] = useState("buyer");
  const [step, setStep] = useState(1);
  const [_otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState(60);
  const [enableResendBtn, setEnableResendBtn] = useState(false);
  const [signUpFields, setSignUpFields] = useState(null);
  const [organizationFields, setOrganizationFields] = useState(null);
  const [imageId, setImageId] = useState(null);

  //CONST VALS
  const [form] = Form.useForm();
  const [orgForm] = Form.useForm();
  const [verifyForm] = Form.useForm();
  const navigate = useNavigate();

  //CUSTOM DISPATCH
  const [setSignupRole] = CustomDispatch(setSginupRoleRequest);
  const [getOtp, getOtpLoader] = CustomDispatch(getOtpRequest);
  const [confirmOtp, confirmOtpLoader] = CustomDispatch(confirmOtpRequest);
  const [signUp, signUpLoader] = CustomDispatch(signUpRequest);
  const [login, loginLoader] = CustomDispatch(loginSuccess);
  const [createCompany, createCompanyLoader] =
    CustomDispatch(createCompanyRequest);

  //HANDLERS
  const handleSignup = (vals) => {
    const isSeller = selectedOption === "seller";
    setSignupRole({ payload: { isSeller } });
    setSignUpFields(vals);
    if (isSeller) {
      getOtp({
        payload: {
          email: vals.email,
          isSignup: true,
          userName: vals?.username,
        },
        logic(res) {
          toastAlert("Otp sent successfully.");

          setStep(3);
        },
      });
    } else {
      setStep(2);
    }
  };

  const handleOrganizationSignup = (vals) => {
    const isSeller = selectedOption === "seller";
    setOrganizationFields(vals);
    setSignupRole({ payload: { isSeller } });
    getOtp({
      payload: { email: signUpFields.email, isSignup: true },
      logic(res) {
        toastAlert("Otp sent successfully.");

        setStep(3);
      },
      error(res) {
        toastAlert(res?.message, ALERT_TYPES.ERROR);
      },
    });
  };

  const handleVerification = (vals) => {
    confirmOtp({
      payload: { email: signUpFields?.email, otp: vals.otp },
      logic(res) {
        const isSeller = selectedOption === "seller";
        signUp({
          payload: {
            email: signUpFields.email,
            username: signUpFields.username,
            password: signUpFields.password,
            phone: signUpFields.phone,
            firstName: signUpFields.firstName,
            lastName: signUpFields.lastName,
            fullName: signUpFields.firstName + " " + signUpFields.lastName,
            confrimed: true,
            isBuyer: !isSeller,
          },
          logic(res) {
            if (!isSeller) {
              createCompany({
                payload: {
                  data: {
                    companyLogo: imageId,
                    companyName: organizationFields.orgName,
                    companyType: organizationFields.orgType,
                    industry: organizationFields.industry,
                    address: organizationFields.address,
                    contact: organizationFields.addPhone,
                    description: organizationFields.description,
                    users: {
                      connect: [
                        {
                          id: res?.user?.id,
                        },
                      ],
                    },
                  },
                },
                otherkeys: { access_token: res?.jwt },
                logic(response) {
                  navigate(AUTH_SUBSCRIPTION_ROUTE);
                },
              });
            } else {
              navigate(AUTH_SUBSCRIPTION_ROUTE);
            }
          },
        });
      },
      error(res) {
        toastAlert(res.message, ALERT_TYPES.ERROR);
      },
    });
  };

  const handleResendCode = () => {
    getOtp({
      payload: { email: signUpFields.email, isSignup: true },
      logic(res) {
        setSeconds(60);
        setEnableResendBtn(false);
        toastAlert("Otp sent successfully.");
      },
      error(res) {
        toastAlert(res?.message, ALERT_TYPES.ERROR);
      },
    });
  };

  // HOOKS
  useEffect(() => {
    if (step === 3) {
      if (seconds === 0) return setEnableResendBtn(true);
      const interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setSeconds(60);
    }
  }, [seconds, step]);

  return (
    <div
      className="auth-box"
      style={step !== 3 ? { alignItems: "flex-start" } : {}}
    >
      <div className="login-container">
        <div className="login-wrapper">
          <div className="login-form-wrapper">
            <CommonHeading
              text={step === 3 ? "Authenticate Your Account" : "Signup"}
              fontSize="39px"
              fontWeight={"600"}
              color="#031946"
              mb={"12px"}
              // whiteSpace="nowrap"
            />
            <CommonTextField
              text={
                step === 3
                  ? "We have sent an OTP to your email"
                  : "We've missed you! Please Signup to catch up on what you've missed"
              }
              fontSize="14px"
              fontWeight={"400"}
              color="#465E7D"
              mb={"30px"}
            />
            {step === 3 && (
              <span className="wrong-acc-text">
                {/* jo*****@domain.com{" "} */}
                {signUpFields?.email
                  ? maskEmail(signUpFields?.email)
                  : "jo*****@domain.com"}{" "}
                <Link onClick={() => setStep(1)}>Not You?</Link>
              </span>
            )}

            <Form
              form={form}
              className={`login-form ${step !== 1 ? "hidden" : ""} `}
              onFinish={handleSignup}
            >
              <CommonInputField
                placeholder={"Enter your username"}
                prefix={<Images.UsernameIcon />}
                rules={inputFieldRule({
                  name: "Username",
                  isMax: true,
                  max: 30,
                })}
                name="username"
              />
              <CommonInputField
                placeholder={"Enter first name"}
                prefix={<Images.UsernameIcon />}
                rules={inputFieldRule({
                  name: "First name",
                  isMax: true,
                  max: 30,
                })}
                name="firstName"
              />
              <CommonInputField
                placeholder={"Enter last name"}
                prefix={<Images.UsernameIcon />}
                rules={inputFieldRule({
                  name: "Last name",
                  isMax: true,
                  max: 30,
                })}
                name="lastName"
              />
              <CommonInputField
                placeholder={"Enter your email"}
                prefix={<Images.EmailIcon />}
                rules={inputFieldRule({
                  name: "Email",
                  isEmail: true,
                  isMax: true,
                  max: 100,
                })}
                name="email"
              />
              <CommonInputField
                placeholder={"Enter phone number"}
                prefix={<Images.PhoneIcon />}
                rules={inputFieldRule({
                  name: "Phone",
                  isMax: true,
                  isMin: true,

                  min: 7,
                  max: 20,
                })}
                name="phone"
                onKeyDown={(e) => creditCardValidator(e, "card", 15)}
              />
              <CommonPasswordInput
                placeholder={"Enter your password"}
                prefix={<Images.PasswordIcon />}
                name="password"
                rules={[
                  ...inputFieldRule({
                    name: "Password",
                    isWhiteSpace: false,
                  }),
                  () => ({
                    validator(_, value) {
                      if (!value || isPasswordValid(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "Password must contain 8 characters including 1 small letter, 1 capital letter, 1 digit and 1 special character!",
                        ),
                      );
                    },
                  }),
                ]}
              />
              <CommonPasswordInput
                placeholder={"Re-enter your password"}
                prefix={<Images.PasswordIcon />}
                name="rePassword"
                rules={[
                  ...inputFieldRule({
                    name: "Confirm password",
                    isWhiteSpace: false,
                  }),
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("The password you entered do not match"),
                      );
                    },
                  }),
                ]}
              />
              <div className="signup-option-wrapper">
                <div
                  className={`signup-option ${
                    selectedOption === "buyer" ? "" : "active"
                  }`}
                  onClick={() => setSelectedOption("seller")}
                >
                  Seller
                </div>
                <div
                  className={`signup-option ${
                    selectedOption === "buyer" ? "active" : ""
                  }`}
                  onClick={() => setSelectedOption("buyer")}
                >
                  Buyer
                </div>
              </div>
              <CommonButton
                text={"Sign Up"}
                fontSize="18px"
                fontWeight={"400"}
                height="56px"
                htmlType="submit"
                loading={getOtpLoader}
              />
            </Form>
            <div className={` ${step !== 2 ? "hidden" : ""} `}>
              <ImageUploader setImageId={setImageId} />
              <Form
                form={orgForm}
                className="login-form"
                onFinish={handleOrganizationSignup}
              >
                <CommonInputField
                  placeholder={"Enter your organization name"}
                  prefix={<Images.Organization />}
                  rules={inputFieldRule({
                    name: "Organization name",
                    isMax: true,
                    max: 30,
                  })}
                  name="orgName"
                />
                <CommonInputField
                  placeholder={"Enter organization type"}
                  prefix={<Images.Organization />}
                  rules={inputFieldRule({
                    name: "Organization type",
                    isMax: true,
                    max: 30,
                  })}
                  name="orgType"
                />
                <CommonInputField
                  placeholder={"Industry or sector"}
                  prefix={<Images.Industry />}
                  rules={inputFieldRule({
                    name: "Industry/Sector",
                    isMax: true,
                    max: 30,
                  })}
                  name="industry"
                />
                <CommonInputField
                  placeholder={"Enter your address"}
                  prefix={<Images.Location />}
                  rules={inputFieldRule({
                    name: "Address",
                    isMax: true,
                    max: 30,
                  })}
                  name="address"
                />
                <CommonInputField
                  placeholder={"Additional contact information"}
                  prefix={<Images.PhoneIcon />}
                  rules={inputFieldRule({
                    name: "Additional contact info",
                    isMax: true,
                    isMin: true,

                    min: 7,
                    max: 20,
                  })}
                  name="addPhone"
                  onKeyDown={(e) => creditCardValidator(e, "card", 20)}
                />
                <CommonTextAreaField
                  placeholder={"Description"}
                  height="148px"
                  name={"description"}
                />
                <CommonButton
                  text={"Continue"}
                  fontSize="18px"
                  fontWeight={"400"}
                  height="56px"
                  htmlType="submit"
                />
              </Form>
            </div>
            <Form
              form={verifyForm}
              className={`login-form ${step !== 3 ? "hidden" : ""} `}
              onFinish={handleVerification}
            >
              <Form.Item
                rules={inputFieldRule({
                  name: "Otp",
                  isWhiteSpace: false,
                  isMin: true,
                })}
                name="otp"
              >
                <OTPInput
                  inputType="number"
                  shouldAutoFocus={true}
                  value={_otp}
                  containerStyle={`otpCodeInput custom-otp-input`}
                  onChange={(e) => setOtp(e)}
                  numInputs={6}
                  renderSeparator={<span>&emsp;</span>}
                  renderInput={(props) => <input {...props} />}
                />
              </Form.Item>
              <span className="resend-timer">
                00:{`${seconds}`.padStart(2, 0)}
              </span>

              <CommonButton
                text={"Verify"}
                fontSize="18px"
                fontWeight={"600"}
                height="56px"
                htmlType="submit"
                marginBottom={"15px"}
                marginTop={"15px"}
                loading={
                  confirmOtpLoader || signUpLoader || createCompanyLoader
                }
              />
              <CommonButton
                text={"Resend Code"}
                fontSize="18px"
                fontWeight={"600"}
                height="56px"
                background={"#031946"}
                onClick={handleResendCode}
                disabled={!enableResendBtn}
                loading={getOtpLoader}
              />
            </Form>
          </div>
          {step === 1 && (
            <span className="signup-prompt" style={{ marginTop: "70px" }}>
              Already have an account? <Link to={LOGIN_ROUTE}>Login</Link>
            </span>
          )}
        </div>
      </div>
      <div
        className="auth-image-wrapper"
        style={{
          height: "100%",
          position: "sticky",
          top: "0px",
          backgroundImage: `url(${
            step === 1
              ? Images.SignupImage1
              : step === 3
              ? Images.AccountVerification
              : Images.SignupImage2
          })`,
        }}
      >
        {/* <div
          className="bgImage"
          style={{
            minHeight: "100%",
            backgroundImage: `url(${Images.SignupImage1})`,
          }}
        ></div> */}
        {/* <img
          style={{ minHeight: "100%" }}
          src={step === 1 ? Images.SignupImage1 : Images.SignupImage2}
          alt="Login Banner"
        /> */}
        <AuthCarousel />
      </div>
    </div>
  );
};
export default Signup;
