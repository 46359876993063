import React from "react";
import { CommonButton, CommonHeading, CommonTextField } from "../../../common";
import "./styles.scss";
import { Col, Row } from "antd";
import { Images } from "../../../../theme";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const HeroSection = () => {
  var settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: false
  };

  return (
    <div className="heroSectionWrapper">
      <div className="container">
        <Row gutter={[40, 20]} className="heroSection">
          <Col lg={11} xs={24} className="heroCol1">
            <CommonHeading
              color={"#021349"}
              text="Bid your way to quality deliveries and profitable transactions."
              fontFamily={"seventyTwoBold"}
              className={"qualityHead"}
              // lineHeight={"68.99px"}
            />
            <CommonTextField
              color="#465E7D"
              text="Discover endless opportunities and engage with leading vendors on our premier bidding platform. Tailored for both private businesses and government agencies, we streamline your procurement process, secures the best deals, and guarantees transparency and efficiency in every transaction."
              mt={"8px"}
              fontFamily={"seventyTwoRegular"}
              fontSize={"16px"}
              lineHeight={"24px"}
            />

            {/* <div className="buttonWrap">
              <CommonButton
                text={"Get Started"}
                width={"186px"}
                height={"50px"}
              />
            </div> */}
          </Col>

          <Col lg={13} xs={24} className="heroCol2">
            <img src={Images.laptop1} />
          </Col>
        </Row>
        {/* <Slider {...settings}>

          <Row gutter={[40, 20]} className="heroSection">
            <Col lg={11} xs={24} className="heroCol1">
              <CommonHeading
                color={"#021349"}
                text="Getting Prepared For Bidding 2"
                fontFamily={"seventyTwoBold"}
                fontSize={"60px"}
                lineHeight={"68.99px"}
              />
              <CommonTextField
                color="#465E7D"
                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam."
                mt={"8px"}
                fontFamily={"seventyTwoRegular"}
                fontSize={"16px"}
                lineHeight={"24px"}
              />

              <div className="buttonWrap">
                <CommonButton
                  text={"Get Started"}
                  width={"186px"}
                  height={"50px"}
                />
              </div>
            </Col>

            <Col lg={13} xs={24} className="heroCol2">
              <img src={Images.laptop1} />
            </Col>
          </Row>
        </Slider> */}

        <div>
          <CommonTextField
            text={"Global Opportunity Bidding"}
            fontSize={"16px"}
            lineHeight={"18.4px"}
            fontFamily={"seventyTwoSemiBold"}
          />
          <div className="imgWrap">
            <img src={Images.image1} />
            <img src={Images.image2} />
            <img src={Images.image3} />
            <img src={Images.image4} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
