import React, { useState } from "react";
import { CommonTextField } from "../../../../components/common";
import { Flex } from "antd";
import { Images } from "../../../../theme";
import { ChangePasswordModal } from "../../../../components";
import { toastAlert } from "../../../../utils";
import moment from "moment";

function ChangePassword({ profileData = {} }) {
  //STATES
  const [changePasswordModalPreview, setChangePasswordModalPreview] =
    useState(false);
  //HANDLERS
  const changePasswordModalPreviewHandler = () => {
    setChangePasswordModalPreview(!changePasswordModalPreview);
  };
  const handleFinish = () => {
    changePasswordModalPreviewHandler();
    toastAlert("Password updated successfully.");
  };
  return (
    <>
      <CommonTextField
        text={"Change Password"}
        fontFamily={"seventyTwoBold"}
        fontSize={"24px"}
        color={"#1C2B48"}
        lineHeight={"28px"}
        mb={"30px"}
      />

      <div
        className="change-pass-option c-p"
        onClick={changePasswordModalPreviewHandler}
      >
        <Flex align="center" justify="space-between" wrap="wrap" gap={"20px"}>
          <Flex align="center" justify="flex-start" gap={"10px"}>
            <Images.Lock />
            <CommonTextField
              text={"Change Password"}
              fontFamily={"seventyTwoSemiBold"}
              fontSize={"16px"}
              color={"#0070F2"}
            />
          </Flex>
          {profileData?.user?.passwordUpdated && (
            <Flex align="center" justify="flex-end" gap={"20px"}>
              <CommonTextField
                text={`Last Change: ${moment(
                  profileData?.user?.passwordUpdated
                ).format("DD-MMM-YYYY")}`}
                fontFamily={"seventyTwoRegular"}
                fontSize={"14px"}
                color={"rgba(2, 19, 73, 0.5)"}
              />
              <Images.Forward />
            </Flex>
          )}
        </Flex>
      </div>
      <ChangePasswordModal
        profileData={profileData}
        preview={changePasswordModalPreview}
        handleClose={changePasswordModalPreviewHandler}
        handleFinish={handleFinish}
      />
    </>
  );
}

export default ChangePassword;
