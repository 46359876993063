import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "antd";
import "./styles.scss";
import { Images } from "../../../../theme";
import {
  CommonButton,
  CommonInputField,
  CommonTextAreaField,
  CommonTextField,
} from "../../../common";
import { creditCardValidator, inputFieldRule } from "../../../../utils";
import ImageUploader from "../../../common/ImageUploader";
import { CustomDispatch } from "../../../../helpers";
import { updateCompanyDetailRequest } from "../../../../redux/slicers/user";

const EditCompanyModal = ({ handleClose, handleFinish, preview, data }) => {
  //CONST VALS
  const [form] = Form.useForm();
  const [uploadImageId, setUploadImageId] = useState(null);
  const [imageUploading, setImageUploading] = useState(false);
  const [updateCompany, loading] = CustomDispatch(updateCompanyDetailRequest);
  //HOOKS
  useEffect(() => {
    form.setFieldsValue({
      organization: data?.name,
      orgType: data?.type,
      industry: data?.industry,
      location: data?.address,
      phone: data?.contact,
      description: data?.description,
    });
  }, [preview]);

  const handleSubmit = (val) => {
    if (imageUploading) return;

    const payload = {
      companyName: val.organization,
      companyType: val?.orgType,
      industry: val?.industry,
      contact: val?.phone,
      description: val?.description,
      address: val?.location,
    };

    if (uploadImageId) {
      payload["companyLogo"] = uploadImageId;
    }

    updateCompany({
      payload: {
        data: payload,
      },
      pathParams: {
        id: data?.id,
      },
      logic: () => {
        handleFinish();
      },
    });
  };

  return (
    <Modal
      destroyOnClose
      open={preview}
      centered
      footer={null}
      width={636}
      className="edit-company-modal"
      closeIcon={false}
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={handleClose}>
            <Images.CloseIcon />
          </div>
          <div className="title">
            <h3>Edit Company Profile</h3>
          </div>
          <Form form={form} onFinish={handleSubmit}>
            <Row gutter={[12, 0]}>
              <Col span={24}>
                <ImageUploader
                  isButton={true}
                  defaultImage={data?.companyLogo}
                  setImageId={setUploadImageId}
                  setLoader={setImageUploading}
                />
              </Col>
              <Col span={24}>
                <CommonInputField
                  placeholder={"Organization"}
                  rules={inputFieldRule({
                    name: "Organization name",
                    isMax: true,
                    max: 30,
                  })}
                  name="organization"
                />
              </Col>
              <Col xs={24} md={12}>
                <CommonInputField
                  placeholder={"Organization Type"}
                  rules={inputFieldRule({
                    name: "Organization type",
                    isMax: true,
                    max: 30,
                  })}
                  name="orgType"
                />
              </Col>
              <Col xs={24} md={12}>
                <CommonInputField
                  placeholder={"Industry or Sector"}
                  rules={inputFieldRule({
                    name: "Industry or Sector",
                    isMax: true,
                    max: 30,
                  })}
                  name="industry"
                />
              </Col>
              <Col span={24}>
                <CommonInputField
                  placeholder={"Location"}
                  rules={inputFieldRule({
                    name: "Location",
                    isMax: true,
                    max: 100,
                  })}
                  name="location"
                />
              </Col>
              <Col span={24}>
                <CommonInputField
                  placeholder={"Additional Contact Information"}
                  rules={inputFieldRule({
                    name: "Additional contact information",
                    isMax: true,
                    isMin: true,

                    min: 7,
                    max: 30,
                  })}
                  name="phone"
                  onKeyDown={(e) => creditCardValidator(e, "card", 20)}
                />
              </Col>
              <Col span={24}>
                <CommonTextAreaField
                  placeholder={"Description"}
                  rules={inputFieldRule({
                    name: "Description",
                  })}
                  height={"156px"}
                  name="description"
                />
              </Col>
              <Col span={24}>
                <CommonButton
                  text={"Update"}
                  width="100%"
                  borderRadius={"12px"}
                  htmlType="submit"
                  height={"56px"}
                  disabled={imageUploading}
                  loading={loading}
                />
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default EditCompanyModal;
