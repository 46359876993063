import React from "react";
import { Col, Form, Modal, Row } from "antd";
import "./styles.scss";
import { Images } from "../../../../theme";
import {
  CommonButton,
  CommonInputField,
  CommonTextField,
} from "../../../common";
import { creditCardValidator, inputFieldRule } from "../../../../utils";
import { useEffect } from "react";
import { CustomDispatch } from "../../../../helpers";
import { updateProfileRequest } from "../../../../redux/slicers/user";

const EditProfileModal = ({ handleClose, handleFinish, preview, data }) => {
  //CONST VALS
  const [form] = Form.useForm();
  const [updateProfile, loading] = CustomDispatch(updateProfileRequest);
  //HOOKS
  useEffect(() => {
    form.setFieldsValue({
      username: data?.userName,
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      phone: data?.phone,
    });
  }, [preview]);

  const handleSubmit = (val) => {
    const payload = {
      firstName: val.firstName,
      lastName: val?.lastName,
      phone: val?.phone,
      fullName: val?.firstName + " " + val?.lastName,
    };

    updateProfile({
      pathParams: {
        id: data?.id,
      },
      payload,
      logic: () => {
        handleFinish();
      },
    });
  };

  return (
    <Modal
      destroyOnClose
      open={preview}
      centered
      footer={null}
      width={636}
      className="edit-profile-modal"
      closeIcon={false}
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={handleClose}>
            <Images.CloseIcon />
          </div>
          <div className="title">
            <h3>Edit Personal Profile</h3>
          </div>
          <Form form={form} onFinish={handleSubmit}>
            <Row gutter={[12, 0]}>
              <Col span={24}>
                <CommonInputField
                  placeholder={"Username"}
                  disabled={true}
                  rules={inputFieldRule({
                    name: "Username",
                    isMax: true,
                    max: 30,
                  })}
                  name="username"
                />
              </Col>
              <Col xs={24} md={12}>
                <CommonInputField
                  placeholder={"First Name"}
                  rules={inputFieldRule({
                    name: "First name",
                    isMax: true,
                    max: 30,
                  })}
                  name="firstName"
                />
              </Col>
              <Col xs={24} md={12}>
                <CommonInputField
                  placeholder={"Last Name"}
                  rules={inputFieldRule({
                    name: "Last name",
                    isMax: true,
                    max: 30,
                  })}
                  name="lastName"
                />
              </Col>
              <Col span={24}>
                <CommonInputField
                  placeholder={"Email Address"}
                  disabled
                  rules={inputFieldRule({
                    name: "Email",
                    isEmail: true,
                    isMax: true,
                    max: 30,
                  })}
                  name="email"
                />
              </Col>
              <Col span={24}>
                <CommonInputField
                  placeholder={"Phone Number"}
                  rules={inputFieldRule({
                    name: "Phone number",
                    isMax: true,
                    isMin: true,

                    min: 7,
                    max: 30,
                  })}
                  name="phone"
                  onKeyDown={(e) => creditCardValidator(e, "card", 20)}
                />
              </Col>
              <Col span={24}>
                <CommonButton
                  text={"Update"}
                  width="100%"
                  borderRadius={"12px"}
                  htmlType="submit"
                  height={"56px"}
                  loading={loading}
                />
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default EditProfileModal;
