import React, { useEffect } from "react";

import { Form, Modal, Space } from "antd";
import "./styles.scss";
import CommonTextField from "../TextField";
import CommonButton from "../CommonButton";
import CommonHeading from "../CommonHeading";
import CommonTextAreaField from "../CommonTextArea";
import { inputFieldRule } from "../../../utils";

const CommonModal = ({
  setIsModalVisible,
  isModalVisible,
  children,
  width,
  title,
  discription,
  onConfirm,
  loading,
  className = "",
  destroyOnClose,
  confirmText = "Confirm",
  descriptionTitle = "Are you sure?",
  showDeleteForm = false,
}) => {
  const [form] = Form.useForm();
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const renderCancelForm = () => {
    return (
      <Form form={form} onFinish={onConfirm}>
        <Form.Item>
          <CommonTextAreaField
            placeholder={"Cancellation Reason"}
            rules={inputFieldRule({
              name: "Reason",
              isRequired: true,
            })}
            name="reason"
            height={"149px"}
          />
        </Form.Item>

        {renderButtons()}
      </Form>
    );
  };

  const renderButtons = () => {
    const submitButtonProps = showDeleteForm
      ? {
          htmlType: "submit",
        }
      : {
          onClick: onConfirm,
        };
    return (
      <div className="button-section">
        <CommonButton
          text={confirmText}
          loading={loading}
          {...submitButtonProps}
        />
        <CommonButton onClick={handleOk} text={"Not Now"} />
      </div>
    );
  };

  return (
    <div>
      <Modal
        destroyOnClose={destroyOnClose}
        className={`common-modal ${
          discription && "confirmation-modal"
        } ${className}`}
        footer={null}
        width={width}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        title={title}
        centered
      >
        {discription ? (
          <>
            <CommonHeading text={descriptionTitle} />
            <Space size={20} direction="vertical" className="confirm-content">
              <CommonTextField text={discription} />

              {showDeleteForm && renderCancelForm()}

              {!showDeleteForm && renderButtons()}
            </Space>
          </>
        ) : (
          children
        )}
      </Modal>
    </div>
  );
};

export default CommonModal;
