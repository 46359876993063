import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../../CheckoutForm";

const stripePromise = loadStripe(
  "pk_test_51Ot6hfP9PcIPENlDRdSAvSAEgsNPgKfN3hwOdd6NY0I5Bvnm28ScI7Dm0HSrcB3BxQorinTkYXqKr53kzzEuK1JN00HsQQLuuw"
);

const BuySubscriptionModal = ({
  handleClose,
  handleFinish,
  preview,
  subscriptionPackage = "",
  selected,
}) => {
  if (!preview) return <></>;

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        subscription={subscriptionPackage}
        handleClose={handleClose}
        handleFinish={handleFinish}
        preview={preview}
        selected={selected}
      />
    </Elements>
  );
};

export default BuySubscriptionModal;
