import { call, fork, put, take, takeEvery } from "redux-saga/effects";
import {
  ADD_DEVICE_TOKENS,
  DELETE_DEVICE_TOKENS,
  ERROR_SOMETHING_WENT_WRONG,
  GET_ALL_NOTIFICATIONS,
  MARK_NOTIFICATION_READ,
  callRequest,
} from "../../config/web-service";
import {
  addDeviceTokenRequest,
  deleteDeviceTokenRequest,
  getAllNotificationsRequest,
  getAllNotificationsSuccess,
  markAllAsReadRequest,
  markNotificationReadRequest,
} from "../slicers/notification";
import { toastAlert } from "../../utils";
import { ALERT_TYPES } from "../../constants";
import { notificationListManipulator } from "../../data-manipulator/user";

function* addDeviceToken({ payload }) {
  const { payload: payloadData, responseCallback } = payload;
  try {
    const response = yield call(callRequest, {
      url: ADD_DEVICE_TOKENS,
      payload: payloadData,
    });

    console.log("addDeviceToken res ==>>>", response);

    responseCallback?.(response?.status ? true : false, response);

    if (response?.status) {
    } else {
      response.message &&
        toastAlert(
          response.message || ERROR_SOMETHING_WENT_WRONG,
          ALERT_TYPES.ERROR
        );
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

function* deleteDeviceToken() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(deleteDeviceTokenRequest.type);
    try {
      const response = yield call(callRequest, {
        url: DELETE_DEVICE_TOKENS,
        payload,
      });

      console.log("deleteDeviceToken res ==>>>", response);

      responseCallback?.(response?.status ? true : false, response);

      if (response?.status) {
      } else {
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getAllNotifications({ payload }) {
  const { queryParams, responseCallback } = payload;
  try {
    const response = yield call(callRequest, {
      url: GET_ALL_NOTIFICATIONS,
      queryParams,
    });

    console.log("getAllNotifications res ==>>>", response);

    responseCallback?.(response?.data ? true : false, response);

    if (response?.data) {
      yield put(
        getAllNotificationsSuccess(notificationListManipulator(response?.data))
      );
    } else {
      response.message &&
        toastAlert(
          response.message || ERROR_SOMETHING_WENT_WRONG,
          ALERT_TYPES.ERROR
        );
    }
  } catch (err) {
    console.error("getAllNotifications error ==>>", err);
    responseCallback?.(false, err);
  }
}

function* markNotificationRead() {
  while (true) {
    const {
      payload: { pathParams, payload, responseCallback },
    } = yield take(markNotificationReadRequest.type);
    try {
      const response = yield call(callRequest, {
        url: { ...GET_ALL_NOTIFICATIONS, type: "PUT" },
        pathParams,
        payload,
      });

      console.log("markNotificationRead res ==>>>", response);

      responseCallback?.(response?.data ? true : false, response);

      if (response?.data) {
      } else {
        response.message &&
          toastAlert(
            response.message || ERROR_SOMETHING_WENT_WRONG,
            ALERT_TYPES.ERROR
          );
      }
    } catch (err) {
      console.error("markNotificationRead error ==>>", err);
      responseCallback?.(false, err);
    }
  }
}

function* markAllNotificationRead() {
  while (true) {
    const {
      payload: { responseCallback },
    } = yield take(markAllAsReadRequest.type);
    try {
      const response = yield call(callRequest, {
        url: MARK_NOTIFICATION_READ,
      });

      console.log("markAllNotificationRead res ==>>>", response);

      responseCallback?.(response?.status ? true : false, response);

      if (response?.status) {
      } else {
        response.message &&
          toastAlert(
            response.message || ERROR_SOMETHING_WENT_WRONG,
            ALERT_TYPES.ERROR
          );
      }
    } catch (err) {
      console.error("markNotificationRead error ==>>", err);
      responseCallback?.(false, err);
    }
  }
}

export default function* root() {
  yield takeEvery(addDeviceTokenRequest.type, addDeviceToken);
  yield fork(deleteDeviceToken);
  yield takeEvery(getAllNotificationsRequest.type, getAllNotifications);
  yield fork(markNotificationRead);
  yield fork(markAllNotificationRead);
}
