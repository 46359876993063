import { combineReducers } from "@reduxjs/toolkit";

import general from "./general";
import user from "./user";
import bid from "./bid";
import notification from "./notification";

export default combineReducers({
  general,
  user,
  bid,
  notification,
});
