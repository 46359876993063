import { Col, Row } from "antd";
import React from "react";
import {
  CommonButton,
  CommonTextField,
} from "../../../../../components/common";
import { useParams } from "react-router-dom";
import { Images } from "../../../../../theme";
import moment from "moment";
import { downloadFileFromUrl } from "../../../../../utils";

function CommonBidData({ id, data, isSeller }) {
  const breakCategory = (parentId) => {
    const subCategory = data?.subCategory?.filter(
      (item) => item?.parentId == parentId
    );
    const onlyNamesOfSubCategories = subCategory?.map((item) => item?.title);
    const text = onlyNamesOfSubCategories?.join(", ");
    return text;
  };
  return (
    <div>
      <Row gutter={[24, 20]} style={{ marginTop: isSeller ? 30 : 0 }}>
        <Col lg={22} xs={24}>
          <CommonTextField
            text={"Bid Name"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={data?.displayName}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        <Col lg={3} xs={24}>
          <CommonTextField
            text={"Bid Classification"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={data?.classification?.title}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        <Col lg={6} xs={24}>
          <CommonTextField
            text={"Bid Type"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={data?.bidType?.title}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        <Col lg={7} xs={24}>
          <CommonTextField
            text={"Bid Number"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={data?.bidNumber}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        {/* <Col lg={8} xs={24}>
          <CommonTextField
            text={"Bid Name"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={"Regina WWTP Expansion - Conceptual Design"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col> */}

        <Col lg={3} xs={24}>
          <CommonTextField
            text={"Bid Status"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={data?.status}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        <Col lg={6} xs={24}>
          <CommonTextField
            text={"Bid Opening Date"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={moment(data?.openingDate).format(
              "ddd MMM DD, YYYY hh:mm:ss A (Z)"
            )}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        <Col lg={7} xs={24}>
          <CommonTextField
            text={"Bid Closing Date"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={moment(data?.closingDate).format(
              "ddd MMM DD, YYYY hh:mm:ss A (Z)"
            )}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        {/* <Col lg={7} xs={24}>
          <CommonTextField
            text={"Question Deadline"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={"Fri Feb 9, 2024 2:00:00 PM (MST)"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col> */}
        <Col lg={8} xs={24}>
          <CommonTextField
            text={"Duration in months"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={data?.expectedDuration}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Negotiation Type"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={data?.negotiationType?.title}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>

        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Condition for Participation"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={data?.participantCondition?.title}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>

        <Col lg={12} xs={24}>
          <CommonTextField
            text={"Electronic Auctions"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={data?.electronicAuction}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Submission Type"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={data?.submissionType}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>

        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Submission Address"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={data?.submissionAddress}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>

        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Submission Status"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={data?.submissionStatus}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>

        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Public Opening"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={data?.publicOpening}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        <Col lg={22} xs={24}>
          <CommonTextField
            text={"Description"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={data?.description}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>

        {/* <Col lg={22} xs={24}>
          <CommonTextField
            text={"Bid Document Access"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={
              "Bid Opportunity notices and awards and a free preview of the bid documents is available on this site free of charge without registration. Please note, some documents may be secured and you will be required to register for the bid to download and view the documents. There is no cost to obtain an unsecured version of the document and /or to participate in this solicitation."
            }
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col> */}

        <Col xs={24}>
          <CommonTextField
            text={"Categories & Sub Categories"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
        </Col>

        {data?.category?.map((t) => {
          return (
            <>
              <Col xs={24}>
                <CommonTextField
                  text={t.title}
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"14px"}
                  lineHeight={"16.1px"}
                  color={"rgba(2, 19, 73, 1)"}
                  mb={"10px"}
                />

                <CommonTextField
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"12px"}
                  lineHeight={"13.8px"}
                  color={"rgba(70, 94, 125, 0.5)"}
                  mb={"3px"}
                >
                  {breakCategory(t.id)}
                </CommonTextField>
              </Col>
            </>
          );
        })}

        {/* <Col lg={5} xs={24}>
          <CommonTextField
            text={"Category"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={data?.category?.title}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>

        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Sub Category"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={data?.subCategory?.title}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col> */}

        <Col span={24}>
          <div className="separator"></div>
        </Col>

        <Col lg={22} xs={24}>
          <CommonTextField
            text={"Contact Person"}
            fontFamily={"seventyTwoSemiBold"}
            fontSize={"24px"}
            lineHeight={"27.6px"}
            color={"rgba(2, 19, 73, 1)"}
          />
          <CommonTextField
            text={
              "The following are the details of the contact person for the bid:"
            }
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
        </Col>
        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Name"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={data?.cpName}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Title"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={data?.cpTitle}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Email"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={data?.cpEmail}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>

        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Phone Number"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={data?.cpPhoneNumber}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>

        <Col span={24}>
          <div className="separator"></div>
        </Col>

        <Col lg={22} xs={24}>
          <CommonTextField
            text={"Purchasing Contact"}
            fontFamily={"seventyTwoSemiBold"}
            fontSize={"24px"}
            lineHeight={"27.6px"}
            color={"rgba(2, 19, 73, 1)"}
          />
          <CommonTextField
            text={
              "The following are the details of the contact person for the bid:"
            }
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
        </Col>
        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Employee"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={data?.purchaseEmployee}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Name"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={data?.purchaseName}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Email"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={data?.purchaseEmail}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>

        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Phone Number"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={data?.purchasePhone}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>

        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Purchase Role"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={data?.purchaseRole}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>

        {/* <Col span={24}>
          <CommonTextField
            text={"Meeting Documents"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
            mt={"20px"}
          />
        </Col>
        <Col lg={12} xs={24}>
          <div className="downloadFileWrapper">
            <div className="downloadFileWrap">
              <Images.DocumentIcon />
              <CommonTextField
                text={"Thursday January 11, 2024 01:00 PM (MST)"}
                fontFamily={"seventyTwoRegular"}
                fontSize={"14px"}
                lineHeight={"16.1px"}
                color={"rgba(0, 112, 242, 1)"}
              />
            </div>
            <Images.DownloadIcon />
          </div>
        </Col>

        <Col lg={12} xs={24}>
          <div className="downloadFileWrapper">
            <div className="downloadFileWrap">
              <Images.DocumentIcon />
              <CommonTextField
                text={"Thursday January 11, 2024 01:00 PM (MST)"}
                fontFamily={"seventyTwoRegular"}
                fontSize={"14px"}
                lineHeight={"16.1px"}
                color={"rgba(0, 112, 242, 1)"}
              />
            </div>
            <Images.DownloadIcon />
          </div>
        </Col>

        <Col lg={12} xs={24}>
          <div className="downloadFileWrapper">
            <div className="downloadFileWrap">
              <Images.DocumentIcon />
              <CommonTextField
                text={"Thursday January 11, 2024 01:00 PM (MST)"}
                fontFamily={"seventyTwoRegular"}
                fontSize={"14px"}
                lineHeight={"16.1px"}
                color={"rgba(0, 112, 242, 1)"}
              />
            </div>
            <Images.DownloadIcon />
          </div>
        </Col>

        <Col lg={12} xs={24}>
          <div className="downloadFileWrapper">
            <div className="downloadFileWrap">
              <Images.DocumentIcon />
              <CommonTextField
                text={"Thursday January 11, 2024 01:00 PM (MST)"}
                fontFamily={"seventyTwoRegular"}
                fontSize={"14px"}
                lineHeight={"16.1px"}
                color={"rgba(0, 112, 242, 1)"}
              />
            </div>
            <Images.DownloadIcon />
          </div>
        </Col> */}
        <Col span={24}>
          <div className="separator"></div>
        </Col>
        <Col span={24}>
          <div className="documentMainWrapper">
            <CommonTextField
              text={"Documents"}
              fontFamily={"seventyTwoSemiBold"}
              fontSize={"24px"}
              lineHeight={"36px"}
              color={"rgba(2, 19, 73, 1)"}
            />
            {data?.documents?.map((t) => (
              <div key={Math.random()} className="documentWrapper">
                <div className="documentItemWrapper documentItemWrapper1">
                  <CommonTextField
                    text={t.name}
                    fontFamily={"seventyTwoRegular"}
                    fontSize={"12px"}
                    lineHeight={"13.8px"}
                    color={"rgba(70, 94, 125, 0.5)"}
                  />
                  <CommonTextField
                    text={moment(t.createdAt).format(
                      "dddd MMMM DD, YYYY hh:mm A"
                    )}
                    fontFamily={"seventyTwoRegular"}
                    fontSize={"14px"}
                    lineHeight={"16.1px"}
                    color={"rgba(2, 19, 73, 1)"}
                    mt={"4px"}
                  />
                </div>
                <div className="documentItemWrapper documentItemWrapper2">
                  {(isSeller ? data?.status == "Open" : true) && (
                    <div>
                      <CommonButton
                        text={"Download"}
                        background={"rgba(3, 25, 70, 1)"}
                        fontFamily={"seventyTwoBold"}
                        fontSize={"18px"}
                        lineHeight={"20.7px"}
                        onClick={() => downloadFileFromUrl(t)}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </Col>
        <Col span={24}>
          <div className="separator"></div>
        </Col>
        <Col span={24}>
          <div className="documentMainWrapper">
            <CommonTextField
              text={"Addenda"}
              fontFamily={"seventyTwoSemiBold"}
              fontSize={"24px"}
              lineHeight={"36px"}
              color={"rgba(2, 19, 73, 1)"}
            />
            {data?.addendas?.map((t) => (
              <div key={Math.random()} className="documentWrapper">
                <div className="documentItemWrapper documentItemWrapper1">
                  <CommonTextField
                    text={t.name}
                    fontFamily={"seventyTwoRegular"}
                    fontSize={"12px"}
                    lineHeight={"13.8px"}
                    color={"rgba(70, 94, 125, 0.5)"}
                  />
                  <CommonTextField
                    text={moment(t.createdAt).format(
                      "dddd MMMM DD, YYYY hh:mm A"
                    )}
                    fontFamily={"seventyTwoRegular"}
                    fontSize={"14px"}
                    lineHeight={"16.1px"}
                    color={"rgba(2, 19, 73, 1)"}
                    mt={"4px"}
                  />
                </div>
                <div className="documentItemWrapper documentItemWrapper2">
                  {(isSeller ? data?.status == "Open" : true) && (
                    <div>
                      <CommonButton
                        text={"Download"}
                        background={"rgba(3, 25, 70, 1)"}
                        fontFamily={"seventyTwoBold"}
                        fontSize={"18px"}
                        lineHeight={"20.7px"}
                        onClick={() => downloadFileFromUrl(t)}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </Col>
        <Col span={24}>
          <div className="separator"></div>
        </Col>
        {/* <Col span={24}>
          <div className="planMainWrapper">
            <CommonTextField
              text={"Plan Takers"}
              fontFamily={"seventyTwoSemiBold"}
              fontSize={"24px"}
              lineHeight={"36px"}
              color={"rgba(2, 19, 73, 1)"}
            />
            <CommonTextField
              text={"The following are the plan takers for the bid:"}
              fontFamily={"seventyTwoRegular"}
              fontSize={"12px"}
              lineHeight={"13.8px"}
              color={"rgba(70, 94, 125, 0.5)"}
              className={"planDesc"}
            />

            <div className="planWrapper">
              <div className="planItemWrapper planItemWrapper1">
                <CommonTextField
                  text={"Company"}
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"12px"}
                  lineHeight={"13.8px"}
                  color={"rgba(70, 94, 125, 0.5)"}
                />
                <CommonTextField
                  text={"AECOM Canada Ltd."}
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"14px"}
                  lineHeight={"16.1px"}
                  color={"rgba(2, 19, 73, 1)"}
                  mt={"4px"}
                />
              </div>

              <div className="planItemWrapper planItemWrapper2">
                <CommonTextField
                  text={"Contact"}
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"12px"}
                  lineHeight={"13.8px"}
                  color={"rgba(70, 94, 125, 0.5)"}
                  textAlign={"right"}
                />
                <CommonTextField
                  text={
                    "Vanthuyne, Jolene 200 - 2100 8th Street East, Saskatoon Saskatchewan, Canada S7H 0V1"
                  }
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"14px"}
                  lineHeight={"16.1px"}
                  color={"rgba(2, 19, 73, 1)"}
                  mt={"4px"}
                  textAlign={"right"}
                />
              </div>
            </div>

            <div className="separator"></div>

            <div className="planWrapper">
              <div className="planItemWrapper planItemWrapper1">
                <CommonTextField
                  text={"Company"}
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"12px"}
                  lineHeight={"13.8px"}
                  color={"rgba(70, 94, 125, 0.5)"}
                />
                <CommonTextField
                  text={"AECOM Canada Ltd."}
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"14px"}
                  lineHeight={"16.1px"}
                  color={"rgba(2, 19, 73, 1)"}
                  mt={"4px"}
                />
              </div>

              <div className="planItemWrapper planItemWrapper2">
                <CommonTextField
                  text={"Contact"}
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"12px"}
                  lineHeight={"13.8px"}
                  color={"rgba(70, 94, 125, 0.5)"}
                  textAlign={"right"}
                />
                <CommonTextField
                  text={
                    "Vanthuyne, Jolene 200 - 2100 8th Street East, Saskatoon Saskatchewan, Canada S7H 0V1"
                  }
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"14px"}
                  lineHeight={"16.1px"}
                  color={"rgba(2, 19, 73, 1)"}
                  mt={"4px"}
                  textAlign={"right"}
                />
              </div>
            </div>

            <div className="separator"></div>

            <div className="planWrapper">
              <div className="planItemWrapper planItemWrapper1">
                <CommonTextField
                  text={"Company"}
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"12px"}
                  lineHeight={"13.8px"}
                  color={"rgba(70, 94, 125, 0.5)"}
                />
                <CommonTextField
                  text={"AECOM Canada Ltd."}
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"14px"}
                  lineHeight={"16.1px"}
                  color={"rgba(2, 19, 73, 1)"}
                  mt={"4px"}
                />
              </div>

              <div className="planItemWrapper planItemWrapper2">
                <CommonTextField
                  text={"Contact"}
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"12px"}
                  lineHeight={"13.8px"}
                  color={"rgba(70, 94, 125, 0.5)"}
                  textAlign={"right"}
                />
                <CommonTextField
                  text={
                    "Vanthuyne, Jolene 200 - 2100 8th Street East, Saskatoon Saskatchewan, Canada S7H 0V1"
                  }
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"14px"}
                  lineHeight={"16.1px"}
                  color={"rgba(2, 19, 73, 1)"}
                  mt={"4px"}
                  textAlign={"right"}
                />
              </div>
            </div>
          </div>{" "}
        </Col> 
        <Col span={24}>
          <div className="separator"></div>
        </Col>*/}
      </Row>
    </div>
  );
}

export default CommonBidData;
