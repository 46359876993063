// Import the functions you need from the SDKs you need
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import dataHandler from "./services/data-handler";
import { getAllNotificationsRequest } from "./redux/slicers/notification";
import { getProfileDataRequest } from "./redux/slicers/user";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDpTq_14qpib7oQpPO0af_7yCrZ-Jr6dr8",
  authDomain: "central-bids.firebaseapp.com",
  projectId: "central-bids",
  storageBucket: "central-bids.appspot.com",
  messagingSenderId: "260717244687",
  appId: "1:260717244687:web:6aaa50b076b931282b0e7a",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

export const fetchToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BLy5yN5T8rIIifBSre_aSxkUHcXNpkiw1bs1jkvDO2V-t6EbIvtL0TRCkvwNAfCTTnRJLe8bqyOUl7i2jIBu4pQ",
  })
    .then((currentToken) => {
      console.log({ currentToken });
      if (currentToken) {
        return currentToken;
      }
      return "";
    })
    .catch((err) => {
      console.log("errtoken", err);
      return "";
    });
};

onMessage(messaging, (payload) => {
  console.log("message-received", payload);
  const userId = dataHandler.getStore().getState().user.data?.id;

  setTimeout(() => {
    dataHandler.getStore().dispatch(
      getAllNotificationsRequest({
        queryParams: `filters[user][id][$eq]=${userId}&pagination[pageSize]=${500000}&sort=id:desc&populate=user.company_detail`,
      })
    );
    dataHandler
      .getStore()
      .dispatch(getProfileDataRequest({ responseCallback: () => {} }));
  }, 5000);
});

export async function requestPermission() {
  console.log("Requesting permission...");
  const res = await Notification.requestPermission();
  console.log("ssss", res);
  if (res === "granted") {
    return true;
  } else {
    return false;
  }
}
