import React from "react";
import { CommonButton, CommonHeading, CommonTextField } from "../../../common";
import "./styles.scss";
import { Col, Row } from "antd";
import { Images } from "../../../../theme";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const whatYouGet = [
  {
    title: "Access to Verified Bids:",
    content: "Connect with a network of reliable vendors and opportunities."
  },
  {
    title: "Real-Time Notifications:",
    content: "Stay updated with instant alerts on bids and deadlines."
  },
  {
    title: "Advanced Search Filters:",
    content: "Find the most relevant bids quickly and efficiently."
  },
  {
    title: "Comprehensive Support:",
    content: "Get help when you need it with our dedicated customer service."
  }
];

const Pricing = () => {
  return (
    <div className="pricingSectionWrapper">
      <div className="container">
        <CommonHeading
          text={"SIMPLE, TRANSPARENT PRICING"}
          fontFamily={"seventyTwoBold"}
          // fontSize={"55px"}
          // lineHeight={"63.24px"}
          textAlign={"Left"}
          className={"pricingHeading"}
        />
        <CommonTextField
          text={"Clear and Affordable Plans"}
          fontFamily={"seventyTwoSemiBold"}
          fontSize={"24px"}
          mt={20}
          lineHeight={"24px"}
          textAlign={"Left"}
        />

        <CommonTextField
          text={
            "Central Bids offers straightforward pricing plans designed to fit the needs of both small businesses and large organizations. No hidden fees, no surprises – just clear and transparent pricing, what you see is what you pay."
          }
          fontFamily={"seventyTwoRegular"}
          fontSize={"16px"}
          lineHeight={"24px"}
          textAlign={"Left"}
          mt={10}
        />

        {/* <CommonTextField
          text={"Choose Your Plan"}
          fontFamily={"seventyTwoSemiBold"}
          fontSize={"18px"}
          mt={20}
          lineHeight={"24px"}
          textAlign={"Left"}
        />

        <CommonTextField
          text={"Basic Plan:"}
          fontFamily={"seventyTwoSemiBold"}
          fontSize={"16px"}
          lineHeight={"24px"}
          textAlign={"Left"}
          mt={10}
        />

        <CommonTextField
          text={
            "Ideal for small businesses looking to explore bid opportunities. Includes access to a limited number of bids per month."
          }
          fontFamily={"seventyTwoRegular"}
          fontSize={"16px"}
          lineHeight={"24px"}
          textAlign={"Left"}
          mt={3}
        />

        <CommonTextField
          text={"Pro Plan:"}
          fontFamily={"seventyTwoSemiBold"}
          fontSize={"16px"}
          lineHeight={"24px"}
          textAlign={"Left"}
          mt={10}
        />

        <CommonTextField
          text={
            "Perfect for growing companies needing more comprehensive access to bids and advanced features."
          }
          fontFamily={"seventyTwoRegular"}
          fontSize={"16px"}
          lineHeight={"24px"}
          textAlign={"Left"}
          mt={3}
        />

        <CommonTextField
          text={"Enterprise Plan:"}
          fontFamily={"seventyTwoSemiBold"}
          fontSize={"16px"}
          lineHeight={"24px"}
          textAlign={"Left"}
          mt={10}
        />

        <CommonTextField
          text={
            "Tailored for large organizations with extensive bidding requirements and customized support."
          }
          fontFamily={"seventyTwoRegular"}
          fontSize={"16px"}
          lineHeight={"24px"}
          textAlign={"Left"}
          mt={3}
        /> */}

        {/* <CommonTextField
          text={"CENTRAL BIDS"}
          fontFamily={"seventyTwoSemiBold"}
          fontSize={"18px"}
          mt={20}
          lineHeight={"24px"}
          textAlign={"Left"}
        />

        <CommonTextField
          text={
            "Choose us to revolutionize your procurement process, connect with top vendors, and achieve optimal procurement outcomes. Join us today and experience the future of bidding."
          }
          fontFamily={"seventyTwoRegular"}
          fontSize={"16px"}
          lineHeight={"24px"}
          textAlign={"Left"}
          mt={10}
        /> */}

        <Row gutter={[30, 30]} className="planMainWrapper">
          <Col lg={8} xs={24}>
            <div className="planWrapper">
              <CommonTextField
                text={"For individuals"}
                fontFamily={"seventyTwoSemiBold"}
                color={"rgba(70, 94, 125, 1)"}
                fontSize={"18px"}
                lineHeight={"20px"}
              />
              <CommonTextField
                text={"Basic"}
                fontFamily={"seventyTwoBold"}
                fontSize={"24px"}
                lineHeight={"35px"}
                color={"rgba(3, 25, 70, 1)"}
              />
              <CommonTextField
                text={
                  "Ideal for small businesses looking to explore bid opportunities. Includes access to a limited number of bids per month."
                }
                fontFamily={"seventyTwoLight"}
                color={"rgba(70, 94, 125, 1)"}
                fontSize={"18px"}
                lineHeight={"30px"}
                mt={"30px"}
              />
              <p className="price">
                $99 <span>/monthly</span>
              </p>
              <CommonTextField
                text={"What's included"}
                fontFamily={"seventyTwoBold"}
                color={"rgba(70, 94, 125, 1)"}
                fontSize={"18px"}
                lineHeight={"20px"}
                mt={"17px"}
                mb={"24px"}
              />

              <div className="priceItemWrap">
                <Images.CheckIcon />
                <CommonTextField
                  text={"All analytics features"}
                  fontFamily={"seventyTwoRegular"}
                  color={"rgba(3, 25, 70, 1)"}
                  fontSize={"18px"}
                  lineHeight={"20px"}
                />
              </div>
              <div className="priceItemWrap">
                <Images.CheckIcon />
                <CommonTextField
                  text={"Up to 250,000 tracked visits"}
                  fontFamily={"seventyTwoRegular"}
                  color={"rgba(3, 25, 70, 1)"}
                  fontSize={"18px"}
                  lineHeight={"20px"}
                />
              </div>
              <div className="priceItemWrap">
                <Images.CheckIcon />
                <CommonTextField
                  text={"Normal support"}
                  fontFamily={"seventyTwoRegular"}
                  color={"rgba(3, 25, 70, 1)"}
                  fontSize={"18px"}
                  lineHeight={"20px"}
                />
              </div>
              <div className="priceItemWrap">
                <Images.CheckIcon />
                <CommonTextField
                  text={"Up to 3 team members"}
                  fontFamily={"seventyTwoRegular"}
                  color={"rgba(3, 25, 70, 1)"}
                  fontSize={"18px"}
                  lineHeight={"20px"}
                />
              </div>
            </div>
          </Col>

          <Col lg={8} xs={24}>
            <div className="planWrapper popular">
              <CommonTextField
                text={"For startups"}
                fontFamily={"seventyTwoSemiBold"}
                color={"#EFF0F6"}
                fontSize={"18px"}
                lineHeight={"20px"}
              />
              <CommonTextField
                text={"Pro"}
                fontFamily={"seventyTwoBold"}
                fontSize={"24px"}
                lineHeight={"35px"}
                color={"#fff"}
              />
              <div className="popular-plan-tag">Popular</div>
              <CommonTextField
                text={
                  "Perfect for growing companies needing more comprehensive access to bids and advanced features."
                }
                fontFamily={"seventyTwoLight"}
                color={"#EFF0F6"}
                fontSize={"18px"}
                lineHeight={"30px"}
                mt={"30px"}
              />
              <p className="price">
                $199 <span>/monthly</span>
              </p>
              <CommonTextField
                text={"What's included"}
                fontFamily={"seventyTwoBold"}
                color={"#fff"}
                fontSize={"18px"}
                lineHeight={"20px"}
                mt={"17px"}
                mb={"24px"}
              />

              <div className="priceItemWrap">
                <Images.CheckIcon />
                <CommonTextField
                  text={"All analytics features"}
                  fontFamily={"seventyTwoRegular"}
                  color={"#D9DBE9"}
                  fontSize={"18px"}
                  lineHeight={"20px"}
                />
              </div>
              <div className="priceItemWrap">
                <Images.CheckIcon />
                <CommonTextField
                  text={"Up to 1,000,000 tracked visits"}
                  fontFamily={"seventyTwoRegular"}
                  color={"#D9DBE9"}
                  fontSize={"18px"}
                  lineHeight={"20px"}
                />
              </div>
              <div className="priceItemWrap">
                <Images.CheckIcon />
                <CommonTextField
                  text={"Premium support"}
                  fontFamily={"seventyTwoRegular"}
                  color={"#D9DBE9"}
                  fontSize={"18px"}
                  lineHeight={"20px"}
                />
              </div>
              <div className="priceItemWrap">
                <Images.CheckIcon />
                <CommonTextField
                  text={"Up to 10 team members"}
                  fontFamily={"seventyTwoRegular"}
                  color={"#D9DBE9"}
                  fontSize={"18px"}
                  lineHeight={"20px"}
                />
              </div>
            </div>
          </Col>

          <Col lg={8} xs={24}>
            <div className="planWrapper">
              <CommonTextField
                text={"For big companies"}
                fontFamily={"seventyTwoSemiBold"}
                color={"rgba(70, 94, 125, 1)"}
                fontSize={"18px"}
                lineHeight={"20px"}
              />
              <CommonTextField
                text={"Enterprise"}
                fontFamily={"seventyTwoBold"}
                fontSize={"24px"}
                lineHeight={"35px"}
                color={"rgba(3, 25, 70, 1)"}
              />
              <CommonTextField
                text={
                  "Tailored for large organizations with extensive bidding requirements and customized support."
                }
                fontFamily={"seventyTwoLight"}
                color={"rgba(70, 94, 125, 1)"}
                fontSize={"18px"}
                lineHeight={"30px"}
                mt={"30px"}
              />
              <p className="price">
                $399 <span>/monthly</span>
              </p>
              <CommonTextField
                text={"What's included"}
                fontFamily={"seventyTwoBold"}
                color={"rgba(70, 94, 125, 1)"}
                fontSize={"18px"}
                lineHeight={"20px"}
                mt={"17px"}
                mb={"24px"}
              />

              <div className="priceItemWrap">
                <Images.CheckIcon />
                <CommonTextField
                  text={"All analytics features"}
                  fontFamily={"seventyTwoRegular"}
                  color={"rgba(3, 25, 70, 1)"}
                  fontSize={"18px"}
                  lineHeight={"20px"}
                />
              </div>
              <div className="priceItemWrap">
                <Images.CheckIcon />
                <CommonTextField
                  text={"Up to 5,000,000 tracked visits"}
                  fontFamily={"seventyTwoRegular"}
                  color={"rgba(3, 25, 70, 1)"}
                  fontSize={"18px"}
                  lineHeight={"20px"}
                />
              </div>
              <div className="priceItemWrap">
                <Images.CheckIcon />
                <CommonTextField
                  text={"Dedicated support"}
                  fontFamily={"seventyTwoRegular"}
                  color={"rgba(3, 25, 70, 1)"}
                  fontSize={"18px"}
                  lineHeight={"20px"}
                />
              </div>
              <div className="priceItemWrap">
                <Images.CheckIcon />
                <CommonTextField
                  text={"Up to 50 team members"}
                  fontFamily={"seventyTwoRegular"}
                  color={"rgba(3, 25, 70, 1)"}
                  fontSize={"18px"}
                  lineHeight={"20px"}
                />
              </div>
            </div>
          </Col>
        </Row>
        <CommonTextField
          text={"What You Get"}
          fontFamily={"seventyTwoSemiBold"}
          fontSize={"24px"}
          mt={20}
          lineHeight={"24px"}
          textAlign={"Left"}
        />

        {whatYouGet?.map((item) => (
          <>
            <CommonTextField
              text={item?.title}
              fontFamily={"seventyTwoSemiBold"}
              fontSize={"16px"}
              lineHeight={"24px"}
              textAlign={"Left"}
              mt={10}
            />

            <CommonTextField
              text={item?.content}
              fontFamily={"seventyTwoRegular"}
              fontSize={"16px"}
              lineHeight={"24px"}
              textAlign={"Left"}
              mt={3}
            />
          </>
        ))}

        <CommonTextField
          text={"No Hidden Fees"}
          fontFamily={"seventyTwoSemiBold"}
          fontSize={"24px"}
          mt={20}
          lineHeight={"24px"}
          textAlign={"Left"}
        />

        <CommonTextField
          text={
            "Our pricing is simple and transparent. You pay for your plan, and that's it. No extra charges, no hidden costs – just straightforward pricing you can trust."
          }
          fontFamily={"seventyTwoRegular"}
          fontSize={"16px"}
          lineHeight={"24px"}
          textAlign={"Left"}
          mt={10}
        />

        <CommonTextField
          text={
            "Explore our pricing plans and choose the one that best suits your business needs. At Central Bids, we make it easy for you to access opportunities and grow your business with confidence."
          }
          fontFamily={"seventyTwoRegular"}
          fontSize={"16px"}
          lineHeight={"24px"}
          textAlign={"Left"}
          mt={6}
        />
      </div>
    </div>
  );
};

export default Pricing;
