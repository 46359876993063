import { toast } from "react-toastify";
import moment from "moment";
import {
  ALERT_POSITIONS,
  ALERT_THEMES,
  ALERT_TIMEOUT,
  ALERT_TYPES,
  ALLOW_ALPHABETS_REGEX,
  ALLOW_ALPHANUM_REGEX,
  ALLOW_NUMBERS_REGEX,
  CARD_EXPIRY_REGEX,
  CARD_NUMBER_REGEX,
} from "../constants";
import DataHandler from "../services/data-handler";
import {
  cloneDeep,
  filter,
  find,
  includes,
  isEmpty,
  isEqual,
  has,
  findIndex,
  every,
} from "lodash";
import { ERROR_SOMETHING_WENT_WRONG } from "../config/web-service";

// GET CURRENT ACCESS TOKEN FROM USER REDUCER
export const getCurrentAccessToken = () => {
  let token = DataHandler.getStore().getState().user.data.access_token;
  return token;
};

// GET CURRENT REFRESH TOKEN FROM USER REDUCER
export const getCurrentRefreshToken = () => {
  let token = DataHandler.getStore().getState().user.data.refresh_token;
  return token;
};

// CHECK IF URL IS VALID
export const isValidURL = (url) => {
  const re =
    /^(http|https|fttp):\/\/|[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?$/;
  return re.test(url);
};

// CHECK IF URL IS VALID AND WITH HTTPS SCHEME
export const isValidHttpsURL = (url) => {
  const re =
    /^(https|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
  return re.test(url);
};

// CHECK IF PROVIDED TIME FORMAT IS CORRECT
export const isTimeFormat = (time) => {
  const re =
    /^([1-9]|([012][0-9])|(3[01]))\/([0]{0,1}[1-9]|1[012])\/[0-9]{4} [012]{0,1}[0-9]:[0-6][0-9]$/;
  let bol = re.test(time);
  return bol;
};

// CHECK IF EMAIL IS VALID
export const isEmailValid = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.trim());
};

// CHECK IF PASSWORD LENGTH IS VALID
export const isPasswordValid = (password) => {
  let length = 5; // u can change pass length according to your requirement
  return password.length > length;
};

// CHECK IF NAME IS VALID
export const isValidName = (name) => {
  return /^[a-zA-Z ]*$/.test(name);
};

// CAPITALIZE FIRST LETTER OF STRING
export const capitalizeFirstLetter = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return "";
};

// FORMAT DATE ACCORDING TO PROVIDED FORMAT
export const getFormattedDateTime = (date, format) => {
  if (date) return moment(date).format(format);
  return "";
};

// FORMAT DATE ACCORDING TO PROVIDED FORMAT AND RETURN TO DATE OBJECT
export const getDateObjectFromString = (date, format) => {
  if (date) return moment(date, format).toDate();
  return "";
};

// CHECK IF MOBILE NUMBER IS VALID
export const isValidMobileNumber = (str) => {
  if (!str) return false;
  const isnum = /^\d+$/.test(str);

  if (str.length < 15 && str.length > 9 && isnum) {
    return true;
  }
  return false;
};

// CHECK IF MOBILE NUMBER IS OF UK NUMBER FORMAT
export const isValidUKMobileNumber = (str) => {
  if (!str) return false;
  str = str.replace(/ /g, "");
  let mobileNumber = str.replace("+", "");
  if (mobileNumber.charAt(0) === "4" && mobileNumber.charAt(1) === "4") {
    mobileNumber = "0" + mobileNumber.slice(2);
  }
  return /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/.test(
    mobileNumber
  );
};

// CLONE ARRAY
export const cloneDeepItem = (array) => cloneDeep(array);

// FIND OBJECT FROM ARRAY
export const findDataFromArray = (array, mObj) => find(array, mObj);

// CHECK IF ARRAY HAS VALUE
export const isArrayIncludesValue = (array, value) => includes(array, value);

// CHECK IF VALUES ARE EQUAL
export const areValuesEqual = (objA, objB) => isEqual?.(objA, objB);

// CHECK IF VALUE IS EMPTY
export const isEmptyValue = (value) => isEmpty(value);

// EXCLUDE OBJECT FROM ARRAY BY ID
export const excludeIdFromArray = (mArr, id) =>
  filter(mArr, (item) => item.id !== id);

// EXCLUDE VALUE FROM ARRAY
export const excludeValueFromArray = (mArr, value) =>
  filter(mArr, (item) => item !== value);

// FILTER ARRAY BY FUNCTION
export const filterArray = (array, func) => filter(array, func);

// CHECK IF ARRAY DATA CONTACT ID
export const doesArrayContainsParticularId = (array, mId) => {
  if (find(array, { id: mId })) return true;
  else return false;
};

// CHECK IF STRING HAS ONLY WHITE SPACE
export const isOnlyWhiteSpace = (str) => {
  return !str.trim();
};

// CHECK IF OBJECT HAS PROVIDED KEY
export const hasObjectWithKey = (mObj, key) => has(mObj, key);

// CHECK IF VALUES IS ACCORDING TO FUNCTION CONDITION
export const hasEvery = (mArr, _func) => every(mArr, _func);

// GET OBJECT INDEX IN ARRAY BY ID
export const getIndexOfObjFromArrayByID = (mArr, id) =>
  findIndex(mArr, (item) => item.id === id);

// DELETE OBJECT FROM ARRAY BY ID
export const deleteObjectFromArray = (arr, id) => {
  let arrToReturn = arr.filter((a) => a.id !== id);
  return arrToReturn;
};

// GENERATE RANDOM STRING
export const generateGuid = () => {
  const S4 = () =>
    (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
};

// REPLACE MULTIPLE VALUES IN STRING BY OBJECT
export const replaceValInString = (string, replacements = {}) => {
  if (!string) return;
  const pattern = new RegExp(Object.keys(replacements).join("|"), "g");
  return string.replace(pattern, (matched) => replacements[matched]);
};

// GET FIRST LETTER FROM NAME
export const getLetterFromName = (string) => {
  let name = string || "";
  const words = name.split(" ");
  let initials = words[0].charAt(0);
  if (words.length > 1) initials = initials + words[1]?.charAt(0);
  return initials;
};

// ANTD FORM INPUT FIELDS RULES
export const inputFieldRule = ({
  name = "Name",
  requiredMessage,
  isRequired = true,
  isWhiteSpace = true,
  isEmail = false,
  emailError = "email address",
  isType = false,
  type,
  isMax = false,
  max = 60,
  isMin = false,
  min = 6,
  isAlphabetsAndNumber = false,
  isAlphabets = false,
  isCardNumber = false,
  isCardExpiry = false,
  isNumberOnly,
  isMultiSelect = false,
}) => {
  const validationRule = [];
  if (isRequired) {
    validationRule.push({
      required: true,
      message: requiredMessage ? requiredMessage : `${name} is required.`,
    });
  }
  if (isWhiteSpace) {
    validationRule.push({
      whitespace: true,
      message: requiredMessage ? requiredMessage : `${name} is required.`,
    });
  }
  if (isMax) {
    validationRule.push({
      max: max,
      message: `Maximum ${max} characters allowed.`,
    });
  }
  if (isMin) {
    validationRule.push({
      min: min,
      message: `Minimum ${min} characters are required.`,
    });
  }
  if (isEmail) {
    validationRule.push({
      type: "email",
      message: `Invalid ${emailError.toLowerCase()}.`,
    });
  }
  if (isType) {
    validationRule.push({
      type,
      message: `Invalid ${name.toLowerCase()}.`,
    });
  }
  if (isAlphabetsAndNumber) {
    validationRule.push({
      pattern: ALLOW_ALPHANUM_REGEX,
      message: `Invalid ${name.toLowerCase()}.`,
    });
  }
  if (isAlphabets) {
    validationRule.push({
      pattern: ALLOW_ALPHABETS_REGEX,
      message: `Invalid ${name.toLowerCase()}.`,
    });
  }
  if (isCardNumber) {
    validationRule.push({
      pattern: CARD_NUMBER_REGEX,
      message: `Invalid ${name.toLowerCase()}.`,
    });
  }
  if (isCardExpiry) {
    validationRule.push({
      pattern: CARD_EXPIRY_REGEX,
      message: `Invalid ${name.toLowerCase()}.`,
    });
  }
  if (isNumberOnly) {
    validationRule.push({
      pattern: ALLOW_NUMBERS_REGEX,
      message: `Only numeric values are allowed.`,
    });
  }
  if (isMultiSelect) {
    validationRule.push({
      required: true,
      type: "array",
      message: `${name} is required.`,
    });
  }

  return validationRule;
};

// ANTD FORM AUTOCOMPLETE FIELDS RULES
export const autoCompleteFieldRule = ({
  value,
  name = "",
  isRequired = true,
  isWhiteSpace = true,
  isEmail = false,
  isMax = false,
  max = 80,
}) => {
  if (isRequired && !value) {
    return Promise.reject(new Error(`${name} is required`));
  }
  if (typeof value === "string") {
    if (isWhiteSpace && isOnlyWhiteSpace(value)) {
      return Promise.reject(new Error(`${name} is required`));
    }
    if (isMax && value.length > max) {
      return Promise.reject(new Error(`Maximum ${max} characters allowed`));
    }
    if (isEmail && !isEmailValid(value)) {
      return Promise.reject(new Error(`Invalid ${name}`));
    }
  }
  return Promise.resolve();
};

// CUSTOM ALERT
// https://fkhadra.github.io/react-toastify/introduction/
export const toastAlert = (
  message = ERROR_SOMETHING_WENT_WRONG,
  type = ALERT_TYPES.SUCCESS,
  position = ALERT_POSITIONS.TOP_Right,
  duration = ALERT_TIMEOUT,
  closeOnClick = true,
  pauseOnHover = false,
  theme = ALERT_THEMES.LIGHT,
  draggable = false,
  isProgressBar = false
) => {
  toast[type](message, {
    position: position,
    autoClose: duration,
    hideProgressBar: isProgressBar,
    closeOnClick: closeOnClick,
    pauseOnHover: pauseOnHover,
    draggable: draggable,
    theme: theme,
  });
};

export const creditCardValidator = (event, type, max) => {
  // Allow only numbers, backspace, delete, arrow keys, and navigation keys
  const { value } = event.target;

  if (type === "card") {
    const allowedKeys = /[0-9\b]/;
    // Restrict length to 12 characters
    const maxLength = max ? max : 12;

    const otherKeys =
      /ArrowLeft|ArrowRight|ArrowUp|ArrowDown|Delete|Backspace|Tab/;
    if (value.length >= maxLength && !otherKeys.test(event.key)) {
      event.preventDefault();
    }

    // Allow the default behavior for allowed keys
    if (!allowedKeys.test(event.key) && !otherKeys.test(event.key)) {
      event.preventDefault();
    }
    return;
  }
  if (type === "date") {
    const allowedKeys = /[0-9\b]/;

    // Restrict length to 7 characters (MM/YY format)
    const maxLength = 7;

    // Card expiry format validation regex
    const cardExpiryRegex = /^(0?[1-9]|1[0-2])\/(2[2-9]|[3-9][0-9])$/;

    // Navigation and editing keys
    const otherKeys =
      /ArrowLeft|ArrowRight|ArrowUp|ArrowDown|Delete|Backspace|Tab/;

    // Automatically add "/" after 2 digits
    if (
      value.length === 2 &&
      !value.includes("/") &&
      !otherKeys.test(event.key)
    ) {
      event.target.value = value + "/";
    }

    // Automatically remove "/" on backspace if it's the third character
    if (
      value.length === 3 &&
      event.key === "Backspace" &&
      !otherKeys.test(event.key)
    ) {
      event.target.value = value.slice(0, 2);
    }

    // If the length is already 7 characters (MM/YY format) and the pressed key is not an allowed navigation or editing key,
    // prevent further input
    if (value.length >= maxLength && !otherKeys.test(event.key)) {
      event.preventDefault();
    }

    // If the pressed key is not an allowed navigation or editing key and doesn't match the card expiry format regex,
    // prevent its default behavior
    if (
      !allowedKeys.test(event.key) &&
      !cardExpiryRegex.test(value + event.key) &&
      !otherKeys.test(event.key)
    ) {
      event.preventDefault();
    }
  }
};
export const imageValidation = (file, setError) => {
  let res = true;
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/jpg" ||
    file.type === "image/png";

  if (!isJpgOrPng) {
    setError("You can only upload JPG/PNG file!");
    res = false;
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    res = false;
    setError("Image must smaller than 2MB!");
  }

  return res;
};
export const maskCreditCardNumber = (cardNumber) => {
  // Remove non-digit characters
  const digitsOnly = cardNumber.replace(/\D/g, "");

  // Get the first four digits
  const firstFour = digitsOnly.slice(0, 4);

  // Mask all digits except the first four
  const maskedDigits = "*".repeat(digitsOnly.length - 4);

  // Combine first four and masked middle
  let formattedNumber = firstFour + " " + maskedDigits;

  for (let i = 5; i < formattedNumber.length; i += 5) {
    formattedNumber =
      formattedNumber.slice(0, i) + " " + formattedNumber.slice(i);
  }

  return formattedNumber;
};
export const maskEmail = (email) => {
  // Split the email address into local part and domain part
  const [localPart, domainPart] = email.split("@");

  // Get the length of the local part
  const localPartLength = localPart.length;

  // Calculate the number of characters to be masked
  const maskLength = Math.max(Math.floor(localPartLength / 2) - 1, 0);

  // Generate the masked local part
  const maskedLocalPart =
    localPart.substring(0, maskLength) +
    "*".repeat(localPartLength - maskLength);

  // Combine the masked local part and domain part
  return maskedLocalPart + "@" + domainPart;
};

export const getBidsByUserQuery = (id) => {
  return `filters[user][id][$eq]=${id}&`;
};
export const getBidsByUserObjectQuery = (id) => {
  return { ["filters[user][id][$eq]"]: id };
};

export const getBidsByTitleQuery = (title) => {
  return `filters[title][$containsi]=${title}&`;
};

export const getBidsByTitleObjectQuery = (title) => {
  return title ? { ["filters[title][$containsi]"]: title } : {};
};

export const getBidsCompletedQuery = (isBuyer = false, sellerId) => {
  if (isBuyer) return `filters[status][$eq]=Awarded&`;

  return `filters[status][$eq]=Awarded&filters[confirm_bids][users_permissions_user][id][$in]=${sellerId}&`;
};

export const getHistoryBidsQuery = (seller = false) => {
  return `filters[$or][0][status][$eq]=Awarded&filters[$or][1][status][$eq]=Terminated${
    seller ? "&filters[$or][2][bid_submissions][is_withdraw][$eq]=true" : ""
  }&`;
};

export const getBidsByStatusQuery = (status = "Open") => {
  return `filters[status][$eq]=${status}`;
};

export const getActiveBidsQuery = (isBuyer, id) => {
  if (isBuyer)
    return `filters[$or][0][status][$eq]=Open&filters[$or][1][status][$eq]=Closed&filters[confirm_bids][id][$null]=true&`;

  return `filters[$and][0][status][$ne]=Terminated&filters[$and][1][status][$ne]=Awarded&filters[bid_submissions][is_withdraw][$eq]=false&filters[$or][0][confirm_bids][users_permissions_user][id][$notIn]=${id}&filters[$or][1][confirm_bids][id][$null]=${true}&`;
};

export const getBidsNotEqCompletedQuery = () => {
  return `filters[status][$ne]=Closed`;
};

export const getBidsWithSubmissionsQuery = () => {
  return `filters[bid_submissions][inquiry][id][$notNull]=true`;
};

export const getBidsOngoingQuery = (isBuyer = false, userId = null) => {
  if (!isBuyer) {
    return `filters[confirm_bids][users_permissions_user][id][$eq]=${userId}&filters[$or][0][status][$eq]=Open&filters[$or][1][status][$eq]=Closed&filters[bid_submissions][is_withdraw][$eq]=false&`;
  }
  return `filters[confirm_bids][id][$notNull]=true&filters[$or][0][status][$eq]=Open&filters[$or][1][status][$eq]=Closed&filters[user][id][$eq]=${userId}&`;
};

export const getBidsBySubmittedUserQuery = (id) => {
  return `filters[bid_submissions][users_permissions_user][id][$in]=${id}&`;
};

export const getBidsBySubmittedUserObjectQuery = (id) => {
  return { ["filters[bid_submissions][users_permissions_user][id][$in]"]: id };
};

export const getBidsByNotSubmittedUserQuery = (id) => {
  return `filters[bid_submissions][users_permissions_user][id][$notIn]=${id}`;
};

export const getPageSizeQuery = (pagesize = 10) => {
  return `pagination[pageSize]=${pagesize}&sort=id:desc&`;
};

export const getPagesSizeQuery = (pagesize = 10) => {
  return {
    ["pagination[pageSize]"]: pagesize,
    sort: "id:desc",
  };
};

export const getPaginationQuery = (page) => {
  return `pagination[page]=${page}&`;
};

export const getPaginationQueryObj = (page) => {
  return { "pagination[page]": page };
};

export const getPageSizeQueryObj = (pagesize = 10) => {
  return { ["pagination[pageSize]"]: pagesize };
};

export function downloadBlob(blob, name = "file.txt") {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement("a");

  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = name;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  // Remove link from body
  document.body.removeChild(link);
}

export function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export const downloadFileFromUrl = async (_record) => {
  console.log({ _record });
  const response = await fetch(_record?.url);
  const blob = await response.blob();
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.target = "_blank";
  link.download = _record?.name ?? "my_downloaded_file.pdf";
  link.click();
};

export const getSubmittedBidQuery = (id, userId) => {
  return {
    "filters[bid][id][$eq]": id,
    ...(userId ? { "filters[users_permissions_user][id][$eq]": userId } : {}),
    populate: "*",
  };
};

export const trimAndOperator = (str) => {
  return str.replace(/^&+|&+$/gm, "");
};

export function timeSince(date2) {
  const now = moment();
  const pastDate = moment(date2);

  const diff = now.diff(pastDate); // Get difference in milliseconds

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days < 1) {
    if (hours < 1) {
      if (minutes < 1) {
        return `${seconds} sec ago`;
      } else {
        return `${minutes} min ago`;
      }
    } else {
      return `${hours} ${days > 1 ? "hrs" : "hour"} ago`;
    }
  } else {
    return `${days} ${days > 1 ? "days" : "day"} ago`;
  }
}
